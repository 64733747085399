import { loadModules } from 'esri-loader';
import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { Trip } from 'app/model/trip';
import { MapUtils } from 'app/utility/MapUtils';
import { LoggerService } from 'app/service/logger.service';

@Component({
  selector: 'app-smiles-esri-map',
  templateUrl: './smiles-esri-map.component.html',
  styleUrls: ['./smiles-esri-map.component.css']
})
export class SmilesEsriMapComponent implements OnInit {
  @Input() vehicleType: string;
  @Input() trip: Trip;
  @Input() tripIndex: number;
  @Input() mapLegend: string[][];

  // signal to day component that map has failed to load or should not display
  @Output() mapError: EventEmitter<string> = new EventEmitter();
  @ViewChild('mapViewNode', { static: false }) private mapViewEl: ElementRef;

  mapView: any;
  esriMapLoaded = true;
  selectedView = false;
  private map: any;

  // Dependencies acquired through ESRI's loader (Dojo):
  private Map = null;
  private MapView = null;
  private Graphic = null;
  private Home = null;
  private Extent = null;
  private dojoLoaderPromise: Promise<any> = null;

  constructor(
    private mapUtil: MapUtils,
    private logger: LoggerService
  ) {
    this.dojoLoaderPromise = this.requestDojoDependencies();
  }

  ngOnInit(): void {
    if (!this.trip?.coordinates || this.trip.coordinates.length < 1) {
      this.esriMapLoaded = false;
      this.mapError.emit('no trip data');
      return;
    }
    this.dojoLoaderPromise = this.awaitDojoDependencies().then(() => {
      this.dojoLoaderPromise = null;
      this.buildMap();
      this.buildMapView();
      this.mapView.when(() => {
        this.drawMapDecorations();
      }).catch((error) => {
        this.logger.error(error);
        this.esriMapLoaded = false;
        this.mapError.emit('the map failed to load');
      });
    }).catch((error) => {
      this.logger.error(error);
      this.esriMapLoaded = false;
      this.mapError.emit('dependencies required for the map failed to load');
    });
  }

  awaitDojoDependencies(): Promise<any> {
    if (this.dojoLoaderPromise) {
      return this.dojoLoaderPromise;
    } else {
      return Promise.resolve(null);
    }
  }

  private buildMap(): void {
    this.map = new this.Map(this.mapUtil.getMapConfig());
  }

  private buildMapView(): void {
    this.mapView = new this.MapView(this.mapUtil.getMapViewConfig(this.mapViewEl, this.map));
    const homeBtn = new this.Home({
      view: this.mapView
    });
    this.mapView.ui.add(homeBtn, 'top-left');
    this.mapView.extent = new this.Extent(this.mapUtil.getMapViewGeometry(this.trip));
  }

  private drawMapDecorations = (): void => {
    this.mapUtil.getLineSymbol(this.trip.coordinates, '#1eb4fa').subscribe((polylineGraphic) => {
      this.mapView.graphics.add(polylineGraphic);
    });

    this.mapUtil.getPointSymbol(this.trip.coordinates[0], 'start').subscribe((pointGraphic) => {
      this.mapView.graphics.add(pointGraphic);
    });

    this.mapUtil.getPointSymbol(this.trip.coordinates[this.trip.coordinates.length - 1], 'end').subscribe((pointGraphic) => {
      this.mapView.graphics.add(pointGraphic);
    });

    for (const legend of this.mapLegend) {
      if (legend[0] === 'night' && this.trip.night > 0) {
        const linePath = this.mapUtil.getNightLine(this.trip);
        for (const line of linePath) {
          this.mapUtil.getLineSymbol(line, '#000000').subscribe((polylineGraphic) => {
            this.mapView.graphics.add(polylineGraphic);
          });
        }
      }
      if (legend[0] === 'idle' && this.trip.idle > 0) {
        const idleCordList = this.mapUtil.getIdleList(this.trip.speeds);
        for (const cord of idleCordList) {
          this.mapUtil.getPointSymbol(this.trip.coordinates[cord], legend[0]).subscribe((pointGraphic) => {
            this.mapView.graphics.add(pointGraphic);
          });
        }
      }
      if (this.trip[legend[0]] && this.trip[legend[0]].length > 0) {
        for (const cord of this.trip[legend[0]]) {
          this.mapUtil.getPointSymbol(this.trip.coordinates[cord], legend[0]).subscribe((pointGraphic) => {
            this.mapView.graphics.add(pointGraphic);
          });
        }
      }
    }
    this.esriMapLoaded = true;
  };

  private requestDojoDependencies(): Promise<void> {
    return loadModules([
      'esri/Map',
      'esri/views/MapView',
      'esri/Graphic',
      'esri/widgets/Home',
      'esri/geometry/Extent',
      'dojo/domReady!'
    ]).then(([Map, MapView, Graphic, Home, Extent]) => {
      this.Map = Map;
      this.MapView = MapView;
      this.Graphic = Graphic;
      this.Home = Home;
      this.Extent = Extent;
    });
  }

  get tripHasCoordinates(): boolean {
    if (!this.trip) {
      return false;
    }
    if (!this.trip.coordinates) {
      return false;
    }
    return this.trip.coordinates.length >= 1;
  }
}
