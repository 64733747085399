import { Device } from 'app/model/device';
import { Trip } from './trip';
import { TimeFrame } from 'app/interfaces/timeframe.interface';
import { Timeline } from './timeline';
import { ContactPreferences } from 'app/model/contact-preferences';
import { VehicleProgram } from 'app/model/vehicle-program';
import { RatedMileage } from './rated-mileage';
import { EstimatedPaymentDetails } from './estimatedPaymentDetails';
import { Moment } from 'moment';
import * as moment from 'moment';
import { VehicleResponse } from 'app/service/vehicle.service';
import { TimeFrameUnit } from 'app/enum/timeframe';
import { Discount } from './discount';
import { SreSummary } from 'app/service/sre-summary.service';
import { MobileProgram } from 'app/model/mobileprogram';
import { TelematicsPaymentDetails } from './telematicsPaymentDetails';
import { environment } from 'environments/environment';

export class Vehicle {
    type: string;
    name: string;
    make: string;
    year: string;
    model: string;
    discountPercent: number;
    milesDriven: number;
    device: Device;
    verifiedStatus: boolean;
    estimate: number;
    vid: string;
    vin: string;
    program: string;
    monthlyEstimate: number;
    baseRate: number;
    mileageRate: number;
    totalMileageRate: number;
    trips: Trip[];
    deviceStatusTimeline: Timeline;
    scoringModel: string;
    programType: string;
    idleTimeMinutes: number;
    nightTimeMinutes: number;
    discountType: string;
    hardBrakingEvents: number;
    discountTrend: string;
    contactPreferences: ContactPreferences;
    vehicleProgram: VehicleProgram;
    fortyFiveDays: boolean;
    annualMileage: number;
    typicalAnnualMileage: number;
    enrollStatus;
    periodStart: Date;
    periodEnd: Date;
    firstLogin: boolean;
    participationDiscount: string;
    scoreTrend: string;
    discounts: Array<Discount>;
    maxDiscount: string;
    vendorIdCode: string;
    enrolledInSRM: boolean;
    mobilePolicy: string;
    smEnrollDate: Date;
    enrolledMidTerm: boolean;
    ratedMileage: RatedMileage[];
    ratedMileageTotal: RatedMileage[];
    policyNum: string;
    paymentDetails: TelematicsPaymentDetails[];
    termBreakpoints: Moment[];
    state: string;
    ratedMileageDataUnavailable: boolean;
    vehicleDataUnavailable: boolean;
    summary: SreSummary;
    mobileProgram: MobileProgram; // If set, the rest of the object is a dummy.
    discount: Discount;
    vehicleId: any;
    acceptanceStatus: string;
    vehicleEnrollments: any;

    constructor(data?: VehicleResponse) {
        this.trips = [];
        this.paymentDetails = [];
        this.ratedMileage = [];
        this.ratedMileageTotal = [];
        this.termBreakpoints = [];
        if (data !== undefined) {
            this.vin = data.vin;
            this.device = data.device;
            this.deviceStatusTimeline = data.deviceStatusTimeline;
            this.contactPreferences = data.preferences;
            this.vid = data.vid;
            this.firstLogin = data.firstLogin;
            this.scoreTrend = data.scoreTrend;
            this.discounts = data.discounts;
            this.discount = data.discount;
            this.participationDiscount = data.participationDiscount;
            this.maxDiscount = data.maxDiscount;
            this.vendorIdCode = data.vendorIdCode;
            this.scoringModel = data.scoringModel;
            this.make = data.make;
            this.year = data.year;
            this.model = data.model;
            this.name = data.nickname;
            this.policyNum = data.policyNumber;
            this.enrolledInSRM = data.isEnrolledInSRM;
            this.mobilePolicy = data.mobilePolicyNumber;
            this.state = data.state;

            if (data.discount) {
                this.discountPercent = data.discount.amount;
                this.discountType = data.discount.discountType;
            }
            if (data.scoringModel === 'SM1' || data.scoringModel === 'SM2') {
                this.type = 'smiles';
            } else {
                this.type = 'sride';
                this.programType = data.programType;
            }
        }
    }

    // eslint-disable-next-line max-params
    buildVehicle(
        type?: string,
        name?: string,
        make?: string,
        discountPercent?: number,
        milesDriven?: number,
        device?: Device,
        verifiedStatus?: boolean,
        estimate?: number,
        vid?: string,
        monthlyEstimate?: number,
        baseRate?: number,
        mileageRate?: number,
        totalMileageRate?: number,
        trips?: Trip[],
        deviceStatus?: Timeline,
        discountType?: string,
        hardBrakingEvents?: number,
        nightTimeMinutes?: number,
        discountTrend?: string,
        vehicleProgram?: VehicleProgram,
        fortyFiveDays?: boolean,
        policyNum?: string,
        paymentDetails?: TelematicsPaymentDetails[],
        firstLogin?: boolean,
        year?: string,
        vin?: string,
        state?: string

    ): void {
        this.type = type;
        this.name = name;
        this.make = make;
        this.discountPercent = discountPercent;
        this.milesDriven = milesDriven;
        this.device = device;
        this.verifiedStatus = verifiedStatus;
        this.estimate = estimate;
        this.vid = vid;
        this.monthlyEstimate = monthlyEstimate;
        this.baseRate = baseRate;
        this.mileageRate = mileageRate;
        this.totalMileageRate = totalMileageRate;
        this.trips = trips;
        this.deviceStatusTimeline = deviceStatus;
        this.discountType = discountType;
        this.hardBrakingEvents = hardBrakingEvents;
        this.nightTimeMinutes = nightTimeMinutes;
        this.discountTrend = discountTrend;
        this.vehicleProgram = vehicleProgram;
        this.fortyFiveDays = fortyFiveDays;
        this.policyNum = policyNum;
        this.paymentDetails = paymentDetails;
        this.firstLogin = firstLogin;
        this.year = year;
        this.vin = vin;
        this.state = state;
    }

    getTripsInTimeframe(tf: TimeFrame): RatedMileage[] {
        if (this.ratedMileageTotal) {
            return this.ratedMileageTotal.filter((rateMile) =>
                tf.start.isSameOrBefore(rateMile.ratedMileageDate, 'day') && tf.end.isSameOrAfter(rateMile.ratedMileageDate, 'day'));
        } else {
            return [];
        }
    }

    getTotalTripsInTimeframe(tf: TimeFrame): RatedMileage[] {
        if (this.ratedMileage) {
            return this.ratedMileage.filter((rateMile) =>
                tf.start.isSameOrBefore(rateMile.ratedMileageDate, 'day') && tf.end.isSameOrAfter(rateMile.ratedMileageDate, 'day'));
        } else {
            return [];
        }
    }

    getActualTripsInTimeframe(tf: TimeFrame): Trip[] {
        if (this.trips) {
            return this.trips.filter((trip) =>
                tf.start.isSameOrBefore(trip.startTS, 'minute') && tf.end.isAfter(trip.endTS, 'minute'));
        } else {
            return [];
        }
    }

    calculateTermBreakpoints(): void {
        if (this.periodStart && this.periodEnd) {
            const startMoment = moment(this.periodStart).startOf('day');
            this.termBreakpoints.push(startMoment);
            let breakpoint = startMoment;
            let monthsFromStart = 1;
            while (breakpoint.isBefore(this.periodEnd, 'day')) {
                breakpoint = startMoment.clone().add(monthsFromStart, 'month');
                this.termBreakpoints.push(breakpoint);
                monthsFromStart++;
            }
        }
    }
    calculateTermBreakpointsAdv(start: Date, end: Date): Moment[] {
        if (start && end) {
            const startMoment = moment(start).startOf('day');
            const termBreak: Moment[] = [];
            termBreak.push(startMoment);
            let breakpoint = startMoment;
            let monthsFromStart = 1;
            while (breakpoint.isBefore(end, 'day')) {
                breakpoint = startMoment.clone().add(monthsFromStart, 'month');
                termBreak.push(breakpoint);
                monthsFromStart++;
            }

            return termBreak;
        }
    }

    calcMilesDrivenInTimeframe(tf: TimeFrame): any {
        const allTrips = this.getTripsInTimeframe(tf);
        let milesFromAllTrips = [];
        if (allTrips) {
            milesFromAllTrips = allTrips.map((ratedMiles) =>
                ratedMiles.ratedMileage || 0);
        }
        if (milesFromAllTrips.length > 0) {
            return milesFromAllTrips.reduce((accumulator, next) =>
                accumulator + next);
        } else {
            return 0;
        }
    }

    getTermLimitInTimeframe(tf: TimeFrame): Moment {
        let result = null;
        for (const limit of this.termBreakpoints) {
            if (limit.isBetween(tf.start, tf.end)) {
                result = limit;
            }
        }
        return result;
    }

    // Finds the correct month term limit to display given a date (useful for the default date range for monthly view)
    getTermLimitsContainingMoment(m: Moment = moment(environment.futureDate)): TimeFrame {
        let result = null;
        let i = 0;
        const limit = this.termBreakpoints.length - 1;
        while (i < limit) {
            if (m.isBetween(this.termBreakpoints[i].clone().subtract(1, 'day'), this.termBreakpoints[i + 1])) {
                result = <TimeFrame>{
                    start: this.termBreakpoints[i].clone(),
                    end: this.termBreakpoints[i + 1].clone().endOf('day'),
                    unit: TimeFrameUnit.MONTH
                };
                if (i + 1 < limit) {
                    result.end.subtract(1, 'day').endOf('day');
                }
                return result;
            }
            i++;
        }
        return <TimeFrame>{
            start: moment(this.termBreakpoints[limit - 1]),
            end: moment(this.termBreakpoints[limit]).subtract(1, 'day').endOf('day'),
            unit: TimeFrameUnit.MONTH
        };
    }

    calcTotalRatedMileage(): void {
        this.milesDriven = this.ratedMileage
            .map((mileageDay) => mileageDay.ratedMileage || 0)
            .reduce((acc = 0, next) => acc + next, 0);
    }

    isFortyFiveDays(): boolean {
        if (this.discounts && this.discounts.length === 0) {
            return false;
        }
        if (!(/final/i).test(this.discountType)) {
            return false;
        }
        for (const discount of this.discounts) {
            if ((/final/i).test(discount.discountType)) {
                const days = 45;
                return moment(environment.futureDate).subtract(days, 'day').isAfter(discount.date);
            }
        }
        return false;
    }

    isDiscountInAnyPartOfRemoval(): boolean {
        return /removed/i.test(this.discountType);
    }
}
