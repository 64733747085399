import { Incident } from 'app/model/incident';
import * as moment from 'moment';
import { IncidentType } from 'app/enum/incidentType';
import { Injectable } from '@angular/core';

// Captures both trip data and trip speed data (the values from both service calls map to this object)
@Injectable()
export class Trip {
    miles: number;
    totalMiles: number;
    speeds: number[];
    coordinates: number[][];
    nightIncident: number;
    night: number;
    startTS: Date;
    endTS: Date;
    minutesDriven: number;
    idleCount: number;
    idle: number;
    hardBrakeCount: number;
    fastAccelerationCount: number;
    brakingAcceleration: number;
    stopsCount: number;
    acceleration: number[];
    braking: number[];
    stop: number[];
    incidents: Incident[];
    tripNb: string;
    tripsCount: number;
    idleTimeRatio: number;
    err = false;

    constructor() { }

    setIncidents(incidents: object[] = []): void {
        this.incidents = [];
        const startAsUnix = moment(this.startTS).unix();
        const allEvents = incidents;

        for (const event of allEvents) {
            const eventTimestamp = startAsUnix + event['positionInSpeedGraph'];
            this.incidents.push(
                new Incident(event['type'], moment.unix(eventTimestamp).toDate())
            );
        }
        this.addAccelEvents();
        this.addBrakingEvents();
        this.addNighttimeIncidents();

        this.incidents.sort((a, b) =>
            // eslint-disable-next-line no-extra-parens
            (a.timestamp < b.timestamp ? -1 : 1));
    }

    addAccelEvents(): void {
        const startTime = moment(this.startTS).unix();
        if (this.acceleration && this.acceleration.length > 0) {
            for (let accelEvent of this.acceleration) {
                accelEvent = startTime + accelEvent;
                this.incidents.push(new Incident('acceleration', moment.unix(accelEvent).toDate()));
            }
        }
    }

    addBrakingEvents(): void {
        const startTime = moment(this.startTS).unix();
        if (this.braking && this.braking.length > 0) {
            for (let brakeEvent of this.braking) {
                brakeEvent = startTime + brakeEvent;
                this.incidents.push(new Incident('braking', moment.unix(brakeEvent).toDate()));
            }
        }
    }

    addNighttimeIncidents(): void {
        this.nightIncident = 0;
        const start = moment(this.startTS);
        const end = moment(this.endTS);

        // Nighttime driving start event
        let dayDifference = end.dayOfYear() - start.dayOfYear();
        while (dayDifference > 0) {
            const midnightForDay = start.clone().add(dayDifference, 'days').startOf('day');
            this.incidents.push(new Incident('nighttimeStart', midnightForDay.toDate()));
            dayDifference--;
        }

        // Nighttime driving end event
        dayDifference = end.dayOfYear() - start.dayOfYear();
        while (dayDifference >= 0) {
            const fiveAm = start.clone().set({ hour: 5, minute: 0, second: 0, millisecond: 0 });
            if (fiveAm.isBetween(this.startTS, end)) {
                this.incidents.push(new Incident('nighttimeEnd', fiveAm.toDate()));
            }
            this.nightIncident++;
            start.add(1, 'day');
            dayDifference--;
        }
        let foundIncident = false;
        let i = 0;
        let nightStart = null;
        while (i < this.incidents.length) {
            if (this.incidents[i].eventType === IncidentType.NIGHT_DRIVING_START) {
                foundIncident = true;
                nightStart = moment(this.incidents[i].timestamp);
            } else if (nightStart !== null && this.incidents[i].eventType === IncidentType.NIGHT_DRIVING_END) {
                this.nightIncident += moment(this.incidents[i].timestamp).diff(nightStart, 'minutes');
                foundIncident = false;
                nightStart = null;
            }
            i++;
        }
        if (foundIncident && nightStart !== null) {
            this.nightIncident += moment(this.endTS).diff(nightStart, 'minutes');
        }
    }
}
