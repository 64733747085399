import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BrandingService } from 'app/service/branding.service';
import { OAuthEmitterService } from '@nationwide/angular-oauth-module';
import { Subscription } from 'rxjs';
import { LoggerService } from 'app/service/logger.service';

@Component({
  selector: 'app-token-capture',
  templateUrl: './token-capture.component.html',
  styleUrls: ['./token-capture.component.css']
})
export class TokenCaptureComponent implements OnInit, OnDestroy {
  canLoadApplication = false;
  oauthSubscription: Subscription;

  // eslint-disable-next-line max-params
  constructor(
    public route: ActivatedRoute,
    public router: Router,
    private oauthEmitterService: OAuthEmitterService,
    public _branding: BrandingService,
    private logger: LoggerService
  ) { }

  ngOnInit(): void {
    BrandingService.setBranding(this.route.snapshot.data.branding);
    this.oauthSubscription = this.oauthEmitterService.oauthEmitter.subscribe((event) => {
      if (event.type === 'OAuthSuccess' || event.type === 'OAuthNotNecessary') {
        // LOGIC HERE TO HANDLE REDIRECTING BASED ON DESTINATION PAGE
        this.router.navigateByUrl('programSelect');
        this.canLoadApplication = true;
      } else if (event.type === 'OAuthFailure') {
        this.canLoadApplication = true;
        this.logger.error('failure in token capture component');
        this._branding.logoutWithBranding('', 'error');
      }
    });
  }

  ngOnDestroy(): void {
    if (this.oauthSubscription && typeof this.oauthSubscription.unsubscribe !== 'undefined') {
      this.oauthSubscription.unsubscribe();
    }
  }
}
