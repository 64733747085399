import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { UserService } from 'app/service/user.service';
import { Vehicle } from 'app/model/vehicle';
import { Observable, throwError } from 'rxjs';
import { VehicleService } from 'app/service/vehicle.service';
import { PersonalLinesPolicyService } from 'app/service/personal-lines-policy.service';
import { BrandingService } from 'app/service/branding.service';
import { SreSummaryService } from '../service/sre-summary.service';
import { map, mergeMap } from 'rxjs/operators';
import { LoggerService } from 'app/service/logger.service';

@Injectable()
export class SingleVehicleResolver implements Resolve<Vehicle> {
  // eslint-disable-next-line max-params
  constructor(
    private user: UserService,
    private vehicle: VehicleService,
    private router: Router,
    public policy: PersonalLinesPolicyService,
    public summary: SreSummaryService,
    private logger: LoggerService
  ) { }

  resolve(route: ActivatedRouteSnapshot): Observable<Vehicle> {
    const branding = BrandingService.getBranding() === 'smartmiles' ? 'smiles' : 'sride';
    if (route.parent.url[0] && branding !== route.parent.url[0].path) {
      this.router.navigateByUrl(`/not-found`);
      return throwError('app branding mismatch');
    }
    const vin = route.params['vin'] || this.user.selectedVin;
    this.user.selectedVin = vin;
    if (!vin) {
      this.router.navigateByUrl(`/programSelect`);
      return throwError('vin not provided');
    }

    return this.vehicle.getVehicleByVin(vin, this.user.programType).pipe(mergeMap((response) => {
      if (response.vin === undefined) {
        this.router.navigateByUrl(`/not-found`);
        return throwError('no vin in returned vehicle');
      }
      if (response.scoringModel === 'SM1' || response.scoringModel === 'SM2') {
        // TODO handle a asyn call
        this.logger.error('SmartMiles vehicle not supported by SingleVehicleResolver');
        this.router.navigateByUrl('nw/systemError');
        return throwError('unsupported scoring model');
      }
      const result = new Vehicle(response);

      return this.summary.getAllSummariesForVehicle(
        response.vid, response.scoringModel, response.state, response.deviceStatusTimeline
      ).pipe(map((summary) => {
        result.summary = summary;
        this.user.loggedIn = true;
        return result;
      }));
    }));
  }
}
