import { Component, OnInit } from '@angular/core';
import { UserService } from 'app/service/user.service';
import { BrandingService } from 'app/service/branding.service';

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit {
    constructor(
        private _user: UserService,
        public _branding: BrandingService) { }

    ngOnInit(): void {
        this._user.loggedIn = false;
    }

    logout(): void {
        this._branding.logoutWithBranding();
    }
}
