import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'roundUpTo1' })
export class RoundToOnePipe implements PipeTransform {
  transform(value: number): number {
    if (value > 0 && value < 1) {
      return 1;
    } else {
      return Math.round(value);
    }
  }
}
