import { Component, OnInit, Input, OnDestroy, OnChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EbiEventService } from 'app/service/ebi-event.service';
import { TimeframeService } from 'app/service/timeframe.service';
import { UserService } from 'app/service/user.service';
import { Subscription } from 'rxjs';
import { TimeFrame } from 'app/interfaces/timeframe.interface';
import { RatedMileageService } from 'app/service/rated-mileage.service';
import { PersonalLinesPolicyService } from 'app/service/personal-lines-policy.service';
import { TimeFrameUnit } from '../../enum/timeframe';
import { Vehicle } from '../../model/vehicle';
import * as moment from 'moment';
import { LoggerService } from 'app/service/logger.service';
import { DollarsPipe } from 'app/pipes/dollars.pipe';
import { CentsPipe } from 'app/pipes/cents.pipe';
import { NavigationService } from 'app/service/navigation.service';

/**
 * FactorCircleComponent is suitable for both SmartMiles and SmartRide.
 * It displays a number in a red, green, or yellow circle with some attendant text.
 * Many properties can be inferred from others if you leave them null.
 * Read-only properties:
 *   trendDelta, number
 *   trendDirection: "below", "above", "equal to"
 *   trendDirectionAbsolute: "down", "up", "equal", to compose arrow image name
 *   trendColorClass: "red", "green", "yellow"
 */

@Component({
    selector: 'app-factor-circle',
    templateUrl: './factor-circle.component.html',
    styleUrls: ['./factor-circle.component.css']
})
export class FactorCircleComponent implements OnInit, OnDestroy, OnChanges {
    @Input() periodTitle: string; // eg "Monthly"
    @Input() period: string; // eg "monthly"
    @Input() isWeeklyDetailsPage: boolean;
    @Input() isMonthlySummaryPage: boolean;
    @Input() factorLong: string; // eg "miles driven"
    @Input() factor: string; // eg "miles", for general use
    @Input() unit: string; // eg "miles", as unit displayed with milesDriven
    @Input() legend: boolean; // Whether to show legend
    @Input() vehicleVin: string;
    @Input() routeToMonth = false;
    @Input() totalPage: boolean;
    @Input() totalMiles: number;
    @Input() term;
    @Input() gracePeriod: boolean;
    @Input() factorCircleVisible: boolean;

    // SM2
    @Input() buttonText: string; // eg "miles driven"
    @Input() nonWeightedCostPerMile;
    @Input() nonWeightedCostPerMileAsString;
    @Input() sm2CardType: string;
    @Input() totalMileage;
    @Input() sm2MilesDriven: number;
    @Input() sm2PreviousMilesDriven: number;
    @Input() nonWeightedCostPerMileFormat;
    @Input() currentMonth;
    @Input() previousMonthNonWeightedCostPerMileAsString;
    @Input() previousMonthNonWeightedCostPerMileFormat;
    @Input() previousMonthNonWeightedCostPerMile;
    @Input() mileageDifference;
    @Input() estimatedMiles;
    @Input() showHelpTooltip;
    @Input() currentTermMileage;

    timeframe: TimeFrame;
    milesDriven = null;
    estimate = 0;
    estimateDiff = 0;
    highest: number;
    timeframeSubscription: Subscription = null;
    termFactor = 0;
    weeklyTimeFrameStart: moment.Moment;
    weeklyTimeFrameEnd: any;
    termStartDate = moment(this.user.getTermStart()).format('MMM DD');
    termEndDate = moment(this.user.getTermEnd()).format('MMM DD');
    url: string;
    monthlySummaryScreen: boolean;
    vehicle: Vehicle;
    currentTermTotalMileage: number;

    // eslint-disable-next-line max-params
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public ebiEvents: EbiEventService,
        public timeframeService: TimeframeService,
        public user: UserService,
        public mileage: RatedMileageService,
        public policy: PersonalLinesPolicyService,
        private logger: LoggerService,
        public nav: NavigationService
    ) {
        this.term = 6;
        this.highest = 8000;
    }

    ngOnInit(): void {
        this.estimate = Math.round(this.estimate);
        this.vehicle = this.route.snapshot.data.vehicle;
        this.setupSubscriptions();
    }

    ngOnChanges(): void {
        // Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
        // Add '${implements OnChanges}' to the class.
        this.estimate = Math.round(this.estimate);
        this.setupSubscriptions();
        if (this.currentMonth && this.sm2CardType === 'total-miles-this-term') {
            this.currentTermTotalMileage = Math.round(this.currentTermMileage + this.milesDriven);
        }
    }

    ngOnDestroy(): void {
        if (this.timeframeSubscription) {
            this.timeframeSubscription.unsubscribe();
        }
    }

    setupSubscriptions = (): void => {
        this.timeframeSubscription = this.timeframeService.timeframeChange$.subscribe((tf) => {
            this.timeframe = tf;
            if (!tf.start || !tf.end) {
                this.buildMonthTimeFrame();
                this.buildWeekTimeFrame();
            }
            this.setMonthTimeFrame(tf, this.route.snapshot.data.vehicle);
        });
        this.policy.getPolicyData(this.user.getPolicyNumber()).subscribe((policy) => {
            const vehicle = this.route.snapshot.data.vehicle;
            if (vehicle && vehicle.type === 'smiles') {
                const sixMonthTerm = 6;
                const halfYearTerm = 0.5;
                const daysInYear = 365;
                const daysInMonth = 30;
                this.termFactor = policy.termLength() > sixMonthTerm ? 1 : halfYearTerm;
                let estimatedMileage = vehicle.estimatedAnnualMileage;
                if (estimatedMileage == null) {
                    estimatedMileage = vehicle.annualMileage * this.termFactor;
                }
                const maxMileage = 7900;
                const maxMileageAboveEstimate = 8000;
                const mileageBelowEstimateFactor = 500;
                const highestMileage = this.getMonthlyBenchmarkValue(vehicle) * policy.termLength();
                this.estimate = Math.round(vehicle.annualMileage / daysInYear) * daysInMonth;
                this.highest = this.getMonthlyBenchmarkValue(vehicle);
                this.estimateDiff = Math.round((this.highest - this.estimate) / policy.termLength());
                if (estimatedMileage > highestMileage) {
                    this.estimate = Math.round(vehicle.annualMileage / daysInYear) * daysInMonth;
                    this.highest = Math.round((estimatedMileage + mileageBelowEstimateFactor) / policy.termLength());
                }
                this.calculateDataForTotal(vehicle, estimatedMileage, highestMileage);
            } else {
                this.logger.error('No SM mod found');
            }
        });
        this.timeframeService.setHighestMonthlyMiles(this.highest);
    };

    calculateDataForTotal(vehicle, estimatedMileage, highestMileage): void {
        const daysInYear = 365;
        const daysInMonth = 30;
        const mileageBelowEstimateFactor = 500;
        if (this.totalPage) {
            this.milesDriven = this.totalMiles;
            this.estimateDiff = estimatedMileage > highestMileage ? mileageBelowEstimateFactor * this.termFactor : this.highest - this.estimate;
            if (this.estimatedMiles === 0) {
                this.estimatedMiles = Math.round(vehicle.annualMileage / daysInYear) * daysInMonth;
            }
        }
    }

    buildMonthTimeFrame(): void {
        const startDateString = this.route.snapshot.paramMap.get('amount');
        const vehicle: Vehicle = this.route.snapshot.data.vehicle;
        const newTf = vehicle.getTermLimitsContainingMoment(moment(startDateString));
        this.timeframe.start = moment.max(newTf.start.clone(), moment(vehicle.smEnrollDate));
        this.timeframe.end = newTf.end.clone();
        const tf = TimeFrame.buildTimeframe(moment(this.timeframe.start), moment(this.timeframe.end), TimeFrameUnit.MONTH);
        this.timeframeService.updateTimeframeRules(tf);
    }

    buildWeekTimeFrame(): void {
      this.weeklyTimeFrameStart = moment(new Date()).startOf('week');
      this.weeklyTimeFrameEnd = this.weeklyTimeFrameStart.clone().add(1, 'week').subtract(1, 'day');
    }

    viewWeeklySummary(): void {
      this.buildWeekTimeFrame();
      this.router.navigateByUrl(`smiles/${moment(this.weeklyTimeFrameStart).format('YYYYMMDD')}/week/weeklySummary`);
      return;
    }

    handleTimeframeChange = (tf: TimeFrame): void => {
        if (!tf.start || !tf.end) {
            this.buildMonthTimeFrame();
        } else {
            this.timeframe = tf;
        }
        if (this.vehicleVin) {
            const vehicle: Vehicle = this.route.snapshot.data.vehicle;
            if (this.timeframe.start.isBefore(vehicle.smEnrollDate)) {
                this.timeframe.start = moment(vehicle.smEnrollDate);
            }
            if (!this.totalPage) {
                this.mileage.getRatedMileage(this.vehicleVin, null, vehicle.smEnrollDate, vehicle.periodEnd).subscribe(() => {
                    this.milesDriven = this.mileage.getTotalMileageInTimeframe(this.vehicleVin, tf);
                });
            }
        }
    };

    setMonthTimeFrame(tf: TimeFrame, vehicle: Vehicle): void {
        // Display the total term mileage
        if (this.totalPage) {
            const totalTimeFrame = <TimeFrame>{ start: moment(vehicle.smEnrollDate), end: moment(vehicle.periodEnd), unit: TimeFrameUnit.TERM };
            this.handleTimeframeChange(totalTimeFrame);
            return;
        }
        tf.unit = TimeFrameUnit.MONTH;
        const newTf = vehicle.getTermLimitsContainingMoment(tf.start.clone().add(1, 'day'));
        this.handleTimeframeChange(newTf);
    }

    get trendDelta(): number {
        // eslint-disable-next-line no-extra-parens
        if (this.shouldUseMilesDriven()) {
            const delta = Math.abs(this.milesDriven - this.estimate);
            return delta;
        } else {
            const sm2Delta = Math.abs(this.sm2MilesDriven - this.estimatedMiles);
            return sm2Delta;
        }
    }

    get trendDirection(): string {
        // eslint-disable-next-line no-extra-parens
        if (this.shouldUseMilesDriven()) {
            if (this.milesDriven < this.estimate) {
                return 'below';
            }
            if (this.milesDriven > this.estimate) {
                return 'above';
            }
            return 'Same as';
        } else {
            if (this.sm2MilesDriven < this.estimatedMiles) {
                return 'below';
            }
            if (this.sm2MilesDriven > this.estimatedMiles) {
                return 'above';
            }
            return 'Same as';
        }
    }

    get trendDirectionAbsolute(): string {
        // eslint-disable-next-line no-extra-parens
        if (this.shouldUseMilesDriven()) {
            if (this.milesDriven < this.estimate) {
                return 'green';
            } else if (this.milesDriven > this.estimate) {
                return 'red';
            }
            return 'equal-grey';
        } else {
                if (this.sm2MilesDriven < this.estimatedMiles) {
                    return 'green';
                } else if (this.sm2MilesDriven > this.estimatedMiles) {
                    return 'red';
                }
                return 'equal-grey';
            }
    }

    get trendColorClass(): string {
        if (this.shouldUseMilesDriven()) {
            if (this.milesDriven == null) {
                return 'equal-grey';
            }
            if (this.milesDriven <= this.estimate) {
                return 'green';
            } else if (this.milesDriven > this.estimate && this.milesDriven < this.highest) {
                return 'yellow';
            } else {
                return 'red';
            }
        } else {
            if (this.sm2MilesDriven == null) {
                return 'equal-grey';
            } else if (this.sm2MilesDriven <= this.estimatedMiles) {
                return 'green';
            } else if (this.sm2MilesDriven > this.estimatedMiles && this.sm2MilesDriven < this.highest) {
                return 'yellow';
            }
            return 'red';
        }
    }

    get legendStatus(): string {
        if (this.legend) {
            return 'legend-row';
        } else {
            return 'legend-off';
        }
    }

    get legendContainer(): string {
        if (this.legend) {
            return 'tile-container-legend';
        } else {
            return 'tile-container';
        }
    }

    get legendCircle(): string {
        if (this.legend) {
            return `factor-circle-legend ${this.trendColorClass}`;
        } else {
            return `factor-circle ${this.trendColorClass}`;
        }
    }

    get legendCircleColumnOne(): string {
        if (this.legend) {
            return 'col-lg-5 col-md-5 col-5';
        } else {
            return 'col-12';
        }
    }

    // TODO create else{} if this.legend is true
    // eslint-disable-next-line getter-return
    get legendCircleColumnTwo(): string {
        if (!this.legend) {
            return 'displayNone';
        }
    }

    get legendTrend(): string {
        if (this.legend) {
            return 'trend-legend';
        } else {
            return 'tile-footer';
        }
    }

    monthDetailsRoute(): void {
        if (this.routeToMonth) {
            this.ebiEvents.postEvent('Monthly miles circle');
            this.router.navigate([
                `/smiles/${moment(this.timeframe.start).format('YYYYMMDD')}/${this.timeframe.unit}/monthlySummaryDetails`
            ]);
        }
    }

    totalDetailsRoute(): void {
      const startDateString = this.route.snapshot.paramMap.get('amount');
      this.router.navigate([
        `/smiles/${startDateString}/term/totalSummary`
      ]);
      }

    get titleLegend(): string {
        if (this.legend) {
            return 'tile-title-legend';
        } else {
            return 'tile-title';
        }
    }

    get timeframefactor(): string {
        if (this.factorCircleVisible) {
            return 'timeframe-factor';
        } else {
            return 'timeframe-factor-total';
        }
    }

    get totalFactorLegendCircle(): string {
        if (this.factorCircleVisible) {
            return 'factorLegendCircle';
        } else {
            return 'totalFactorLegendCircle';
        }
    }

    shouldUseMilesDriven(): boolean {
      return this.currentMonth || !this.sm2MilesDriven && !this.estimatedMiles;
    }

    getMonthlyBenchmarkValue(vehicle: Vehicle): number {
        let tamValue = 0;
        const tamMultiplier = 1.3;
        let benchmarkValue = 0;
        const sixMonthTerm = 6;
        const twelveMonthTerm = 12;
        const monthInTerm = this.termFactor < 1 ? sixMonthTerm : twelveMonthTerm;
        tamValue = vehicle.typicalAnnualMileage * this.termFactor;
        tamValue = tamValue * tamMultiplier;
        benchmarkValue = Math.round(tamValue / monthInTerm);
        return benchmarkValue;
    }
}
