export class RatedMileage {
    vin: string;
    policyNumber: string;
    ratedMileageDate: Date;
    ratedMileage: number;
    actualMileage: number;
    overriddenMileage: number;
    reconnects: number;
    ruleApplied: string;
    heartbeatIndicator: boolean;
    type: string;
    constructor() { }
}
