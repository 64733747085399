/* eslint-disable max-params*/
import { Injectable } from '@angular/core';
import {
  PersonalLinesDiscount, PersonalLinesModifier, PersonalLinesPerson, PersonalLinesDriver, PersonalLinesVehicle,
  PersonalLinesAutoLob, PersonalLinesAddress, PersonalLinesPolicy, PersonalLinesSmartMilesModifier
} from 'app/model/rest/personal-lines-policy';

@Injectable()
export class DtoMapperService {
  static personalLinesPolicyFromServiceResponse(policyInput: any, driversInput: any, discountsInput: any, vehicleInput: any, programEnrollments: any): PersonalLinesPolicy {
    const output: PersonalLinesPolicy = new PersonalLinesPolicy();
    if (policyInput && vehicleInput) {
      output.appliedAndAvailableDiscounts = DtoMapperService.mapPersonalLinesDiscounts(discountsInput);
      output.baseState = policyInput.policyAddress.state;
      output.effectiveDate = DtoMapperService.mapDate(policyInput.effectiveDate);
      output.lob = DtoMapperService.mapPersonalLinesAutoLob(vehicleInput, discountsInput, programEnrollments, driversInput);
      output.originalInceptionDate = DtoMapperService.mapDate(policyInput.originalPolicyStartDate);
      output.periodEnd = DtoMapperService.mapDate(policyInput.policyEndDate);
      output.periodStart = DtoMapperService.mapDate(policyInput.policyStartDate);
      output.policyAddress = DtoMapperService.mapPersonalLinesAddress(policyInput.policyAddress);
      output.policyNumber = policyInput.policyNumber;
      output.status = policyInput.status;
    }
    return output;
  }

  static mapPersonalLinesDiscounts(inputs): Array<PersonalLinesDiscount> {
    if (!inputs) {
 return null;
}
    const outputs = [];
    for (const input of inputs) {
      if (input.isDiscountApplied) {
        outputs.push(DtoMapperService.mapPersonalLinesDiscount(input));
      }
    }
    return outputs;
  }

  static mapPersonalLinesDiscount(input): PersonalLinesDiscount {
    if (!input) {
 return null;
}
    const output = new PersonalLinesDiscount();
    output.code = input.coverableId;
    output.description = input.description;
    output.isDiscountApplied = input.isDiscountApplied;
    output.category = input.category;
    return output;
  }

  static mapDate(input: string): Date {
    if (!input) {
 return null;
}
    if (input.match(/^\d{4}-\d{2}-\d{2}$/)) {
      input = `${input}T00:00`;
    }
    return new Date(input);
  }

  static mapPersonalLinesAutoLob(vehiclesInput, discountsInput, programEnrollments, driversInput): PersonalLinesAutoLob {
    if (!vehiclesInput) {
 return null;
}
    const output = new PersonalLinesAutoLob();
    output.appliedDiscounts = DtoMapperService.mapPersonalLinesDiscounts(discountsInput);
    output.drivers = DtoMapperService.mapPersonalLinesDrivers(driversInput);
    output.vehicles = DtoMapperService.mapPersonalLinesVehicles(vehiclesInput, programEnrollments);
    return output;
  }

  static mapPersonalLinesDrivers(inputs): Array<PersonalLinesDriver> {
    if (!inputs) {
 return null;
}
    const outputs = [];
    for (const input of inputs) {
      const output = new PersonalLinesDriver();
      output.ageFirstLicensed = input.ageFirstLicensed;
      output.driverType = input.driverType;
      output.isFinancialFilingReq = input.isFinancialFilingRequired;
      output.isInternationalLicense = input.isInternationalLicense;
      output.isNewlyAddedDriver = input.isNewlyAddedDriver;
      output.licenseNumber = input.licenseNumber;
      output.licenseState = input.licenseState;
      output.person = DtoMapperService.mapPersonalLinesPerson(input.person);
      output.relationToPNI = input.relationToPrimaryNamedInsured;
      outputs.push(output);
    }
    return outputs;
  }

  static mapPersonalLinesModifiers(input, vehicleEnrollments): Array<PersonalLinesModifier> {
    if (!vehicleEnrollments) {
 return null;
}
    const outputs = [];
    const output = new PersonalLinesModifier();
    output.vehicleEnrollment = vehicleEnrollments;
    for (const vehicleEnrollment of vehicleEnrollments.vehicles) {
      if (input.vehicleId === Number(vehicleEnrollment.vehicleId)) {
        output.code = vehicleEnrollment.vehicleProgram;
        output.smartMiles = DtoMapperService.mapPersonalLinesSmartMilesModifier(vehicleEnrollment);
        outputs.push(output);
      }
    }
    return outputs;
  }

  static mapPersonalLinesSmartMilesModifier(input): PersonalLinesSmartMilesModifier {
    if (!input) {
 return null;
}
    const output = new PersonalLinesSmartMilesModifier();
    output.isDeviceCompatible = input.deviceCompatible;
    output.enrollStatus = input.enrollmentStatus;
    output.deviceStatus = input.deviceStatus;
    output.enrollDate = DtoMapperService.mapDate(input.enrollmentDate);
    output.annualMileage = input.annualMileage;
    output.typicalAnnualMileage = input.typicalAnnualMileage;
    return output;
  }

  static mapPersonalLinesPerson(input): PersonalLinesPerson {
    if (!input) {
 return null;
}
    const output = new PersonalLinesPerson();
    output.age = input.age;
    output.dateOfBirth = DtoMapperService.mapDate(input.dateOfBirth);
    output.firstName = input.firstName;
    output.gender = input.gender;
    output.lastName = input.lastName;
    output.maritalStatus = input.maritalStatus;
    return output;
  }

  static mapPersonalLinesVehicles(inputs, programEnrollments): Array<PersonalLinesVehicle> {
    if (!inputs) {
 return null;
}
    const outputs = [];
    for (const input of inputs) {
      const output = new PersonalLinesVehicle();
      output.additionalInterests = input.additionalInterests;
      output.antiTheftDevice = input.antiTheftDevice;
      output.bodyStyle = input.bodyStyle;
      output.bodyTypeExt = input.bodyType;
      output.fixedId = input.vehicleId;
      output.garageLocation = DtoMapperService.mapPersonalLinesAddress(input.garageLocation);
      output.isPurchasedNew = input.isPurchasedNew;
      output.make = input.make;
      output.model = input.model;
      output.modifiers = DtoMapperService.mapPersonalLinesModifiers(input, programEnrollments.vehicleEnrollment);
      output.primaryUse = input.primaryUse;
      output.vehicleType = input.vehicleType;
      output.vin = input.vin;
      output.year = input.year;
      outputs.push(output);
    }
    return outputs;
  }

  static mapPersonalLinesAddress(input): PersonalLinesAddress {
    if (!input) {
 return null;
}
    const output = new PersonalLinesAddress();
    output.addressLine1 = input.addressLine1;
    output.city = input.city;
    output.country = input.country;
    output.postalCode = input.postalCode;
    output.state = input.state;
    return output;
  }
}
