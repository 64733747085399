import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { TimeFrameUnit } from 'app/enum/timeframe';
import { Vehicle } from 'app/model/vehicle';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { Moment, unitOfTime } from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'app/service/user.service';
import { NavbarService } from 'app/service/nav-bar.service';
import { TimeframeService } from 'app/service/timeframe.service';
import { EbiEventService } from 'app/service/ebi-event.service';
import { TimeFrame } from 'app/interfaces/timeframe.interface';
import { VehicleService } from 'app/service/vehicle.service';
import StartOf = unitOfTime.StartOf;
import { environment } from 'environments/environment';

@Component({
  selector: 'app-smartride-vehicle-summary',
  templateUrl: './smartride-vehicle-summary.component.html',
  styleUrls: ['./smartride-vehicle-summary.component.css']
})

export class SmartrideVehicleSummaryComponent implements OnInit, OnDestroy, AfterViewInit {
  MONTH = TimeFrameUnit.MONTH;
  DAY = TimeFrameUnit.DAY;
  WEEK = TimeFrameUnit.WEEK;
  TERM = TimeFrameUnit.TERM;

  vehicle: Vehicle;
  timeFormatStart = 'MMMM';
  timeFormatEnd = '';
  timeFrameStart: Moment;
  timeFrameEnd: Moment;
  timeframe = this.MONTH;
  canDecrement = false;
  canIncrement = true;
  displayTimeBar = true;
  startDate: Date;
  routerSubscription: Subscription;
  private timeFrameSubscription: Subscription;

  // eslint-disable-next-line max-params
  constructor(
    public router: Router,
    public _user: UserService,
    public _navBar: NavbarService,
    public _timeframe: TimeframeService,
    public _vehicle: VehicleService,
    private route: ActivatedRoute,
    public _ebiEvents: EbiEventService,
    public changeDetector: ChangeDetectorRef,
    public routeSnapshot: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.vehicle = this.route.snapshot.data.vehicle;
    this.startDate = this.vehicle.deviceStatusTimeline.installDate || this.vehicle.deviceStatusTimeline.enrollDate;
    this._navBar.headerDisplay(this._user.isLoggedIn, this.router.url);
    if (this.router.url.indexOf('deviceAlert') !== -1 || this.router.url.indexOf('deviceStatusTimeline') !== -1) {
      this.displayTimeBar = false;
    } else {
      this.displayTimeBar = true;
    }
    this.setupSubscriptions();
  }

  // Force change detection again because this view relies upon updates from the TimeframeService, which may change
  // values of canIncrement and canDecrement variables when the timeframe updates
  ngAfterViewInit(): void {
    this.changeDetector.detectChanges();
  }

  setupSubscriptions = (): void => {
    this.timeFrameSubscription = this._timeframe.timeframeChange$.subscribe((newTf) => {
      this.timeFrameStart = newTf.start.clone();
      this.timeFrameEnd = newTf.end.clone();
      this.timeframe = newTf.unit;
      this.setTimeNavigationRules();
      if (newTf.unit === TimeFrameUnit.WEEK) {
        this.timeFormatStart = 'LLL d';
        this.timeFormatEnd = 'LLL d';
      }
      if (newTf.unit === TimeFrameUnit.MONTH) {
        this.timeFormatStart = 'LLLL';
        this.timeFormatEnd = '';
      }
      if (newTf.unit === TimeFrameUnit.DAY) {
        this.timeFormatStart = 'EEEE, LLL d';
        this.timeFormatEnd = '';
      }
      if (newTf.unit === TimeFrameUnit.TERM) {
        this.timeFormatStart = 'LLL d';
        this.timeFormatEnd = 'LLL d';
      }
    });
    let end = moment(environment.futureDate);
    if (this.vehicle.deviceStatusTimeline.completionDate !== undefined) {
      end = moment.min(moment(environment.futureDate), moment(this.vehicle.deviceStatusTimeline.completionDate));
    }
    const tf = TimeFrame.buildTimeframe(
      end,
      end,
      this.timeframe
    );
    this._timeframe.updateTimeframeRules(tf);
    this.setTimeFrame(tf.unit);
  };

  ngOnDestroy(): void {
    if (this.timeFrameSubscription) {
      this.timeFrameSubscription.unsubscribe();
    }
  }

  routeWeek(): void {
    const finalAllowedDate = moment(this.vehicle.deviceStatusTimeline.completionDate) || moment(environment.futureDate);
    if (this.timeFrameEnd.isSameOrBefore(finalAllowedDate, 'week')) {
      this.router.navigate(['summary', 'week', this.timeFrameEnd.format('YYYYMMDD')]);
    } else {
      this.router.navigate(['summary', 'week', finalAllowedDate.format('YYYYMMDD')]);
    }
  }

  setTimeFrame = (timeframe: string): void => {
    let tf: TimeFrame;
    let latestDate = moment.min([
      this.timeFrameEnd, moment(environment.futureDate), moment(this.vehicle.deviceStatusTimeline.completionDate) || moment('99991231')
    ]);
    timeframe = timeframe.trim().toLowerCase();
    if (timeframe === 'term' || timeframe === 'total') {
      let end = moment(environment.futureDate);
      if (this.vehicle.deviceStatusTimeline.completionDate !== undefined) {
        end = moment(this.vehicle.deviceStatusTimeline.completionDate);
      }
      this.timeFormatStart = 'LLL d';
      this.timeFormatEnd = 'LLL d';
      tf = <TimeFrame>{
        start: moment(this.startDate),
        end,
        unit: TimeFrameUnit.TERM
      };
      this.timeFrameEnd = end;
      this.timeFrameStart = moment(this.startDate);
      this._timeframe.updateTimeframeRules(tf);
      this.canIncrement = false;
      this.canDecrement = false;
      return;
    } else if (timeframe === 'month') {
      if (this.timeframe === TimeFrameUnit.WEEK) {
        latestDate = moment.min([this.timeFrameEnd, moment(this.vehicle.deviceStatusTimeline.completionDate)]);
      }
      tf = <TimeFrame>{
        start: latestDate.clone().startOf('month'),
        end: latestDate.clone().endOf('month'),
        unit: TimeFrameUnit.MONTH
      };
      this._timeframe.updateTimeframeRules(tf);
      this.timeFormatStart = 'LLLL';
      this.timeFormatEnd = '';
      return;
    } else if (timeframe === 'week') {
      tf = <TimeFrame>{
        start: latestDate.clone().startOf('week'),
        end: latestDate.clone().endOf('week'),
        unit: TimeFrameUnit.WEEK
      };
      this._timeframe.updateTimeframeRules(tf);
      this.timeframe = TimeFrameUnit.WEEK;
      this.timeFormatStart = 'LLL d';
      this.timeFormatEnd = 'LLL d';
    } else if (timeframe === 'day') {
      tf = <TimeFrame>{
        start: latestDate.clone().startOf('day'),
        end: latestDate.clone().endOf('day'),
        unit: TimeFrameUnit.DAY
      };
      this._timeframe.updateTimeframeRules(tf);
      this.timeFormatStart = 'EEEE, LLL d';
      this.timeFormatEnd = '';
      this.timeframe = TimeFrameUnit.DAY;
    }
    this.setTimeNavigationRules();
  };

  decrementTimeSlice(): void {
    if (this.canDecrement) {
      switch (this.timeframe) {
        case TimeFrameUnit.DAY:
          this.timeFrameStart.subtract(1, 'day');
          this.timeFrameEnd.subtract(1, 'day');
          break;
        case TimeFrameUnit.WEEK:
          this.timeFrameStart.subtract(1, 'week');
          this.timeFrameEnd.subtract(1, 'week');
          break;
        case TimeFrameUnit.MONTH:
          this.timeFrameStart.subtract(1, 'month');
          this.timeFrameEnd.subtract(1, 'month');
          break;
      }
      const tf = TimeFrame.buildTimeframe(this.timeFrameStart, this.timeFrameEnd, this.timeframe);
      this._timeframe.updateTimeframeRules(tf);
      this.setTimeNavigationRules();
    }
  }

  incrementTimeSlice(): void {
    if (this.canIncrement) {
      switch (this.timeframe) {
        case TimeFrameUnit.DAY:
          this.timeFrameStart.add(1, 'day');
          this.timeFrameEnd.add(1, 'day');
          break;
        case TimeFrameUnit.WEEK:
          this.timeFrameStart.add(1, 'week');
          this.timeFrameEnd.add(1, 'week');
          break;
        case TimeFrameUnit.MONTH:
          this.timeFrameStart.add(1, 'month');
          this.timeFrameEnd.add(1, 'month');
          break;
      }
      const tf = TimeFrame.buildTimeframe(this.timeFrameStart, this.timeFrameEnd, this.timeframe);
      this._timeframe.updateTimeframeRules(tf);
      this.setTimeNavigationRules();
    }
  }

  setTimeNavigationRules(): void {
    let end = moment(environment.futureDate);
    if (this.vehicle.deviceStatusTimeline.completionDate !== undefined) {
      end = moment.min(moment(environment.futureDate), moment(this.vehicle.deviceStatusTimeline.completionDate));
    }
    if (this.vehicle && this.timeFrameStart && this.timeFrameEnd) {
      if (this.timeframe === TimeFrameUnit.MONTH) {
        this.canIncrement = this.timeFrameEnd.isBefore(end, 'month');
        this.canDecrement = this.timeFrameStart.isAfter(moment(this.startDate), 'month');
      } else if (this.timeframe === TimeFrameUnit.WEEK) {
        this.canIncrement = this.timeFrameEnd.isBefore(end, 'week');
        this.canDecrement = this.timeFrameStart.isAfter(moment(this.startDate), 'week');
      } else if (this.timeframe === TimeFrameUnit.DAY) {
        this.canIncrement = this.timeFrameEnd.isBefore(end, 'day');
        this.canDecrement = this.timeFrameStart.isAfter(moment(this.startDate), 'day');
      }
    }
  }

  setLabel(timeStartMoment: Moment, timeEndMoment: Moment): string {
    return `${timeStartMoment.format('MMMM')}-${timeEndMoment.format('MMMM')}`;
  }

  viewCurrent = (): void => {
    const earlierDate = moment.min([moment(environment.futureDate), moment(this.vehicle.deviceStatusTimeline.completionDate)]);
    this.timeFrameStart = earlierDate.clone().startOf(<StartOf> this.timeframe.toLowerCase());
    this.timeFrameEnd = earlierDate.clone().endOf(<StartOf> this.timeframe.toLowerCase());
    this._timeframe.updateTimeframeRules(<TimeFrame>{
      start: this.timeFrameStart,
      end: this.timeFrameEnd,
      unit: <TimeFrameUnit> this.timeframe
    });
    this.setTimeNavigationRules();
  };
}
