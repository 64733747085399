import { Observable, of, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { BrandingService } from 'app/service/branding.service';
import { LoggerService } from 'app/service/logger.service';
import { UserService } from 'app/service/user.service';
import { environment } from 'environments/environment';

@Injectable()
export class ServiceErrorHandler {
  // eslint-disable-next-line max-params
  constructor(
    public router: Router,
    public _branding: BrandingService,
    private logger: LoggerService,
    private user: UserService,
  ) { }

  handleError(operation = 'operation'): (() => Observable<any>) | ((error: any) => Observable<any>) {
    if (operation === 'PersonalLinesPolicyService' && this.user.isAdmin() && !environment.production) {
      return (): Observable<any> => of({});
    } else {
      return (error: any): Observable<any> => {
        // eslint-disable-next-line no-magic-numbers
        if (error.status === 401 || error.status === 403) {
          this._branding.logoutWithBranding('expired');
        }
        this.logger.error(`${operation} failed: ${error.message}`);
        return throwError(error);
      };
    }
  }

  handleSummaryError(operation = 'operation') {
    return (error: any): Observable<any> => {
      // eslint-disable-next-line no-magic-numbers
      if (error.status === 401 || error.status === 403) {
        this._branding.logoutWithBranding('expired');
      }
      this.logger.error(error);
      this.logger.error(`${operation} failed: ${error.message}`);
      this.router.navigateByUrl('nw/systemError');
      return throwError(error);
    };
  }
}
