import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable()
export class NavigationService {
  @Output() url: EventEmitter<string> = new EventEmitter();

  constructor() { }

  navigated(url: string): void {
    this.url.emit(url);
  }
}
