import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';
import { LoggerService } from 'app/service/logger.service';

@Component({
    selector: 'app-personal-policy-quick-id-zip',
    templateUrl: './quick-id-zip.component.html',
    providers: [LoggerService]
})
export class QuickIdZipComponent implements OnInit {
    clientId: string;
    contextCacheResponse = {};
    continueButtonEvent = '';
    envName: string;
    euaResponse = {};
    isLoadComplete = false;
    missingCacheRedirect = 'smartride/login';
    dobOptionVisible = true;
    header = 'Enter details to continue';

    constructor(
        private router: Router,
        private logger: LoggerService
    ) { }

    ngOnInit(): void {
        this.clientId = environment.authorize.client_id;
        this.envName = environment.envName;
    }

    setContextCacheResponse(response: any): void {
        this.contextCacheResponse = response;
        if (this.contextCacheResponse['context']) {
            sessionStorage.setItem('selectedPolicy', this.contextCacheResponse['context'].policyNumber);
            this.logger.info('Quick Id ZIP Auth: Context Cache Call successful.');
            this.isLoadComplete = true;
        } else {
            this.logger.error('Quick Id ZIP Auth: Contexct Cache unsuccessful.');
            this.isLoadComplete = true;
        }
    }

    setContinueButtonEvent(response: any): void {
        this.continueButtonEvent = response;
        this.logger.info(`Quick Id ZIP Auth: ${this.continueButtonEvent}`);
    }

    setEUAResponse(response: any): void {
        this.euaResponse = response;
        if (this.euaResponse['successful']) {
            this.logger.info('Quick Id ZIP Auth: EUA call successful');
            this.router.navigateByUrl('smartride/login');
        } else {
            this.logger.error('Quick Id ZIP Auth: EUA response unsuccessful, going to quick-id-search');
            this.router.navigateByUrl('smartride/login');
        }
    }
}
