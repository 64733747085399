import { Component, OnInit } from '@angular/core';
import { WindowRef } from 'app/service/window-ref';
import { environment } from 'environments/environment';
import { SelfEnrollmentService } from 'app/service/self-enrollment.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-system-error',
  templateUrl: './system-error.component.html',
  styleUrls: ['./system-error.component.css']
})
export class SystemErrorComponent implements OnInit {
  isSelfEnrollFlowInPlay: boolean;
  _window: Window;
  showSelfEnrollmentErrorMsg: boolean;

  constructor(
    private _windowRef: WindowRef,
    private selfEnrollmentService: SelfEnrollmentService,
    private router: Router) {
    this._window = this._windowRef.nativeWindow;
    if (this.router.getCurrentNavigation().extras.state) {
      this.showSelfEnrollmentErrorMsg = this.router.getCurrentNavigation().extras.state.ineligibleToEnroll;
    }
  }

  ngOnInit(): void {
    this.isSelfEnrollFlowInPlay = this.selfEnrollmentService.isSelfEnrollFlowInPlay;
    sessionStorage.removeItem('oaToken');
    sessionStorage.removeItem('isAdmin');
    sessionStorage.removeItem('selectedPolicy');
    sessionStorage.removeItem('allPolicies');
    sessionStorage.removeItem('bkey');
  }

  goToAccountSummary(): void {
    this._window.location.replace(environment.myAccountUrl);
  }
}
