import { Injectable, EventEmitter } from '@angular/core';
import { Alert } from 'app/enum/alert';
import { MobileProgram } from 'app/model/mobileprogram';
import { ContactPreferences } from 'app/model/contact-preferences';
import { ProgramCollection } from 'app/model/program-collection';
import { Policy } from '../model/policy';
import { BrandingService } from 'app/service/branding.service';
import { PolicyNumberFormatService } from './policy-number-format.service';
import { LoggerService } from './logger.service';
@Injectable()
export class UserService {
  loggedIn = false;
  containsPCPolicy = false;
  idTokenFirst: any;
  idTokenSecond: any;
  idToken: any;
  selectedVIN: string;
  programType: string;
  mobileProgram: MobileProgram;
  contactPreferences: ContactPreferences;
  vehiclePrograms: ProgramCollection;
  policies: Policy[];
  logOutEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  accessToken: string;

  private username: string;
  private firstName: string;
  private lastName: string;
  private email: string;
  private phoneNumber = '';
  private state = '';
  private _alertType: Alert;
  private _tokenDetails: string;
  private printLabelNoticesAcknowledged = {};
  private termStart: Date;
  private termEnd: Date;

  constructor(
    public branding: BrandingService,
    public logger: LoggerService
  ) { }

  getTermEnd(): Date {
    return new Date(this.termEnd.valueOf());
  }
  setTermEnd(value: Date): void {
    this.termEnd = new Date(value.valueOf());
  }
  getTermStart(): Date {
    return new Date(this.termStart.valueOf());
  }
  setTermStart(value: Date): void {
    this.termStart = new Date(value.valueOf());
  }

  get alertType(): Alert {
    return this._alertType;
  }

  set alertType(alert: Alert) {
    this._alertType = alert;
  }

  get selectedVin(): string {
    return this.selectedVIN;
  }

  set selectedVin(vin: string) {
    this.selectedVIN = vin;
  }

  get selectedVehicleProgramType(): string {
    return this.programType;
  }

  set selectedVehicleProgramType(programType: string) {
    this.programType = programType;
  }

  get tokenDetails(): string {
    return this._tokenDetails;
  }

  set tokenDetails(token: string) {
    this._tokenDetails = token;
  }

  getShowPrintLabel(key: string): boolean {
    key = key || '';
    const ack = this.printLabelNoticesAcknowledged[key] || false;
    return !ack;
  }

  setShowPrintLabel(key: string, value: boolean): void {
    key = key || '';
    this.printLabelNoticesAcknowledged[key] = !value;
  }

  logout(): void {
    this.logOutEventEmitter.emit();
    this.branding.logoutWithBranding('loggedout', '', false, this._tokenDetails.split(',')[0]);
    this.loggedIn = false;
    this._tokenDetails = null;
    this.selectedVin = null;
    this.username = null;
    this.mobileProgram = null;
    this.contactPreferences = null;
    this.vehiclePrograms = null;
    this.policies = null;
    this.firstName = null;
    this.lastName = null;
    this.email = null;
    this.phoneNumber = null;
    this.state = null;
    this.termStart = null;
    this.termEnd = null;
  }

  get isLoggedIn(): boolean {
    return this.loggedIn;
  }

  getUsername(): string {
    return this.isAdmin() ? 'Admin' : this.username;
  }

  getFirstName(): string {
    return this.firstName;
  }

  getLastName(): string {
    return this.lastName;
  }

  getEmail(): string {
    return this.email;
  }

  setPhoneNumber(phone: string): void {
    this.phoneNumber = phone;
  }

  getPhoneNumber(): string {
    return this.phoneNumber;
  }

  setState(state: string): void {
    this.state = state;
  }

  getState(): string {
    return this.state;
  }

  getPolicies(): Array<string> {
    let policies = [];
    const agreements = this.getAgreements();
    if (agreements && agreements.length > 0) {
      for (const agreement of agreements) {
        if (agreement.product_type === 'Auto') {
          if (agreement.source_system === 'PolicyCenter') {
            this.containsPCPolicy = true;
            policies.push(PolicyNumberFormatService.formatPolicyNumber(agreement.agreement_number));
          }
        }
      }
    } else {
      const policiesText = sessionStorage.getItem('allPolicies') || '';
      policies = policiesText.split(';').map((policyNum) => PolicyNumberFormatService.formatPolicyNumber(policyNum));
    }
    if (policies.length === 1) {
      sessionStorage.setItem('selectedPolicy', policies[0]);
    }
    return policies;
  }

  getPolicyNumber(): string {
    const selectedPolicyFromSession = sessionStorage.getItem('selectedPolicy');
    if (
      selectedPolicyFromSession === null ||
      selectedPolicyFromSession === undefined ||
      selectedPolicyFromSession.length === 0
    ) {
      this.getPolicies();
    }
    return sessionStorage.getItem('selectedPolicy');
  }

  getSelfEnrollPolicy(): string {
    const agreements = this.getAgreements();
    if (agreements && agreements.length > 0) {
      for (const agreement of agreements) {
        if (agreement.product_type === 'Auto' && agreement.source_system === 'PolicyCenter') {
          return PolicyNumberFormatService.formatPolicyNumber(agreement.agreement_number);
        }
      }
    }
  }

  getAgreements(): any {
    return this.idTokenSecond ? this.idTokenSecond.agreements : null;
  }

  isSelfEnrollEligible(): boolean {
    return this.containsPCPolicy;
  }

  getEcn(): string {
    return this.idTokenSecond ? this.idTokenSecond.ecn : null;
  }

  evaluateJwtInEuaResponse(): void {
    this.idToken = sessionStorage.getItem('id_token');
    if (!this.idToken) {
      return;
    }
    const idTokenElements = this.idToken.split('.');
    if (idTokenElements.length < 2) {
      return;
    }

    try {
      this.idTokenFirst = JSON.parse(atob(idTokenElements[0]));
    } catch (err) {
      this.logger.error('Failed to parse first section of EUA JWT.', { err });
    }

    try {
      this.idTokenSecond = JSON.parse(atob(idTokenElements[1]));
    } catch (err) {
      this.logger.error('Failed to parse second section of EUA JWT.', { err });
    }
    sessionStorage.setItem('allPolicies', this.getPolicies().join(';'));
    sessionStorage.setItem('bkey', this.getBillingAccountNumber());
    this.username = this.idTokenSecond['userId'];
    this.firstName = this.idTokenSecond['given_name'];
    this.lastName = this.idTokenSecond['family_name'];
    this.email = this.idTokenSecond['email'];
    this.logger.setUser(this.username);
  }

  getBillingAccountNumber(): string {
    const storedBillingAccountNumber = sessionStorage.getItem('bkey');
    if (storedBillingAccountNumber) {
      return storedBillingAccountNumber;
    }
    if (!this.idToken) {
      return '';
    }
    if (!this.idTokenSecond) {
      return '';
    }
    if (!this.idTokenSecond.agreements) {
      return '';
    }
    for (const agreement of this.idTokenSecond.agreements) {
      if (agreement.product_type === 'Billing') {
        if (agreement.agreement_number) {
          return agreement.agreement_number;
        }
      }
    }
    return '';
  }

  // True if we are the Admin app.
  // Be mindful that the clever user can easily toggle this on and off, if he cares to.
  isAdmin(): boolean {
    const isAdmin: string = sessionStorage.getItem('isAdmin');
    return isAdmin === 'true';
  }
}
