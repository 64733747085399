import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { APICommonService } from '@nationwide/api-common-service';

@Injectable({
  providedIn: 'root'
})
export class PolicyPreferencesService {
  constructor(
    private _http: HttpClient,
    private apiCommonService: APICommonService
  ) { }

  setTextingPreference(payload, ecn): Observable<Object> {
    const url = `${environment.policyPreferencesService.url}/${ecn}/preferences`;
    const httpOptions = this.getHttpOptions();
    payload = {
      preferences: [
        payload
      ]
    };
    return this._http.post(url, payload, { ...httpOptions });
  }

  getTextingPreference(ecn): Observable<Object> {
    const url = `${environment.policyPreferencesService.url}/${ecn}/preferences`;
    const httpOptions = this.getHttpOptions();
    return this._http.get(url, { ...httpOptions });
  }

  getHttpOptions(): any {
    return {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'X-NW-Transaction-Id': this.apiCommonService.generateTransactionId(),
          // eslint-disable-next-line camelcase
          client_id: environment.policyPreferencesService.apiKey,
          Accept: 'application/json, text/plain, */*',
          'X-NWD-ConsumerID': 'SmartRide Experience',
          'Accept-Language': 'en-US,en;q=0.5'
        })
    };
  }
}
