import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SreSummaryContent, RatingFactorForDisplay } from './sre-summary-content';
import { Subscription } from 'rxjs';
import { UserService } from 'app/service/user.service';
import { FactorId } from 'app/service/sre-summary.service';
import { NavbarService } from 'app/service/nav-bar.service';
import { EbiEventService } from 'app/service/ebi-event.service';
import { BrandingService } from 'app/service/branding.service';
import { TermAndConditionStatusService } from 'app/service/term-and-condition-status.service';
import { LoggerService } from 'app/service/logger.service';
import { DataService } from 'app/service/data.service';

@Component({
    selector: 'app-sre-summary',
    templateUrl: './sre-summary.component.html',
    styleUrls: ['./sre-summary.component.css']
})

export class SreSummaryComponent implements OnInit, OnDestroy, AfterViewInit {
    content: SreSummaryContent;
    dataSubscription: Subscription;
    termAndConditionConsent: string;
    userRole: string;

    // eslint-disable-next-line max-params
    constructor(
        public route: ActivatedRoute,
        public userService: UserService,
        public router: Router,
        public navbarService: NavbarService,
        public ebi: EbiEventService,
        private logger: LoggerService,
        public dataService: DataService,
        public termAndConditionStatusService: TermAndConditionStatusService
    ) { }

    ngOnInit(): void {
        this.dataSubscription = this.route.data.subscribe((params) => {
            this.content = params['content'];
            if (this.content) {
                this.postEbiEvent();
                if (this.content.vehicle && !sessionStorage.getItem('vidcode')) {
                    sessionStorage.setItem('vidcode', this.content.vehicle.vendorIdCode);
                }
            }
        });

        // User Role Splunk Logging
        // Reading roles directly from session storage due to current issues with role data service (data.service.ts)

        let currentSessionStorageUserRole;
        if (sessionStorage.getItem('isAgent') === 'true') {
          currentSessionStorageUserRole = 'Agent';
        } else if (sessionStorage.getItem('isAdmin') === 'true') {
          currentSessionStorageUserRole = 'Admin';
        } else {
          currentSessionStorageUserRole = 'Member';
        }

        this.logger.info('Landed on SmartRide Monthly Summary Page', {
          policyNumber: this.content.vehicle.policyNumber,
          brand: 'SmartRide',
          deviceTypeIdentifier: this.content.vehicle.programType,
          role: currentSessionStorageUserRole
        });

        // XXX Configuring the header should not be this component's responsibility:
        this.navbarService.headerDisplay(this.userService.isLoggedIn, this.router.url);
        this.termAndConditionConsent = this.getTermsAndConditionConsentStatus(this.content.vehicle.vin, 'sride');
    }

    ngAfterViewInit(): void {
        if (BrandingService.getBranding() === 'smartride') {
            this.ebi.assignProgramType(this.content.vehicle.programType);
            this.ebi.postSrideEvent('425080003');
        }
    }

    ngOnDestroy(): void {
        if (this.dataSubscription) {
            this.dataSubscription.unsubscribe();
        }
    }

    postEbiEvent(): void {
        switch (this.content.summary.periodUnit) {
            case 'week':
                this.ebi.postSrideEvent('425080005');
                break;
            case 'month':
                this.ebi.postSrideEvent('425080008');
                break;
            case 'total':
                this.ebi.assignProgramType(this.content.vehicle.programType);
                this.ebi.postSrideEvent('425080010');
                break;
        }
    }

    getPageTitle(): string {
        switch (this.content.summary.periodUnit) {
            case 'day': return 'Daily Summary'; // actually no such page
            case 'week': return 'Weekly Summary';
            case 'month': return 'Monthly Summary';
            case 'total': return 'Total Summary';
        }
        return '';
    }

    shouldDisplayPrintLabelAlert(): boolean {
        if (this.content.vehicle.device.status.toLowerCase().indexOf('return device') < 0) {
            return false;
        }
        if (this.userService.getShowPrintLabel(this.content.vehicle.vin)) {
            return true;
        }
        return false;
    }

    shouldDisplayFinalDiscountDisclaimer(): boolean {
        return this.content.discount.discountType === 'Final Discount';
    }

    shouldDisplayAnnualMileageEstimate(): boolean {
        return this.content.vehicle.scoringModel === 'MO';
    }

    enterDetailsForFactor(factor: RatingFactorForDisplay): void {
        this.router.navigate([
            'sride', 'details',
            FactorId[factor.event.factorId],
            this.route.snapshot.params.periodUnit,
            this.route.snapshot.params.periodEnd
        ]);
    }

    navigateToDiscountTrend(): void {
        if (this.content.discount.discountType !== 'Participation Discount') {
            this.router.navigate([
                'sride', 'discountTrend',
                this.route.snapshot.params.periodUnit,
                this.route.snapshot.params.periodEnd
            ]);
        }
    }

    getDateForPeriodChange(): string {
        return this.content.dateAsRequested;
    }

    getTermsAndConditionConsentStatus(vin, type): string {
        let status: string;
        if (this.content.vehicle.programType === 'TC') {
            this.termAndConditionStatusService.getConnectedCarConsentStatus(vin, type).subscribe((response) => {
                status = response?.[0]?.statusCode;
            });
            return status;
        }
    }

    isDataSharing(): boolean {
        return this.content.vehicle.device.status.toLowerCase().includes('active');
    }

    showDataNotSharingNotification(): boolean {
        if (this.content.vehicle.programType === 'TC' && !this.isDataSharing()) {
            return true;
        }
        // else {
        //     return false;
        // }
    }
}
