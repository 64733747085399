import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'app/service/user.service';
import { Alert } from 'app/enum/alert';
import { Vehicle } from 'app/model/vehicle';
import { NavbarService } from '../../service/nav-bar.service';
import { EbiEventService } from 'app/service/ebi-event.service';
import * as moment from 'moment';
import { environment } from 'environments/environment';

@Component({
    selector: 'app-device-alert',
    templateUrl: './device-alert.component.html',
    styleUrls: ['./device-alert.component.css']
})
export class DeviceAlertComponent implements OnInit {
    vehicle: Vehicle;
    alertType: Alert;
    helpPhoneNumber: string;
    dailyDefaultMiles: number;
    roadTripMaxMiles: number;
    deviceLastUpdated: Date;

    // eslint-disable-next-line max-params
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private user: UserService,
        private navBar: NavbarService,
        public ebiEvents: EbiEventService
    ) { }

    ngOnInit(): void {
        this.vehicle = this.route.snapshot.data.vehicle;
        this.helpPhoneNumber = '1-855-421-2688';
        this.dailyDefaultMiles = 35;
        this.roadTripMaxMiles = 250;
        this.alertType = this.route.snapshot.params.alertType;
        if (<any> this.alertType === Alert.None) {
            const showMonth = moment.min(moment(this.vehicle.periodEnd), moment(environment.futureDate)).format('YYYYMMDD');
            this.router.navigateByUrl(`smiles/${showMonth}/month/monthlySummary`);
        } else {
            this.navBar.headerDisplay(this.user.isLoggedIn, this.router.url);
        }
        this.overwriteDeviceLastUpdated();
    }

    closeAlert(): void {
        this.user.alertType = Alert.None;
        if (this.alertType === Alert.RoadTripException) {
            this.ebiEvents.postEvent('Trip exception continue');
        }
        const showMonth = moment.min(moment(this.vehicle.periodEnd), moment(environment.futureDate)).format('YYYYMMDD');
        this.router.navigateByUrl(`smiles/${showMonth}/month/monthlySummary`);
    }

    private overwriteDeviceLastUpdated(): void {
        if (this.vehicle?.ratedMileage && this.vehicle.ratedMileage.length > 0) {
            let lastUpdated = this.vehicle.deviceStatusTimeline.enrollDate;
            this.vehicle.ratedMileage.forEach((mileage) => {
                if (moment(mileage.ratedMileageDate).isSameOrAfter(moment(lastUpdated))) {
                    lastUpdated = mileage.ratedMileageDate;
                }
            });
            this.deviceLastUpdated = lastUpdated;
        }
    }
}
