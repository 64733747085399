import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private isAdmin: boolean;
  private isAgent: boolean;

  ngOnInit(): void {
    if (sessionStorage.getItem('isAgent') === 'true') {
        this.isAgent = true;
    }
    if (sessionStorage.getItem('isAdmin') === 'true') {
      this.isAdmin = true;
    }
  }

  setUser(user: string): void {
    if (user === 'Admin') {
      this.isAdmin = true;
    } else if (user === 'Agent') {
      this.isAgent = true;
      sessionStorage.setItem('isAgent', 'true');
    }
  }

  getIsAdmin(): boolean {
    return this.isAdmin;
  }

  getIsCustomer(): boolean {
    if (!this.isAdmin && !this.isAgent) {
      return true;
    }
    return false;
  }

  getIsAgent(): boolean {
    return this.isAgent;
  }
}
