import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { OAuthEmitterService, OAuthEvent } from '@nationwide/angular-oauth-module';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { timeOutConfig } from './app-service-config';
import { BrandingService } from 'app/service/branding.service';
import { environment } from '../../environments/environment';
import { TimeoutModalComponent } from './time-out-modal.component';
import { UserService } from 'app/service/user.service';
import { TermsAndConditionsPopupComponent } from './terms-and-conditions-popup.component';

@Injectable()
export class TimeoutModalService {
  private lastSessionActivityTime: number;
  private isOpen: boolean;
  private checkTimeOutInterval: any;
  private isTermsAndConditionsPopupOpen: boolean;
  // eslint-disable-next-line max-params
  constructor(
    protected router: Router,
    private oauthEmitterService: OAuthEmitterService,
    private modalService: NgbModal,
    private brandingService: BrandingService,
    public user: UserService
  ) {
    this.lastSessionActivityTime = this.currentTime();
    this.isOpen = false;
  }

  pollTimeOut(): void {
    if (this.checkTimeOutInterval !== undefined) {
      clearInterval(this.checkTimeOutInterval);
    }
    this.checkTimeOutInterval = setInterval(() => this.onTimeoutPoll(), timeOutConfig.pollIntervalMilliseconds);
  }

  onExcludedRoute(): boolean {
    return environment.authorize.excludedRoutes.indexOf(this.router.url) !== -1;
  }

  gotoLogin(): void {
    this.brandingService.logoutWithBranding();
    window.sessionStorage.clear();
    this.modalService.dismissAll();
  }

  logOutUser(): void {
    this.user.logout();
  }

  recordSessionActivity(): void {
    this.lastSessionActivityTime = this.currentTime();
  }

  private onTimeoutPoll(): void {
    if (!this.onExcludedRoute()) {
      if (this.shouldTimeoutUser()) {
        this.gotoLogin();
      } else if (this.shouldRefreshSessionForActiveUser()) {
        this.refreshSession();
      } else if (this.shouldWarnUser()) {
        this.showTimeOutModal();
      }
    }
  }

  private shouldTimeoutUser(): boolean {
    return this.currentTime() > this.tokenExpiration();
  }

  private shouldRefreshSessionForActiveUser(): boolean {
    return this.nearSessionExpiration() && this.userIsActive();
  }

  private refreshSession(): void {
    this.oauthEmitterService.emitEvent(new OAuthEvent('OAuthPending'));
    this.isOpen = false;
  }

  private shouldWarnUser(): boolean {
    return this.nearSessionExpiration() && !this.isOpen;
  }

  private showTimeOutModal(): Promise<void> {
    const modalRef = this.openModal();
    const onKeepWorking = (): void => this.refreshSession();
    const onLogOut = (): void => this.logOutUser();
    return modalRef.result.then(onKeepWorking, onLogOut);
  }

  private openModal(): NgbModalRef {
    this.isOpen = true;
    return this.modalService.open(TimeoutModalComponent, {
      backdrop: 'static', // So overlay click doesn't dismiss modal
      keyboard: false, // So ESC key doesn't dismiss modal
      windowClass: 'wait-overlay'
    });
  }

  openTermsAndConditionsModal(): NgbModalRef {
    this.isTermsAndConditionsPopupOpen = true;
    return this.modalService.open(TermsAndConditionsPopupComponent, {
      backdrop: 'static', // So overlay click doesn't dismiss modal
      keyboard: false, // So ESC key doesn't dismiss modal
      windowClass: 'wait-overlay'
    });
  }

  private userIsActive(): boolean {
    return this.currentTime() - timeOutConfig.inactivityThresholdMilliseconds < this.lastSessionActivityTime;
  }

  private nearSessionExpiration(): boolean {
    return this.currentTime() > this.tokenExpiration() - timeOutConfig.timeToTimeoutWarningMilliseconds;
  }

  private currentTime(): number {
    return new Date().getTime();
  }

  private tokenExpiration(): number {
    const concatenatedDetailsString = window.sessionStorage.getItem(timeOutConfig.accessTokenKey);
    if (concatenatedDetailsString) {
      const details = concatenatedDetailsString.split(',');
      const lifetime = +details[1];
      return lifetime;
    }
    return NaN;
  }
}
