/* eslint-disable no-magic-numbers */
import { Injectable } from '@angular/core';
import { CustomValidatorService, Form } from '@nationwide/dgs-internet-servicing-dynamic-forms';

@Injectable()
export class QuickIDSearchFormService {
    private currentDate: CurrentDate;

    constructor(
        private customValidatorService: CustomValidatorService,
    ) { }

    setCurrentDate(): void {
        const currentTime = new Date();
        this.currentDate.day = String(currentTime.getDay());
        this.currentDate.month = String(currentTime.getMonth());
        this.currentDate.year = String(currentTime.getFullYear());
    }

    returnJSON(isPhoneNumberFieldVisible?: boolean): Form {
        this.currentDate = {
          day: '',
          month: '',
          year: ''
        };
        this.setCurrentDate();
        return {
            sections: {
                quickIDSearch: {
                    visible: true,
                    fields: {
                        SelectNumber: {
                            required: true,
                            label: 'What number do you want to use?',
                            htmlClass: 'col-sm-12 clearfix radio-stack-label',
                            type: 'radios',
                            labelHtmlClass: 'legend-label',
                            options: [{ value: true, key: 'Phone number' }, { value: false, key: 'Auto policy account number' }],
                            condition: `${isPhoneNumberFieldVisible}`,
                            useBolt: true
                        },
                        PhoneNumber: {
                            required: true,
                            label: 'Phone number',
                            validationMessage: {
                                invalidPhone: 'Phone number is invalid.',
                                required: 'Phone number is required.'
                            },
                            validator: [
                                this.customValidatorService.validatePhoneNumber()
                            ],
                            htmlClass: 'col-12 clearfix margin-bottom-2xl',
                            fieldHtmlClass: 'nw-input-xxs--full nw-input-xs--full nw-input-sm--medium nw-input-md--short',
                            condition: `${isPhoneNumberFieldVisible} && form.value['quickIDSearch-SelectNumber'] == 'true'`,
                            mask: 'phone',
                            useBolt: true
                        },
                        PolicyNumber: {
                            required: true,
                            label: 'Auto policy account number',
                            validationMessage: {
                                invalidPolicy: 'Policy number is invalid.',
                                required: 'Policy number is required.'
                            },
                            validator: [
                                this.customValidatorService.validatePolicyNumber()
                            ],
                            htmlClass: 'col-12 clearfix margin-bottom-2xl',
                            fieldHtmlClass: 'nw-input-xxs--full nw-input-xs--full nw-input-sm--medium nw-input-md--short',
                            serviceCall: 'policyNumber',
                            condition: !isPhoneNumberFieldVisible || `form.value['quickIDSearch-SelectNumber'] == 'false'`,
                            useBolt: true
                        },
                        ZipCodeAndDateHeader: {
                            template: `<div id="zipCodeAndDateHeader"><h2 class="landing-page-header-text">Also enter your ZIP code and date of birth.</h2></div>`,
                            type: 'template'
                        },
                        ZipInput: {
                            required: true,
                            label: 'ZIP code',
                            maxLength: 5,
                            validationMessage: {
                                invalidZip: 'Zip code is invalid.',
                                required: 'Zip code is required.'
                            },
                            validator: [
                                this.customValidatorService.validateZip()
                            ],
                            htmlClass: 'col-12 clearfix',
                            fieldHtmlClass: 'nw-input-xxs--full nw-input-xs--full nw-input-sm--medium nw-input-md--short',
                            useBolt: true
                        },
                        DobInput: {

                            required: true,
                            label: 'Date of birth',
                            validationMessage: {
                                required: 'Date of birth is required.',
                                pattern: 'Check the date of birth format.',
                                invalidAge: 'Driver must be of legal driving age.',
                                futureDateError: 'The date cannot be in the future.',
                                invalidDate: 'Invalid date format.',
                                ageRangeError: 'Please enter a date 16 years old or older.'
                            },
                            maxLength: 10,
                            validator: [
                                this.customValidatorService.futureDate(this.currentDate),
                                this.customValidatorService.minimumAge(14, this.currentDate),
                                this.customValidatorService.validDate(),
                                this.customValidatorService.ageWithinRange(13, 16, this.currentDate)
                            ],
                            serviceCall: 'dateOfBirth',
                            htmlClass: 'col-12 clearfix',
                            fieldHtmlClass: 'nwpii nw-input-xxs--full nw-input-xs--full nw-input-sm--medium nw-input-md--short',
                            subtext: 'Use this format: MM/DD/YYYY',
                            subtextClass: 'sub-help-text',
                            pattern: '^(0[1-9]|1[012])[/](0[1-9]|[12][0-9]|3[01])[/][0-9]{4}$',
                            shouldAppendSlashes: true,
                            useBolt: true
                        }
                    }
                }
            }
        };
    }
}

export interface CurrentDate {
  month: string;
  day: string;
  year: string;
}
