import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Moment } from 'moment';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { Discount } from 'app/model/discount';
import { SmartRideDiscountTrendContent } from './smart-ride-discount-trend-content';
import { EbiEventService } from 'app/service/ebi-event.service';
import { NavbarService } from 'app/service/nav-bar.service';
import { UserService } from 'app/service/user.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'smart-ride-discount-summary/app-smart-ride-discount-trend',
  templateUrl: './smart-ride-discount-trend.component.html',
  styleUrls: ['./smart-ride-discount-trend.component.css']
})
export class SmartRideDiscountTrendComponent implements OnInit, OnDestroy {
  periodUnit: string;
  date: string;
  content: SmartRideDiscountTrendContent;
  private dataSubscription: Subscription;
  private pathParamsSubscription: Subscription;

  // eslint-disable-next-line max-params
  constructor(
    public route: ActivatedRoute,
    public _router: Router,
    public ebiService: EbiEventService,
    public _navbar: NavbarService,
    public _user: UserService
  ) {
  }

  ngOnInit(): void {
    if (this.route.snapshot) { // Sets smartride header
      this._navbar.headerDisplay(this._user.isLoggedIn, this.route.snapshot.url.toString());
    }
    this.dataSubscription = this.route.data.subscribe((params) => {
      this.content = params['content'];
    });
    this.pathParamsSubscription = this.route.params.subscribe((params) => {
      this.periodUnit = params['periodUnit'];
      this.date = params['date'];
      this.postEbiLoadEvent();
    });
  }

  ngOnDestroy(): void {
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
      this.dataSubscription = null;
    }
    if (this.pathParamsSubscription) {
      this.pathParamsSubscription.unsubscribe();
      this.pathParamsSubscription = null;
    }
  }

  postEbiLoadEvent(): void {
    switch (this.periodUnit) {
      case 'total':
      case 'month':
        this.ebiService.postSrideEvent('425080022');
        break;
      case 'week':
        this.ebiService.postSrideEvent('425080020');
        break;
    }
  }

  // User clicks a date range on the trend graph, this routes them to that weekly or monthly summary
  routeToTime(discount, event: Event): Promise<boolean> {
    event.preventDefault();

    const targetDate = discount.date;
    const targetPeriodUnit = this.periodUnit === 'week' ? 'week' : 'month';
    return this._router.navigate([
      'sride', 'summary',
      targetPeriodUnit,
      targetDate.format('YYYYMMDD')
    ]);
  }

  goBack(): void {
    const targetDate = this.content.discounts[0].date.clone().add(-1, <any> this.periodUnitAsMoment());
    this.navigateToMoment(targetDate);
  }

  goForward(): void {
    const targetDate = this.content.discounts[this.content.discounts.length - 1].date.clone().add(1, <any> this.periodUnitAsMoment());
    this.navigateToMoment(targetDate);
  }

  getUrlForBreadcrumbLink(): string {
    return `/sride/summary/${this.periodUnit}/${this.date}`;
  }

  getTextForBreadcrumbLink(): string {
    const vehicleTitle = `${this.content.vehicle.year} ${this.content.vehicle.make} ${this.content.vehicle.model}`;
    let pageTitle;
    switch (this.periodUnit) {
      case 'week': pageTitle = 'Weekly Summary'; break;
      case 'month': pageTitle = 'Monthly Summary'; break;
      case 'total': pageTitle = 'Total Summary'; break;
      default: pageTitle = '';
    }
    return `< ${vehicleTitle} ${pageTitle}`;
  }

  getPageTitleText(): string {
    switch (this.periodUnit) {
      case 'week': return 'Weekly Discount Trend';
      case 'month': return 'Monthly Discount Trend';
      case 'total': return 'Total Discount Trend';
    }
    return 'Discount Trend';
  }

  shouldShowDashesInPlaceOfDiscountAmount(discount: Discount): boolean {
    if (!discount) {
      return true;
    }
    if (discount.discountType === 'No Data') {
      return true;
    }
    if (discount.discountType === 'Leftover') {
      return true;
    }
    if (discount.amount === null) {
      return true;
    }
    if (discount.amount === undefined) {
      return true;
    }
    return false;
  }

  selectCssClassForDiscountGradient(discount: Discount): string {
    if (!discount) {
      return 'high-discount-null';
    }
    if (discount.amount === null) {
      return 'high-discount-null';
    }
    if (discount.amount === undefined) {
      return 'high-discount-null';
    }
    if (discount.discountType === 'No Data') {
      return 'high-discount-null';
    }
    if (discount.discountType === 'Leftover') {
      return 'high-discount-null';
    }
    const gradientThreshold = 30;
    if (discount.amount <= gradientThreshold) {
      return 'no-gradient';
    }
    if (this.isCurrentDiscount(discount)) {
      return 'high-discount-current';
    }
    return 'high-discount-gradient';
  }

  selectCssClassForDiscountBar(discount: Discount): string {
    if (this.isCurrentDiscount(discount)) {
      return 'trend--level-current';
    }
    return 'trend--level';
  }

  isCurrentDiscount(discount: Discount): boolean {
    const now = moment(environment.futureDate);
    if (this.periodUnit === 'week') {
      if (now < discount.beginningOfWeek) {
        return false;
      }
      if (now > discount.endOfWeek) {
        return false;
      }
      return true;
    } else {
      return now.isSame(discount.date, 'month');
    }
  }

  shouldShowDiscountTrendBar(discount: Discount): boolean {
    if (!discount) {
      return false;
    }
    if (discount.amount === null) {
      return false;
    }
    if (discount.amount === undefined) {
      return false;
    }
    return true;
  }

  getDateDisplayMode(discount: Discount): string {
    const vehicle = this.content.vehicle;
    let endDate;
    const finalDate = vehicle.deviceStatusTimeline.completionDate;
    if (vehicle.deviceStatusTimeline && finalDate && moment(finalDate).isBefore(moment(environment.futureDate))) {
      endDate = moment(finalDate).clone().endOf(<any> this.periodUnitAsMoment());
    } else {
      endDate = moment(environment.futureDate);
    }
    if (discount && discount.date > endDate) {
      discount.afterPolicyEnd = true;
    }

    let leftoverPrefix = '';
    if (discount && discount.date > moment(vehicle.deviceStatusTimeline.finalDiscountDate).clone().endOf('month')) {
      discount.afterPolicyEnd = true;
      leftoverPrefix = 'leftover-';
      discount.amount = null;
    }

    if (!discount) {
      leftoverPrefix = 'leftover-';
    }
    if (this.periodUnit === 'month') {
      return `${leftoverPrefix}month`;
    }
    if (this.periodUnit === 'week') {
      return `${leftoverPrefix}week`;
    }
    return `${leftoverPrefix}month`; // Total, or unexpected.
  }

  createAriaLabel(amount: number, date: string): string {
    let label = '';
    label = `${label + moment(date).format('MMMM')} Discount is ${amount} percent clickable`;
    return label;
  }

  filterDiscounts(discounts: any[]): any[] {
    const maxMonthsToShow = 7;
    const filteredDiscounts = [];
    discounts.forEach((discount) => {
      if (discount.amount !== null) {
        filteredDiscounts.push(discount);
      }
    });
    this.content.laterDataExist = filteredDiscounts.length === maxMonthsToShow;
    return filteredDiscounts;
  }

  private periodUnitAsMoment(): string {
    if (this.periodUnit === 'week') {
      return 'week';
    }
    return 'month';
  }

  private navigateToMoment(targetDate: Moment): Promise<boolean> {
    return this._router.navigate([
      'sride', 'discountTrend',
      this.periodUnit,
      targetDate.format('YYYYMMDD')
    ]);
  }
}
