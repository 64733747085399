import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ServiceErrorHandler } from '../utility/service-error-handler';
import { catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { APICommonService } from '@nationwide/api-common-service';

@Injectable()
export class PrintLabelService {
  printLabelURL = 'printLabel';

  constructor(
    public _http: HttpClient,
    public _errorHandler: ServiceErrorHandler,
    private apiCommonService: APICommonService
  ) { }

  printLabel(vendorId: string): Observable<any> {
    const urlPrefix = environment.sreApiURL;
    const reqHeader = new HttpHeaders()
      .set('X-NW-Message-ID', this.apiCommonService.generateTransactionId())
      .set('client_id', environment.apiKey)
      .set('Accept', 'application/pdf');

    return this._http.get(urlPrefix + this.printLabelURL, {
      headers: reqHeader,
      params: {
        policy: decodeURIComponent(sessionStorage.getItem('selectedPolicy')),
        deviceVendorID: vendorId
      }, responseType: 'blob'
    })
      .pipe(
        catchError((error) => {
          this._errorHandler.handleError('printLabel');
          return of(error);
        })
      );
  }
}
