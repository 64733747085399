import { environment } from 'environments/environment';
import { ParamsDataInterface } from '@nationwide/nw-validator-service';

export const ValidatorParams: ParamsDataInterface[] = [
  {
    uniqueIdInPayload: false,
    endpointPrefix: environment.sreApiURL,
    urlUniqueId: (requestUrl, requestUrlWithParams): string => {
      let uniqueId = '';
      const includedEndPoint: Array<string> = ['summary'];
      includedEndPoint.forEach((word) => {
        if (requestUrlWithParams.indexOf(word) > -1) {
          uniqueId = word;
        }
      });

      uniqueId = uniqueId + requestUrlWithParams.substring(requestUrl.length, requestUrlWithParams.indexOf('&')).split('=').pop();
      return uniqueId;
    },
    includedEndpoint: ['summary'],
    excludedEndpoint: ['trips', 'preferences', 'discounts', 'vehicles'],
    verb: 'GET',
    serviceName: 'SmartRideExperience_2'
  },
  {
    uniqueIdInPayload: false,
    endpointPrefix: environment.personalLinesPolicyService.url,
    urlUniqueId: (requestUrlWithParams): string => {
      let uniqueId = '';
      uniqueId = `policies${
        requestUrlWithParams.substring(environment.personalLinesPolicyService.url.length, requestUrlWithParams.indexOf('?'))}`;
      return uniqueId;
    },
    includedEndpoint: ['policies'],
    excludedEndpoint: ['trips', 'preferences', 'discounts', 'experience', 'vehicles'],
    verb: 'POST',
    serviceName: 'SmartRideExperience_2'
  },
  {
    uniqueIdInPayload: false,
    endpointPrefix: environment.personalLinesPolicyService.url,
    urlUniqueId: (requestUrlWithParams): string => {
      let uniqueId = '';
      uniqueId = `policies${
        requestUrlWithParams.substring(environment.personalLinesPolicyService.url.length, requestUrlWithParams.indexOf('?'))}`;
      return uniqueId;
    },
    includedEndpoint: ['policies'],
    excludedEndpoint: ['trips', 'preferences', 'discounts', 'experience'],
    verb: 'POST',
    serviceName: 'SmartRideExperience_2'
  },
  {
    uniqueIdInPayload: false,
    endpointPrefix: 'https://api-test.nwie.net/policy-management/telematics/v1/policies/',
    urlUniqueId: (requestUrlWithParams): string => {
      let uniqueId = '';
      const request = 'https://api-test.nwie.net/policy-management/telematics/v1/policies/';
      uniqueId = requestUrlWithParams.substring(request.length, requestUrlWithParams.indexOf('vehicles') - 1);
      uniqueId = `ratedMileage${uniqueId}`;
      return uniqueId;
    },
    includedEndpoint: ['rated-mileages'],
    excludedEndpoint: ['estimated-premium'],
    verb: 'GET',
    serviceName: 'SmartRideExperience_2'
  }
];
