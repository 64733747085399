import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-timeout-modal',
  templateUrl: './time-out.component.html'
})
export class TimeoutModalComponent {
  tridionHelpText;
  timeoutModalComponent;

  constructor(public activeModal: NgbActiveModal) {
    this.timeoutModalComponent = this;
  }
}
