import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dollars'
})
export class DollarsPipe implements PipeTransform {
  transform(value: number): string {
    let valueNum: number = +value;
    const centsInDollar = 100;
    if (valueNum < 0 && valueNum % 1 !== 0) {
      valueNum = valueNum + 1;
    }
    valueNum = Math.floor(valueNum * centsInDollar);
    valueNum = Math.floor(valueNum / centsInDollar);
    if (valueNum === 0) {
      return '0';
    }
    return valueNum.toString();
  }
}
