import { NgModule } from '@angular/core';
import { TimeoutModalComponent } from './time-out-modal.component';
import { TimeoutModalService } from './time-out.service';
import { ModalWrapperComponent } from './modal-wrapper.component';
import { InternetServicingCommonModule } from '@nationwide/dgs-internet-servicing-common';
import { CommonModule } from '@angular/common';
import { IsFocusedDirective } from './is-focused.directive';
import { TermsAndConditionsPopupComponent } from './terms-and-conditions-popup.component';

@NgModule({
  imports: [
    InternetServicingCommonModule,
    CommonModule
  ],
  declarations: [
    ModalWrapperComponent,
    TimeoutModalComponent,
    IsFocusedDirective,
    TermsAndConditionsPopupComponent
  ],
  exports: [
    ModalWrapperComponent,
    IsFocusedDirective,
    TermsAndConditionsPopupComponent
  ],
  entryComponents: [
    TimeoutModalComponent
  ],
  providers: [
    TimeoutModalService
  ]
})
export class SessionServicesModule { }
