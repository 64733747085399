/* eslint-disable max-params*/
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { SreSummaryContent, RatingFactorForDisplay } from 'app/components/sre-summary/sre-summary-content';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { VehicleService } from 'app/service/vehicle.service';
import { SreSummaryService, MonthlyWeeklyTotalSummary, EventInformation, Threshold } from 'app/service/sre-summary.service';
import { Discount } from 'app/model/discount';
import * as moment from 'moment';
import { UserService } from 'app/service/user.service';
import { LoggerService } from 'app/service/logger.service';
import { environment } from 'environments/environment';

@Injectable()
export class SreSummaryContentResolver {
    constructor(
        private _vehicleService: VehicleService,
        private _sreSummaryService: SreSummaryService,
        private _user: UserService,
        private _router: Router,
        private logger: LoggerService
    ) { }

    resolve(route: ActivatedRouteSnapshot): Observable<SreSummaryContent> {
        const vin = this._user.selectedVin;
        const programType = this._user.selectedVehicleProgramType;
        const periodUnit = route.params.periodUnit;
        let periodEnd = route.params.periodEnd;
        const result = new SreSummaryContent();
        if (!vin || vin === '') {
            this._router.navigateByUrl('programSelect');
        }
        if (!periodUnit || periodUnit === '') {
            this._router.navigateByUrl('not-found');
        }
        if (periodUnit !== 'day' && periodUnit !== 'week' && periodUnit !== 'month' && periodUnit !== 'total') {
            this._router.navigateByUrl('not-found');
        }
        result.dateAsRequested = route.params.periodEnd;
        this._user.selectedVin = vin;

        if (periodEnd !== 'latest' && !(/^\d+$/).test(periodEnd)) {
            periodEnd = 'latest';
        }

        return this._vehicleService.getVehicleByVin(vin, programType).pipe(mergeMap((vehicleResponse) => {
            result.vehicle = vehicleResponse;
            result.discount = this.selectDiscount(vehicleResponse, periodEnd, periodUnit);

            return this._sreSummaryService.fetchOneSummaryReport(
                vehicleResponse.vid, periodEnd, periodUnit,
                vehicleResponse.scoringModel, vehicleResponse.state, vehicleResponse.deviceStatusTimeline
            ).pipe(
                map((summaryResponse) => {
                    result.summary = summaryResponse;
                    result.vehicle = vehicleResponse;
                    result.firstSummaryDate = this._sreSummaryService.getFirstCachedDateForPeriodUnit(periodUnit, vehicleResponse.vid);
                    result.lastSummaryDate = this._sreSummaryService.getLastCachedDateForPeriodUnit(periodUnit, vehicleResponse.vid);
                    result.projectedAnnualMileage = this._sreSummaryService.getProjectedAnnualMileage(
                        vehicleResponse.vid, periodEnd, periodUnit, vehicleResponse.deviceStatusTimeline);
                    result.factors = this.buildFactorsFromSummary(result.summary, result.vehicle.scoringModel);
                    return result;
                }),
                catchError((error) => {
                    this.logger.error('!!! Caught error in SreSummaryContentResolver. Defaulting summary.', { error });
                    result.summary = <MonthlyWeeklyTotalSummary>{}; // TODO default summary
                    return of(result);
                })
            );
        }));
    }

    selectDiscount(vehicleResponse, periodEnd: string, periodUnit: string): Discount {
        const daysInWeek = 7;
        let nearest: Discount = null;
        let nearestDistance = 0;
        let referenceDate;
        if (periodEnd === 'latest') {
            referenceDate = moment(environment.futureDate);
        } else if (periodUnit === 'month' || periodUnit === 'total') {
            referenceDate = moment(periodEnd).endOf('month');
        } else {
            // Weekly reference date, advance to next Sunday:
            referenceDate = moment(periodEnd);
            if (referenceDate.day() > 0) {
                referenceDate.day(daysInWeek);
            }
        }
        if (vehicleResponse.discounts) {
            for (const discount of vehicleResponse.discounts) {
                const distance = referenceDate.diff(discount.date);
                if (!nearest || distance < nearestDistance && distance >= 0) {
                    nearest = discount;
                    nearestDistance = distance;
                }
            }
        }

        if (!nearest) {
            return null;
        }

        // If we ended up with a discount in the wrong period, that's OK, but clone it and wipe the trend.
        // This can happen if the discount list is incomplete; we return the first or last one repeatedly.
        const actualPeriodUnit = periodUnit === 'total' ? 'month' : periodUnit;
        const elapsed = referenceDate.diff(nearest.date, actualPeriodUnit);
        if (elapsed > 0) {
            nearest = nearest.clone();
            nearest.discountLastWeek = null;
            nearest.discountLastMonth = null;
        }

        return nearest;
    }

    buildFactorsFromSummary(summary: MonthlyWeeklyTotalSummary, scoringModel: string): RatingFactorForDisplay[] {
        const factors = [];

        let threshold: Threshold = summary.thresholds;
        if (!threshold) {
            threshold = <Threshold>{};
        }

        this.addFactor(factors, 'Miles Driven', summary.miles, 'mile', threshold.miles);

        if (scoringModel === 'TW' || scoringModel === 'TW1') {
            this.addFactor(factors, 'Hard Braking', summary.braking, 'event', threshold.braking);
            this.addFactor(factors, 'Fast Acceleration', summary.acceleration, 'event', threshold.acceleration);
        }

        if (scoringModel !== 'MO') {
            this.addFactor(factors, 'Nighttime Driving', summary.night, 'minute', threshold.night);
        }

        if (scoringModel === 'ND1' || scoringModel === 'TW2') {
            if (summary.idleTimeRatio) {
                const number100 = 100;
                summary.idle.amount = summary.idleTimeRatio.amount * number100;
            }
            this.addFactor(factors, 'Idle Time', summary.idle, null, threshold.idle);
            this.addFactor(factors, 'Hard Braking / Acceleration', summary.brakingAcceleration, 'event', threshold.brakingAcceleration);
        }

        return factors;
    }

    addFactor(factors: RatingFactorForDisplay[], name: string, event: EventInformation, unit: string, threshold: number[]): void {
        const factor: RatingFactorForDisplay = new RatingFactorForDisplay();
        factor.event = event;
        factor.displayName = name;
        factor.unit = unit;
        factor.thresholds = threshold;
        factors.push(factor);
    }
}
