export class TelematicsPaymentDetails {
    costPerMile;
    estimatedMileagePremium;
    actualTotalPremium;
    monthlyBasePremium;
    estimatedMiles;
    annualMileage;
    typicalAnnualMileage;
    currentTermMileage;
    estimatedTermMileage;
    ratedTermMileage;
    mileageDifference;
    mileagePremiumDifference;
    startDate;
    endDate;
    reverifiedMiles: any;
    nonWeightedCostPerMile: any;
    previousMonthNonWeightedCostPerMile;
    actualMiles: number;
    version: string;
    previousMonthMiles;
    firstMonthStartDate;
    lastMonthEndDate;
    actualMileagePremium;
}
