import { AddressDTO } from './addressDTO';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const AdditionalInterestDTO = {
    MiddleName: {
        name: 'MiddleName',
        type: 'String',
        required: false
    },
    LastName: {
        name: 'LastName',
        type: 'String',
        required: true,
        visibilityCondition: '\'true\' === form.value[\'IsPerson\']'
    },
    PublicID: {
        name: 'PublicID',
        type: 'String',
        required: true,
        visibilityCondition: '\'\' === form.value[\'TempID\']'
    },
    CompanyName: {
        name: 'CompanyName',
        type: 'String',
        required: true,
        visibilityCondition: '\'true\' === form.value[\'IsCompany\']'
    },
    AdditionalInterestType: {
        name: 'AdditionalInterestType',
        class: 'typekey.AdditionalInterestType',
        required: true
    },
    IsCertRequired: {
        name: 'IsCertRequired',
        type: 'Boolean',
        required: false
    },
    TempID: {
        name: 'TempID',
        type: 'Integer',
        required: true,
        visibilityCondition: '\'\' === form.value[\'PublicID\']'
    },
    FixedId: {
        name: 'FixedId',
        type: 'Long',
        required: true,
        visibilityCondition: '\'\' === form.value[\'TempID\']'
    },
    Suffix: {
        name: 'Suffix',
        class: 'typekey.NameSuffix',
        required: false
    },
    IsCompany: {
        name: 'IsCompany',
        type: 'Boolean',
        required: true
    },
    SsnOfficialId: {
        name: 'SsnOfficialId',
        type: 'String',
        required: false
    },
    Address: {
        name: 'Address',
        class: 'com.nationwide.edge.capabilities.address.dto.NWAddressDTO',
        required: true,
        ...AddressDTO
    },
    ContractNumber: {
        name: 'ContractNumber',
        type: 'String',
        required: false
    },
    FirstName: {
        name: 'FirstName',
        type: 'String',
        required: true,
        visibilityCondition: '\'true\' === form.value[\'IsPerson\']'
    },
    ExpirationDate: {
        name: 'ExpirationDate',
        type: 'Date',
        required: false
    },
    IsPerson: {
        name: 'IsPerson',
        type: 'Boolean',
        required: true
    }
};
