import { Component, Input, OnInit } from '@angular/core';
import { ContentfulService } from '@nationwide/internet-servicing-contentful';
import { LoggerService } from 'app/service/logger.service';

@Component({
    selector: 'app-wait-message-wrapper',
    templateUrl: './wait-message-wrapper.component.html'
})
export class WaitMessageWrapperComponent implements OnInit {
    @Input() autoFocusMessages = false;
    @Input() expirationTime: number;
    @Input() loadComplete = false;
    @Input() timeoutAmount: number;
    @Input() waitMessage: string;

    constructor(
        private LOGGER: LoggerService,
        public contentfulService: ContentfulService
    ) { }

    ngOnInit(): void {
        if (!this.expirationTime) {
            this.expirationTime = 40000; // 40 seconds
        }
    }

    waitTimeExpired(reason: string): void {
        if (reason) {
            // eslint-disable-next-line no-magic-numbers
            this.LOGGER.info(`Wait spinner has been displaying for ${this.expirationTime / 1000} seconds`);
        }
    }
}
