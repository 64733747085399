// eslint-disable-next-line @typescript-eslint/naming-convention
export const VehiclePrimaryUse = {
    type: 'edge.metadata.typeinfo.typelistinfo.dto.TypelistMetadataDTO',
    value: {
        codes: [
            {
                code: 'Farm',
                priority: 10,
                displayKey: 'typekey.VehiclePrimaryUse_Ext.Farm'
            },
            {
                code: 'IndividualBusiness',
                priority: 20,
                displayKey: 'typekey.VehiclePrimaryUse_Ext.IndividualBusiness'
            },
            {
                code: 'Pleasure',
                priority: 30,
                displayKey: 'typekey.VehiclePrimaryUse_Ext.Pleasure'
            },
            {
                code: 'Restricted',
                priority: 40,
                displayKey: 'typekey.VehiclePrimaryUse_Ext.Restricted'
            },
            {
                code: 'Work',
                priority: 50,
                displayKey: 'typekey.VehiclePrimaryUse_Ext.Work'
            },
            {
                code: 'CorporatelyOwnedBusinessUse',
                priority: 60,
                displayKey: 'typekey.VehiclePrimaryUse_Ext.CorporatelyOwnedBusinessUse'
            }
        ],
        filters: [
            {
                name: 'ExcludeRestricted',
                includedCodes: [
                    'Farm',
                    'IndividualBusiness',
                    'Pleasure',
                    'Work'
                ]
            },
            {
                name: 'ExcludeRestrictedIncludesCorpo',
                includedCodes: [
                    'Farm',
                    'IndividualBusiness',
                    'Pleasure',
                    'Work',
                    'CorporatelyOwnedBusinessUse'
                ]
            }
        ],
        namedMetadata: []
    }
};
