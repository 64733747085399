import { Component, ElementRef, OnInit, OnDestroy, HostListener } from '@angular/core';
import { OAuthEmitterService } from '@nationwide/angular-oauth-module';
import { Subscription } from 'rxjs';
import { UserService } from 'app/service/user.service';
import { TimeoutModalService } from './session-time-out/time-out.service';
import { LoggerService } from './service/logger.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit, OnDestroy {
    oauthSubscription: Subscription;
    canLoadApplication = false;
    targetOpts = {
        mbox: 'simpleDirective'
    };

    // eslint-disable-next-line max-params
    constructor(
        private el: ElementRef,
        private oauthEmitterService: OAuthEmitterService,
        public _user: UserService,
        private timeoutModalService: TimeoutModalService,
        private logger: LoggerService
    ) { }

    @HostListener('window:mousedown', ['$event'])
    @HostListener('window:mouseup', ['$event'])
    onWindowScroll(): void {
        this.timeoutModalService.recordSessionActivity();
    }

    ngOnInit(): void {
        this.oauthSubscription = this.oauthEmitterService.oauthEmitter.subscribe((event) => {
            if (!this.oauthEmitterService.inDescendantIframe()) {
                if (event.type === 'OAuthSuccess' || event.type === 'OAuthNotNecessary') {
                    this.canLoadApplication = true;
                    this._user.evaluateJwtInEuaResponse();
                    this.timeoutModalService.pollTimeOut();
                } else if (event.type === 'OAuthFailure') {
                    this.canLoadApplication = true;
                    this.logger.error('failure in app component', { error: event.description });
                }
            }
        });
    }

    ngOnDestroy(): void {
        if (this.oauthSubscription && typeof this.oauthSubscription.unsubscribe !== 'undefined') {
            this.oauthSubscription.unsubscribe();
        }
    }

    skipToMain(): void {
        let mainElement = this.el.nativeElement.querySelector('#main');
        if (!mainElement) {
            mainElement = this.el.nativeElement.querySelector('.smiles-main-content');
        }
        if (mainElement) {
            mainElement.focus();
        }
    }
}
