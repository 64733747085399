export class FactorBreakdown {
  headerDescription: string;
  rows: FactorBreakdownRow[];
  maximumAmount: number;
  rowPeriodUnit: string;

  constructor() {
    this.rows = [];
    this.maximumAmount = 0;
  }
}

export class FactorBreakdownRow {
  startDate: string;
  endDate: string;
  amount: number;
  partial: boolean;
  dataMissing: boolean;
  ariaLabel: string;
}
