import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { environment } from 'environments/environment';
import { UserService } from 'app/service/user.service';
import { NavbarService } from 'app/service/nav-bar.service';
import { SmartApplicationBranding } from 'app/enum/SmartApplicationBranding';
import { BrandingService } from 'app/service/branding.service';
import { LoggerService } from 'app/service/logger.service';
import { IpService } from 'app/service/ip-service.service';
import { CircleConstants } from 'app/core/core.module';
import { SmilesSREConstants } from 'app/model/smilessreconstants';
import { BehaviorSubject, of, Subscription } from 'rxjs';
import { TimeoutModalService } from 'app/session-time-out/time-out.service';
import { VehicleService } from 'app/service/vehicle.service';
import { Vehicle } from 'app/model/vehicle';
import { TermAndConditionStatusService } from 'app/service/term-and-condition-status.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
    @ViewChild('usernameElement', { static: false }) usernameElement: ElementRef;
    @ViewChild('passwordElement', { static: false }) passwordElement: ElementRef;
    @ViewChild('messageAlert', { static: false }) alertElement: ElementRef;
    @ViewChild('loginForm', { static: false }) formElement: ElementRef;
    message: string;
    username = '';
    password = '';
    branding: SmartApplicationBranding;
    usernameBlank = false;
    passwordBlank = false;
    showLogoutMessage = false;
    formSubmitUrl = environment.login.url;
    iamHostName = environment.login.iamHostName;
    formDestination = environment.login.destination;
    chatbotSecret = environment.chatbot.secret;
    keyUp = false;
    showBanner = false;
    routerSubscription: Subscription;
    connectedCarRowArray = [];
    connectedCarData$: BehaviorSubject<{}> = new BehaviorSubject({});
    connectedCarDataChanges = this.connectedCarData$.asObservable();

    // eslint-disable-next-line max-params
    constructor(
        protected user: UserService,
        private router: Router,
        private navBar: NavbarService,
        private activatedRoute: ActivatedRoute,
        private titleService: Title,
        private LOGGER: LoggerService,
        private http: HttpClient,
        private logger: LoggerService,
        private ip: IpService,
        @Inject(CircleConstants) private circleConstants: SmilesSREConstants,
        public timeoutModalService: TimeoutModalService,
        public vehicleService: VehicleService,
        public termAndConditionStatusService: TermAndConditionStatusService
    ) {
        this.routerSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.setBranding(event.url);
            }
        });
    }

    setBranding(url: string): void {
        if (url.match('/smartmiles/login')) {
            BrandingService.setBranding('smartmiles');
        } else if (url.match('/smartride/login')) {
            BrandingService.setBranding('smartride');
        }
    }

    ngOnInit(): void {
        const invalidLoginAttemptCounter = sessionStorage.invalidLoginAttemptCounter;
        this.user.loggedIn = false;
        sessionStorage.invalidLoginAttemptCounter = invalidLoginAttemptCounter || 0;
        this.activatedRoute.queryParamMap.subscribe((params) => {
            const message = params.get('message');
            if (message === 'loggedout') {
                this.message = 'logged-out';
            } else if (message === 'expired') {
                this.message = 'session-error';
                this.user.logout();
            } else if (params.get('error')) {
                this.message = 'login-error';
                sessionStorage.invalidLoginAttemptCounter = Number(sessionStorage.invalidLoginAttemptCounter) + 1;
                if (sessionStorage.invalidLoginAttemptCounter > 1) {
                    /* XXX Disable chatbot
                      this._scriptService
                      .load(['botchates5', 'nwchatbot'])
                      .then( data => {
                        nwChatbot.presentPWResetOption(this.chatbotSecret);
                      });
                    */
                }
            }
        });
        this.branding = this.activatedRoute.snapshot.data.branding || BrandingService.getBranding();
        if (this.branding === 'smartride') {
            this.setTitle('SmartRide');
            /* XXX Disable chatbot
            this._scriptService
            .load(['botchates5', 'nwchatbot'])
            .then( data => {
              nwChatbot.showProactiveButton(this.chatbotSecret);
            });
            */
        } else {
            this.setTitle('SmartMiles');
        }
        this.navBar.headerDisplay(this.user.isLoggedIn, this.router.url);
        if (this.user.isLoggedIn) {
            this.navigateToUserLandingPage();
        }
        if (sessionStorage.getItem('selectedPolicy') && !sessionStorage.getItem('IsAdmin')) {
            this.vehicleService.getVehicleByPolicy(sessionStorage.getItem('selectedPolicy')).subscribe((vehicleResponses) => {
                this.singlePolicyLoad(vehicleResponses);
            });
        }
        sessionStorage.setItem('login', 'true');
    }

    submitLoginViaDomEvent(keyUp: boolean): void {
        try {
            BrandingService.setBranding(this.branding);

            if (this.readyToAttemptLogin()) {
              this.formElement.nativeElement.submit();
            }
        } catch (e) {
            // If we throw an exception, the form will submit.
            this.logger.error(e);
        }
    }

    readyToAttemptLogin(): boolean {
        let ready = false;
        this.message = '';
        this.username = this.username.trim();
        this.usernameElement.nativeElement.value = this.username;
        this.usernameBlank = this.username.length === 0;
        this.passwordBlank = this.password.length === 0;
        const shortTimeout = 100;
        if (!this.username.length && this.password.length) {
            this.message = 'username-required';
            if (this.keyUp) {
                setTimeout(() => {
                    this.alertElement.nativeElement.focus();
                }, shortTimeout);
            }
        } else if (this.username.length && !this.password.length) {
            this.message = 'password-required';
            if (this.keyUp) {
                setTimeout(() => {
                    this.alertElement.nativeElement.focus();
                }, shortTimeout);
            }
            return false;
        } else if (!this.username.length && !this.password.length) {
            this.message = 'username-password-required';
            if (this.keyUp) {
                setTimeout(() => {
                    this.alertElement.nativeElement.focus();
                }, shortTimeout);
            }
        } else {
            ready = true;
        }
        this.keyUp = false;
        return ready;
    }

    navigateToUserLandingPage(): void {
        this.router.navigateByUrl('/programSelect');
    }

    setTitle(newTitle: string): void {
        this.titleService.setTitle(newTitle);
    }

    // TODO: Find a test URL we can point to for lower envs.
    /* eslint-disable sre/no-hardcoded-nw-url */
    urlForDeviceVideoLink(): string {
        if (this.branding === SmartApplicationBranding.SMARTMILES) {
            return 'https://www.nationwide.com/personal/insurance/auto/discounts/smartmiles/videos/install';
        } else {
            return 'https://www.nationwide.com/smartride-videos.jsp?_ga=2.63561839.1321295535.1539518507-419607180.1538663412';
        }
    }
    /* eslint-enable sre/no-hardcoded-nw-url */

    singlePolicyLoad(vehicleResponses): void {
        for (const vehResp of vehicleResponses) {
            const vehicle = new Vehicle(vehResp);
            if (vehicle.vendorIdCode === 'FORD' || vehicle.programType === 'TC') {
                this.getPopupIfConnectedcar(vehicle);
            }
        }
        if (this.connectedCarRowArray && this.connectedCarRowArray.length > 0) {
            this.vehicleService.setConnectedCarDataForLogin(this.connectedCarRowArray);
            this.timeoutModalService.openTermsAndConditionsModal();
            this.updateAcceptanceStatus();
        }
    }

    getPopupIfConnectedcar(vehicle): void {
        let acceptancestatus: string;
        let type: string;
        if (vehicle.vendorIdCode === 'FORD') {
            type = 'smiles';
        } else if (vehicle.programType === 'TC') {
            type = 'sride';
        }
        this.termAndConditionStatusService
            .getConnectedCarConsentStatus(vehicle.vin, type)
            .subscribe({
                next: (response) => {
                    acceptancestatus = response?.[0]?.statusCode;
                },
                error: () => of(Error)
            });
        if (acceptancestatus !== 'Y') {
            this.connectedCarRowArray.push({ vin: vehicle.vin, type });
        }
    }

    updateAcceptanceStatus(): void {
        for (const vehResp of this.connectedCarRowArray) {
            if (vehResp.type === 'sride') {
                sessionStorage.setItem('SmartRide ConnectedCar', 'true');
                this.connectedCarUpdate(vehResp);
            } else if (vehResp.type === 'smiles') {
                sessionStorage.setItem('SmartMiles ConnectedCar', 'true');
                this.connectedCarUpdate(vehResp);
            }
        }
    }

    connectedCarUpdate(vehicle): any {
        this.connectedCarData$.next({ vin: vehicle.vin, type: vehicle.type });
    }
}
