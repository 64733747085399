import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor() { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Get the auth token from the service.
        let authToken = sessionStorage.getItem('tokenDetails');
        if (authToken) {
            authToken = authToken.split(',')[0];
        }

        if (this.shouldAddAuthorizationToRequest(req, authToken)) {
            req = req.clone({
                headers: req.headers.set('Authorization', `Bearer ${authToken}`)
            });
        }

        // send cloned request with header to the next handler.
        return next.handle(req);
    }

    private shouldAddAuthorizationToRequest(request: HttpRequest<any>, authToken: string): boolean {
        // If the request is euarevoke, it shouldn't attach the headers
        if (this.requestIsEuaRevoke(request)) {
 return false;
}

        // If we don't have a token, obviously don't add it.
        if (!authToken) {
 return false;
}

        // If the request is going to localhost, permit anything.
        if (this.requestIsToLocalhost(request)) {
 return true;
}

        // If it's leaving this machine, it must be encrypted.
        if (!this.requestIsEncrypted(request)) {
 return false;
}

        // Must go to Nationwide (nationwide.com or nwie.net).
        if (this.requestIsToNationwide(request)) {
 return true;
}

        return false;
    }
    private requestIsEuaRevoke(request: HttpRequest<any>): boolean {
        return request.url.endsWith('enterprise-user-auth/v2/revoke');
    }

    private requestIsToLocalhost(request: HttpRequest<any>): boolean {
        const hostname = this.hostnameFromUrl(request.url);
        return hostname === 'localhost';
    }

    private requestIsToNationwide(request: HttpRequest<any>): boolean {
        const hostname = this.hostnameFromUrl(request.url);
        // eslint-disable-next-line sre/no-hardcoded-nw-url
        if (hostname.endsWith('.nationwide.com')) {
 return true;
}
        if (hostname.endsWith('.nwie.net')) {
 return true;
}
        return false;
    }

    private requestIsEncrypted(request: HttpRequest<any>): boolean {
        const scheme = this.schemeFromUrl(request.url);
        return scheme === 'https';
    }

    private hostnameFromUrl(url: string): string {
        const match = url.match(/^.*:\/\/([a-zA-Z0-9_.-]*).*$/);
        if (match === null || match.length < 2) {
 return '';
}
        return match[1].toLowerCase();
    }

    private schemeFromUrl(url: string): string {
        const separatorPosition = url.indexOf('://');
        if (separatorPosition < 0) {
 return '';
}
        const scheme = url.substr(0, separatorPosition);
        return scheme.toLowerCase();
    }
}
