import { CurrencyAmount } from '../currency-amount';
import * as moment from 'moment';

export class PersonalLinesDiscount {
  description: string;
  isDiscountApplied: boolean;
  category: string;
  code: string;
}

export class PersonalLinesCoverageOption {
  code: string;
  optionValue: number;
  description: string;
  priority: number;
}

export class PersonalLinesCoverageTerm {
  chosenTerm: string;
  chosenTermValue: string;
  description: string;
  isAscendingBetter: boolean;
  modelType: string;
  name: string;
  options: Array<PersonalLinesCoverageOption>;
  patternCode: string;
  priority: number;
  refCode: string;
  required: boolean;
  termType: string;
  updated: boolean;
}

export class PersonalLinesCoverage {
  available: boolean;
  category: string;
  coverageCost: Array<any>;
  description: string;
  name: string;
  patternCode: string;
  priority: number;
  refCode: string;
  selected: boolean;
  terms: Array<PersonalLinesCoverageTerm>;
}

export class PersonalLinesDriverCoverage {
  coverages: Array<PersonalLinesCoverage>;
  driverCost: CurrencyAmount;
  fixedId: number;
  nwDriverNumber: number;
  publicId: string;
}

export class PersonalLinesVehicleCoverage {
  coverages: Array<PersonalLinesCoverage>;
  fixedId: number;
  nwVehicleNumber: number;
  publicId: string;
  ratedTermMileage: number;
  totalBasePremium: number;
  totalCostPerMile: number;
  totalMileagePremium: number;
  totalPremium: number;
  totalTraditionalPremium: number;
  vehicleName: string;
}

export class PersonalLinesAutoCoverage {
  driverCoverages: Array<PersonalLinesDriverCoverage>;
  lineCoverages: Array<PersonalLinesCoverage>;
  vehicleCoverages: Array<PersonalLinesVehicleCoverage>;
  totalDriverCovCost: CurrencyAmount;
  totalLineCovCost: CurrencyAmount;
  totalVehicleCovCost: CurrencyAmount;
}

export class PersonalLinesSurcharge {
  description: string;
}

export class PersonalLinesInfraction {
  fixedId: number;
  forgiveOrWaiveIndicator: string;
  infractionDesc: string;
  infractionSource: string;
  infractionType: string;
  occurrenceDate: Date;
  publicId: string;
}

export class PersonalLinesSmartMilesModifier {
  isDeviceCompatible: boolean;
  enrollStatus: string;
  deviceStatus: string;
  enrollDate: Date;
  annualMileage: number;
  typicalAnnualMileage: number;
}

export class PersonalLinesModifier {
  booleanModifier: boolean;
  code: string;
  description: string;
  fixedId: number;
  modifierDataType: string;
  name: string;
  priority: number;
  publicId: string;
  refCode: string;
  state: string;
  smartMiles: PersonalLinesSmartMilesModifier;
  vehicleEnrollment: any;
}

export class PersonalLinesPerson {
  age: number;
  dateOfBirth: Date;
  firstName: string;
  gender: string;
  lastName: string;
  maritalStatus: string;
}

export class PersonalLinesDriver {
  ageFirstLicensed: number;
  appliedDiscounts: Array<PersonalLinesDiscount>;
  appliedSurcharges: Array<PersonalLinesSurcharge>;
  dateFirstLicensed: Date;
  driverType: string;
  finResDetails: Array<any>;
  fixedId: number;
  infractions: Array<PersonalLinesInfraction>;
  isFinancialFilingReq: boolean;
  isInternationalLicense: boolean;
  isLicenseInSameStateForThreeYrsMandatory: boolean;
  isNewlyAddedDriver: boolean;
  licenseNumber: string;
  licenseState: string;
  licenseStatus: string;
  licenseStatusOverride: string;
  modifiers: Array<PersonalLinesModifier>;
  nwDriverNumber: number;
  person: PersonalLinesPerson;
  principalDriver: boolean;
  publicId: string;
  relationToPNI: string;
  yearsLicensed: number;
}

export class PersonalLinesVehicle {
  additionalInterests: Array<any>;
  annualMiles: number;
  antiTheftDevice: string;
  appliedDiscounts: Array<PersonalLinesDiscount>;
  bodyStyle: string;
  bodyTypeExt: string;
  fixedId: number;
  garageLocation: PersonalLinesAddress;
  hasPriorDamage: boolean;
  isPurchasedNew: boolean;
  make: string;
  model: string;
  modifiers: Array<PersonalLinesModifier>;
  nwVehicleNumber: number;
  primaryUse: string;
  publicId: string;
  vehicleType: string;
  vin: string;
  year: number;
}

export class PersonalLinesAutoLob {
  appliedDiscounts: Array<PersonalLinesDiscount>;
  appliedSurcharges: Array<PersonalLinesSurcharge>;
  drivers: Array<PersonalLinesDriver>;
  hasNWAutoPolicies: boolean;
  monthlyMileageUpdateDay: number;
  vehicles: Array<PersonalLinesVehicle>;
}

export class PersonalLinesAddress {
  addressLine1: string;
  city: string;
  country: string;
  postalCode: string;
  state: string;
}

export class PersonalLinesTotalInsuranceCost {
  estimatedNewTermPremium: CurrencyAmount;
  fees: CurrencyAmount;
  previousTotalCost: CurrencyAmount;
  taxes: CurrencyAmount;
  totalBasePremium: CurrencyAmount;
  totalCost: CurrencyAmount;
  totalMileagePremium: CurrencyAmount;
  totalPremium: CurrencyAmount;
  totalTraditionalPremium: CurrencyAmount;
  transactionCost: CurrencyAmount;
}

export class PersonalLinesPolicy {
  appliedAndAvailableDiscounts: Array<PersonalLinesDiscount>;
  asOfDate: Date;
  baseState: string;
  controlVersion: number;
  coverageLob: PersonalLinesAutoCoverage; // Flattened from coverageLobs.personalAuto
  currentDateTime: Date;
  effectiveDate: Date;
  lob: PersonalLinesAutoLob; // Flattened from lobs.personalAuto
  messages: Array<string>; // Flattened from messages.message
  originalInceptionDate: Date;
  periodEnd: Date;
  periodStart: Date;
  policyAddress: PersonalLinesAddress;
  policyNumber: string;
  status: string;
  tagSource: string;
  totalInsuranceCost: PersonalLinesTotalInsuranceCost;
  uwCompanyCode: string;
  severity: string; // Used to catch errors in Vehicle Call

  // 6 or 12 month term? TODO: is there a better way to calculate this?
  termLength = (): number =>
    // eslint-disable-next-line no-magic-numbers, no-extra-parens
    (moment(this.periodEnd).diff(moment(this.periodStart), 'month') < 11 ? 6 : 12);

  getAllVinNumbers(): string[] {
    if (this.lob?.vehicles?.length > 0) {
      return this.lob.vehicles.map((v) => v.vin);
    }
    return [];
  }
}
