import { Component, OnInit } from '@angular/core';
import { UserService } from 'app/service/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NavbarService } from 'app/service/nav-bar.service';
import * as moment from 'moment';
import { SaveVehiclePrefService } from 'app/service/save-vehicle-pref.service';
import { Vehicle } from 'app/model/vehicle';
import { EbiEventService } from 'app/service/ebi-event.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-welcome-page',
  templateUrl: './welcome-page.component.html',
  styleUrls: ['./welcome-page.component.css']
})

export class WelcomePageComponent implements OnInit {
  programType: string;
  scoringModel: string;
  isInstalled: boolean;
  firstName: string;
  deviceStatus: string;
  maxDiscount: string;
  participationDiscount: string;
  footerDisplay: any[];
  vin: string;
  vehicle: Vehicle;

  pluginStrSr = 'Once you install the device, keep it plugged in until the SmartRide program end date.';
  driveStrSr = 'Drive as you normally do. Your first trip may take up to 24 hours to show as Active on our website.';
  trackProgressStrSr = 'Get personalized feedback about your driving trends on our website or in your weekly summary emails.';
  lockDiscountStrSr = 'At the end of the program, simply send back your device and we\'ll calculate your ' +
    'final discount. It will be applied at your next policy renewal.';

  milesDrivenStrSr = 'The more miles you put on your car, the more likely it is you will have an accident. ' +
    'Plus, keeping mileage down saves gas and money on car repairs.';

  nightTimeDrivingStrSr = 'Driving between midnight and 5 a.m. increases your chances of having an accident.' +
    ' Drivers during these times may be tired or distracted.';

  idleTimeStrSr = 'Measures the length of time stopped while in a trip. This could be an indication of traffic congestion, ' +
    'which increases the chance of an accident.';

  hardBreakFastAccelStrSr = 'These are sudden stops and quick starts in excess of 7.7 mph per second. They ' +
    'could be a sign of aggressive driving.';

  hardBreakStrSr = 'Hard braking means forcing the vehicle to suddenly stop or decelerate in excess of 7.7 ' +
    'mph per second. Safe drivers will see very few instances of hard braking.';

  fastAccelStrSr = 'Fast acceleration is abruptly speeding more than 7.7 mph per second. It can be a sign of aggressive driving.';

  footerHeader = 'Your driving trends will determine your discount, based on 4 factors:';

  milesDrivenHeaderSr = 'Miles Driven';
  nightTimeDrivingHeaderSr = 'Nighttime Driving';
  idleTimeHeaderSr = 'Idle Time';
  hardBreakHeaderSr = 'Hard Braking';
  fastAccelHeaderSr = 'Fast Acceleration';
  hardBreakAccelHeaderSr = 'Hard Braking / Acceleration';

  milesDrivenImg = 'assets/image/smartride/icon_miles-white.png';
  nightTimeDrivingImg = 'assets/image/smartride/icon_night-white.png';
  idleTimeImg = 'assets/image/smartride/icon_idle-white.png';
  hardBreakImg = 'assets/image/smartride/icon_braking-white.png';
  fastAccelImg = 'assets/image/smartride/icon_acceleration-white.png';

  // eslint-disable-next-line max-params
  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public user: UserService,
    public navBar: NavbarService,
    private preferencesService: SaveVehiclePrefService,
    public ebi: EbiEventService
  ) { }

  setFooterDisplay(scoringModel: string): void {
    if (scoringModel === 'MO') {
      this.footerHeader = 'Your discount is based on the number of miles you drive.';
      this.footerDisplay = [
        { image: this.milesDrivenImg, header: this.milesDrivenHeaderSr, body: this.milesDrivenStrSr, alt: 'smart ride miles driven image' }
      ];
    } else if (scoringModel === 'ND1' || scoringModel === 'TW2') {
      this.footerDisplay = [
        { image: this.milesDrivenImg, header: this.milesDrivenHeaderSr, body: this.milesDrivenStrSr, alt: 'smart ride miles driven image' },
        {
          image: this.nightTimeDrivingImg, header: this.nightTimeDrivingHeaderSr, body: this.nightTimeDrivingStrSr,
          alt: 'smart ride night time driving image'
        },
        { image: this.idleTimeImg, header: this.idleTimeHeaderSr, body: this.idleTimeStrSr, alt: 'smart ride idle time image' },
        {
          image: this.hardBreakImg, header: this.hardBreakAccelHeaderSr, body: this.hardBreakFastAccelStrSr,
          alt: 'smart ride hard braking image'
        }
      ];
    } else if (scoringModel === 'TW' || scoringModel === 'TW1') {
      this.footerDisplay = [
        { image: this.milesDrivenImg, header: this.milesDrivenHeaderSr, body: this.milesDrivenStrSr, alt: 'smart ride miles driven image' },
        { image: this.hardBreakImg, header: this.hardBreakHeaderSr, body: this.hardBreakStrSr, alt: 'smart ride hard braking image' },
        { image: this.fastAccelImg, header: this.fastAccelHeaderSr, body: this.fastAccelStrSr, alt: 'smart ride fast acceleration image' },
        {
          image: this.nightTimeDrivingImg, header: this.nightTimeDrivingHeaderSr, body: this.nightTimeDrivingStrSr,
          alt: 'smart ride night time driving image'
        }
      ];
    } else {
      this.footerDisplay = [
        { image: this.milesDrivenImg, header: this.milesDrivenHeaderSr, body: this.milesDrivenStrSr, alt: 'smart ride miles driven image' },
        { image: this.hardBreakImg, header: this.hardBreakHeaderSr, body: this.hardBreakStrSr, alt: 'smart ride hard braking image' },
        { image: this.fastAccelImg, header: this.fastAccelHeaderSr, body: this.fastAccelStrSr, alt: 'smart ride fast acceleration image' },
        {
          image: this.nightTimeDrivingImg, header: this.nightTimeDrivingHeaderSr, body: this.nightTimeDrivingStrSr,
          alt: 'smart ride night time driving image'
        }
      ];
    }
  }

  ngOnInit(): void {
    this.navBar.headerDisplay(this.user.isLoggedIn, this.router.url);
    this.ebi.postSrideEvent('425080109');
    const vehicle = this.route.snapshot.data.vehicle;
    if (vehicle) {
      this.vehicle = vehicle;
      this.scoringModel = vehicle.scoringModel;
      this.maxDiscount = vehicle.maxDiscount;
      this.participationDiscount = vehicle.participationDiscount;
      this.deviceStatus = vehicle.device.status.toLowerCase();
      this.vin = vehicle.vin;
      vehicle.firstLogin = false;
      this.isInstalledCheck();
      this.savePreferencesWithFirstLoginDate();
    }
    this.setFooterDisplay(this.scoringModel);
  }

  isInstalledCheck(): void {
    if (this.deviceStatus === 'device shipped') {
      this.isInstalled = false;
    } else {
      this.isInstalled = true;
    }
  }

  redirectVehicles(): void {
    this.router.navigateByUrl('/programSelect');
  }

  redirectToWeek(): void {
    this.router.navigateByUrl(`sride/week/latest`);
  }

  savePreferencesWithFirstLoginDate(): void {
    const firstLoginString = moment(environment.futureDate).format('YYYY-MM-DD');
    this.preferencesService.setVehiclePref(this.vehicle, firstLoginString).subscribe();
  }
}
