import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SmartrideFactorCircleComponent } from 'app/components/factor-circle/smartride-factor-circle/smartride-factor-circle.component';
import { ServiceModule } from 'app/service/service.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModelModule } from 'app/model/model.module';
import { AppRoutingModule } from 'app/app-routing.module';
import { FormsModule } from '@angular/forms';
import { SharedUiModule } from 'app/shared-ui/shared-ui.module';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { CoreModule } from 'app/core/core.module';
import { AppComponent } from 'app/app.component';
import { TelematicsService } from 'app/service/telematics.service';
import { NavbarService } from 'app/service/nav-bar.service';
import { AlertGuard } from 'app/router-guards/alert-guard';
import { DeviceTimelineBarComponent } from '../components/miles-bar/device-timeline-bar.component';
import { DeviceTimelineDetailsComponent } from '../components/miles-bar/device-timeline-details/device-timeline-details.component';
import { TokenGuard } from 'app/router-guards/token-guard';
import { SingleVehicleResolver } from 'app/router-guards/single-vehicle-resolver';
import { SreDetailsContentResolver } from 'app/router-guards/sre-details-content-resolver';
import { SreSummaryContentResolver } from 'app/router-guards/sre-summary-content-resolver';
import { AdminComponent } from 'app/components/admin/admin.component';
import { AdminGuard } from 'app/router-guards/admin-guard.service';
import { AgentGuard } from 'app/router-guards/agent-guard.service';
import { AdminSearchFormService } from 'app/components/admin/admin-search-form.service';
import { AdminSrpComponent } from 'app/components/admin/admin-srp/admin-srp.component';
import { MobileDeviceComponent } from 'app/components/mobile-device/mobile-device.component';
import { PrintLabelComponent } from 'app/components/print-label/print-label.component';
import { SystemErrorComponent } from 'app/components/system-error/system-error.component';
import { PrintLabelService } from 'app/service/print-label.service';
import { DeviceSearchResultsComponent } from 'app/components/admin/device-search-results/device-search-results.component';
import { TripDataService } from 'app/service/tripSpeed-data-service';
import { MapUtils } from 'app/utility/MapUtils';
import { SreSummaryService } from 'app/service/sre-summary.service';
import { MobilePolicyInfoService } from 'app/service/mobile-policy-info.service';
import { SaveVehiclePrefService } from 'app/service/save-vehicle-pref.service';
import { MobileSearchResultComponent } from 'app/components/admin/mobile-search-result/mobile-search-result.component';
import { SmartrideVehicleSummaryComponent } from 'app/components/smartride-vehicle-summary/smartride-vehicle-summary.component';
import { WelcomePageComponent } from 'app/components/welcome-page/welcome-page.component';
import { SreSummaryComponent } from 'app/components/sre-summary/sre-summary.component';
import { SreDetailsComponent } from 'app/components/sre-details/sre-details.component';
import { VehicleIdentityComponent } from 'app/components/vehicle-identity/vehicle-identity.component';
import { FactorBreakdownComponent } from 'app/components/factor-breakdown/factor-breakdown.component';
import { DailyContentResolver } from 'app/router-guards/daily-content-resolver';

import {
    SmartRideDiscountTrendContentResolver
} from 'app/components/vehicle-summary/smart-ride-discount-trend/smart-ride-discount-trend-content-resolver';
import {
    SmartRideDiscountSummaryComponent
} from '../components/vehicle-summary/smart-ride-discount-summary/smart-ride-discount-summary.component';
import {
    SmartRideDiscountTrendComponent
} from '../components/vehicle-summary/smart-ride-discount-trend/smart-ride-discount-trend.component';
import { SelfEnrollmentService } from 'app/service/self-enrollment.service';
import { SelfEnrollmentGuardService } from 'app/service/self-enrollment-guard.service';
import { TelematicsExperienceProgramDataService } from 'app/service/telematics-experience-program-data.service';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        CoreModule.forRoot(),
        NgbModule,
        FormsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        ModelModule,
        SharedUiModule,
        ServiceModule,
        AppRoutingModule
    ],
    exports: [
    ],
    declarations: [
        SmartrideFactorCircleComponent,
        DeviceTimelineBarComponent,
        DeviceTimelineDetailsComponent,
        SmartRideDiscountSummaryComponent,
        SmartRideDiscountTrendComponent,
        AdminSrpComponent,
        AdminComponent,
        MobileSearchResultComponent,
        MobileDeviceComponent,
        PrintLabelComponent,
        SystemErrorComponent,
        SmartrideVehicleSummaryComponent,
        DeviceSearchResultsComponent,
        WelcomePageComponent,
        SreSummaryComponent,
        SreDetailsComponent,
        VehicleIdentityComponent,
        FactorBreakdownComponent
    ],
    providers: [
        AlertGuard,
        TokenGuard,
        SingleVehicleResolver,
        SreSummaryContentResolver,
        SreDetailsContentResolver,
        TelematicsService,
        NavbarService,
        PrintLabelService,
        AdminGuard,
        AgentGuard,
        AdminSearchFormService,
        TripDataService,
        SreSummaryService,
        MapUtils,
        MobilePolicyInfoService,
        SaveVehiclePrefService,
        DailyContentResolver,
        SmartRideDiscountTrendContentResolver,
        SelfEnrollmentService,
        SelfEnrollmentGuardService,
        TelematicsExperienceProgramDataService
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
    bootstrap: [AppComponent]
})
export class SmartRideModule { }
