import { Component, Input, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'iframe-components',
    templateUrl: './iframe.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IframeComponent implements OnInit {
    @Input() src: string;
    isSourceReady = false;
    trustedSource: SafeResourceUrl;

    constructor(private sanitizer: DomSanitizer) { }

    ngOnInit(): void {
        this.trustedSource = this.sanitizer.bypassSecurityTrustResourceUrl(this.src);
        this.isSourceReady = true;
    }
}
