/* eslint no-constant-condition: ["error", { "checkLoops": false }]*/
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-vehicle-identity',
  templateUrl: './vehicle-identity.component.html',
  styleUrls: ['./vehicle-identity.component.css']
})
export class VehicleIdentityComponent {
  @Input() vehicle: any;

  generateDeviceStatusDiscriminator(input): string {
    const lettersInDevice = 6;
    if (!input) {
      return '';
    }
    if (typeof input !== 'string') {
      return '';
    }
    let simplified = input.trim().toLowerCase();
    while (true) {
      if (simplified.startsWith('device')) {
        simplified = simplified.substr(lettersInDevice).trim();
      } else if (simplified.startsWith(':')) {
        simplified = simplified.substr(1).trim();
      } else if (simplified.endsWith('device')) {
        simplified = simplified.substr(0, simplified.length - lettersInDevice).trim();
      } else {
        break;
      }
    }
    return simplified;
  }

  getDeviceStatusImageUrl(): string {
    let suffix;
    switch (this.generateDeviceStatusDiscriminator(this.vehicle.device.status)) {
      case 'active':
        suffix = 'icon-check.png';
        break;
      case 'shipped':
      case 'not yet shipped':
      case 'returned':
        suffix = 'icon-check-inactive.png';
        break;
      case 'inactive':
      case 'installed':
      case 'return':
        suffix = 'icon-exclamation.png';
        break;
      default:
        suffix = 'icon-exclamation.png';
    }
    return `/assets/image/smartride/${suffix}`;
  }
}
