import { Component, OnInit, Input } from '@angular/core';
import { Vehicle } from 'app/model/vehicle';
import { Router } from '@angular/router';

@Component({
    selector: 'app-program-select-return-device-tab',
    templateUrl: './program-select-return-device-tab.component.html',
    styleUrls: ['./program-select-return-device-tab.component.css']
})
export class ProgramSelectReturnDeviceTabComponent implements OnInit {
    @Input() vin: string;
    @Input() vehicle: Vehicle;

    constructor(private router: Router) { }

    ngOnInit(): void { }

    navigateToPrintLabel(): void {
        sessionStorage.setItem('selectedPolicy', this.vehicle.policyNum);
        this.router.navigateByUrl('/sride/printLabel');
    }
}
