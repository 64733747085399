import { Injectable } from '@angular/core';
import { APICommonService } from '@nationwide/api-common-service';
import { DatePipe } from '@angular/common';
import { environment } from '../../../environments/environment';

@Injectable()
export class QuickAuthenticateService {
    ttl: number;
    useDevEnvironment = false;

    // eslint-disable-next-line max-params
    constructor(
        private apiCommonService: APICommonService,
        private datePipe: DatePipe,
    ) {
        // this.ttl = environment.authorize.ttl;
    }

    getKnowledgeBasedOAuthCall(addendumString): string {
        const scope = 'test';
        const oAuthEndpoint = `${environment.authorize.oauthEndpoint
            }?client_id=${environment.authorize.client_id
            }&nonce=${environment.authorize.nonce
            }&redirect_uri=${'https://www.sre.t.awspubliccloud.nationwide.com/assets/html/eua-callback.html'
            }&response_type=${environment.authorize.responseType
            }&scope=${scope
            }&realm=unidentified` +
            `&message_id=${this.apiCommonService.generateTransactionId()
            }&state=knowledgeState` +
            `&ttl=${this.ttl
            }${addendumString}`;
        return oAuthEndpoint;
    }

    nwExpressAuth(params: NwExpressAuthParams): string {
        const variableSearchParam = params.agreementNumber ? `auth_id_agreementNumber=${params.agreementNumber}` : `auth_id_phoneNumber=${params?.phoneNumber}`;
        const queryString = `&auth_id_postalCode=${params.zipCode}&auth_id_dateOfBirth=${params.birthDate}&${variableSearchParam}&auth_method=nw-express`;
        return this.getKnowledgeBasedOAuthCall(queryString);
    }

    searchByPhoneNumber({ phoneNumber, postalCode, birthDate, lastName }: { phoneNumber; postalCode; birthDate; lastName }): string {
        const formattedDateOfBirth = this.datePipe.transform(new Date(birthDate), 'yyyy-MM-dd');
        const AREA_CODE_LENGTH = 3;
        const addendumString = `&auth_id_areaCode=${phoneNumber.slice(0, AREA_CODE_LENGTH)
            }&auth_id_localNumber=${phoneNumber.substring(AREA_CODE_LENGTH, phoneNumber.length)
            }&auth_id_postalCode=${postalCode
            }&auth_id_birthDate=${formattedDateOfBirth
            }&auth_id_lastName=${lastName
            }&auth_method=customer-search`;
        return this.getKnowledgeBasedOAuthCall(addendumString);
    }
}

export interface NwExpressAuthParams {
    agreementNumber?: string;
    phoneNumber?: string;
    birthDate?: string;
    zipCode?: string;
    guid?: string;
}
