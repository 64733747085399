import { Injectable } from '@angular/core';
import { ConvertPatternFromPCPipe } from 'app/pipes/policy.pipe';
import { MetaDataService } from './metadata.service';

export interface PolicyField { formObject: any; pcFieldData: any }
export interface FieldOptions { optionsList: any[]; foundItems: any[]; displayName: string; key: string }

@Injectable({
    providedIn: 'root'
})
export class SchemaFormService {
    // eslint-disable-next-line max-params
    constructor(
        private metaDataService: MetaDataService,
        private convertPatternFromPCPipe: ConvertPatternFromPCPipe,
    ) {
    }

    // TODO: Fix complexity
    // eslint-disable-next-line complexity, sonarjs/cognitive-complexity
    buildForm(formFromComponent, formTypeMap?): any {
        const pcMetadata = [];
        let pcSection = [];
        let ispForm = JSON.parse(JSON.stringify(formFromComponent));
        if (formTypeMap) {
            ispForm = this.mergeFormWithFormTypeMap(ispForm, formTypeMap);
        }
        for (const section in ispForm.sections) {
            if (section) {
                pcMetadata[section] = this.metaDataService.getDTOInfo(ispForm.sections[section].metaDTO);
                if (ispForm.sections[section].condition != null) {
                    // This will set the condition once and then not need to again
                    ispForm.sections[section].condition = this.setSectionCondition(ispForm.sections[section], pcMetadata[section]);
                }

                let itemIndex;
                const endsWithIntegerRegex = /^[a-zA-Z\s]+[0-9]{1}$/;
                if (endsWithIntegerRegex.test(section)) {
                    itemIndex = section.slice(section.length - 1);
                }
                for (const fieldName in ispForm.sections[section].fields) {
                    if (fieldName) {
                        const field = ispForm.sections[section].fields[fieldName];
                        if (field.condition) {
                            field.condition = this.insertRegEx(field.condition, pcSection);
                        }
                        pcSection = field.parent != null && pcMetadata[section]?.[field.parent] != null ? pcMetadata[section][field.parent] : pcMetadata[section];
                        let formObject = this.initializeFormObject(field, formTypeMap, itemIndex);
                        formObject.key = `${section}-${fieldName}`;
                        const pcFieldData = pcSection?.[fieldName];

                        if (formObject.type !== 'template') {
                            const camelCaseKey = fieldName.charAt(0).toLowerCase() + fieldName.slice(1);

                            if (formTypeMap[fieldName] != null) {
                                formObject.value = formTypeMap[fieldName];
                            } else if (formTypeMap[section]?.[fieldName] != null) {
                                formObject.value = formTypeMap[section][fieldName];
                            } else if (formTypeMap[section]?.[camelCaseKey] != null) {
                                formObject.value = formTypeMap[section][camelCaseKey];
                            }
                            formObject.metaName = fieldName;
                            if (pcFieldData != null) {
                                formObject.metaName = pcFieldData.class == null ? fieldName : pcFieldData.class.slice(pcFieldData.class.lastIndexOf('.') + 1);
                                if (pcFieldData.maxLength != null && formObject.maxLength == null) {
                                    formObject.maxLength = pcFieldData.maxLength;
                                }
                                if (pcFieldData.minLength != null && formObject.minLength == null) {
                                    formObject.minLength = pcFieldData.minLength;
                                }
                                if (pcFieldData.maxAmount != null && formObject.maxAmount == null) {
                                    formObject.maxAmount = pcFieldData.maxAmount;
                                }
                                if (pcFieldData.minAmount != null && formObject.minAmount == null) {
                                    formObject.minAmount = pcFieldData.minAmount;
                                }
                                if (pcFieldData.required != null && formObject.required == null) {
                                    formObject.required = pcFieldData.required;
                                }
                                if (pcFieldData.optional != null && formObject.optional == null) {
                                    formObject.optional = pcFieldData.optional;
                                }
                                if (pcFieldData.hashMap != null && formObject.hashMap == null) {
                                    formObject.hashMap = pcFieldData.hashMap;
                                }
                                if (pcFieldData.type != null && pcFieldData.type === 'Boolean' && formObject.type == null) {
                                    formObject.type = 'radios-inline';
                                    formObject.options = [{ key: 'Yes', value: true }, { key: 'No', value: false }];
                                }
                                if (pcFieldData.regEx != null && formObject.regEx == null) {
                                    // parses a string that's a conditional statement that uses the model variable
                                    if (pcFieldData.regEx.charAt(0) === '(') {
                                        const parseFunction =
                                            new Function('model', `try{
                                                return ${pcFieldData.regEx}
                                            } catch (error) {
                                                console.log('Exception creating pattern from PC regex', error);
                                                return '.*';
                                            }`
                                            );
                                        const model = this.buildModelForSection(ispForm.sections[section].fields);
                                        const regEx = parseFunction(model);
                                        formObject.pattern = this.convertPatternFromPCPipe.transform(regEx);
                                    } else {
                                        formObject.pattern = this.convertPatternFromPCPipe.transform(pcFieldData.regEx);
                                    }
                                }
                            }
                            /* TODO: Might not need this. Copied from old service, once the service is complete, this can be removed.*/
                            if (formObject.type === 'dropdown' && formObject.listOptions != null) {
                                formObject.options = this.buildIspDropDown(formObject, formTypeMap);
                            } else if (formObject.staticDisplay === 'static') {
                                this.buildStaticDropDown(formObject);
                            }
                            /* TODO: Controlling inline and block for radios can also be controlled with css classes. Can be removed.*/
                            if (formObject.listDisplay === 'radio') {
                                formObject.type = 'radios-inline';
                                if (formObject.label === 'BodyStyle') {
                                    formObject.type = 'radios';
                                }
                            }

                            // functions don't live through json string/parse have to grab from original
                            let originalSection = formFromComponent.sections[section];
                            if (originalSection == null) {
                                originalSection = formFromComponent.sections[section.slice(0, section.length - 1)];
                            }
                            if (originalSection) {
                                const originalField = originalSection.fields[fieldName];
                                if (originalField.validator != null) {
                                    formObject.validator = originalField.validator;
                                }
                            }
                            if (field.type === 'hidden') {
                                // This is to hide fields that are not required to be shown on the page.
                                formObject.htmlClass = 'no-display';
                            }

                            formObject.controlType = formObject.type;
                            if (formObject.type === 'radios') {
                                formObject.controlType = 'radio';
                            } else if (formObject.type == null) {
                                formObject.controlType = 'textbox';
                            }
                        } else if (formTypeMap[fieldName]) {
                            formObject.template = formObject.template.replace(/{{.+}}/g, formTypeMap[fieldName]);
                        } else if (formTypeMap[section][fieldName]) {
                            formObject.template = formObject.template.replace(/{{.+}}/g, formTypeMap[section][fieldName]);
                        }

                        if (formObject.required) {
                            if (formObject.labelHtmlClass == null) {
                                formObject.labelHtmlClass = 'required';
                            } else {
                                formObject.labelHtmlClass += ' required';
                            }
                        } else if (formObject.showOptional) {
                            if (formObject.labelHtmlClass == null) {
                                formObject.labelHtmlClass = 'optional';
                            } else {
                                formObject.labelHtmlClass += ' optional';
                            }
                        }

                        if (ispForm.sections[section].condition != null) {
                            if (formObject.condition == null) {
                                formObject.condition = ispForm.sections[section].condition;
                            } else {
                                formObject.condition += ` && ${ispForm.sections[section].condition}`;
                            }
                        }
                        if (formObject.condition != null) {
                            formObject = this.checkAndSetConditionObject(formObject, formObject.condition, itemIndex);
                        }
                        if (pcFieldData?.visibilityCondition != null) {
                            // assigns the pc condition into form required field and adds section name to match model
                            const addSectionNameRegex = /form\.value\['(.*?)/g;
                            const condition = pcFieldData.visibilityCondition.replace(addSectionNameRegex, `form.value['${section}-$1`);
                            formObject = this.checkAndSetConditionObject(formObject, condition, itemIndex);
                        }
                        ispForm.sections[section].fields[fieldName] = formObject;
                    }
                }
            }
        }
        return ispForm;
    }

    // TODO: Fix complexity
    // eslint-disable-next-line complexity, sonarjs/cognitive-complexity
    private buildIspDropDown(formObject, formTypeMap): any[] {
        const optionsList = [];
        if (formObject.includeSelect) {
            optionsList.push({ key: 'Select', value: '' });
        }
        if (formObject.listOptions != null) {
            const listKeys = Object.keys(formObject.listOptions);
            const foundItems = [];
            for (const key of listKeys) {
                const displayObject = formObject.listOptions[key];
                let displayName = '';
                if (typeof displayObject === 'string') {
                    displayName = displayObject;
                }
                if (displayName !== '') {
                    const iterate = '[iterate-';
                    if (key.includes(iterate)) {
                        const conditionStart = key.indexOf(iterate);
                        const conditionEnd = key.slice(conditionStart).indexOf(']') + conditionStart;
                        const formTypeField = key.slice(conditionStart + iterate.length, conditionEnd);
                        const iteratorCount = formTypeMap[formTypeField];
                        for (let index = 0; index < iteratorCount; index++) {
                            const dropDownKey = key.slice(0, conditionStart) + index + key.slice(conditionEnd + 1);
                            this.pushIspDropDownItem({ optionsList, foundItems, displayName, key: dropDownKey });
                        }
                    } else {
                        this.pushIspDropDownItem({ optionsList, foundItems, displayName, key });
                    }
                }
            }
        }
        if (formObject.sortOrder === 'custom') {
            const sortedList = [];
            const priorityList = this.buildPriorityList(formObject.priority);
            for (let index = 0; index < Object.keys(optionsList).length; index++) {
                // Get the priority and assign the code/key object into that specific index
                let fieldPriority = priorityList[optionsList[index].key];
                if (fieldPriority == null) {
                    fieldPriority = priorityList['Placeholder'];
                    // Iterate until we find an empty spot in sortedList.  Shouldn't ever be more than 15.
                    // eslint-disable-next-line no-magic-numbers
                    for (let fieldIndex = fieldPriority; fieldIndex < 15; fieldIndex++) {
                        if (sortedList[fieldIndex] == null) {
                            sortedList[fieldIndex] = optionsList[fieldIndex];
                            break;
                        }
                    }
                } else {
                    sortedList[fieldPriority - 1] = optionsList[index];
                }
            }
            const sortedDropDown = [];
            for (const listItem of sortedList) {
                if (listItem != null) {
                    sortedDropDown.push(listItem);
                }
            }
            return sortedDropDown;
        }
        return optionsList;
    }

    private buildModelForSection(model): any {
        const updatedModel = {};
        const modelKeys = Object.keys(model);
        for (const modelKey of modelKeys) {
            updatedModel[modelKey] = model[modelKey].value;
        }
        return updatedModel;
    }

    private buildPriorityList(existingList): any {
        const priorityList = {};
        for (const key in existingList) {
            if (existingList[key]) {
                const value = existingList[key];
                if (!key.includes('-')) {
                    priorityList[key] = value;
                }
            }
        }
        return priorityList;
    }

    private buildStaticDropDown(formObject): any[] {
        const displayList = [];

        if (formObject.includeSelect) {
            displayList.push({ key: 'Select', value: '' });
        }
        if (formObject.listDisplay === 'radio') {
            formObject.type = 'radios';
        } else {
            formObject.type = 'dropdown';
        }
        return displayList;
    }

    private checkAndSetConditionObject(formObject, condition, itemIndex): any {
        condition = this.setIterativeConditions(condition, itemIndex);
        if (formObject.condition != null && formObject.condition !== condition && !formObject.condition.includes('(n)')) {
            if (formObject.conditionTemplate) {
                formObject.condition = formObject.condition.replace(/pcCondition/, condition);
            } else {
                formObject.condition = `${formObject.condition} && ${condition}`;
            }
        } else {
            formObject.condition = condition;
        }
        return formObject;
    }

    private initializeFormObject(ispField, formTypeMap, itemIndex): any {
        // buildFormObject from formTypeMap, I dont think this is required.
        const formObject: any = {};
        const keys = Object.keys(ispField);
        if (keys.length > 0) {
            for (const key of keys) {
                if (typeof ispField[key] === 'string' && ispField[key].includes('(n)') && key !== 'condition') {
                    const field = ispField[key].replace(/\(n\)/g, itemIndex);
                    if (formTypeMap[field] == null) {
                        formObject[key] = field;
                    } else {
                        formObject[key] = formTypeMap[field];
                    }
                } else {
                    formObject[key] = ispField[key];
                }
            }
        }
        return formObject;
    }

    private insertRegEx(condition, pcSection): string | boolean {
        if (typeof condition === 'string' && condition.includes('regExPattern')) {
            const startIndex = condition.indexOf('{regExPattern');
            const endIndex = condition.indexOf('}', startIndex);
            const fieldNameToFind = condition.slice(startIndex + '{regExPattern-'.length, endIndex);
            if (pcSection?.[fieldNameToFind]?.regEx == null) {
                // In order to produce a more readable error.
                condition = condition.replace(`{regExPattern-${fieldNameToFind}}`, `regExPattern${fieldNameToFind}`);
            } else {
                const regExToInsert = pcSection[fieldNameToFind].regEx;
                condition = condition.replace(`{regExPattern-${fieldNameToFind}}`, `'${regExToInsert}'`);
                condition = this.insertRegEx(condition, pcSection);
            }
        }
        return condition;
    }

    private mergeFormWithFormTypeMap(form, formTypeMap): { sections: any } {
        const mergedForm = { sections: {} };
        const formKeys = Object.keys(form.sections);
        const formTypeMapKeys = Object.keys(formTypeMap);
        for (const formKey of formKeys) {
            for (const formTypeMapKey of formTypeMapKeys) {
                if (formKey === formTypeMapKey) {
                    mergedForm.sections[formKey] = form.sections[formKey];
                } else if (formKey === formTypeMapKey.slice(0, formTypeMapKey.length - 1)) {
                    mergedForm.sections[formTypeMapKey] = JSON.parse(JSON.stringify(form.sections[formKey]));
                }
            }
        }
        return mergedForm;
    }

    private pushIspDropDownItem(fieldOptions: FieldOptions): void {
        const optionValue = fieldOptions.key;

        if (!fieldOptions.foundItems.includes(optionValue)) {
            fieldOptions.optionsList.push({ key: fieldOptions.displayName, value: optionValue });
            fieldOptions.foundItems.push(optionValue);
        }
    }

    private setIterativeConditions(condition, itemIndex): string | boolean {
        if (typeof condition === 'string' && condition.includes('(n)')) {
            condition = condition.replace(/\(n\)/g, itemIndex);
        }
        return condition;
    }

    private setSectionCondition(section, pcSection): string | boolean {
        for (const field in section.fields) {
            if (section.fields[field]) {
                section.condition = section.fields[field].parent ? this.insertRegEx(section.condition, pcSection[section.fields[field].parent]) : this.insertRegEx(section.condition, pcSection);
            }
        }
        return section.condition;
    }
}
