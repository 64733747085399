/* eslint-disable max-params */
/* eslint no-constant-condition: ["error", { "checkLoops": false }]*/
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Moment } from 'moment';
import * as moment from 'moment';
import { SingleVehicleResolver } from 'app/router-guards/single-vehicle-resolver';
import { SmartRideDiscountTrendContent } from './smart-ride-discount-trend-content';
import { Vehicle } from 'app/model/vehicle';
import { Discount } from 'app/model/discount';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable()
export class SmartRideDiscountTrendContentResolver {
  private DISCOUNT_COUNT: number;

  constructor(
    private _singleVehicleResolver: SingleVehicleResolver
  ) {
    this.DISCOUNT_COUNT = 7;
  }

  resolve(route: ActivatedRouteSnapshot): Observable<SmartRideDiscountTrendContent> {
    const periodUnit: string = route.params.periodUnit;
    const date: string = route.params.date;

    return this._singleVehicleResolver.resolve(route).pipe(map((vehicle) => {
      const content = new SmartRideDiscountTrendContent();
      content.vehicle = vehicle;
      content.discounts = this.findOrSynthesizeDiscountsAroundDate(vehicle, periodUnit, date, this.DISCOUNT_COUNT);
      this.determineWhetherEarlierAndLaterDataExist(content);
      return content;
    }));
  }

  private determineWhetherEarlierAndLaterDataExist(content: SmartRideDiscountTrendContent): void {
    if (!content.vehicle?.discounts) {
      return;
    }
    const sliderStartDate = moment(content.discounts[0].date).clone().startOf('month');
    const sliderEndDate = moment(content.discounts[content.discounts.length - 1].date);
    let endDate;
    if (content.vehicle.deviceStatusTimeline?.finalDiscountDate) {
      endDate = content.vehicle.deviceStatusTimeline.finalDiscountDate;
    } else {
      endDate = content.vehicle.deviceStatusTimeline.completionDate;
    }
    if (sliderStartDate.isAfter(moment(content.vehicle.deviceStatusTimeline.installDate))) {
      content.earlierDataExist = true;
    }
    if (sliderEndDate.clone().endOf('month').isBefore(moment(endDate))) {
      content.laterDataExist = true;
    }
  }

  private findOrSynthesizeDiscountsAroundDate(
    vehicle: Vehicle, periodUnit: string, date: string, outputCount: number
  ): Discount[] {
    const startDate = this.getDiscountsStartDateFromVehicle(vehicle);
    const endDate = this.getDiscountsEndDateFromVehicle(vehicle);

    if (periodUnit === 'total') {
      periodUnit = 'month';
    }

    let targetDate = date.toLowerCase() === 'latest' ? moment(environment.futureDate) : moment(date, 'YYYYMMDD');
    if (targetDate < startDate) {
      targetDate = startDate;
    } else if (targetDate > endDate) {
      targetDate = endDate;
    }

    // Move a sliding window from the start until it contains targetDate.
    const windowStartDate = startDate.clone().startOf(<any>periodUnit);
    const windowEndDate = windowStartDate.clone().add(outputCount, <any>periodUnit);
    let panic = 500;
    while (true) {
      if (--panic < 0) {
        // This is a loosely-terminated loop. Just to be safe, we'll abort after some crazy count of iterations.
        // Meaningless but unique error code:
        throw new Error('Discounts not found [A6392]');
      }

      if (windowStartDate <= targetDate && targetDate < windowEndDate) {
        return this.findOrSynthesizeDiscountsForDates(vehicle, periodUnit, windowStartDate, outputCount);
      }

      if (windowStartDate > endDate) {
        // Meaningless but unique error code:
        throw new Error('Discounts not found [B2503]');
      }
      windowStartDate.add(outputCount, <any>periodUnit);
      windowEndDate.add(outputCount, <any>periodUnit);
    }
  }

  private findOrSynthesizeDiscountsForDates(
    vehicle: Vehicle, periodUnit: string, date: Moment, outputCount: number
  ): Discount[] {
    const discounts = [];
    for (let i = 0; i < outputCount; i++) {
      let discount = this.findLastDiscountInPeriod(vehicle, periodUnit, date);
      if (!discount) {
        discount = this.synthesizeDiscountForPeriod(date.clone());
      }
      discount.beginningOfWeek = discount.date.clone().startOf('week');
      discount.endOfWeek = discount.date.clone().endOf('week');
      discounts.push(discount);
      date.add(1, <any>periodUnit);
    }
    return discounts;
  }

  private findLastDiscountInPeriod(vehicle: Vehicle, periodUnit: string, date: Moment): Discount {
    if (!vehicle?.discounts) {
      return null;
    }

    let result: Discount = null;
    let resultIfFutureDataPresent: Discount = null;
    let earliestParticipationDiscount: Discount = null;
    let futureDataPresent = false;

    for (const discount of vehicle.discounts) {
      // Take the most recent if it matches the requested period.
      if (moment(discount.date).isSame(date, <any>periodUnit)) {
        if (!result || discount.date > result.date) {
          result = discount;
        }

        // Provisionally take discounts from before this period. Only use them if we find a discount after this period.
      } else if (moment(discount.date).isBefore(date, <any>periodUnit)) {
        if (!resultIfFutureDataPresent || discount.date > resultIfFutureDataPresent.date) {
          resultIfFutureDataPresent = discount;
        }

        // OK, we found a discount after this period.
      } else {
        futureDataPresent = true;
        if (discount.discountType === 'Participation Discount') {
          if (!earliestParticipationDiscount || discount.date < earliestParticipationDiscount.date) {
            earliestParticipationDiscount = discount;
          }
        }
      }
    }

    if (result) {
      return result;
    }
    if (futureDataPresent && resultIfFutureDataPresent) {
      return resultIfFutureDataPresent;
    }
    return earliestParticipationDiscount;
  }

  private synthesizeDiscountForPeriod(date: Moment): Discount {
    const discount = new Discount(date, null, '');
    return discount;
  }

  private getDiscountsStartDateFromVehicle(vehicle: Vehicle): Moment {
    if (vehicle.deviceStatusTimeline) {
      if (vehicle.deviceStatusTimeline.installDate) {
        return moment(vehicle.deviceStatusTimeline.installDate);
      }
      if (vehicle.deviceStatusTimeline.enrollDate) {
        return moment(vehicle.deviceStatusTimeline.enrollDate);
      }
    }
    if (vehicle.discounts && vehicle.discounts.length > 0) {
      // eslint-disable-next-line no-extra-parens
      return moment(vehicle.discounts.reduce((a, b) => (a.date < b.date ? a : b)).date);
    }
    throw new Error('Unable to determine program start date');
  }

  private getDiscountsEndDateFromVehicle(vehicle: Vehicle): Moment {
    if (vehicle.deviceStatusTimeline) {
      if (vehicle.deviceStatusTimeline.finalDiscountDate) {
        return moment(vehicle.deviceStatusTimeline.finalDiscountDate);
      } else if (vehicle.deviceStatusTimeline.completionDate) {
        return moment(vehicle.deviceStatusTimeline.completionDate);
      }
    }
    if (vehicle.discounts && vehicle.discounts.length > 0) {
      // eslint-disable-next-line no-extra-parens
      return moment(vehicle.discounts.reduce((a, b) => (a.date > b.date ? a : b)).date);
    }
    return moment(environment.futureDate);
  }
}
