import { Component, OnInit, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Vehicle } from 'app/model/vehicle';
import { TimeframeService } from 'app/service/timeframe.service';
import { EbiEventService } from 'app/service/ebi-event.service';
import { NavbarService } from 'app/service/nav-bar.service';
import { UserService } from 'app/service/user.service';

@Component({
  selector: 'app-device-timeline-details',
  templateUrl: './device-timeline-details.component.html',
  styleUrls: ['./device-timeline-details.component.css']
})

@Injectable()
export class DeviceTimelineDetailsComponent implements OnInit {
  vehicle: Vehicle;
  returnPeriod: string;
  returnDate: string;
  breadCrumbTitle: string;
  breadcrumbTitleMap = {
    total: 'Total',
    month: 'Monthly',
    week: 'Weekly'
  };

  // eslint-disable-next-line max-params
  constructor(
    private route: ActivatedRoute,
    public _timeframe: TimeframeService,
    public _ebiService: EbiEventService,
    public _navbar: NavbarService,
    public _user: UserService
  ) {
  }

  ngOnInit(): void {
    if (this.route.snapshot) { // Sets smartride header
      this._navbar.headerDisplay(this._user.isLoggedIn, this.route.snapshot.url.toString());
    }
    this.returnPeriod = this.route.snapshot.paramMap.get('returnPeriod');
    this.returnDate = this.route.snapshot.paramMap.get('returnDate');
    this.breadCrumbTitle = this.breadcrumbTitleMap[this.returnPeriod] || '';
    this.vehicle = this.route.snapshot.data.vehicle;

    this._ebiService.postSrideEvent('425080018');
  }
}
