import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientJsonpModule, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CoreModule } from 'app/core/core.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ModelModule } from './model/model.module';
import { SharedUiModule } from './shared-ui/shared-ui.module';
import { ServiceModule } from './service/service.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SmartMilesModule } from 'app/modules/smart-miles.module';
import { SmartRideModule } from 'app/modules/smart-ride.module';
import { PreferencesComponent } from 'app/components/preferences/preferences.component';
import { EditableTextComponent } from 'app/components/editable-text/editable-text.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { TokenCaptureComponent } from './components/token-capture/token-capture.component';
import { SpeedGraphComponent } from './components/speed-graph/speed-graph.component';
import { SmilesEsriMapComponent } from 'app/components/smiles-esri-map/smiles-esri-map.component';
import { SpeedGraphService } from 'app/service/speed-graph.service';
import { FactorTitlePipe } from 'app/pipes/factor-title.pipe';
import { UnitsPipe } from 'app/pipes/units.pipe';
import { RoundToOnePipe } from 'app/pipes/round-to-one.pipe';
import { NearestMinPipe } from 'app/pipes/nearest-min.pipe';
import { SreAdminReloadComponent } from './components/admin/sre-admin-reload/sre-admin-reload.component';
import { WindowRef } from 'app/service/window-ref';
import { SreDailySummaryComponent } from './components/sre-daily-summary/sre-daily-summary.component';
import { DailySummaryComponent } from 'app/components/vehicle-summary/daily/daily-summary.component';
import { OAuthModule } from '@nationwide/angular-oauth-module';
import { environment } from '../environments/environment';
import { ValidatorParams } from './validator/params-interceptor.data';
import { ValidatorService } from '@nationwide/nw-validator-service';
import { SessionServicesModule } from './session-time-out/session-services.module';
import { SelfEnrollmentComponent } from './components/self-enrollment/self-enrollment.component';
import { SelfEnrollmentReviewComponent } from './components/self-enrollment/review/self-enrollment-review.component';
import { SelfEnrollmentConfirmationComponent } from './components/self-enrollment/confirmation/self-enrollment-confirmation.component';
import { PolicyRequestsModule } from '@nationwide/dgs-internet-servicing-policy-requests';
import { WaitSpinnerComponent } from './components/wait-spinner/wait-spinner.component';
import { FocusOnInitDirective } from './directives/focus-on-init.directive';
import { WaitSpinnerService } from './service/wait-spinner.service';
import { InternetServicingAngularPipesModule } from '@nationwide/internet-servicing-angular-pipes';
import { QuickIDMainComponent } from './light-auth/quick-id-main/quick-id-main.component';
import { QuickIdZipComponent } from './light-auth/quick-id-zip/quick-id-zip.component';
import { ZipCodeQuickAuthModule } from '@nationwide/customer-component-library';
import { BoltDirectivesModule } from '@nationwide-bolt/ng-directives';
import { APICommonService } from '@nationwide/api-common-service';
import { QuickIDSearchFormService } from './light-auth/quick-id-main/quick-id-search-form.service';
import { QuickIdDataService } from './light-auth/quick-id-main/quick-id-data.service';
import { DynamicFormsModule } from '@nationwide/dgs-internet-servicing-dynamic-forms';
import { MetaDataService } from './service/metadata.service';
import { ConvertPatternFromPCPipe } from './pipes/policy.pipe';
import { InternetServicingAngularComponentsModule } from '@nationwide/internet-servicing-angular-components';
import { InternetServicingContentfulModule } from '@nationwide/internet-servicing-contentful';
import { IframeComponent } from './shared-ui/iframe/iframe.component';
import { WaitMessageWrapperComponent } from './shared-ui/wait-message-wrapper/wait-message-wrapper.component';
import { LoggerService } from './service/logger.service';

@NgModule({
  imports: [
    DynamicFormsModule,
    BrowserModule,
    CoreModule.forRoot(),
    NgbModule,
    FormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    ModelModule,
    SharedUiModule,
    ServiceModule,
    AppRoutingModule,
    SmartMilesModule,
    SmartRideModule,
    OAuthModule,
    SessionServicesModule,
    PolicyRequestsModule,
    BoltDirectivesModule,
    InternetServicingContentfulModule,
    InternetServicingAngularComponentsModule,
    InternetServicingAngularPipesModule,
    ZipCodeQuickAuthModule,
    ...environment.imports
  ],
  exports: [
    FactorTitlePipe,
    UnitsPipe,
    RoundToOnePipe,
    NearestMinPipe
  ],
  bootstrap: [AppComponent],
  declarations: [
    PreferencesComponent,
    EditableTextComponent,
    NotFoundComponent,
    TokenCaptureComponent,
    SmilesEsriMapComponent,
    DailySummaryComponent,
    SpeedGraphComponent,
    FactorTitlePipe,
    UnitsPipe,
    RoundToOnePipe,
    NearestMinPipe,
    SreAdminReloadComponent,
    SreDailySummaryComponent,
    SelfEnrollmentComponent,
    SelfEnrollmentReviewComponent,
    SelfEnrollmentConfirmationComponent,
    WaitSpinnerComponent,
    FocusOnInitDirective,
    QuickIDMainComponent,
    QuickIdZipComponent,
    IframeComponent,
    WaitMessageWrapperComponent
  ],
  providers: [
    SpeedGraphService,
    WaitSpinnerService,
    WindowRef,
    APICommonService,
    QuickIDSearchFormService,
    QuickIdDataService,
    Title,
    MetaDataService,
    LoggerService,
    ConvertPatternFromPCPipe,
    { provide: 'pbsConfig', useValue: environment },
    { provide: 'ctAuthConfig', useFactory: (): any => environment.authorize },
    { provide: 'validatorParams', useValue: ValidatorParams },
    { provide: 'validatorConfig', useValue: environment },
    { provide: HTTP_INTERCEPTORS, useClass: ValidatorService, multi: true },
    { provide: 'policyRequestsConfig', useValue: environment },
    { provide: 'contentfulConfig', useValue: environment.contentful },
    { provide: 'config', useValue: environment }
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule {
}
