import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe, DatePipe } from '@angular/common';
// TODO Check web and mobile to make sure all of these are used
@Pipe({ name: 'nonDriverStatus' })
export class NonDriverStatusPipe implements PipeTransform {
    transform(input: string): string {
        if (input != null) {
            if (input === 'NonDriver') {
                return 'Non-Driver';
            }
            return 'Excluded Driver';
        }
        return '';
    }
}

@Pipe({ name: 'maskLicenseNumber' })
export class MaskLicenseNumberPipe implements PipeTransform {
    transform(input: string): string {
        return maskLicenseNumber(input);
    }
}

function maskLicenseNumber(input: string): string {
    let maskedNumber = '';
    if (input != null) {
        maskedNumber = input;
        // eslint-disable-next-line no-magic-numbers
        if (maskedNumber.length > 4) {
            maskedNumber = maskedNumber.replace(/.(?=.{4})/g, '*');
        } else {
            maskedNumber = maskedNumber.replace(/[a-zA-Z0-9]/g, '*');
        }
    }
    return maskedNumber;
}

@Pipe({ name: 'maskDateOfBirth' })
export class MaskDateOfBirthPipe implements PipeTransform {
    transform(input: string): string {
        return maskDateOfBirth(input);
    }
}

function maskDateOfBirth(input: string): string {
    let maskedString = '';
    if (input != null) {
        const dateString = input.trim();
        if (dateString.includes('/')) {
            maskedString = `**/**/${dateString.slice(dateString.lastIndexOf('/') + 1)}`;
        }
    }
    return maskedString;
}

@Pipe({ name: 'licenseType' })
export class LicenseTypePipe implements PipeTransform {
    transform(input: boolean): string {
        if (input) {
            return 'International';
        }
        return 'Not International';
    }
}

@Pipe({ name: 'PCDate' })
export class PCDatePipe extends DatePipe implements PipeTransform {
    transform(date: string, format?: 'isMidnight' | 'isV10Date'): any {
        if (date) {
            try {
                const convertedDate = super.transform(date, 'yyyy-MM-dd');
                if (format === 'isV10Date') {
                    return convertedDate;
                }
                const time = format === 'isMidnight' ? 'T00:00:00Z' : 'T12:00:00Z';
                return convertedDate + time;
            } catch (error) {
                return date;
            }
        }
        return date;
    }
}

@Pipe({ name: 'convertToPCDate' })
export class ConvertToPCDatePipe implements PipeTransform {
    transform(date: string, config?: { isMidnight?: boolean; isV10Date?: boolean }): string {
        const regex = /^(0[1-9]|1[012])[/](0[1-9]|[12][0-9]|3[01])[/][0-9]{4}$/;
        const time = config?.isMidnight ? 'T00:00:00Z' : 'T12:00:00Z';
        if (regex.test(date)) {
            const dateArray = date.split('/');
            if (config?.isV10Date) {
                return `${dateArray[2]}-${dateArray[0]}-${dateArray[1]}`; // yyyy-mm-dd format
            } else {
                return `${dateArray[2]}-${dateArray[0]}-${dateArray[1]}${time}`; // yyyy-mm-ddThh:mm:ssZ format
            }
        }
        return date;
    }
}

@Pipe({ name: 'convertDateFromPC' })
export class ConvertDateFromPCPipe implements PipeTransform {
    transform(date: string, isV10Date?: boolean): string {
        if (date != null) {
            date = date.slice(0, 10);
            const regex = /^[0-9]{4}[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])$/;
            if (regex.test(date) || isV10Date) {
                const dateArray = date.split('-');
                return `${dateArray[1]}/${dateArray[2].slice(0, 2)}/${dateArray[0]}`; // mm/dd/yyyy format
            }
        }
        return '';
    }
}

@Pipe({ name: 'convertPatternFromPC' })
export class ConvertPatternFromPCPipe implements PipeTransform {
    transform(pcPattern: string): string {
        if (pcPattern != null && pcPattern[0] !== '^' && pcPattern[pcPattern.length - 1] !== '$') {
            return `^${pcPattern}$`;
        }
        return pcPattern;
    }
}

@Pipe({ name: 'discountsUpsell' })
export class DiscountsUpsellPipe implements PipeTransform {
    transform(discounts: any): any {
        const shownDiscounts = [];
        for (const discount of discounts) {
            if (discount.isDiscountApplied || discount.Upsell === 'yes') {
                shownDiscounts.push(discount);
            }
        }
        return shownDiscounts;
    }
}

@Pipe({ name: 'convertGisAddressToPCAddress' })
export class ConvertGisAddressToPCAddressPipe implements PipeTransform {
    transform(gisAddress: any): any {
        delete gisAddress.zip;
        delete gisAddress.inputPointZipWarning;
        delete gisAddress.longitude;
        delete gisAddress.latitude;
        delete gisAddress.matchCodeInterpretation;
        return gisAddress;
    }
}

@Pipe({ name: 'displayMonthlyAmount' })
export class DisplayMonthlyAmountPipe implements PipeTransform {
    constructor(private currencyPipe: CurrencyPipe) { }
    transform(amount, review?: boolean, perMonthSuffix?: boolean): string {
        let textAmount = '';
        if (amount < 1 && amount > 0) {
            // eslint-disable-next-line no-magic-numbers
            textAmount = `${Math.round(amount * 100)}¢`;
        } else {
            textAmount = this.currencyPipe.transform(amount, 'USD', 'symbol');
        }
        const suffix = perMonthSuffix ? ' per month' : '';
        return review ? textAmount : textAmount + suffix;
    }
}

@Pipe({ name: 'childCoverage' })
export class ChildCoveragePipe implements PipeTransform {
    transform(terms: any): any {
        return terms.filter((term) => term.childCoverage);
    }
}

@Pipe({ name: 'translateToYesNo' })
export class TranslateToYesNoPipe implements PipeTransform {
    transform(value: any): string {
        if (value) {
            return 'Yes';
        }
        return 'No';
    }
}

@Pipe({ name: 'limitOptionCoverage' })
export class LimitOptionCoveragePipe implements PipeTransform {
    transform(terms: any): any {
        if (terms?.length > 1 && terms[0].name === 'Limit') {
            return terms.filter((term) => term.name === 'Option');
        }
        return [];
    }
}

@Pipe({ name: 'childAndOptionCoverage' })
export class ChildAndOptionCoveragePipe implements PipeTransform {
    constructor(
        private childCoveragePipe: ChildCoveragePipe,
        private limitOptionCoveragePipe: LimitOptionCoveragePipe
    ) { }
    transform(terms: any): any {
        const childTerms = this.childCoveragePipe.transform(terms);
        const optionTerms = this.limitOptionCoveragePipe.transform(terms);
        return optionTerms.concat(childTerms);
    }
}

@Pipe({ name: 'trimPhoneNumber' })
export class TrimPhoneNumberPipe implements PipeTransform {
    transform(phoneNumber: string): string {
        return phoneNumber ? phoneNumber.replace(/[()-\s]/g, '') : '';
    }
}
