import { Trip } from 'app/model/trip';
import { VehicleResponse } from 'app/service/vehicle.service';

export class DailyContent {
  date: string;
  firstDate: string;
  lastDate: string;
  trips: Trip[];
  vehicle: VehicleResponse;

  constructor() {
  }
}
