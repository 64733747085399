// eslint-disable-next-line @typescript-eslint/naming-convention
export const State = {
    type: 'edge.metadata.typeinfo.typelistinfo.dto.TypelistMetadataDTO',
    value: {
        codes: [
            {
                code: 'AL',
                priority: 1,
                displayKey: 'typekey.State.AL'
            },
            {
                code: 'AK',
                priority: 2,
                displayKey: 'typekey.State.AK'
            },
            {
                code: 'AB',
                priority: 3,
                displayKey: 'typekey.State.AB'
            },
            {
                code: 'AZ',
                priority: 5,
                displayKey: 'typekey.State.AZ'
            },
            {
                code: 'AR',
                priority: 6,
                displayKey: 'typekey.State.AR'
            },
            {
                code: 'BC',
                priority: 7,
                displayKey: 'typekey.State.BC'
            },
            {
                code: 'CA',
                priority: 8,
                displayKey: 'typekey.State.CA'
            },
            {
                code: 'CO',
                priority: 9,
                displayKey: 'typekey.State.CO'
            },
            {
                code: 'CT',
                priority: 10,
                displayKey: 'typekey.State.CT'
            },
            {
                code: 'DE',
                priority: 11,
                displayKey: 'typekey.State.DE'
            },
            {
                code: 'DC',
                priority: 12,
                displayKey: 'typekey.State.DC'
            },
            {
                code: 'FL',
                priority: 14,
                displayKey: 'typekey.State.FL'
            },
            {
                code: 'GA',
                priority: 15,
                displayKey: 'typekey.State.GA'
            },
            {
                code: 'HI',
                priority: 17,
                displayKey: 'typekey.State.HI'
            },
            {
                code: 'ID',
                priority: 18,
                displayKey: 'typekey.State.ID'
            },
            {
                code: 'IL',
                priority: 19,
                displayKey: 'typekey.State.IL'
            },
            {
                code: 'IN',
                priority: 20,
                displayKey: 'typekey.State.IN'
            },
            {
                code: 'IA',
                priority: 21,
                displayKey: 'typekey.State.IA'
            },
            {
                code: 'KS',
                priority: 22,
                displayKey: 'typekey.State.KS'
            },
            {
                code: 'KY',
                priority: 23,
                displayKey: 'typekey.State.KY'
            },
            {
                code: 'LA',
                priority: 24,
                displayKey: 'typekey.State.LA'
            },
            {
                code: 'ME',
                priority: 25,
                displayKey: 'typekey.State.ME'
            },
            {
                code: 'MB',
                priority: 26,
                displayKey: 'typekey.State.MB'
            },
            {
                code: 'MD',
                priority: 28,
                displayKey: 'typekey.State.MD'
            },
            {
                code: 'MA',
                priority: 29,
                displayKey: 'typekey.State.MA'
            },
            {
                code: 'MI',
                priority: 30,
                displayKey: 'typekey.State.MI'
            },
            {
                code: 'MN',
                priority: 31,
                displayKey: 'typekey.State.MN'
            },
            {
                code: 'MS',
                priority: 32,
                displayKey: 'typekey.State.MS'
            },
            {
                code: 'MO',
                priority: 33,
                displayKey: 'typekey.State.MO'
            },
            {
                code: 'MT',
                priority: 34,
                displayKey: 'typekey.State.MT'
            },
            {
                code: 'NE',
                priority: 35,
                displayKey: 'typekey.State.NE'
            },
            {
                code: 'NV',
                priority: 36,
                displayKey: 'typekey.State.NV'
            },
            {
                code: 'NB',
                priority: 37,
                displayKey: 'typekey.State.NB'
            },
            {
                code: 'NH',
                priority: 38,
                displayKey: 'typekey.State.NH'
            },
            {
                code: 'NJ',
                priority: 39,
                displayKey: 'typekey.State.NJ'
            },
            {
                code: 'NM',
                priority: 40,
                displayKey: 'typekey.State.NM'
            },
            {
                code: 'NY',
                priority: 41,
                displayKey: 'typekey.State.NY'
            },
            {
                code: 'NC',
                priority: 43,
                displayKey: 'typekey.State.NC'
            },
            {
                code: 'ND',
                priority: 44,
                displayKey: 'typekey.State.ND'
            },
            {
                code: 'NT',
                priority: 46,
                displayKey: 'typekey.State.NT'
            },
            {
                code: 'NS',
                priority: 47,
                displayKey: 'typekey.State.NS'
            },
            {
                code: 'NU',
                priority: 48,
                displayKey: 'typekey.State.NU'
            },
            {
                code: 'OH',
                priority: 49,
                displayKey: 'typekey.State.OH'
            },
            {
                code: 'OK',
                priority: 50,
                displayKey: 'typekey.State.OK'
            },
            {
                code: 'ON',
                priority: 51,
                displayKey: 'typekey.State.ON'
            },
            {
                code: 'OR',
                priority: 52,
                displayKey: 'typekey.State.OR'
            },
            {
                code: 'PA',
                priority: 53,
                displayKey: 'typekey.State.PA'
            },
            {
                code: 'PE',
                priority: 54,
                displayKey: 'typekey.State.PE'
            },
            {
                code: 'QC',
                priority: 56,
                displayKey: 'typekey.State.QC'
            },
            {
                code: 'RI',
                priority: 57,
                displayKey: 'typekey.State.RI'
            },
            {
                code: 'SK',
                priority: 58,
                displayKey: 'typekey.State.SK'
            },
            {
                code: 'SC',
                priority: 59,
                displayKey: 'typekey.State.SC'
            },
            {
                code: 'SD',
                priority: 60,
                displayKey: 'typekey.State.SD'
            },
            {
                code: 'TN',
                priority: 61,
                displayKey: 'typekey.State.TN'
            },
            {
                code: 'TX',
                priority: 62,
                displayKey: 'typekey.State.TX'
            },
            {
                code: 'UT',
                priority: 63,
                displayKey: 'typekey.State.UT'
            },
            {
                code: 'VT',
                priority: 64,
                displayKey: 'typekey.State.VT'
            },
            {
                code: 'VA',
                priority: 66,
                displayKey: 'typekey.State.VA'
            },
            {
                code: 'WA',
                priority: 67,
                displayKey: 'typekey.State.WA'
            },
            {
                code: 'WV',
                priority: 68,
                displayKey: 'typekey.State.WV'
            },
            {
                code: 'WI',
                priority: 69,
                displayKey: 'typekey.State.WI'
            },
            {
                code: 'WY',
                priority: 70,
                displayKey: 'typekey.State.WY'
            },
            {
                code: 'YT',
                priority: 71,
                displayKey: 'typekey.State.YT'
            },
            {
                code: 'AA_Ext',
                priority: 110,
                displayKey: 'typekey.State.AA_Ext'
            },
            {
                code: 'AE_Ext',
                priority: 120,
                displayKey: 'typekey.State.AE_Ext'
            },
            {
                code: 'AP_Ext',
                priority: 130,
                displayKey: 'typekey.State.AP_Ext'
            }
        ],
        filters: [
            {
                name: 'fiftystates',
                includedCodes: [
                    'AL',
                    'AK',
                    'AZ',
                    'AR',
                    'CA',
                    'CO',
                    'CT',
                    'DE',
                    'DC',
                    'FL',
                    'GA',
                    'HI',
                    'ID',
                    'IL',
                    'IN',
                    'IA',
                    'KS',
                    'KY',
                    'LA',
                    'ME',
                    'MD',
                    'MA',
                    'MI',
                    'MN',
                    'MS',
                    'MO',
                    'MT',
                    'NE',
                    'NV',
                    'NH',
                    'NJ',
                    'NM',
                    'NY',
                    'NC',
                    'ND',
                    'OH',
                    'OK',
                    'OR',
                    'PA',
                    'RI',
                    'SC',
                    'SD',
                    'TN',
                    'TX',
                    'UT',
                    'VT',
                    'VA',
                    'WA',
                    'WV',
                    'WI',
                    'WY'
                ]
            }
        ],
        namedMetadata: []
    }
};
