import { Component, Input, OnDestroy, OnInit, OnChanges } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TimeFrameUnit } from 'app/enum/timeframe';
import { TimeFrame } from 'app/interfaces/timeframe.interface';
import { EstimatedPaymentDetails } from 'app/model/estimatedPaymentDetails';
import { TelematicsPaymentDetails } from 'app/model/telematicsPaymentDetails';
import { Vehicle } from 'app/model/vehicle';
import { LoggerService } from 'app/service/logger.service';
import { PersonalLinesPolicyService } from 'app/service/personal-lines-policy.service';
import { TelematicsTransactionsService } from 'app/service/telematics-transactions.service';
import { TimeframeService } from 'app/service/timeframe.service';
import { UserService } from 'app/service/user.service';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'app-estimated-premium',
    templateUrl: './estimated-premium.component.html',
    styleUrls: ['./estimated-premium.component.css']
})
export class EstimatedPremiumComponent implements OnInit, OnDestroy {
    @Input() currentMonth;

    vehicle: Vehicle;
    timeframe: TimeFrame;
    routerSubscription: Subscription;
    milesInTimeframe: number;
    estimatedPremiumStart: string;
    estimatedPremiumEnd: string;
    paymentDetails: TelematicsPaymentDetails;
    partialMonth = false;
    paymentDataLoaded = false;
    noData = false;
    estimate = 0;
    timeframeSubscription: Subscription = null;
    currentMonthPaymentDetail: boolean;
    paymentData: any;
    isSmartMiles2 = false;

    // eslint-disable-next-line max-params
    constructor(
        public router: Router,
        public route: ActivatedRoute,
        public timeframeService: TimeframeService,
        public user: UserService,
        public policy: PersonalLinesPolicyService,
        public telematicTransactions: TelematicsTransactionsService,
        private logger: LoggerService
    ) { }

    ngOnInit(): void {
        this.vehicle = this.route.snapshot.data.vehicle;
        this.setupSubscriptions();
        if (this.vehicle.scoringModel === 'SM2') {
          this.isSmartMiles2 = true;
      }
    }

    setupSubscriptions = (): void => {
        this.routerSubscription = this.router.events.pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(this.loadEstimatedPaymentDetails);
        this.loadEstimatedPaymentDetails(null);
        const vehicle = this.route.snapshot.data.vehicle;
        const daysInYear = 365;
        const daysInMonth = 30;
        this.estimate = Math.round(vehicle.annualMileage / daysInYear) * daysInMonth;
    };

    loadEstimatedPaymentDetails = (event): void => {
        if (event && event.url.indexOf('paymentDetails') === -1) {
            return;
        }

        this.paymentDataLoaded = false;
        this.timeframeSubscription = this.timeframeService.timeframeChange$.subscribe((tf) => {
            this.timeframe = tf;
            if (!tf.start || !tf.end) {
                this.buildMonthTimeFrame();
            }
            this.setMonthTimeFrame(tf, this.route.snapshot.data.vehicle);
        });
    };

    buildMonthTimeFrame(): void {
        const startDateString = this.route.snapshot.paramMap.get('amount');
        const newTf = this.vehicle.getTermLimitsContainingMoment(moment(startDateString));
        this.timeframe.start = moment.max(newTf.start.clone(), moment(this.vehicle.smEnrollDate));
        this.timeframe.end = newTf.end.clone();
        const tf = TimeFrame.buildTimeframe(moment(this.timeframe.start), moment(this.timeframe.end), TimeFrameUnit.MONTH);

        this.partialMonth = this.timeframe.start.isSame(moment(this.vehicle.smEnrollDate), 'day') &&
            moment(this.vehicle.smEnrollDate).isAfter(moment(this.vehicle.periodStart), 'day') &&
            this.timeframe.start.isAfter(this.timeframe.start.clone().startOf('month'), 'day');

        this.timeframeService.updateTimeframeRules(tf);
    }

    setMonthTimeFrame(tf: TimeFrame, vehicle: Vehicle): void {
        tf.unit = TimeFrameUnit.MONTH;
        const newTf = vehicle.getTermLimitsContainingMoment(tf.start.clone().add(1, 'day'));
        this.handleTimeframeChange(newTf);
    }

    handleTimeframeChange = (tf: TimeFrame): void => {
        if (!tf.start || !tf.end) {
            this.buildMonthTimeFrame();
        } else {
            this.timeframe = tf;
        }
        this.milesInTimeframe = this.vehicle.calcMilesDrivenInTimeframe(tf);
        this.vehiclePaymentDetails(tf);
    };

    vehiclePaymentDetails(tf): void {
        this.currentMonthPaymentDetail = false;
        this.estimatedPremiumStart = tf.start.format('YYYY-MM-DD');
        this.estimatedPremiumEnd = tf.end.format('YYYY-MM-DD');
        if (moment(this.estimatedPremiumEnd).isSameOrAfter(moment(environment.futureDate).format('YYYY-MM-DD'))) {
            this.currentMonthPaymentDetail = true;
        }

        this.paymentDetails = this.telematicTransactions.getTelematicsPaymentDetailsData(
            this.vehicle?.vehicleId, this.estimatedPremiumStart, this.vehicle.vehicleEnrollments
        );
        this.paymentDataLoaded = true;
    }

    get trendDelta(): number {
        const delta = Math.abs(this.milesInTimeframe - this.paymentDetails.estimatedMiles);
        return delta;
    }

    get absoluteChange(): number {
        return Math.abs(this.paymentDetails.mileagePremiumDifference);
    }

    get absoluteMileageChange(): number {
        return Math.abs(this.paymentDetails.mileageDifference);
    }

    ngOnDestroy(): void {
        if (this.routerSubscription) {
            this.routerSubscription.unsubscribe();
            this.routerSubscription = null;
        }
    }
}
