import { Component, OnInit } from '@angular/core';
import { UserService } from 'app/service/user.service';
import { NavbarService } from 'app/service/nav-bar.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MobileProgram } from 'app/model/mobileprogram';
import { MobilePolicyInfoService } from 'app/service/mobile-policy-info.service';

@Component({
  selector: 'app-mobile-device',
  templateUrl: './mobile-device.component.html',
  styleUrls: ['./mobile-device.component.css']
})

export class MobileDeviceComponent implements OnInit {
  counter: number;
  policyNumber: string;
  mobileProgram: MobileProgram;

  // eslint-disable-next-line max-params
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _user: UserService,
    private _mobilePolicyService: MobilePolicyInfoService,
    private _navBar: NavbarService
  ) { }

  ngOnInit(): void {
    this._navBar.headerDisplay(this._user.isLoggedIn, this.router.url);
    this.policyNumber = this.route.snapshot.paramMap.get('policyNumber');
    this._mobilePolicyService.getMobileProgramData(this.policyNumber).subscribe((mobileProgramData) => {
      this.mobileProgram = mobileProgramData;
    });
  }
}
