// eslint-disable-next-line @typescript-eslint/naming-convention
export const NameSuffix = {
    type: 'edge.metadata.typeinfo.typelistinfo.dto.TypelistMetadataDTO',
    value: {
        codes: [
            {
                code: 'c_Ir',
                priority: 3,
                displayKey: 'typekey.NameSuffix.c_Ir'
            },
            {
                code: 'c_II',
                priority: 4,
                displayKey: 'typekey.NameSuffix.c_II'
            },
            {
                code: 'c_III',
                priority: 5,
                displayKey: 'typekey.NameSuffix.c_III'
            },
            {
                code: 'c_IV',
                priority: 6,
                displayKey: 'typekey.NameSuffix.c_IV'
            },
            {
                code: 'c_V_Ext',
                priority: 7,
                displayKey: 'typekey.NameSuffix.c_V_Ext'
            },
            {
                code: 'c_VI_Ext',
                priority: 8,
                displayKey: 'typekey.NameSuffix.c_VI_Ext'
            },
            {
                code: 'c_VII_Ext',
                priority: 9,
                displayKey: 'typekey.NameSuffix.c_VII_Ext'
            },
            {
                code: 'c_VIII_Ext',
                priority: 10,
                displayKey: 'typekey.NameSuffix.c_VIII_Ext'
            },
            {
                code: 'c_IX_Ext',
                priority: 11,
                displayKey: 'typekey.NameSuffix.c_IX_Ext'
            },
            {
                code: 'c_X_Ext',
                priority: 12,
                displayKey: 'typekey.NameSuffix.c_X_Ext'
            },
            {
                code: 'jr',
                priority: 13,
                displayKey: 'typekey.NameSuffix.jr'
            },
            {
                code: 'sr',
                priority: 14,
                displayKey: 'typekey.NameSuffix.sr'
            }
        ],
        filters: [
            {
                name: 'PAQuickQuoteSuffix_Ext',
                includedCodes: [
                    'c_Ir',
                    'c_II',
                    'c_III',
                    'c_IV',
                    'c_V_Ext',
                    'c_VI_Ext',
                    'c_VII_Ext',
                    'c_VIII_Ext',
                    'c_IX_Ext',
                    'c_X_Ext',
                    'jr',
                    'sr'
                ]
            }
        ]
    }
};
