import { Injectable } from '@angular/core';
import { OrderByPipe } from '@nationwide/internet-servicing-angular-pipes';
import { METADATA } from 'app/model/metadata';

export interface PropertyCondition { conditions: any[]; propertyName: string; operator: string; hasNotOperator?: boolean }

export interface PropertyMetadata { property: string; conditions: any[]; propertyName?: string; propertySuffix: string }

@Injectable()
export class MetaDataService {
    ignorableOperators = ['isQPCApplicable', 'isGroupOccupationApplicable', 'isQPCApplicableForJob'];

    constructor(
        private orderByPipe: OrderByPipe
    ) { }

    getDTOInfo(dtoValue: string): any {
        if (METADATA[dtoValue]) {
            return JSON.parse(JSON.stringify(METADATA[dtoValue]));
        }
    }

    getFilterList(displayKey, filterName): any {
        if (METADATA[displayKey]?.value?.filters != null) {
            for (const filter of METADATA[displayKey].value.filters) {
                if (filter.name === filterName) {
                    return JSON.parse(JSON.stringify(filter.includedCodes));
                }
            }
        }
    }

    // TODO: Too many loops :(
    // eslint-disable-next-line sonarjs/cognitive-complexity
    getFilteredCategories(displayKey, filterCategories): Array<any> {
        const filteredCodes = [];
        if (METADATA[displayKey]?.value?.codes) {
            for (let index = 0, length2 = METADATA[displayKey].value.codes.length; index < length2; index++) {
                let categoryMatchCount = 0;
                for (let l = 0, length4 = filterCategories.length; l < length4; l++) {
                    for (let k = 0, length3 = METADATA[displayKey].value.codes[index].categories.length; k < length3; k++) {
                        if (METADATA[displayKey].value.codes[index].categories[k].typecode === filterCategories[l]) {
                            categoryMatchCount++;
                            break;
                        }
                    }
                }
                if (categoryMatchCount === filterCategories.length) {
                    filteredCodes.push({
                        name: METADATA[displayKey].value.codes[index].code,
                        value: METADATA[displayKey].value.codes[index].code,
                        priority: METADATA[displayKey].value.codes[index].priority
                    });
                }
            }
        }
        return filteredCodes;
    }

    sortListByPriority(displayKey, list): Array<any> {
        const sortedList = [];
        if (METADATA[displayKey]?.value?.codes) {
            let priorityList = METADATA[displayKey].value.codes;
            priorityList = this.orderByPipe.transform({ array: priorityList, sortFieldArray: ['priority'] });
            for (const priorityElement of priorityList) {
                for (const listElement of list) {
                    if (priorityElement.code === listElement || priorityElement.code === listElement.value) {
                        sortedList.push(listElement);
                    }
                }
            }
        }
        return sortedList;
    }
}
