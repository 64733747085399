import { Discount } from 'app/model/discount';
import { Vehicle } from 'app/model/vehicle';

export class SmartRideDiscountTrendContent {
  discounts: Discount[];
  vehicle: Vehicle;
  earlierDataExist: boolean;
  laterDataExist: boolean;

  constructor() {
    this.discounts = [];
  }
}
