/* eslint-disable @typescript-eslint/naming-convention */
export const Translations = {
    'AdditionalInterestType.AdditionalInsured_Ext': 'Additional Insured',
    'AdditionalInterestType.AdditionalInterest_Ext': 'Additional Interest',
    'AdditionalInterestType.CertHolder_Ext': 'Certificate Holder',
    'AdditionalInterestType.CertificateOfIns_Ext': 'Certificate of Insurance',
    'AdditionalInterestType.CONSALE': 'Contract of Sale',
    'AdditionalInterestType.LIEN': 'Lienholder',
    'AdditionalInterestType.LENDLOSS': 'Lenders Loss Payable',
    'AdditionalInterestType.LESSOR': 'Lease Holder',
    'AdditionalInterestType.LOSSP': 'Loss Payee',
    'AdditionalInterestType.LOSSPAY': 'Loss Payable',
    'AdditionalInterestType.Mortgagee_Ext': 'Mortgagee',
    'AdditionalInterestType.NoticeDesignee_Ext': 'Notice Designee',
    'AdditionalInterestType.ServiceCompany_Ext': 'Service Company',
    'AdditionalInterestType.ThirdParty_Ext': 'Third Party Designee',
    'DriverType_Ext.Driver': 'Driver',
    'DriverType_Ext.Excluded': 'Excluded',
    'DriverType_Ext.NonDriver': 'Non Driver',
    'DriverType_Ext.OccasionalDriver': 'Occasional Driver',
    'DriverType_Ext.PrincipalDriver': 'Principal Driver',
    'DriverType_Ext.PotentialYouthfulDriver': 'Potential Youthful Driver',
    'DriverType_Ext.RatedOnNonNationwidePol': 'Rated on Non-Nationwide Policy',
    'DriverType_Ext.Unlicensed': 'Unlicensed',
    'DurationAtWorkLocat_Ext.0-5mo': '0-5 months',
    'DurationAtWorkLocat_Ext.6-11mo': '6-11 months',
    'DurationAtWorkLocat_Ext.GE_12mo': '12+ months',
    'DurationAtWorkLocat_Ext.Other': 'Other',
    'EmploymentStatusType.0To5MoNotInclStudent_Ext': '0-5 months (NOT including Student)',
    'EmploymentStatusType.0To5MoStudent_Ext': '0-5 months (Student ONLY)',
    'EmploymentStatusType.6To11MoNotInclStudent_Ext': '6-11 months (NOT including Student)',
    'EmploymentStatusType.6To11MoStudent_Ext': '6-11 months (Student ONLY)',
    'EmploymentStatusType.AFULL': 'Apprentice - full time',
    'EmploymentStatusType.APART': 'Apprentice - part time',
    'EmploymentStatusType.GE12MoNotInclStudent_Ext': '12+ months (NOT including Student)',
    'EmploymentStatusType.GE12MoStudent_Ext': '12+ months (Student ONLY)',
    'EmploymentStatusType.Homemaker_Ext': 'Homemaker',
    'EmploymentStatusType.LaidOff_Ext': 'Laid Off',
    'EmploymentStatusType.OFF': 'Officer',
    'EmploymentStatusType.Other': 'Other',
    'EmploymentStatusType.PART': 'Partner',
    'EmploymentStatusType.PI': 'Piece work',
    'EmploymentStatusType.REG': 'Regular employee full time',
    'EmploymentStatusType.RET': 'Retired',
    'EmploymentStatusType.Retired_Ext': 'Retired',
    'EmploymentStatusType.SEASN': 'Seasonal',
    'EmploymentStatusType.STRI': 'On strike',
    'EmploymentStatusType.TIME': 'Part time employee',
    'EmploymentStatusType.UNEM': 'Unemployed',
    'EmploymentStatusType.Unemployed_Ext': 'Unemployed',
    'EmploymentStatusType.UNEMPSCR': 'Unemployed due to plant shutdown, closing or other reduction',
    'EmploymentStatusType.VOLUN': 'Volunteer',
    'FormSubCategory_Ext.ApplicationPrivacyStatement': 'Application Privacy Statement ',
    'FormSubCategory_Ext.StatementResidency': 'Statement of Residency',
    'HealthCarePlan_Ext.Medicaid': 'Medicaid',
    'HealthCarePlan_Ext.Medicare': 'Medicare',
    'HealthCarePlan_Ext.NoQualifiedHealthCoverage': 'No Qualified Health Coverage',
    'HealthCarePlan_Ext.OtherPIPHealthBenefitPolicy': 'Other PIP Health Benefit Policy',
    'HealthCarePlan_Ext.QualifyingHealthCoverage': 'Qualifying Health Coverage',
    'InfractionSubType_Ext.999': 'Violation',
    'InfractionSubType_Ext.888': 'Violation',
    'InfractionSubType_Ext.777': 'Administrative - Registration, Title, License',
    'InfractionSubType_Ext.333': 'Comprehensive and Unattended Collision Loss',
    'InfractionSubType_Ext.100': 'Careless Driving',
    'InfractionSubType_Ext.101': 'Cancelled License',
    'InfractionSubType_Ext.102': 'Accident',
    'InfractionSubType_Ext.103': 'Accident',
    'InfractionSubType_Ext.104': 'At-Fault Accident',
    'InfractionSubType_Ext.105': 'At-Fault Accident',
    'InfractionSubType_Ext.106': 'At-Fault Accident',
    'InfractionSubType_Ext.107': 'Operator in Household Under Suspension or Revocation',
    'InfractionSubType_Ext.108': 'Inexperienced Driver',
    'InfractionSubType_Ext.109': 'Loaning License Plates or Registration',
    'InfractionSubType_Ext.110': 'Suspended License',
    'InfractionSubType_Ext.111': 'Previous Suspension',
    'InfractionSubType_Ext.112': 'Suspension for Accumulation of Points',
    'InfractionSubType_Ext.113': 'Unable to Verify Driving Record',
    'InfractionSubType_Ext.114': 'Suspension due to Failed Exam',
    'InfractionSubType_Ext.115': 'Moving Violation',
    'InfractionSubType_Ext.116': 'Unable to Verify Driving Record',
    'InfractionSubType_Ext.117': 'Inexperienced Driver',
    'InfractionSubType_Ext.118': 'Restored Driving Privileges',
    'InfractionSubType_Ext.119': 'Moving Violation',
    'InfractionSubType_Ext.120': 'Littering',
    'InfractionSubType_Ext.121': 'Accident',
    'InfractionSubType_Ext.122': 'Injury Accident',
    'InfractionSubType_Ext.123': 'Non-Moving Accident',
    'InfractionSubType_Ext.124': 'Speeding',
    'InfractionSubType_Ext.125': 'Speeding, 15 MPH over limit',
    'InfractionSubType_Ext.126': 'Violation with Collision',
    'InfractionSubType_Ext.127': 'Administrative - Registration, Title, License',
    'InfractionSubType_Ext.129': 'Commercial Violation',
    'InfractionSubType_Ext.130': 'Equipment Violation',
    'InfractionSubType_Ext.131': 'Motorcycle Violation',
    'InfractionSubType_Ext.132': 'Violation Involving Substance Abuse',
    'InfractionSubType_Ext.133': 'Violation Involving Controlled Substance',
    'InfractionSubType_Ext.134': 'Injury Accident',
    'InfractionSubType_Ext.135': 'Excessive Violations',
    'InfractionSubType_Ext.136': 'Moving Violation',
    'InfractionSubType_Ext.137': 'Moving Accident, Not-At-Fault',
    'InfractionSubType_Ext.138': 'Glass Damage',
    'InfractionSubType_Ext.139': 'Seat Belt Violation',
    'InfractionSubType_Ext.140': 'Major Violation',
    'InfractionSubType_Ext.141': 'Accident',
    'InfractionSubType_Ext.142': 'Major Speeding Violation',
    'InfractionSubType_Ext.143': 'Speeding',
    'InfractionSubType_Ext.144': 'Speeding, 26-30 MPH over limit',
    'InfractionSubType_Ext.145': 'Aggressive Driving',
    'InfractionSubType_Ext.146': 'Comprehensive Loss',
    'InfractionSubType_Ext.000': 'Accident',
    'InfractionSubType_Ext.001': 'Unable to Verify Driving Record',
    'InfractionSubType_Ext.002': 'Accident Involving Property Damage',
    'InfractionSubType_Ext.003': 'Collision Accident',
    'InfractionSubType_Ext.004': 'Accident, Not-At-Fault',
    'InfractionSubType_Ext.005': 'Accident Involving Property Damage',
    'InfractionSubType_Ext.006': 'Accident',
    'InfractionSubType_Ext.007': 'Moving Accident',
    'InfractionSubType_Ext.008': 'Legally Stopped or Parked',
    'InfractionSubType_Ext.009': 'Accident, Not-At-Fault',
    'InfractionSubType_Ext.010': 'Speeding, Less than 10 MPH over limit',
    'InfractionSubType_Ext.011': 'Stop Sign Violation',
    'InfractionSubType_Ext.012': 'Failure to Yield',
    'InfractionSubType_Ext.013': 'Red Light Violation',
    'InfractionSubType_Ext.014': 'Speeding',
    'InfractionSubType_Ext.015': 'Inexperienced Driver',
    'InfractionSubType_Ext.016': 'Driving an Unregistered Vehicle',
    'InfractionSubType_Ext.017': 'Driving Through a Safety Zone',
    'InfractionSubType_Ext.018': 'No Prior Insurance/Inexperienced Driver',
    'InfractionSubType_Ext.019': 'Failure to Report an Accident',
    'InfractionSubType_Ext.020': 'Speeding',
    'InfractionSubType_Ext.021': 'Unable to Verify Driving Record',
    'InfractionSubType_Ext.022': 'Following too Close',
    'InfractionSubType_Ext.023': 'Driving on the Wrong Side of the Road',
    'InfractionSubType_Ext.024': 'Illegal Passing',
    'InfractionSubType_Ext.025': 'Speeding, More than 10 MPH over limit',
    'InfractionSubType_Ext.026': 'Speeding',
    'InfractionSubType_Ext.027': 'Passing a Stopped School Bus',
    'InfractionSubType_Ext.028': 'Implied Consent/Refused Chemical Test and/or 10 day revocation conviction',
    'InfractionSubType_Ext.029': 'DUI/DWI',
    'InfractionSubType_Ext.030': 'Transportation of Alcohol or Drugs for Sale',
    'InfractionSubType_Ext.031': 'Speeding',
    'InfractionSubType_Ext.032': 'Negligent Driving',
    'InfractionSubType_Ext.033': 'Racing',
    'InfractionSubType_Ext.034': 'Racing',
    'InfractionSubType_Ext.035': 'Reckless Driving',
    'InfractionSubType_Ext.036': 'Accompanying a Driving Permitte While Impaired',
    'InfractionSubType_Ext.037': 'Driving Using a Provisional License After Consuming Drugs or Alcohol',
    'InfractionSubType_Ext.039': 'Failure to Dim Headlights',
    'InfractionSubType_Ext.040': 'Speeding, More than 10 MPH over limit',
    'InfractionSubType_Ext.041': 'Speeding',
    'InfractionSubType_Ext.042': 'Speeding - Resulting in a Suspended or Revoked License',
    'InfractionSubType_Ext.043': 'Speeding',
    'InfractionSubType_Ext.045': 'Speeding in School Zone',
    'InfractionSubType_Ext.046': 'Speeding in School Zone',
    'InfractionSubType_Ext.047': 'Driving Too Fast for Conditions',
    'InfractionSubType_Ext.048': 'Defective Taillight',
    'InfractionSubType_Ext.049': 'Speeding, 16 MPH over limit',
    'InfractionSubType_Ext.050': 'Felony Involving a Vehicle',
    'InfractionSubType_Ext.051': 'Driving with Suspended or Revoked License',
    'InfractionSubType_Ext.052': 'Driving a vehicle without owner\'s permission',
    'InfractionSubType_Ext.053': 'Giving Your License to Others',
    'InfractionSubType_Ext.054': 'Misrepresentation of Facts on a License Application',
    'InfractionSubType_Ext.055': 'False Representation of Identity on a License Application',
    'InfractionSubType_Ext.056': 'Driving an Uninsured Vehicle ',
    'InfractionSubType_Ext.057': 'Driving Without a Valid License',
    'InfractionSubType_Ext.059': 'Filing a False Accident Report',
    'InfractionSubType_Ext.061': 'Speeding',
    'InfractionSubType_Ext.062': 'Reckless Driving',
    'InfractionSubType_Ext.064': 'Failure to Stop',
    'InfractionSubType_Ext.065': 'Accident Involving Speeding and Damages',
    'InfractionSubType_Ext.066': 'Accident Involving Speeding and Injury',
    'InfractionSubType_Ext.067': 'Accident Involving Speeding and Injury/Damages',
    'InfractionSubType_Ext.068': 'Speeding, Less than 10 MPH over limit',
    'InfractionSubType_Ext.069': 'Speeding, 10 MPH over limit',
    'InfractionSubType_Ext.070': 'Moving Violation',
    'InfractionSubType_Ext.071': 'Failure to Obey Restrictions',
    'InfractionSubType_Ext.072': 'Accident Involving Death',
    'InfractionSubType_Ext.073': 'Permitted an Unlicensed Driver to Drive',
    'InfractionSubType_Ext.074': 'Speeding',
    'InfractionSubType_Ext.075': 'Driving with Suspended or Revoked Registration',
    'InfractionSubType_Ext.076': 'Failure to Respond',
    'InfractionSubType_Ext.077': 'Drug or Alcohol Violation',
    'InfractionSubType_Ext.078': 'Proof of insurance required',
    'InfractionSubType_Ext.080': 'Driving with Improper Lights',
    'InfractionSubType_Ext.081': 'DUI/DWI',
    'InfractionSubType_Ext.082': 'Hit and Run',
    'InfractionSubType_Ext.083': 'Manslaughter/Vehicular Homicide, Assault or Negligent Homicide',
    'InfractionSubType_Ext.084': 'Driving with Improper Brakes',
    'InfractionSubType_Ext.085': 'Driving in Unsafe Conditions',
    'InfractionSubType_Ext.086': 'Leaving the Scene of an Accident',
    'InfractionSubType_Ext.087': 'Speeding, Over 100 MPH',
    'InfractionSubType_Ext.088': 'Non-Moving Violation',
    'InfractionSubType_Ext.089': 'Non-Moving Violation',
    'InfractionSubType_Ext.090': 'Accident with Additional Moving Violation',
    'InfractionSubType_Ext.091': 'Violation Involving Hazardous Materials',
    'InfractionSubType_Ext.092': 'Driving with No Headlights',
    'InfractionSubType_Ext.093': 'Violation with Collision',
    'InfractionSubType_Ext.094': 'Driving Without Lights to Avoid Arrest',
    'InfractionSubType_Ext.095': 'Violation with Collision',
    'InfractionSubType_Ext.096': 'Accident Involving Damage with Other Moving Violation',
    'InfractionSubType_Ext.097': 'Accident',
    'InfractionSubType_Ext.098': 'Failure to Obey Traffic Device',
    'InfractionSubType_Ext.099': 'No Accidents or Violations on Driving Record',
    'LicenseStatus_Ext.Active': 'Active',
    'LicenseStatus_Ext.Cancelled': 'Cancelled',
    'LicenseStatus_Ext.International': 'International',
    'LicenseStatus_Ext.Other': 'Other',
    'LicenseStatus_Ext.Permit': 'Permit',
    'LicenseStatus_Ext.Revoked': 'Revoked',
    'LicenseStatus_Ext.Suspended': 'Suspended',
    'LicenseStatus_Ext.UnverifiableNoHit': 'Unverifiable',
    'GenderType.F': 'Female',
    'GenderType.M': 'Male',
    'MaritalStatus.D': 'Divorced',
    'MaritalStatus.M': 'Married',
    'MaritalStatus.P': 'Separated',
    'MaritalStatus.S': 'Single',
    'MaritalStatus.W': 'Widowed',
    'NameSuffix.c_Ir': 'I',
    'NameSuffix.c_II': 'II',
    'NameSuffix.c_III': 'III',
    'NameSuffix.c_IV': 'IV',
    'NameSuffix.c_V_Ext': 'V',
    'NameSuffix.c_VI_Ext': 'VI',
    'NameSuffix.c_VII_Ext': 'VII',
    'NameSuffix.c_VIII_Ext': 'VIII',
    'NameSuffix.c_IX_Ext': 'IX',
    'NameSuffix.c_X_Ext': 'X',
    'NameSuffix.jr': 'Jr.',
    'NameSuffix.sr': 'Sr.',
    'OccupationGroup_Ext.Accountant': 'Accountant',
    'OccupationGroup_Ext.AccreditedCollegeAlumni': 'Accredited College Alumni',
    'OccupationGroup_Ext.AllOther': 'All Other',
    'OccupationGroup_Ext.BusinessOwner': 'Business Owner',
    'OccupationGroup_Ext.Dentist': 'Dentist',
    'OccupationGroup_Ext.Educator': 'Educator',
    'OccupationGroup_Ext.Firefighter': 'Firefighter',
    'OccupationGroup_Ext.LawEnforcementOfficer': 'Law Enforcement Officer',
    'OccupationGroup_Ext.LicensedPharmacist': 'Licensed Pharmacist',
    'OccupationGroup_Ext.NationwideEmployeeAgent': 'Nationwide Employee/Agent',
    'OccupationGroup_Ext.Paramedic': 'Paramedic',
    'OccupationGroup_Ext.PhysicianRegisteredNurse': 'Physician/Registered Nurse',
    'OccupationGroup_Ext.Pilot': 'Pilot',
    'OccupationGroup_Ext.ScientistOrEngineer': 'Scientist or Engineer',
    'OccupationGroup_Ext.Veterinarian': 'Veterinarian',
    'Policy.BaseStateCannotBeChanged': 'Policy Base State cannot be changed',
    'RelationToPNI_Ext.Child': 'Child',
    'RelationToPNI_Ext.CivilUnion': 'Civil Union',
    'RelationToPNI_Ext.CoOwner': 'Co-Owner',
    'RelationToPNI_Ext.Employee': 'Employee',
    'RelationToPNI_Ext.HouseholdMember': 'Household Member',
    'RelationToPNI_Ext.Other': 'Other',
    'RelationToPNI_Ext.OtherNonRelative': 'Other - Non-Relative',
    'RelationToPNI_Ext.OtherRelative': 'Other - Relative',
    'RelationToPNI_Ext.Parent': 'Parent',
    'RelationToPNI_Ext.PrimaryNamedInsured': 'Primary Named Insured',
    'RelationToPNI_Ext.Spouse': 'Spouse',
    'State.AA_Ext': 'AA - Military Base',
    'State.AE_Ext': 'AE - Military Base',
    'State.AP_Ext': 'AP - Military Base',
    'State.AB': 'Alberta',
    'State.AK': 'Alaska',
    'State.AL': 'Alabama',
    'State.AR': 'Arkansas',
    'State.AZ': 'Arizona',
    'State.BC': 'British Columbia',
    'State.CA': 'California',
    'State.CO': 'Colorado',
    'State.CT': 'Connecticut',
    'State.DC': 'District of Columbia',
    'State.DE': 'Delaware',
    'State.FL': 'Florida',
    'State.GA': 'Georgia',
    'State.HI': 'Hawaii',
    'State.IA': 'Iowa',
    'State.ID': 'Idaho',
    'State.IL': 'Illinois',
    'State.IN': 'Indiana',
    'State.KS': 'Kansas',
    'State.KY': 'Kentucky',
    'State.LA': 'Louisiana',
    'State.MA': 'Massachusetts',
    'State.MB': 'Manitoba',
    'State.MD': 'Maryland',
    'State.ME': 'Maine',
    'State.MI': 'Michigan',
    'State.MN': 'Minnesota',
    'State.MO': 'Missouri',
    'State.MS': 'Mississippi',
    'State.MT': 'Montana',
    'State.NB': 'New Brunswick',
    'State.NC': 'North Carolina',
    'State.ND': 'North Dakota',
    'State.NE': 'Nebraska',
    'State.NH': 'New Hampshire',
    'State.NJ': 'New Jersey',
    'State.NM': 'New Mexico',
    'State.NS': 'Nova Scotia',
    'State.NT': 'Northwest Territories',
    'State.NU': 'Nunavut',
    'State.NV': 'Nevada',
    'State.NY': 'New York',
    'State.OH': 'Ohio',
    'State.OK': 'Oklahoma',
    'State.ON': 'Ontario',
    'State.OR': 'Oregon',
    'State.PA': 'Pennsylvania',
    'State.PE': 'Prince Edward Island',
    'State.QC': 'Quebec',
    'State.RI': 'Rhode Island',
    'State.SC': 'South Carolina',
    'State.SD': 'South Dakota',
    'State.SK': 'Saskatchewan',
    'State.TN': 'Tennessee',
    'State.TX': 'Texas',
    'State.UT': 'Utah',
    'State.VA': 'Virginia',
    'State.VT': 'Vermont',
    'State.WA': 'Washington',
    'State.WI': 'Wisconsin',
    'State.WV': 'West Virginia',
    'State.WY': 'Wyoming',
    'State.YT': 'Yukon',
    'VehiclePrimaryUse_Ext.CorporatelyOwnedBusinessUse': 'Corporately Owned Business Use',
    'VehiclePrimaryUse_Ext.Farm': 'Farm',
    'VehiclePrimaryUse_Ext.IndividualBusiness': 'Individual Business',
    'VehiclePrimaryUse_Ext.Pleasure': 'Pleasure',
    'VehiclePrimaryUse_Ext.Restricted': 'Restricted',
    'VehiclePrimaryUse_Ext.Work': 'Work',
    'VehicleType.Antique_Ext': 'Antique',
    'VehicleType.auto': 'Private Passenger',
    'VehicleType.Classic_Ext': 'Classic',
    'VehicleType.Commercial': 'Trucks,Tractors,Trailers',
    'VehicleType.NamedNonOwner_Ext': 'Named Non-owner',
    'VehicleType.other': 'Other',
    'VehicleType.PP': 'Passenger Vehicles',
    'VehicleType.PublicTransport': 'Livery Vehicles',
    'VehicleType.Trailer_Ext': 'Utility Trailer',
    'VehicleType.Special': 'Special',
    'VINOverrideReason_Ext.VINServiceNotAvailableForVehicleType': 'VIN service not available for vehicle type'
};
