import { Component, Input, Injectable } from '@angular/core';
import { FactorId } from 'app/service/sre-summary.service';
import { RatingFactorForDisplay } from 'app/components/sre-summary/sre-summary-content';
import { DecimalPipe } from '@angular/common';

@Component({
    selector: 'app-smartride-factor-circle',
    templateUrl: './smartride-factor-circle.component.html',
    styleUrls: ['./smartride-factor-circle.component.css']
})

@Injectable()
export class SmartrideFactorCircleComponent {
    @Input() content: RatingFactorForDisplay;
    @Input() periodUnit: string;
    @Input() showBorderAndTitle = true;

    constructor(
        public _number: DecimalPipe
    ) { }

    getAmountForDisplay(): string {
        const amount = this.content.event?.percent ? this.content.event?.percent : this.content.event?.amount;
        if (!this.content.event || !amount && amount !== 0 || amount < 0) {
            return '--';
        }
        if (this.content.event.factorId === FactorId.idle) {
            return `${this._number.transform((this.content.event?.amount || 0).toFixed(0))}%`;
        }
        return this._number.transform((this.content.event?.amount || 0).toFixed(0));
    }

    getUnitsLabel(): string {
        if (this.content.event && this.content.event?.amount === 1) {
            return this.content.unit;
        }
        // English has other modes of pluralization, but our unit will only be
        // 'event', 'minute', or 'mile', which all pluralize with a simple 's'.
        return this.content.unit ? `${this.content.unit}s` : '';
    }

    shouldDisplayTrend(): boolean {
        // A trend of zero means "no change"; undefined means "unknown" (eg first period).
        const trend = this.content.event?.percentTrend ? this.content.event?.percentTrend : this.content.event?.trend;
        if (!this.content.event) {
 return false;
}
        if (trend === undefined) {
 return false;
}
        if (this.periodUnit === 'total') {
 return false;
}
        return true;
    }

    getTrendArrowUrl(): string {
        const trend = this.content.event?.percentTrend ? this.content.event?.percentTrend : this.content.event?.trend;
        if (!trend) {
            return '/assets/image/svg/trend-arrows-equal-grey.svg';
        }
        if (trend < 0) {
            return '/assets/image/svg/trend-arrows_down-green.svg';
        } else {
            return '/assets/image/svg/trend-arrows_up-red.svg';
        }
    }

    getTrendText(): string {
        const trend = this.content.event?.percentTrend ? this.content.event?.percentTrend : this.content.event?.trend;
        let numberText;
        if (this.content.event.factorId === FactorId.idle && this.content.event.percentTrend !== undefined) {
            numberText = `${Math.abs(this.content.event?.percentTrend).toFixed(1)}%`;
        } else if (this.content.event.trend !== undefined) {
            numberText = Math.abs(this.content.event?.trend).toFixed(0);
        }
        if (trend < 0 && numberText !== undefined) {
            return `${numberText} less than last ${this.periodUnit}`;
        } else if (trend > 0 && numberText !== undefined) {
            return `${numberText} more than last ${this.periodUnit}`;
        } else {
            return `Same as last ${this.periodUnit}`;
        }
    }

    getCircleColorClassName(): string {
        if (this.periodUnit === 'total') {
 return 'blue';
}
        const amount = this.content.event?.percent ? this.content.event?.percent : this.content.event?.amount;
        if (
            this.content.thresholds && amount > this.content.thresholds[1]
        ) {
 return 'red';
}
        if (this.content.thresholds &&
            this.content.thresholds.length > 1 &&
            amount > this.content.thresholds[0] &&
            amount < this.content.thresholds[1]
        ) {
 return 'yellow';
}
        return 'green';
    }

    createAriaLabelForFullCircle(): string {
        const amount = this.content.event?.percent ? this.content.event?.percent : this.content.event?.amount;
        if (this.periodUnit === 'total') {
            return `${this.content.displayName} ${
                 Math.round(amount)} ${
                 this.getUnitsLabel()}`;
        } else {
            return `${this.content.displayName} ${
                 Math.round(amount)} ${
                 this.getUnitsLabel()} ${
                 this.getTrendText()}`;
        }
    }
}
