import { Injectable } from '@angular/core';
import { loadModules } from 'esri-loader';
import { Trip } from 'app/model/trip';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { TimeFrameUnit } from 'app/enum/timeframe';

@Injectable()
export class MapUtils {
  constructor() { }

  getMapConfig(): any {
    return {
      basemap: 'streets',
      autoResize: false
    };
  }

  getMapViewConfig(mapViewEl: any, map: any): any {
    const mapViewProperties: any = {
      container: mapViewEl.nativeElement,
      constraints: {
        snapToZoom: false,
        minZoom: 7,
        maxZoom: 20
      },
      map
    };

    return mapViewProperties;
  }

  getExtentsAndCenterFromCoordinateList(coords: any): any {
    const geometry = {
      xmin: 0,
      xmid: 0,
      xmax: 0,
      ymin: 0,
      ymid: 0,
      ymax: 0
    };
    if (coords && coords.length > 0) {
      geometry.ymin = geometry.ymax = coords[0][0];
      geometry.xmin = geometry.xmax = coords[0][1];
      for (let i = 1; i < coords.length; i++) {
        const coord = coords[i];
        if (coord[0] < geometry.ymin) {
          geometry.ymin = coord[0];
        } else if (coord[0] > geometry.ymax) {
          geometry.ymax = coord[0];
        }
        if (coord[1] < geometry.xmin) {
          geometry.xmin = coord[1];
        } else if (coord[1] > geometry.xmax) {
          geometry.xmax = coord[1];
        }
      }
      geometry.ymid = (geometry.ymin + geometry.ymax) / 2;
      geometry.xmid = (geometry.xmin + geometry.xmax) / 2;
    }
    const MINIMUM_RADIUS = 0.0038;
    if (geometry.xmin > geometry.xmax - MINIMUM_RADIUS * 2.0) {
      geometry.xmin = geometry.xmid - MINIMUM_RADIUS;
      geometry.xmax = geometry.xmid + MINIMUM_RADIUS;
    }
    if (geometry.ymin > geometry.ymax - MINIMUM_RADIUS * 2.0) {
      geometry.ymin = geometry.ymid - MINIMUM_RADIUS;
      geometry.ymax = geometry.ymid + MINIMUM_RADIUS;
    }
    return geometry;
  }

  extendExtents(geometry: any, scale: number): void {
    const width = geometry.xmax - geometry.xmin;
    const height = geometry.ymax - geometry.ymin;
    const adjustedWidth = width * scale / 2;
    const adjustedHeight = height * scale / 2;
    geometry.xmin = geometry.xmid - adjustedWidth;
    geometry.xmax = geometry.xmid + adjustedWidth;
    geometry.ymin = geometry.ymid - adjustedHeight;
    geometry.ymax = geometry.ymid + adjustedHeight;
  }

  getPointSymbol(location: any, imgName: string): Observable<any> {
    let pointGraphic: any;
    const simpleObservable = new Observable((observer) => {
      loadModules([
        'esri/Graphic',
        'esri/geometry/Point',
        'esri/symbols/PictureMarkerSymbol'
      ]).then(([Graphic, Point, PictureMarkerSymbol]) => {
        const pointLocation = new Point({
          type: 'point', // autocasts as new Point()
          longitude: location[1],
          latitude: location[0]
        });

        const pointSymbol = new PictureMarkerSymbol({
          url: `/assets/image/map/gps-pins_${imgName}.png`,
          width: 20,
          height: 24,
          yoffset: 10,
          xoffset: 0,
          angle: 0
        });

        pointGraphic = new Graphic({
          geometry: pointLocation,
          symbol: pointSymbol
        });

        observer.next(pointGraphic);
      });
    });

    return simpleObservable;
  }

  getLineSymbol(path: any, lineClr: string): Observable<any> {
    let tripLine: any;
    const simpleObservable = new Observable((observer) => {
      loadModules([
        'esri/Graphic',
        'esri/geometry/Polyline',
        'esri/symbols/LineSymbol'
      ]).then(([Graphic]) => {
        const polyline = {
          type: 'polyline', // autocasts as new Polyline()
          paths: []
        };

        let pathIndex = 0;
        for (const individualPath of path) {
          if (individualPath?.[0]) {
            polyline.paths[pathIndex++] = [individualPath[1], individualPath[0]];
          }
        }

        const lineSymbol = {
          type: 'simple-line', // autocasts as SimpleLineSymbol()
          color: lineClr,
          width: 4
        };

        tripLine = new Graphic({
          geometry: polyline,
          symbol: lineSymbol

        });

        observer.next(tripLine);
      });
    });
    return simpleObservable;
  }

  getMapViewGeometry(trip: Trip): any {
    const desiredScale = 1.4;
    this.calculateMissingCoordinates(trip);
    const tripGeometry = this.getExtentsAndCenterFromCoordinateList(trip.coordinates);
    this.extendExtents(tripGeometry, desiredScale);
    return tripGeometry;
  }

  calculateMissingCoordinates(trip: Trip): void {
    if (!trip.coordinates) {
      return;
    }

    let position = 0;
    while (position < trip.coordinates.length) {
      let nextPosition = position;
      while (nextPosition < trip.coordinates.length && !trip.coordinates[nextPosition]) {
        nextPosition++;
      }
      if (nextPosition > position) { // nulls are present
        if (!position) { // Leading nulls.
          if (nextPosition < trip.coordinates.length) {
            this.fillRange(position, nextPosition, trip.coordinates[nextPosition], trip.coordinates);
          }
        } else if (nextPosition === trip.coordinates.length) { // Trailing nulls.
          this.fillRange(position, nextPosition, trip.coordinates[position - 1], trip.coordinates);
        } else { // Intermediate nulls.
          this.interpolateRange(position, nextPosition, trip.coordinates[position - 1],
            trip.coordinates[nextPosition], trip.coordinates);
        }
        position = nextPosition;
      } else { // valid coordinate; advance to the next
        position++;
      }
    }
  }

  // eslint-disable-next-line max-params
  fillRange(firstPosition, terminalPosition, value, coordinates): void {
    for (let i = firstPosition; i < terminalPosition; i++) {
      coordinates[i] = value;
    }
  }

  // eslint-disable-next-line max-params
  interpolateRange(firstPosition, terminalPosition, firstValue, terminalValue, coordinates): void {
    const rangeLength = terminalPosition - firstPosition + 1;
    const latitudeDifference = terminalValue[0] - firstValue[0];
    const longitudeDifference = terminalValue[1] - firstValue[1];
    for (let i = firstPosition; i < terminalPosition; i++) {
      const p = i - firstPosition + 1;
      const latitude = firstValue[0] + latitudeDifference * p / rangeLength;
      const longitude = firstValue[1] + longitudeDifference * p / rangeLength;
      coordinates[i] = [latitude, longitude];
    }
  }

  getIdleList(mph: any): any {
    const idlePositions = [];
    const aMileAMinute = 60;
    let mphPosition = 0;
    let mphLength = mph.length;

    /* Trim leading and trailing zeroes. */
    while (mphLength > 0 && mph[mphLength - 1] === 0) {
      mphLength--;
    }
    while (mphPosition < mphLength && mph[mphPosition] === 0) {
      mphPosition++;
    }

    while (mphPosition < mphLength) {
      let zeroCount = 0;
      while (mphPosition + zeroCount < mphLength && mph[mphPosition + zeroCount] === 0) {
        zeroCount++;
      }
      if (zeroCount >= aMileAMinute) {
        idlePositions.push(mphPosition);
      }
      mphPosition += zeroCount;
      mphPosition++;
    }

    return idlePositions;
  }

  getNightLine(tripData: Trip): any[] {
    const tripCoordinates = tripData.coordinates;
    const pathLen = tripCoordinates.length;
    const nightPathCoordinates = [];
    const nightPeriods = [];

    // Trip is one day, and does not cross midnight
    if (moment(tripData.startTS).dayOfYear() === moment(tripData.endTS).dayOfYear()) {
      const nightTimeFrame = {
        start: moment(tripData.startTS).startOf('day'),
        // eslint-disable-next-line no-magic-numbers
        end: moment(tripData.startTS).startOf('day').add(5, 'hours'),
        unit: TimeFrameUnit.DAY
      };
      nightPeriods.push(nightTimeFrame);
    } else if (moment(tripData.startTS).dayOfYear() < moment(tripData.endTS).dayOfYear()) {
      // Trip spans at least two days and may roll calendar year, get night period for each day
      const start = moment(tripData.startTS);
      while (start.dayOfYear() <= moment(tripData.endTS).dayOfYear()) {
        const nightTimeFrame = {
          start: moment(start).startOf('day'),
          // eslint-disable-next-line no-magic-numbers
          end: moment(start).startOf('day').add(5, 'hours'),
          unit: TimeFrameUnit.DAY
        };
        nightPeriods.push(nightTimeFrame);

        start.add(1, 'day');
      }
    }

    let thisTripMoment;
    for (const nightPeriod of nightPeriods) {
      thisTripMoment = moment(tripData.startTS);
      const nightLine = [];
      for (let traversePath = 0; traversePath < pathLen; traversePath++) {
        if (thisTripMoment.isBetween(nightPeriod.start, nightPeriod.end, 'second')) {
          nightLine.push(tripCoordinates[traversePath]);
        }

        thisTripMoment.add(1, 'second');
      }
      if (nightLine.length > 0) {
        nightPathCoordinates.push(nightLine);
      }
    }

    return nightPathCoordinates;
  }
}
