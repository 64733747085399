import { Component, OnInit, Input } from '@angular/core';
import { Vehicle } from 'app/model/vehicle';

@Component({
  selector: 'app-mobile-pending-card',
  templateUrl: './mobile-pending-card.component.html',
  styleUrls: ['./mobile-pending-card.component.css']
})
export class MobilePendingCardComponent implements OnInit {
  @Input() vehicle: Vehicle;

  constructor() { }

  ngOnInit(): void { }

  getFirstDriverName(): string {
    if (!this.vehicle) {
      return '';
    }
    if (!this.vehicle.mobileProgram) {
      return '';
    }
    if (this.vehicle.mobileProgram.drivers.length < 1) {
      return '';
    }
    const driver = this.vehicle.mobileProgram.drivers[0];
    return `${driver.firstName || ''} ${driver.lastName || ''}`;
  }
}
