import { Component, OnInit, OnDestroy, Injectable, HostListener, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UserService } from 'app/service/user.service';
import { EbiEventService } from 'app/service/ebi-event.service';
import { NavbarService } from 'app/service/nav-bar.service';
import { BrandingService } from 'app/service/branding.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { NavigationService } from 'app/service/navigation.service';

@Component({
    selector: 'app-smiles-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})

@Injectable()
export class FooterComponent implements OnInit, OnDestroy {
    footerType: string;
    routerSubscription: Subscription;
    brandingValue: Subscription;
    smilesVersion: string;
    smilesVersionType: string;
    mobileView: boolean;
    @Output() url: string;
    agnosticfooter: boolean;
    loginBranding: string;

    @HostListener('window:resize', ['$event'])
    onResize(): void {
        this.checkInnerWidth(window.innerWidth);
    }

    // eslint-disable-next-line max-params
    constructor(
        public nav: NavigationService,
        public user: UserService,
        public router: Router,
        public ebiEvents: EbiEventService,
        public navBar: NavbarService,
        public branding: BrandingService
    ) {
        nav.url.subscribe((url) => {
            this.agnosticfooter = false;
            this.url = url;
            if (this.url === '/programSelect' || this.url === '/smartrideAdmin' || this.url === '/mobileNumberUpdate' || this.url.includes('/smartrideAdmin?userRole=Agent') || this.url === '/') {
                this.agnosticfooter = true;
            }
            this.footerType = this.determineFooter();
        });
    }

    ngOnInit(): void {
        // this.navBar.headerDisplay(this.user.isLoggedIn, this.router.url);
        this.brandingValue = this.branding.loginBrandingChange$.subscribe((value: string) => {
            this.loginBranding = BrandingService.getBranding();
        });
        this.branding.smilesVersionChange$.subscribe((value: string) => {
            this.smilesVersionType = BrandingService.getSmartMilesVersion();
        });
        this.smilesVersion = this.setSmilesVersion();
        this.checkInnerWidth(window.innerWidth);
    }

    ngOnDestroy(): void {
        if (this.brandingValue) {
            this.brandingValue.unsubscribe();
            this.brandingValue = null;
        }
        this.agnosticfooter = false;
    }

    determineFooter(): string {
        const branding = BrandingService.getBranding();
        const agnostic = this.agnosticfooter;
        if (agnostic === true) {
            return 'UNDEFINED';
        }
        if (!agnostic && this.loginBranding === 'smartmiles') {
            return 'smiles';
        }
        if (!agnostic && this.loginBranding === 'smartride') {
            return 'sride';
        }
        return 'UNDEFINED';
    }

    setSmilesVersion(): string {
        const smilesVersion = this.smilesVersionType;
        if (this.footerType === 'smiles' && smilesVersion === '2.0') {
            return '2.0';
        } else {
            return '1.0';
        }
    }

    logout(): void {
        this.user.logout();
        if (BrandingService.getBranding() === 'smartride') {
            this.ebiEvents.postSrideEvent('425080047');
        }
        this.branding.logoutWithBranding('loggedout');
    }

    logoEbiEvent(eventName: string): void {
        if (BrandingService.getBranding() === 'smartride') {
            if (eventName === 'contactUs') {
                this.ebiEvents.postSrideEvent('425080051');
            } else if (eventName === 'privacy') {
                this.ebiEvents.postSrideEvent('425080053');
            } else if (eventName === 'terms') {
                this.ebiEvents.postSrideEvent('425080055');
            }
        }
    }

    checkInnerWidth(screenWidth: number): void {
        this.mobileView = false;
        const maxMobileWidth = 768;
        if (screenWidth < maxMobileWidth) {
            this.mobileView = true;
        }
    }
}
