/* eslint-disable max-params*/
import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { OauthRevocationService } from './oauth-revocation.service';
import { environment } from 'environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BrandingService {
    loginBrandingChange$: Observable<string>;
    private static loginBranding = new BehaviorSubject<string>('');
    smilesVersionChange$: Observable<string>;
    private static smilesVersion = new BehaviorSubject<string>('');
    termAndConditionsLink: string;

    constructor(
        public router: Router,
        public title: Title,
        private oauthRevocationService: OauthRevocationService,
        @Inject(DOCUMENT) private document: Document
    ) {
        this.loginBrandingChange$ = BrandingService.loginBranding.asObservable();
        this.smilesVersionChange$ = BrandingService.smilesVersion.asObservable();
    }

    static getBranding(): string {
        return sessionStorage.getItem('LoginBranding');
    }

    static setSmartMilesVersion(smilesVersion: string): void {
        sessionStorage.setItem('SmartmilesVersion', smilesVersion);
        this.smilesVersion.next(smilesVersion);
    }

    static getSmartMilesVersion(): string {
        return sessionStorage.getItem('SmartmilesVersion');
    }

    static setBranding(branding: string): void {
        sessionStorage.setItem('LoginBranding', branding);
        this.loginBranding.next(branding);
    }

    logoutWithBranding(message = '', error = '', replaceUrl = false, accessToken?): void {
        const branding = BrandingService.getBranding();
        const vehicleType = sessionStorage.getItem('VehicleType');
        let logoutUrl = '';
        if (branding === 'smartmiles') {
            logoutUrl = 'smartmiles/login';
            this.title.setTitle('SmartMiles');
        } else if (branding === 'smartride') {
            logoutUrl = 'smartride/login';
            this.title.setTitle('SmartRide');
        }
        this.oauthRevocationService.revokeOauthToken(accessToken).subscribe();
        this.pingLogout();
        BrandingService.setBranding(branding);
        sessionStorage.setItem('VehicleType', vehicleType);
        this.router.navigate([logoutUrl], { queryParams: { message, error }, replaceUrl });
    }
    pingLogout(): void {
        const fiveSecondTimeout = 5000;
        window.sessionStorage.clear();
        window.sessionStorage.setItem('logout', 'true');
        const iframeElement = this.document.createElement('iframe');
        const url = environment.pingLogOutUrl;
        iframeElement.src = url;
        iframeElement.style.position = 'absolute';
        iframeElement.style.right = '110%';
        this.document.body.appendChild(iframeElement);
        iframeElement.addEventListener('load', () => {
            setTimeout(() => {
                if (iframeElement?.parentNode) {
                    iframeElement.parentNode.removeChild(iframeElement);
                }
            },
                fiveSecondTimeout);
        });
    }
}
