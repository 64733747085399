import { Directive, ElementRef, OnInit, OnChanges, Input } from '@angular/core';

@Directive({
  selector: '[appIsFocused]'
})
export class IsFocusedDirective implements OnInit, OnChanges {
  @Input('appIsFocused') isFocused: boolean;

  constructor(private hostElement: ElementRef) { }

  ngOnInit(): void {
    this.setFocus();
  }

  ngOnChanges(): void {
    this.setFocus();
  }

  setFocus(): void {
    if (this.isFocused) {
      this.hostElement.nativeElement.focus();
    }
  }
}
