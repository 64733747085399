import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EbiEventService } from 'app/service/ebi-event.service';
import { BrandingService } from 'app/service/branding.service';
import { LoggerService } from 'app/service/logger.service';

@Component({
  selector: 'app-editable-text',
  templateUrl: './editable-text.component.html',
  styleUrls: ['./editable-text.component.css']
})
export class EditableTextComponent implements OnInit {
  @Input() elementId: string;
  @Input() value = '';
  @Input() placeholder: string;
  @Input() save: Function;
  @Input() inputElemClass?: string;
  @Input() maxLength?: number;
  @Input() regex?: RegExp;
  @Input() smartMiles: boolean;
  @Input() editEmail: boolean;
  @Input() editVehicle: boolean;
  @Input() set setEditButtonEnabled(value: boolean) {
    this.editButtonEnabled = value;
    if (this.editing && !this.editButtonEnabled) {
      this.editing = false;
    }
  }
  @Output() newValue = new EventEmitter();
  @Output() updateValue = new EventEmitter();
  @Output() canceledValue = new EventEmitter();

  editButtonEnabled: boolean;
  isSaveDisabled = false;
  editing: boolean;
  savedValue: string;
  index: number;

  constructor(
    private ref: ChangeDetectorRef,
    public ebiEvents: EbiEventService,
    private logger: LoggerService
  ) {
  }

  ngOnInit(): void {
    if (this.save === undefined) {
      this.logger.error(`no save function defined for element ${this.elementId}! It won't save anything like this!`);
    }
    this.editing = false;
    this.value = this.value || '';
    this.savedValue = this.value;
    this.index = this.getIndex();
    this.newValue.emit(this.value);
    if (this.editButtonEnabled === undefined) {
      this.editButtonEnabled = true;
    }
  }

  editValue(target: any): void {
    if (this.editButtonEnabled) {
      this.savedValue = this.value;
      this.editing = true;
      this.logEbi();
      this.ref.detectChanges();
      this.isSaveDisabled = typeof this.value === 'undefined' || this.value.length === 0;
      if (this.regex) {
        this.isSaveDisabled = this.isSaveDisabled || !this.regex.test(this.value);
      }

      target.setAttribute('maxlength', `${this.maxLength}`);
      target.focus();
      target.setSelectionRange(0, target.value.length);
    }
  }

  saveValue(value): void {
    if (this.elementId !== 'phoneNumberElement') {
      const index = this.getIndex();
      if (value.length > 0 && !this.regex) {
        this.save(index, value);
        this.savedValue = value;
        this.editing = false;
      } else if (value.length > 0 && this.regex.test(value)) {
        this.save(index, value);
        this.savedValue = value;
        this.editing = false;
      }
    } else if (value.length > 0 && !this.regex || value.length > 0 && this.regex.test(value)) {
        this.save(value);
        this.savedValue = value;
        this.editing = false;
      }
    this.updateValue.emit(value);
    this.logEbi();
  }

  cancelValue(target): void {
    this.value = this.savedValue;
    target.value = this.value;
    this.editing = false;
    this.canceledValue.emit(true);
    if (target.id.indexOf('email') >= 0) {
      this.ebiEvents.postSrideEvent('425080061');
    }
  }

  getIndex(): number {
    return Number(this.elementId.substring(this.elementId.lastIndexOf('-') + 1));
  }

  textChange(event: any): void {
    if (this.elementId === 'phoneNumberElement') {
      event.target.value = event.target.value.replace(/(\d{3})-?(\d{3})-?(\d{4})/, '$1-$2-$3');
    }
    this.isSaveDisabled = event.target.value.length === 0 ||
      (typeof this.regex === 'undefined' ? false : !this.regex.test(event.target.value));
    this.newValue.emit(event.target.value);
  }

  logEbi(): void {
    if (BrandingService.getBranding() === 'smartride') {
      if (this.editEmail && this.editing) {
        this.ebiEvents.postSrideEvent('425080057');
      } else if (this.editEmail && !this.editing) {
        this.ebiEvents.postSrideEvent('425080059');
      } else if (this.editVehicle) {
        this.ebiEvents.postSrideEvent('425080063');
      }
    }
  }
}
