import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { UserService } from 'app/service/user.service';
import { InjectionToken, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { EbiEventService } from 'app/service/ebi-event.service';
import { ServiceErrorHandler } from 'app/utility/service-error-handler';
import { TimeframeService } from 'app/service/timeframe.service';
import { SmilesSREConstants } from 'app/model/smilessreconstants';
import { TimeNavigationBarComponent } from 'app/components/time-navigation-bar/time-navigation-bar.component';
import { PrintLabelAlertComponent } from 'app/components/print-label-alert/print-label-alert.component';
import { CustomAlertComponent } from 'app/components/custom-alert/custom-alert.component';
import { OauthRevocationService } from 'app/service/oauth-revocation.service';
import { BrandingService } from 'app/service/branding.service';

export const CircleConstants = new InjectionToken<string>('CircleConstants');

const constants: SmilesSREConstants = {
  miles: 'miles',
  braking: 'braking',
  night: 'night',
  idle: 'idle',
  acceleration: 'acceleration',
  brakingAcceleration: 'brakingAcceleration',
  eventsFactor: 'events',
  minutesFactor: 'minutes',
  milesFactor: 'miles',
  milesDrivenInfo: 'The more miles you put on your car, the more likely it is you will have an accident. ' +
    'Plus, keeping mileage down saves gas and money on car repairs.',
  nighttimeDrivingInfo: 'Driving between midnight and 5 am increases your chances of having an accident. Drivers ' +
    'during these times may be tired or distracted.',
  brakingInfo: 'Hard braking means forcing the vehicle to suddenly stop or decelerate in excess of 7.7 ' +
    'mph per second. Safe drivers will see very few instances of hard braking. Some drivers speed toward stop ' +
    'lights, then have to slam on the brakes. Other drivers tailgate then brake hard when the driver ahead of them ' +
    'slows down. Doing this more than typical drivers can increase your chance of an accident.',
  accelerationInfo: 'Fast acceleration is abruptly speeding more than 7.7 mph per second. It can be a sign of ' +
    'aggressive driving. If you have more fast acceleration events than typical drivers, you are more likely to have an accident.',
  idleInfo: 'Measures the length of time stopped while in a trip. This could be an indication of traffic congestion, ' +
    'which increases the chance of an accident.',
  brakingAccelerationInfo: 'Means forcing the vehicle to suddenly stop or abruptly speed up in excess of 7.7 mph ' +
    'per second.  These could be a sign of aggressive driving.',
  nightTimeDrivingInfoTitle: 'Why does driving at night matter?',
  brakingInfoTitle: 'What is a hard brake?',
  accelerationInfoTitle: 'What is a fast acceleration?',
  milesDrivenTitle: 'Miles Driven',
  nighttimeDrivingTitle: 'Nighttime Driving',
  idletimeTitle: 'Idle Time',
  brakingAccelerationTitle: 'Hard Braking / Acceleration',
  brakingTitle: 'Hard Braking',
  accelerationTitle: 'Fast Acceleration',
  totalView: 'total',
  week: 'week',
  term: 'term',
  month: 'month',
  day: 'day'
};

@NgModule({
  imports: [
    CommonModule,
    RouterModule
  ],
  providers: [ // Provide application-wide singleton services here
    UserService,
    TimeframeService,
    EbiEventService,
    ServiceErrorHandler,
    OauthRevocationService,
    BrandingService,
    { provide: CircleConstants, useValue: constants }
  ],
  declarations: [
    TimeNavigationBarComponent,
    PrintLabelAlertComponent,
    CustomAlertComponent
  ],
  exports: [
    TimeNavigationBarComponent,
    PrintLabelAlertComponent,
    CustomAlertComponent
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only.');
    }
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule
    };
  }
}
