import { Component, OnInit, Input } from '@angular/core';
import { TermAndConditionStatusService } from 'app/service/term-and-condition-status.service';
import { Vehicle } from '../../../model/vehicle';

@Component({
    selector: 'app-device-search-results',
    templateUrl: './device-search-results.component.html',
    styleUrls: ['./device-search-results.component.css']
})
export class DeviceSearchResultsComponent implements OnInit {
    @Input() vehicles: Vehicle[] = [];
    @Input() vehicle: Vehicle;
    // @Input() vin: string;
    @Input() index: number;
    termAndConditionConsent: string;
    dataSharing = 'Data: Sharing';
    dataNotSharing = 'Data: Not sharing';
    connectedcarProgramType: string;

    constructor(public termAndConditionStatusService: TermAndConditionStatusService) { }

    ngOnInit(): void {
      this.getTermsAndConditionConsentStatus(this.vehicle?.vin, this.getConnectedcarProgramType());
    }

    getTermsAndConditionConsentStatus(vin, type): void {
        let status: string;
        if ((this.vehicle?.scoringModel === 'SM1' || this.vehicle?.scoringModel === 'SM2') && this.vehicle.vendorIdCode === 'FORD' || this.vehicle?.programType === 'TC') {
            this.termAndConditionStatusService.getConnectedCarConsentStatusWithPolicy(vin, type, this.vehicle.policyNum).subscribe((response) => {
                status = response?.[0]?.statusCode;
                if (status === 'Y') {
                  this.termAndConditionConsent = 'Accepted';
                } else {
                  this.termAndConditionConsent = 'Not Accepted';
                }
            });
        }
    }

    getConnectedcarProgramType(): string {
        if ((this.vehicle?.scoringModel === 'SM1' || this.vehicle?.scoringModel === 'SM2') && this.vehicle.vendorIdCode === 'FORD') {
            this.connectedcarProgramType = 'smiles';
        }
        if (this.vehicle?.programType === 'TC') {
            this.connectedcarProgramType = 'sride';
        }
        return this.connectedcarProgramType;
    }
}
