import { TimeOutConfig } from './time-out.config';

const MILLISECONDS_IN_SECOND = 1000;
export const timeout = {
  pollIntervalSeconds: 10,
  inactivityThresholdSeconds: 300,
  secondsToTimeoutWarning: 180,
  excludedPaths: []
};
const OauthConfig = {
  tokenStorage: 'tokenDetails'
};
export const timeOutConfig: TimeOutConfig = {
  pollIntervalMilliseconds: timeout.pollIntervalSeconds * MILLISECONDS_IN_SECOND,
  inactivityThresholdMilliseconds: timeout.inactivityThresholdSeconds * MILLISECONDS_IN_SECOND,
  timeToTimeoutWarningMilliseconds: timeout.secondsToTimeoutWarning * MILLISECONDS_IN_SECOND,
  excludedPaths: timeout.excludedPaths,
  accessTokenKey: OauthConfig.tokenStorage
};
