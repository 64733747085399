import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'units' })
export class UnitsPipe implements PipeTransform {
  units = {
    miles: ['miles', 'mile'],
    braking: ['events', 'event'],
    acceleration: ['events', 'event'],
    night: ['minutes', 'minute'],
    idle: ['minutes', 'minute'],
    brakingAcceleration: ['events', 'event'],
    trips: ['Trips', 'Trip']
  };
  transform(factor: string, amount: number | string): any {
    let casing;
    if (amount === 1 || amount === '1') {
      casing = 1;
    } else {
      casing = 0;
    }
    return this.units[factor][casing];
  }
}
