// eslint-disable-next-line @typescript-eslint/naming-convention
export const HealthCarePlan = {
    type: 'edge.metadata.typeinfo.typelistinfo.dto.TypelistMetadataDTO',
    value: {
        codes: [
            {
                code: 'Medicare',
                priority: 10,

                displayKey: 'typekey.HealthCarePlan_Ext.Medicare'
            },
            {
                code: 'Medicaid',
                priority: 20,

                displayKey: 'typekey.HealthCarePlan_Ext.Medicaid'
            },
            {
                code: 'QualifyingHealthCoverage',
                priority: 30,

                displayKey: 'typekey.HealthCarePlan_Ext.QualifyingHealthCoverage'
            },
            {
                code: 'OtherPIPHealthBenefitPolicy',
                priority: 40,

                displayKey: 'typekey.HealthCarePlan_Ext.OtherPIPHealthBenefitPolicy'
            },
            {
                code: 'NoQualifiedHealthCoverage',
                priority: 50,

                displayKey: 'typekey.HealthCarePlan_Ext.NoQualifiedHealthCoverage'
            }
        ],
        filters: [
            {
                name: 'WithoutNoQualifiedHealthCoverage',
                includedCodes: [
                    'Medicare',
                    'Medicaid',
                    'QualifyingHealthCoverage',
                    'OtherPIPHealthBenefitPolicy'
                ]
            }
        ],
        namedMetadata: []
    }
};
