// eslint-disable-next-line @typescript-eslint/naming-convention
export const AddressDTO = {
    CountyConfidenceCode: {
        name: 'CountyConfidenceCode',
        type: 'String',
        required: false
    },
    PublicID: {
        name: 'PublicID',
        type: 'String',
        required: true,
        visibilityCondition: '\'\' === form.value[\'TempID\']'
    },
    TerritoryCode: {
        name: 'TerritoryCode',
        type: 'String',
        required: false
    },
    AddressLine1: {
        name: 'AddressLine1',
        type: 'String',
        required: true,
        maxLength: 60,
        minLength: 1
    },
    County: {
        name: 'County',
        type: 'String',
        required: false
    },
    City: {
        name: 'City',
        type: 'String',
        required: true,
        maxLength: 60,
        minLength: 1
    },
    DisplayName: {
        name: 'DisplayName',
        type: 'String',
        required: false
    },
    TempID: {
        name: 'TempID',
        type: 'Integer',
        required: true,
        visibilityCondition: '\'\' === form.value[\'PublicID\']'
    },
    FixedID: {
        name: 'FixedID',
        type: 'Long',
        required: false
    },
    TerritoryConfidenceCode: {
        name: 'TerritoryConfidenceCode',
        type: 'String',
        required: false
    },
    State: {
        name: 'State',
        class: 'typekey.State',
        required: true,
        visibilityCondition: '(\'\' !== form.value[\'Country\'] && (\'US\' === form.value[\'Country\'] || \'CA\' === form.value[\'Country\']))',
        filterCategories: [
            'form.value[\'Country\']'
        ]
    },
    Country: {
        name: 'Country',
        class: 'typekey.Country',
        required: true
    },
    CountyCode: {
        name: 'CountyCode',
        type: 'String',
        required: false
    },
    PostalCode: {
        name: 'PostalCode',
        type: 'String',
        required: true,
        regEx: '(model[\'Country\'] == \'CA\')?\'[a-zA-Z][0-9][a-zA-Z] [0-9][a-zA-Z][0-9]\'  : (model[\'Country\'] == \'US\')?\'[0-9]{5}(-[0-9]{4})?\'  : undefined'
    },
    Updated: {
        name: 'Updated',
        type: 'Boolean',
        required: true,
        minAmount: true,
        maxAmount: true
    },
    TownCode: {
        name: 'TownCode',
        type: 'String',
        required: false
    }
};
