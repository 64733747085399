import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DailyContent } from 'app/components/vehicle-summary/daily/daily-content';
import { Vehicle } from 'app/model/vehicle';
import { TripDataService } from 'app/service/tripSpeed-data-service';
import * as moment from 'moment';
import { NgbPanelChangeEvent, NgbTooltipConfig, NgbAccordion } from '@ng-bootstrap/ng-bootstrap';
import { NavbarService } from 'app/service/nav-bar.service';
import { UserService } from 'app/service/user.service';
import { EbiEventService } from 'app/service/ebi-event.service';
import { WindowRef } from 'app/service/window-ref';
import { Trip } from 'app/model/trip';
import { LoggerService } from 'app/service/logger.service';

@Component({
  selector: 'app-sre-daily-summary',
  templateUrl: './sre-daily-summary.component.html',
  styleUrls: ['./sre-daily-summary.component.css']
})

export class SreDailySummaryComponent implements OnInit, OnDestroy {
  @ViewChild('acc', { static: false }) accordionComponent: NgbAccordion;
  vehicle: Vehicle;
  vehicleStats: VehicleStat[];
  dataSubscription: Subscription;
  content: DailyContent;
  accordionRows: boolean[] = [];
  selectedView = false;
  mapLegend: string[][];
  mapTwNdLegend: string[][];
  mapTwTw1Legend: string[][];
  graphKeys: string[];
  selectId: string;
  isAgent: boolean;

  // eslint-disable-next-line max-params
  constructor(
    public router: Router,
    private route: ActivatedRoute,
    public tripService: TripDataService,
    public config: NgbTooltipConfig,
    public navBar: NavbarService,
    public user: UserService,
    public ebi: EbiEventService,
    public window: WindowRef,
    private logger: LoggerService
  ) {
    config.placement = 'right';
  }

  ngOnInit(): void {
        if (sessionStorage.getItem('isAgent') && sessionStorage.getItem('isAgent') === 'true') {
        this.isAgent = true;
    }
    this.navBar.headerDisplay(this.user.isLoggedIn, this.router.url);
    this.dataSubscription = this.route.data.subscribe((data) => {
      this.content = data['content'];
      this.vehicle = new Vehicle(this.content.vehicle);
      this.requestTripSpeeds();
      this.calculateVehicleStats();
      this.setTripLegend();
      this.setMapLegend();
      this.setupAccordion();
      this.ebi.assignProgramType(this.content.vehicle.programType);
      this.ebi.postSrideEvent('425080006');
    });
  }

  ngOnDestroy(): void {
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
      this.dataSubscription = null;
    }
  }

  requestTripSpeeds(): void {
    if (!this.content.trips || this.content.trips.length < 1) {
      return;
    }
    for (const trip of this.content.trips) {
      this.tripService.getTripSpeedData(trip.tripNb.toString(), this.content.vehicle.vid).pipe(tap((tripSpeedResponse) => {
        this.addSpeedResponseToTrip(trip, tripSpeedResponse);
      })).subscribe(() => { }, () => {
        trip.err = true;
      });
    }
  }

  addSpeedResponseToTrip(trip, tripSpeedResponse): void {
    if (tripSpeedResponse.err) {
        trip.err = true;
    } else {
        trip.startTS = moment.unix(tripSpeedResponse['start']).toDate();
        trip.endTS = moment.unix(tripSpeedResponse['end']).toDate();
        trip.acceleration = tripSpeedResponse['acceleration'];
        trip.speeds = tripSpeedResponse['mph'];
        trip.coordinates = tripSpeedResponse['latlong'];
        trip.fastAccelerationCount = tripSpeedResponse['acceleration'] ? tripSpeedResponse['acceleration'].length : 0;
        trip.braking = tripSpeedResponse['braking'];
        trip.hardBrakeCount = tripSpeedResponse['braking'] ? tripSpeedResponse['braking'].length : 0;
        trip.stop = tripSpeedResponse['stop'];
        trip.stopsCount = tripSpeedResponse.stop ? tripSpeedResponse.stop.length : 0;
        trip.setIncidents([]);
    }
  }

  calculateVehicleStats(): void {
    this.vehicleStats = [];

    if (this.content.trips && this.content.trips.length > 0) {
      // # of trips and miles driven are always included regardless of scoring model
      this.vehicleStats.push({
        label: 'Trips',
        value: this.content.trips.length
      });

      this.vehicleStats.push({
        label: 'Miles Driven',
        value: this.content.trips
          .map((trip) => trip.miles || 0)
          .reduce((accumulator, next) => accumulator + next)
      });

      let nightTimeDrivingMinutes = 0;
      let brakingAndAccelCount = 0;
      // Depending on scoring model, optionally add in a few more stats
      switch (this.vehicle.scoringModel) {
        case 'ND1':
        case 'TW2': {
          nightTimeDrivingMinutes = this.content.trips
            .map((trip) => trip.night || 0)
            .reduce((acc, next) => acc + next);

          brakingAndAccelCount = this.content.trips
            .map((trip) => trip.brakingAcceleration || 0)
            .reduce((acc, next) => acc + next);

          this.vehicleStats.push({
            label: 'Nighttime Driving minutes',
            value: nightTimeDrivingMinutes
          });

          this.vehicleStats.push({
            label: 'Hard Braking / Acceleration events',
            value: brakingAndAccelCount
          });

          break;
        }
        case 'TW':
        case 'TW1': {
          brakingAndAccelCount = this.content.trips
            .map((trip) => trip.brakingAcceleration || 0)
            .reduce((acc, next) => acc + next);

          nightTimeDrivingMinutes = this.content.trips
            .map((trip) => trip.night || 0)
            .reduce((acc, next) => acc + next);

          this.vehicleStats.push({
            label: 'Nighttime Driving minutes',
            value: nightTimeDrivingMinutes
          });

          this.vehicleStats.push({
            label: 'Hard Braking / Acceleration events',
            value: brakingAndAccelCount
          });
          break;
        }
        case 'MO': {
          break;
        }
        default: {
          if (this.vehicle.scoringModel !== 'SM1' && this.vehicle.scoringModel !== 'SM2') {
            this.logger.error('Vehicle has a scoring model listed as something other than: (ND1,TW1,TW2,MO) This probably isn\'t right...');
          }
        }
      }
    }
  }

  toggle(event: NgbPanelChangeEvent): void {
    if (this.vehicle.scoringModel === 'MO') {
      event.preventDefault();
      return;
    }
    for (const i in this.accordionRows) {
      if (`toggle-${i}` === event.panelId || `sre-toggle-${i}` === event.panelId) {
        this.accordionRows[i] = !this.accordionRows[i];
      } else {
        this.accordionRows[i] = false;
      }
    }
    this.selectId = event.panelId;
  }

  setMapLegend(): void {
    this.mapLegend = [[]];
    switch (this.vehicle.scoringModel) {
      // ND1, TW2:  miles driven, nighttime, hardbrake/accel
      case 'ND1':
      case 'TW2':
        this.mapLegend = [
          ['miles', 'Miles Driven', '', 'miles'],
          ['night', 'Nighttime Driving', 'minutes', 'night'],
          ['braking', 'Hard Braking', 'events', 'hardBrakeCount'],
          ['acceleration', 'Fast Acceleration', 'events', 'fastAccelerationCount']
        ];
        this.graphKeys = ['night', 'braking', 'acceleration'];
        break;

      // TW, TW1:  miles driven, hardbrake, accel, nighttime
      case 'TW':
      case 'TW1':
        this.mapLegend = [
          ['miles', 'Miles Driven', '', 'miles'],
          ['braking', 'Hard Braking', 'events', 'hardBrakeCount'],
          ['acceleration', 'Fast Acceleration', 'events', 'fastAccelerationCount'],
          ['night', 'Nighttime Driving', 'minutes', 'night']
        ];
        this.graphKeys = ['night', 'braking', 'acceleration'];
        break;
      case 'MO':
        this.mapLegend = [
          ['miles', 'Miles Driven', '', 'miles']
        ];
        this.graphKeys = [];
        break;
      default:
        this.logger.error('Vehicle has a scoring model listed as something other than: (ND1,TW1,TW2,MO) This probably isn\'t right...');
        this.graphKeys = [];
        this.mapLegend = [];
    }
  }

  setTripLegend(): void {
    this.mapTwNdLegend = [[]];
    if (this.vehicle.scoringModel === 'TW2' || this.vehicle.scoringModel === 'ND1') {
      // ND1, TW2:  miles driven, nighttime, hardbrake/accel
      this.mapTwNdLegend = [
        ['miles', 'Miles Driven', '', 'miles'],
        ['night', 'Nighttime Driving', 'minutes', 'night'],
        ['brakingAcceleration', 'Hard Braking / Acceleration', 'events', 'brakingAcceleration']
      ];
      this.graphKeys = ['night', 'braking', 'acceleration'];
    } else {
      // TW, TW1:  miles driven, nighttime, hardbrake/accel
      this.mapTwTw1Legend = [
        ['miles', 'Miles Driven', '', 'miles'],
        ['night', 'Nighttime Driving', 'minutes', 'night'],
        ['brakingAcceleration', 'Hard Braking / Acceleration', 'events', 'brakingAcceleration']
      ];
      this.graphKeys = ['night', 'braking', 'acceleration'];
    }
  }

  mapErrorHandler(errorMessage: string, trip: Trip): void {
    this.logger.error(`Defaulting to speed graph because ${errorMessage}`);
    trip.coordinates = null;
  }

  sortTrips(): void {
    // sorting the trips with the latest one on the top
    this.content.trips.sort((a: any, b: any) => {
      if (a.startTS && b.startTS) {
        if (a.startTS > b.startTS) {
          return -1;
        } else if (a.startTS < b.startTS) {
          return 1;
        }
      }
      return 0;
    });
  }

  setupAccordion(): void {
    // sorting to display the latest trip on the top
    this.accordionRows = [];
    if (!this.content.trips || this.content.trips.length === 0) {
      return;
    }
    this.sortTrips();
    let i = 0;
    while (this.content.trips !== undefined && i < this.content.trips.length) {
      this.accordionRows.push(false);
      i = i + 1;
    }
    this.window.nativeWindow.setTimeout(() => {
      if (this.content.vehicle.scoringModel !== 'MO') {
        this.accordionComponent.expand('sre-toggle-0');
        this.accordionRows[0] = true;
      }
    }, 0);
  }

  createEventsAriaLabel(value: number, label: string): string {
    if (label === 'Miles Driven') {
      return `${value.toFixed(2)} ${label}`;
    } else {
      return `${value.toFixed(0)} ${label}`;
    }
  }

  createTabEventsAriaLabel(value: number, label: string, unit: string): string {
    const endOfLabel = ` ${label} ${unit}`;
    if (!value) {
      value = 0;
    }
    if (value === 0) {
      return `${0} ${label} ${unit}`;
    } else if (label === 'Miles Driven') {
      return value.toFixed(2) + endOfLabel;
    } else {
      return value.toFixed(0) + endOfLabel;
    }
  }

  createSelectorAriaLabel(trip: Trip): string {
    if (this.selectedView) {
      return 'List View is Selected';
    } else if (trip.coordinates?.length > 0) {
      return 'Map View is Selected';
    } else {
      return 'Graph View is Selected';
    }
  }

  clickSelector(i: number): void {
    document.getElementById(`display-slider-${i}`).click();
  }

  createListRowAriaLabel(time: string, labelOne: string, labelTwo: string): string {
    return `${time} ${labelOne}${labelTwo}`;
  }

  /* Returns one of: 'loading', 'error', 'map', 'graph', 'list'
   */
  getUiModeForTrip(trip: Trip): string {
    if (trip.err) {
      return 'error';
    }

    const haveMap = trip.coordinates?.length > 0;
    const haveGraph = trip.speeds?.length > 0;

    if (!haveMap && !haveGraph) {
      return 'loading';
    }

    if (this.selectedView) {
      return 'list';
    }

    if (haveMap) {
      return 'map';
    }

    if (haveGraph) {
      return 'graph';
    }

    return 'loading';
  }

  getLabelForToggleLeftSide(trip: Trip): string {
    if (trip.coordinates?.length > 0) {
      return 'Map View';
    } else {
      return 'Graph View';
    }
  }
}

class VehicleStat {
  value: number;
  label: string;
}
