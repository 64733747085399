import { Injectable } from '@angular/core';

// eslint-disable-next-line no-shadow
export enum VehicleChangeType {
    Add = 'Add',
    Replace = 'Replace'
}

export interface VehicleBasicInfo {
    year: string;
    make: string;
    model: string;
    vin?: string;
    friendlyYearMakeModel?: string;
}

@Injectable({
    providedIn: 'root'
})

export class QuickFlowDataService {
    addedVehicleId: number;
    addedVehicleYear: number;
    authenticationFailureCount = 0;
    dateOfBirth: string;
    fromQuickIdPolicySelectionPage: boolean;
    guid: string;
    historicalGuid: string;
    isPolicyCancelled: boolean;
    newVehicle: VehicleBasicInfo;
    pbsResponse: any;
    phoneNumber: string;
    policyChangeId: number;
    previousUrl: string;
    replacedVehicleCoverages: any;
    replacedVehicleId: number;
    searchType: string;
    selectedAutoPolicy: any;
    vehicleChangeType: VehicleChangeType;
    workflow: string;
    zipCode: string;

    clear(): void {
        this.addedVehicleId = undefined;
        this.addedVehicleYear = undefined;
        this.authenticationFailureCount = 0;
        this.dateOfBirth = undefined;
        this.fromQuickIdPolicySelectionPage = false;
        this.guid = undefined;
        this.historicalGuid = undefined;
        this.isPolicyCancelled = false;
        this.newVehicle = undefined;
        this.pbsResponse = undefined;
        this.phoneNumber = undefined;
        this.policyChangeId = undefined;
        this.previousUrl = undefined;
        this.replacedVehicleCoverages = undefined;
        this.replacedVehicleId = undefined;
        this.searchType = undefined;
        this.selectedAutoPolicy = undefined;
        this.vehicleChangeType = undefined;
        this.workflow = undefined;
        this.zipCode = undefined;
    }
}
