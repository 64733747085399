import { Component, OnInit, Input, Injectable } from '@angular/core';
import { Vehicle } from 'app/model/vehicle';

@Component({
  selector: 'app-mobile-final-card',
  templateUrl: './mobile-final-card.component.html',
  styleUrls: ['./mobile-final-card.component.css']
})

@Injectable()
export class MobileFinalCardComponent implements OnInit {
  @Input() vehicle: Vehicle;

  constructor() { }

  ngOnInit(): void { }
}
