import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UserService } from 'app/service/user.service';
import { Vehicle } from 'app/model/vehicle';
import { NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { NavbarService } from 'app/service/nav-bar.service';
import { EbiEventService } from 'app/service/ebi-event.service';
import { SaveVehiclePrefService } from 'app/service/save-vehicle-pref.service';
import { VehicleService } from 'app/service/vehicle.service';
import { PersonalLinesPolicyService } from 'app/service/personal-lines-policy.service';
import { PersonalLinesVehicle, PersonalLinesModifier } from '../../model/rest/personal-lines-policy';
import { PolicyPreferencesService } from 'app/service/policy-preferences.service';
import { Observable, Subscription } from 'rxjs';
import { BrandingService } from '../../service/branding.service';

@Component({
    selector: 'app-preferences',
    templateUrl: './preferences.component.html',
    styleUrls: ['./preferences.component.css']
})

export class PreferencesComponent implements OnInit, OnDestroy {
    programType: string;
    programVehicles: Array<Vehicle>;
    accordionRows: boolean[];
    activePanelIndex = 0;
    programEndedVehicles: Array<Vehicle>;
    routerSubscription: Subscription;
    phoneNumber = '';
    textingEnrolled = false;
    textingPreference;
    previousElectionExists = false;
    updatedPhoneNumberForTexting;
    policyNumber: string;
    showTextReplyMessage = false;
    showSmartMilesPreferences = false;

    emailRegex = /^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/;
    phoneRegex = /^[2-9]\d{2}-\d{3}-\d{4}$/;

    // eslint-disable-next-line space-before-function-paren
    loadSinglePolicyData = function (networkResponses): void {
        let hasSMVehicles = false;

        for (const vehResp of networkResponses) {
            const transformVehicle = new Vehicle(vehResp);
            if (vehResp.scoringModel === 'SM1' || vehResp.scoringModel === 'SM2') {
                hasSMVehicles = true;
            }
            if (this.user.isAdmin()) {
                this.programVehicles.push(transformVehicle);
            } else if (transformVehicle.isDiscountInAnyPartOfRemoval() || transformVehicle.isFortyFiveDays()) {
                this.programEndedVehicles.push(transformVehicle);
            } else {
                this.programVehicles.push(transformVehicle);
            }
        }
        this.setupAccordion();

        if (hasSMVehicles) {
            this._policy.getPolicyData(sessionStorage.getItem('selectedPolicy')).subscribe((pcResponse) => {
                this.programVehicles = this.programVehicles.filter((vehicle) => {
                    if (vehicle.scoringModel !== 'SM1' || vehicle.scoringModel !== 'SM2') {
                        return true;
                    }
                    const pcSMVehicle = pcResponse.lob.vehicles.find(
                        (pcVehicle: PersonalLinesVehicle) => vehicle.vin === pcVehicle.vin && this.findActiveSMVehicle(pcVehicle)
                    );
                    return pcSMVehicle != null;
                });
            }, () => { }, () => {
                this.setupAccordion();
            });
        } else {
            this.setupAccordion();
        }
    }.bind(this);

    // eslint-disable-next-line max-params
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        public user: UserService,
        private navBar: NavbarService,
        public ebiEvents: EbiEventService,
        public prefService: SaveVehiclePrefService,
        public vehicle: VehicleService,
        public policy: PersonalLinesPolicyService,
        public policyPreferencesService: PolicyPreferencesService
    ) {
        this.programVehicles = [];
        this.programEndedVehicles = [];
    }

    @HostListener('window:beforeunload', ['$event'])

    updateVehicleName = (index, value): void => {
        const vehicle = this.programVehicles[index];
        const oldValue = vehicle.name;
        vehicle.name = value;
        this.prefService.setVehiclePref(vehicle).subscribe((res) => {
            if (res['data'] === undefined || !res['data'].success) {
                vehicle.name = oldValue;
            } else {
                this.vehicle.updateContactPreferences(vehicle);
            }
        });
    };

    updateTextingPreferences = (value): void => {
        const parsedPhoneNumber = value.split('-');
        this.textingPreference.contactPoint.areaCode = parsedPhoneNumber[0];
        this.textingPreference.contactPoint.localNumber = parsedPhoneNumber[1] + parsedPhoneNumber[2];
        /* eslint no-unused-expressions: [2, { allowTernary: true }]*/
        this.textingEnrolled ? this.textingPreference.election = 'Yes' : this.textingPreference.election = 'No';
        this.ebiEvents.postTextingPrefEvent('SmartRide Texting');
        this.policyPreferencesService.setTextingPreference(this.textingPreference, this.user.getEcn()).subscribe((res) => res);
    };

    updateEmail = (index, email): void => {
        const vehicle = this.programVehicles[index];
        const oldValue = vehicle.contactPreferences.email;
        vehicle.contactPreferences.email = email;
        this.prefService.setVehiclePref(vehicle).subscribe((res) => {
            if (res['data'] === undefined || !res['data'].success) {
                vehicle.contactPreferences.email = oldValue;
            } else {
                this.vehicle.updateContactPreferences(vehicle);
            }
        });
    };

    toggleSendEmails = (index, sendEmails, type): void => {
        const vehicle = this.programVehicles[index];
        let oldValue;
        switch (type) {
            case 'weekly':
                oldValue = vehicle.contactPreferences.sendWeeklyEmail;
                this.toggleWeeklyEmail(sendEmails, vehicle, oldValue);
                break;
            case 'braking':
                oldValue = vehicle.contactPreferences.sendDailyBrakingEmail;
                this.toggleDailyBrakingEmail(sendEmails, vehicle, oldValue);
                break;
            case 'acceleration':
                oldValue = vehicle.contactPreferences.sendDailyAccelerationEmail;
                this.toggleDailyAccelerationEmail(sendEmails, vehicle, oldValue);
                break;
            case 'idle':
                oldValue = vehicle.contactPreferences.sendDailyIdleTimeEmail;
                this.toggleDailyIdleTimeEmail(sendEmails, vehicle, oldValue);
                break;
            case 'brakingAcceleration':
                oldValue = vehicle.contactPreferences.sendDailyBrakingAccelerationEmail;
                this.toggleDailyBrakingAccelerationEmail(sendEmails, vehicle, oldValue);
                break;
            case 'night':
                oldValue = vehicle.contactPreferences.sendDailyNightDrivingEmail;
                this.toggleDailyNightEmail(sendEmails, vehicle, oldValue);
                break;
        }
    };

    toggleWeeklyEmail = (weekly, vehicle, oldValue): void => {
        if (weekly.target.checked) {
            this.ebiEvents.postSrideEvent('425080065');
            vehicle.contactPreferences.sendWeeklyEmail = true;
        } else {
            vehicle.contactPreferences.sendWeeklyEmail = false;
            this.ebiEvents.postEvent('opt out bi-weekly emails');
        }
        this.prefService.setVehiclePref(vehicle).subscribe((res) => {
            if (res['data'] === undefined || !res['data'].success) {
                vehicle.contactPreferences.sendWeeklyEmail = oldValue;
            } else {
                this.vehicle.updateContactPreferences(vehicle);
            }
        });
    };

    toggleDailyBrakingEmail = (braking, vehicle, oldValue): void => {
        if (braking.target.checked) {
            this.ebiEvents.postSrideEvent('425080067');
            vehicle.contactPreferences.sendDailyBrakingEmail = true;
        } else {
            vehicle.contactPreferences.sendDailyBrakingEmail = false;
        }
        this.prefService.setVehiclePref(vehicle).subscribe((res) => {
            if (res['data'] === undefined || !res['data'].success) {
                vehicle.contactPreferences.sendDailyBrakingEmail = oldValue;
            } else {
                this.vehicle.updateContactPreferences(vehicle);
            }
        });
    };

    toggleDailyAccelerationEmail = (acceleration, vehicle, oldValue): void => {
        if (acceleration.target.checked) {
            this.ebiEvents.postSrideEvent('425080069');
            vehicle.contactPreferences.sendDailyAccelerationEmail = true;
        } else {
            vehicle.contactPreferences.sendDailyAccelerationEmail = false;
        }
        this.prefService.setVehiclePref(vehicle).subscribe((res) => {
            if (res['data'] === undefined || !res['data'].success) {
                vehicle.contactPreferences.sendDailyAccelerationEmail = oldValue;
            } else {
                this.vehicle.updateContactPreferences(vehicle);
            }
        });
    };

    toggleDailyNightEmail = (night, vehicle, oldValue): void => {
        if (night.target.checked) {
            this.ebiEvents.postSrideEvent('425080080');
            vehicle.contactPreferences.sendDailyNightDrivingEmail = true;
        } else {
            vehicle.contactPreferences.sendDailyNightDrivingEmail = false;
        }
        this.prefService.setVehiclePref(vehicle).subscribe((res) => {
            if (res['data'] === undefined || !res['data'].success) {
                vehicle.contactPreferences.sendDailyNightDrivingEmail = oldValue;
            } else {
                this.vehicle.updateContactPreferences(vehicle);
            }
        });
    };

    toggleDailyBrakingAccelerationEmail = (brakingAcceleration, vehicle, oldValue): void => {
        if (brakingAcceleration.target.checked) {
            this.ebiEvents.postSrideEvent('425080106');
            vehicle.contactPreferences.sendDailyBrakingAccelerationEmail = true;
        } else {
            vehicle.contactPreferences.sendDailyBrakingAccelerationEmail = false;
        }
        this.prefService.setVehiclePref(vehicle).subscribe((res) => {
            if (res['data'] === undefined || !res['data'].success) {
                vehicle.contactPreferences.sendDailyBrakingAccelerationEmail = oldValue;
            } else {
                this.vehicle.updateContactPreferences(vehicle);
            }
        });
    };

    toggleDailyIdleTimeEmail = (idle, vehicle, oldValue): void => {
        if (idle.target.checked) {
            this.ebiEvents.postSrideEvent('425080105');
            vehicle.contactPreferences.sendDailyIdleTimeEmail = true;
        } else {
            vehicle.contactPreferences.sendDailyIdleTimeEmail = false;
        }
        this.prefService.setVehiclePref(vehicle).subscribe((res) => {
            if (res['data'] === undefined || !res['data'].success) {
                vehicle.contactPreferences.sendDailyIdleTimeEmail = oldValue;
            } else {
                this.vehicle.updateContactPreferences(vehicle);
            }
        });
    };

    async ngOnInit(): Promise<void> {
        await this.getTextingPreferences();
        this.navBar.headerDisplay(this.user.isLoggedIn, this.router.url);
        this.programType = BrandingService.getBranding() === 'smartmiles' ? 'smiles' : 'sride';
        this.ebiEvents.postEvent('Preferences page');
        this.ebiEvents.postSrideEvent('425080073');
        this.policyNumber = sessionStorage.getItem('selectedPolicy');
        this.vehicle.getVehicleByPolicy(this.policyNumber).subscribe(this.loadSinglePolicyData);
        this.routerSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.navBar.headerDisplay(true, event.url);
            }
        });
    }

    ngOnDestroy(): void {
        if (this.routerSubscription) {
            this.routerSubscription.unsubscribe();
            this.routerSubscription = null;
        }
    }

    findActiveSMVehicle = (vehicle: PersonalLinesVehicle): PersonalLinesModifier =>
        vehicle.modifiers.find((mod) => mod.booleanModifier && mod.code === 'PA_VehicleProgram_PayPerMile_Ext');

    setupAccordion(): void {
        this.accordionRows = [];
        let i = 0;
        const vinProvided = !!this.route.snapshot.paramMap.get('vin');
        if (!vinProvided) {
            this.activePanelIndex = 0;
        }
        while (this.programVehicles !== undefined && i < this.programVehicles.length) {
            if (vinProvided && this.router.url.match(this.programVehicles[i].vin)) {
                this.accordionRows.push(true);
                this.activePanelIndex = i;
            } else if (vinProvided) {
                this.accordionRows.push(false);
            } else {
                i === 0 ? this.accordionRows.push(true) : this.accordionRows.push(false);
            }
            i = i + 1;
        }
    }

    toggle(event: NgbPanelChangeEvent): void {
        let toggle = 'toggle-';
        if (this.programType === 'smiles') {
            toggle = 'smiles-toggle-';
        }
        for (const i in this.accordionRows) {
            if (toggle + i === event.panelId) {
                this.accordionRows[i] = !this.accordionRows[i];
            } else {
                this.accordionRows[i] = false;
            }
        }
    }

    getTextingPreferences(): void {
        this.textingEnrolled = false;
        let found = false;
        const digitsInExchangeCode = 3;
        this.policyPreferencesService.getTextingPreference(this.user.getEcn()).subscribe((response) => {
            for (const preference of response['preferences']) {
                if (preference.name === 'Usage Based Ins Program Text') {
                    found = true;
                    this.previousElectionExists = true;
                    this.phoneNumber = preference.contactPoint.areaCode && preference.contactPoint.localNumber ? `${preference.contactPoint.areaCode}-${preference.contactPoint.localNumber.slice(0, digitsInExchangeCode)}-${preference.contactPoint.localNumber.slice(digitsInExchangeCode)}` : null;
                    this.textingPreference = preference;
                    this.updatedPhoneNumberForTexting = this.phoneNumber;
                }
            }
            if (!found) {
                this.textingPreference = {
                    preferenceType: 'AgreementPreference',
                    name: 'Usage Based Ins Program Text',
                    election: 'No',
                    category: 'Servicing',
                    lineOfBusiness: 'Personal',
                    href: `https://api-test.nwie.net/customerrelationshipmanagement/preferencesmanagement/v2/customers/${this.user.getEcn()}/preferences/U3BvbnNvcnNoaXBzfHxkZ3NfYnNhX2ludGVybmV0X3NlcnZpY2luZ0BuYXRpb253aWRlLmNvbQ==1`,
                    canModify: true,
                    relatedAgreement: {
                        agreementNumber: this.user.getPolicyNumber().replace(/\s/g, ''),
                        productType: 'Personal Auto',
                        lineOfBusiness: 'NI',
                        businessOrgUnit: 'NBP',
                        automaticEnrollment: false,
                        href: 'NA'
                    },
                    contactPoint: {
                        contactPointType: 'PhoneNumber',
                        areaCode: '',
                        localNumber: '',
                        href: 'NA'
                    }
                };
            }
            if (this.textingPreference.election === 'Yes' || this.textingPreference.election === 'Pending') {
                this.textingEnrolled = true;
            }
        });
    }

    comparePhoneNumbers(newPhoneNumber: any): void {
        this.updatedPhoneNumberForTexting = newPhoneNumber;
    }

    updatePhoneNumber(updatedPhoneNumber: any): void {
        this.phoneNumber = updatedPhoneNumber;
        this.showTextReplyMessage = true;
    }

    canceledTextPreference(cancelCheckBox: boolean): void {
        if (this.textingPreference.election !== 'Yes' && this.textingPreference.election !== 'Pending') {
            this.textingEnrolled = !cancelCheckBox;
        }
        this.updatedPhoneNumberForTexting = this.phoneNumber;
    }

    beforeLeavingPage($event): string {
        if (this.updatedPhoneNumberForTexting !== this.phoneNumber && this.user.isLoggedIn) {
            return $event.returnValue = 'Changes you made may not be saved.';
        }
    }

    changeTextingElection(): void {
        this.textingEnrolled = !this.textingEnrolled;
    }

    canDeactivate(): Observable<boolean> | boolean {
        if (this.updatedPhoneNumberForTexting !== this.phoneNumber && this.user.isLoggedIn) {
            // eslint-disable-next-line no-alert
            return confirm('Changes you made may not be saved.');
        } else {
            return true;
        }
    }
}
