import { Component, OnInit, Input, Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Timeline } from 'app/model/timeline';
import { environment } from 'environments/environment';

@Component({
    selector: 'app-time-navigation-bar',
    templateUrl: './time-navigation-bar.component.html',
    styleUrls: ['./time-navigation-bar.component.css']
})

@Injectable()
export class TimeNavigationBarComponent implements OnInit {
    @Input() periodUnit: string;
    @Input() date: string;
    @Input() dateForPeriodChange: string;
    @Input() vehicleTimeline: Timeline;
    @Input() unitOnly = false;
    firstDate: Moment;
    lastDate: Moment;

    constructor(
        private _router: Router,
        private _activatedRoute: ActivatedRoute
    ) { }

    ngOnInit(): void {
        if (this.vehicleTimeline) {
            this.firstDate = moment(this.vehicleTimeline.installDate || this.vehicleTimeline.enrollDate);
            this.lastDate = moment.min(moment(environment.futureDate), moment(this.vehicleTimeline.completionDate) || moment('99991231'));
        }
    }

    canIncrement(): boolean {
        if (this.periodUnit === 'total') {
            return false;
        }
        if (!this.lastDate) {
            return true;
        }
        if (moment(this.date).isBefore(this.lastDate, <any> this.periodUnit)) {
            return true;
        }
        return false;
    }

    canDecrement(): boolean {
        if (this.periodUnit === 'total') {
            return false;
        }
        if (!this.firstDate) {
            return true;
        }
        if (moment(this.date).isAfter(this.firstDate, <any> this.periodUnit)) {
            return true;
        }
        return false;
    }

    getPeriodForDisplay(): string {
        if (this.periodUnit === 'day') {
            return this.formatDateForSingleDay(this.date);
        }
        if (this.periodUnit === 'month') {
            return this.formatMonth(this.date);
        }
        const startDate = this.getStartDateFromUnitAndEndDate(this.periodUnit, this.date);
        if (this.periodUnit === 'total' && (moment(startDate).isAfter(moment(environment.futureDate), 'day') || !this.date)) {
            return this.formatDateForRange(startDate);
        }
        // U+2013 = EN DASH
        return `${this.formatDateForRange(startDate)} \u2013 ${this.formatDateForRange(this.date)}`;
    }

    formatDateForSingleDay(date: string): string {
        return moment(date).format('dddd, MMM D');
    }

    formatMonth(date: string): string {
        return moment(date).format('MMMM');
    }

    formatDateForRange(date: string): string {
        return moment(date).format('MMM D');
    }

    getStartDateFromUnitAndEndDate(unit: string, endDate: string): string {
        const daysInWeekMinusOne = 6;
        switch (unit) {
            case 'day': return endDate;
            case 'week': return moment(endDate).subtract(daysInWeekMinusOne, 'days').format('YYYYMMDD'); // sic 6: Endpoint dates are both inclusive
            case 'month': return moment(endDate).startOf('month').format('YYYYMMDD');
            case 'total': return moment(this.vehicleTimeline.installDate || this.vehicleTimeline.enrollDate).format('YYYYMMDD');
        }
        return endDate;
    }

    decrementTimeSlice(): void {
        const targetDate = this.getPreviousDate(this.periodUnit, this.date);
        this._router.navigate(['..', targetDate], { relativeTo: this._activatedRoute });
    }

    incrementTimeSlice(): void {
        const targetDate = this.getNextDate(this.periodUnit, this.date);
        this._router.navigate(['..', targetDate], { relativeTo: this._activatedRoute });
    }

    viewCurrent(): void {
        this._router.navigate(['..', 'latest'], { relativeTo: this._activatedRoute });
    }

    getPreviousDate(unit: string, date: string): string {
        switch (unit) {
            case 'day': return moment(date).subtract(1, 'days').format('YYYYMMDD');
            case 'week': return moment(date).subtract(1, 'weeks').format('YYYYMMDD');
            case 'month': return moment(date).subtract(1, 'months').format('YYYYMMDD');
        }
        return date;
    }

    getNextDate(unit: string, date: string): string {
        switch (unit) {
            case 'day': return moment(date).add(1, 'days').format('YYYYMMDD');
            case 'week': return moment(date).add(1, 'weeks').format('YYYYMMDD');
            case 'month': return moment(date).add(1, 'months').format('YYYYMMDD');
        }
        return date;
    }

    createLabel(): string {
        switch (this.periodUnit) {
            case 'total':
                return `${moment(this.firstDate).format('MMMM Do')} to ${moment(this.lastDate).format('MMMM Do')}`;
            case 'month':
                return moment(this.getStartDateFromUnitAndEndDate(this.periodUnit, this.date)).format('MMMM');
            case 'week':
                return `${moment(this.getStartDateFromUnitAndEndDate(this.periodUnit, this.date)).format('MMMM Do')
                    } to ${moment(this.date).format('MMMM Do')}`;
            case 'day':
                return moment(this.getStartDateFromUnitAndEndDate(this.periodUnit, this.date)).format('dddd MMMM Do');
        }
    }
}
