/* eslint-disable no-magic-numbers*/
import { Component, OnDestroy, OnInit, Input, HostListener } from '@angular/core';
import { Vehicle } from 'app/model/vehicle';
import { TimeFrame } from 'app/interfaces/timeframe.interface';
import { RatedMileageService } from 'app/service/rated-mileage.service';
import { TimeframeService } from 'app/service/timeframe.service';
import { TimeFrameUnit } from 'app/enum/timeframe';
import * as moment from 'moment';
import { PersonalLinesPolicyService } from 'app/service/personal-lines-policy.service';
import { UserService } from 'app/service/user.service';

@Component({
    selector: 'app-miles-bar',
    templateUrl: './miles-bar.component.html',
    styleUrls: ['./miles-bar.component.css']
})

export class MilesBarComponent implements OnInit, OnDestroy {
    @Input() vehicle: Vehicle;
    term: number;
    showFullDateFormat = false;
    mileage: number;
    policyChangeMiles: number;
    progressBarStyle: object;
    overEstimateStyle: object;
    policyTimeframe: TimeFrame;
    doubleDash: boolean;
    timeframeSubscription: any;
    typicalAnnualMileage: number;
    estimatedHoverText: string;
    policyChangeHoverText: string;
    innerWidth: number;
    mobileView: boolean;
    annualMileage: number;
    // eslint-disable-next-line max-params
    constructor(
        public policy: PersonalLinesPolicyService,
        public ratedMileage: RatedMileageService,
        public timeframe: TimeframeService,
        public user: UserService) {
        this.mileage = 0;
        this.term = 6;
    }

    @HostListener('window:resize', ['$event'])
    onResize(): void {
        this.innerWidth = window.innerWidth;
        this.checkInnerWidth();
    }

    ngOnInit(): void {
        this.setPolicyTerm();
        const term = <TimeFrame>{ start: moment(this.vehicle.periodStart), end: moment(this.vehicle.periodEnd), unit: TimeFrameUnit.CUSTOM };
        if (!this.vehicle.ratedMileage || this.vehicle.ratedMileage.length === 0) {
            this.ratedMileage.getRatedMileage(this.vehicle.vin, null, this.vehicle.smEnrollDate, this.vehicle.periodEnd).subscribe(() => {
                this.mileage = this.ratedMileage.getTotalMileageInTimeframe(this.vehicle.vin, term);
                this.setProgressBarStyle();
                this.setOverEstimateStyle();
            });
        } else {
            this.mileage = this.ratedMileage.getTotalMileageInTimeframe(this.vehicle.vin, term);
            this.setProgressBarStyle();
            this.setOverEstimateStyle();
        }
        this.estimatedHoverText = 'Driving within the estimated mileage you provided when you enrolled in SmartMiles will keep you at or below your estimated premium provided at the beginning of the program.';
        this.policyChangeHoverText = 'The most miles you can drive and still pay less than a regular policy Nationwide could provide you.';
    }

    ngOnDestroy(): void {
    }

    setPolicyTerm = (): void => {
        this.policyTimeframe = <TimeFrame>{
            start: this.vehicle.enrolledMidTerm ? moment(this.vehicle.smEnrollDate) : moment(this.vehicle.periodStart),
            end: moment(this.vehicle.periodEnd),
            unit: TimeFrameUnit.CUSTOM
        };
        this.policy.getPolicyData(this.user.getPolicyNumber()).subscribe((policy) => {
            const monthsInYearDifference = 11;
            const tamMultiplier = 1.3;

            if (moment(policy.periodEnd).diff(moment(policy.periodStart), 'months') >= monthsInYearDifference) {
                this.term = 12;
                this.typicalAnnualMileage = this.vehicle.typicalAnnualMileage;
                this.policyChangeMiles = this.vehicle.typicalAnnualMileage * tamMultiplier;
            } else {
                this.typicalAnnualMileage = Math.round(this.vehicle.typicalAnnualMileage / 2);
                this.policyChangeMiles = Math.round(this.vehicle.typicalAnnualMileage / 2 * tamMultiplier);
            }
            if (this.vehicle.enrolledMidTerm) {
                this.typicalAnnualMileage = Math.round(this.typicalAnnualMileage * this.getInterimFactor());
                this.policyChangeMiles = Math.round(this.policyChangeMiles * this.getInterimFactor());
            }

            const milesToShowBeyondEstimate = 250;
            if (this.typicalAnnualMileage + milesToShowBeyondEstimate >= this.policyChangeMiles) {
                const halfYearTerm = 6;
                if (this.term > halfYearTerm) {
                    this.policyChangeMiles = this.typicalAnnualMileage + milesToShowBeyondEstimate * 2;
                } else {
                    this.policyChangeMiles = this.typicalAnnualMileage + milesToShowBeyondEstimate;
                }
            }

            if (isNaN(this.typicalAnnualMileage)) {
                this.doubleDash = true;
            }
            this.showFullDateFormat = this.vehicle.periodEnd.getFullYear() > this.vehicle.periodStart.getFullYear();
        });
    };

    getInterimFactor = (): number => {
        const daysInTerm = moment(this.vehicle.periodEnd).diff(moment(this.vehicle.periodStart), 'days');
        const daysRemaining = moment(this.vehicle.periodEnd).diff(moment(this.vehicle.smEnrollDate), 'days');
        return daysRemaining / daysInTerm;
    };

    setProgressBarStyle = (): void => {
        let style = {};
        const mileageWidthFactor = 70;
        let progressBarWidth;
        if (this.mileage === 0) { // Minimum display
            style = {
                width: '0%',
                visibility: 'hidden',
                height: '0.6875rem'
            };
        } else if (this.mileage <= this.typicalAnnualMileage) { // Anything above 0 but below/equal the estimate
            const minWidth = 5;

            style = {
                width: `${Math.max(minWidth, mileageWidthFactor * (this.mileage / this.typicalAnnualMileage))}%`,
                height: '0.6875rem',
                'min-width': '1rem',
                'background-color': '#6F5091'
            };
        } else if (this.mileage <= this.policyChangeMiles) { // Anything between the estimate and 'consider policy change' limit
            progressBarWidth = 30 * (this.mileage - this.typicalAnnualMileage) / (this.policyChangeMiles - this.typicalAnnualMileage) + mileageWidthFactor;
            if (progressBarWidth > 95) {
                style = {
                    width: `${progressBarWidth}%`,
                    height: '0.6875rem',
                    'background-image': '#6F5091'
                };
            } else {
                style = {
                    width: `${progressBarWidth}%`,
                    height: '0.6875rem',
                    'background-color': '#6F5091'
                };
            }
        } else {
            // Anything above the 'consider policy change' limit
            style = {
                width: '90.5%',
                height: '0.6875rem',
                'background-image': '#6F5091'
            };
        }
        this.progressBarStyle = style;
    };

    setOverEstimateStyle = (): void => {
        let style = {};
        const minWidth = 2;
        const progressBarWidth = Math.max(minWidth, (this.mileage - this.typicalAnnualMileage) / (this.policyChangeMiles - this.typicalAnnualMileage));
        if (this.mileage > this.policyChangeMiles) {
            style = {
                width: `${progressBarWidth}%`,
                height: '0.6875rem'
            };
        } else {
            style = {
                display: 'none'
            };
        }
        this.overEstimateStyle = style;
    };

    createAriaLabel(): string {
        const policyEndDate = moment(this.policyTimeframe.end).format('MMMM D, YYYY');
        const policyStartDate = moment(this.policyTimeframe.start).format('MMMM D, YYYY');
        const monthsInHalfTerm = 6;
        if (this.term === monthsInHalfTerm) {
            this.annualMileage = this.vehicle.annualMileage / 2;
        } else {
            this.annualMileage = this.vehicle.annualMileage;
        }
        return `Total miles driven in ${this.term} month term (${policyStartDate} -- ${policyEndDate
            }). You estimated ${this.annualMileage} miles per term (${this.vehicle.annualMileage} per year) and have driven ${this.mileage} miles. You should consider Policy Change at ${this.policyChangeMiles} miles driven.`;
    }

    checkInnerWidth(): void {
        this.mobileView = false;
        if (this.innerWidth < 402) {
            this.mobileView = true;
        }
    }
}
