import { Pipe, PipeTransform } from '@angular/core';
import { SmartApplicationBranding } from 'app/enum/SmartApplicationBranding';

@Pipe({
  name: 'longBrandName'
})
export class LongBrandNamePipe implements PipeTransform {
  transform(branding: SmartApplicationBranding): string {
    switch (branding) {
      case SmartApplicationBranding.SMARTRIDE: return 'Smart Ride';
      case SmartApplicationBranding.SMARTMILES: return 'Smart Miles';
    }
    return '';
  }
}

@Pipe({
  name: 'tinyBrandAbbreviation'
})
export class TinyBrandAbbreviationPipe implements PipeTransform {
  transform(branding: SmartApplicationBranding): string {
    switch (branding) {
      case SmartApplicationBranding.SMARTRIDE: return 'sr';
      case SmartApplicationBranding.SMARTMILES: return 'sm';
    }
    return '';
  }
}
