import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Injectable()
export class AdminSearchFormService {
    constructor(private formBuilder: UntypedFormBuilder, public activatedRoute: ActivatedRoute) {
    }

  buildForm(): UntypedFormGroup {
    let policyNumber = '';
    const vin = '';
    if (sessionStorage.getItem('agentSelectedPolicy')) {
        policyNumber = sessionStorage.getItem('agentSelectedPolicy');
    }
    return this.formBuilder.group({
      policyNumber: [policyNumber, [
        Validators.pattern('^ *[a-zA-Z\\d ]{1,32} *$')
      ]],
      vin: [vin, [
        Validators.pattern('^ *[a-zA-Z\\d ]{1,32} *$')
      ]]
    });
  }
}
