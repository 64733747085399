import { Component, Injectable } from '@angular/core';

@Component({
    selector: 'app-custom-alert',
    templateUrl: './custom-alert.component.html',
    styleUrls: ['./custom-alert.component.css']
})

@Injectable()
export class CustomAlertComponent { }
