import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientJsonpModule, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CoreModule } from 'app/core/core.module';
import { FactorCircleComponent } from 'app/components/factor-circle/factor-circle.component';
import { VehicleSummaryComponent } from 'app/components/vehicle-summary/vehicle-summary.component';
import { DailySummaryComponent } from 'app/components/vehicle-summary/daily/daily-summary.component';
import { WeeklySummaryComponent } from 'app/components/vehicle-summary/weekly/weekly-summary.component';
import { MonthlySummaryComponent } from 'app/components/vehicle-summary/monthly/monthly-summary.component';
import { TotalSummaryComponent } from 'app/components/vehicle-summary/total/total-summary.component';
import { MonthlyDetailsComponent } from 'app/components/vehicle-summary/monthly-details/monthly-details.component';
import { DeviceAlertComponent } from 'app/components/device-alert/device-alert.component';
import { AlertGuard } from 'app/router-guards/alert-guard';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TelematicsService } from 'app/service/telematics.service';
import { NavbarService } from 'app/service/nav-bar.service';
import { LoginComponent } from 'app/components/login/login.component';
import { ProgramSelectComponent } from 'app/components/program-select/program-select.component';
import { HowItWorksComponent } from 'app/components/how-it-works/how-it-works.component';
import { HelpComponent } from 'app/components/help/help.component';
import { ModelModule } from 'app/model/model.module';
import { SharedUiModule } from 'app/shared-ui/shared-ui.module';
import { AppRoutingModule } from 'app/app-routing.module';
import { ServiceModule } from 'app/service/service.module';
import { AppComponent } from 'app/app.component';
import { DeviceSummaryComponent } from 'app/components/device-summary/device-summary.component';
import { DollarsPipe } from 'app/pipes/dollars.pipe';
import { CentsPipe } from 'app/pipes/cents.pipe';
import { LongBrandNamePipe, TinyBrandAbbreviationPipe } from 'app/pipes/branding.pipe';
import { TimeframePipe } from 'app/pipes/timeframe.pipe';
import { MilesBarComponent } from 'app/components/miles-bar/miles-bar.component';
import { TokenGuard } from 'app/router-guards/token-guard';
import { TripDataService } from 'app/service/tripSpeed-data-service';
import { VehicleService } from 'app/service/vehicle.service';
import { TelematicsTransactionsService } from 'app/service/telematics-transactions.service';
import { PersonalLinesPolicyService } from 'app/service/personal-lines-policy.service';
import { RatedMileageService } from 'app/service/rated-mileage.service';
import { DtoMapperService } from 'app/service/dto-mapper.service';
import { MapUtils } from 'app/utility/MapUtils';
import { UserService } from 'app/service/user.service';
import { PolicyNumberFormatService } from 'app/service/policy-number-format.service';
import { SaveVehiclePrefService } from 'app/service/save-vehicle-pref.service';
import { ProgramSelectService } from 'app/service/program-select.service';
import { AuthInterceptor } from 'app/service/auth-interceptor';
import { VehicleNicknameForDisplayPipe } from 'app/pipes/vehicle-nickname-for-display.pipe';
import { SmartMilesVehicleResolver } from 'app/router-guards/smiles-vehicle-resolver';
import { DecimalPipe } from '@angular/common';
import { MobilePendingCardComponent } from 'app/components/program-select/mobile-pending-card/mobile-pending-card.component';
import { MobileFinalCardComponent } from 'app/components/program-select/mobile-final-card/mobile-final-card.component';
import {
  ProgramSelectCardComponent
} from 'app/components/program-select/program-select-card/program-select-card.component';
import {
  ProgramSelectReturnDeviceTabComponent
} from 'app/components/program-select/program-select-return-device-tab/program-select-return-device-tab.component';
import { OAuthModule } from '@nationwide/angular-oauth-module';
import { LoggerService } from 'app/service/logger.service';
import { APICommonService } from '@nationwide/api-common-service';
import { environment } from 'environments/environment';
import { CmtCardComponent } from 'app/components/program-select/cmt-card/cmt-card.component';
import { EstimatedPremiumComponent } from 'app/components/estimated-premium/estimated-premium.component';
import { Smartmiles2DeviceSummaryComponent } from 'app/components/smartmiles2-device-summary/smartmiles2-device-summary.component';
import { MobileNumberUpdateCardComponent } from 'app/components/mobile-number-update-card/mobile-number-update-card.component';

@NgModule({
  imports: [
    BrowserModule,
    CoreModule,
    NgbModule,
    FormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    ModelModule,
    SharedUiModule,
    ServiceModule,
    AppRoutingModule,
    OAuthModule
  ],
  declarations: [
    AppComponent,
    LoginComponent,
    ProgramSelectComponent,
    HowItWorksComponent,
    MilesBarComponent,
    HelpComponent,
    EstimatedPremiumComponent,
    VehicleSummaryComponent,
    WeeklySummaryComponent,
    MonthlySummaryComponent,
    TotalSummaryComponent,
    DeviceSummaryComponent,
    MonthlyDetailsComponent,
    FactorCircleComponent,
    DeviceAlertComponent,
    DollarsPipe,
    CentsPipe,
    TimeframePipe,
    LongBrandNamePipe,
    TinyBrandAbbreviationPipe,
    ProgramSelectCardComponent,
    ProgramSelectReturnDeviceTabComponent,
    VehicleNicknameForDisplayPipe,
    MobilePendingCardComponent,
    MobileFinalCardComponent,
    MobileNumberUpdateCardComponent,
    CmtCardComponent,
    Smartmiles2DeviceSummaryComponent
  ],
  exports: [
    DollarsPipe,
    CentsPipe,
    TimeframePipe,
    LongBrandNamePipe,
    TinyBrandAbbreviationPipe,
    VehicleNicknameForDisplayPipe,
    Smartmiles2DeviceSummaryComponent
  ],
  providers: [
    AlertGuard,
    TokenGuard,
    SmartMilesVehicleResolver,
    TelematicsService,
    NavbarService,
    TripDataService,
    VehicleService,
    UserService,
    PolicyNumberFormatService,
    TelematicsTransactionsService,
    TripDataService,
    PersonalLinesPolicyService,
    ProgramSelectService,
    MapUtils,
    SaveVehiclePrefService,
    DtoMapperService,
    RatedMileageService,
    TimeframePipe,
    DecimalPipe,
    APICommonService,
    LoggerService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  bootstrap: [AppComponent]
})
export class SmartMilesModule {
}
