import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'app/service/user.service';
import { NavbarService } from 'app/service/nav-bar.service';
import { WindowRef } from 'app/service/window-ref';
import { EbiEventService } from 'app/service/ebi-event.service';
import { environment } from 'environments/environment';
import { SelfEnrollmentService } from 'app/service/self-enrollment.service';
import { LoggerService } from 'app/service/logger.service';

@Component({
  selector: 'app-self-enrollment-confirmation',
  templateUrl: './self-enrollment-confirmation.component.html',
  styleUrls: ['./self-enrollment-confirmation.component.css']
})
export class SelfEnrollmentConfirmationComponent implements OnInit {
  _window: any;

  // eslint-disable-next-line max-params
  constructor(
    public router: Router,
    public _user: UserService,
    public _navBar: NavbarService,
    public _windowRef: WindowRef,
    public _ebiEvent: EbiEventService,
    private _selfEnrollmentService: SelfEnrollmentService,
    public _loggerService: LoggerService
  ) {
    this._window = this._windowRef.nativeWindow;
  }

  ngOnInit(): void {
    this._selfEnrollmentService.isSelfEnrollFlowInPlay = false;
    this._navBar.headerDisplay(this._user.loggedIn, this.router.url);
    this._ebiEvent.postSrideEvent('425080114');
    this._loggerService.info('Successful Mobile Enrollment', {
      policyNumber: this._user.getPolicyNumber(),
      userId: this._user.getUsername()
    });
  }

  done(): void {
    this._window.location.replace(environment.myAccountUrl);
  }

  isEligibleForEarlyRewards(): boolean {
    const eligibleDiscountId = sessionStorage.getItem('eligibleDiscountId');
    return environment.eligibleEarlyRewardsDiscounts.indexOf(eligibleDiscountId) >= 0;
  }
}
