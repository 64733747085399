import { TimeFrame } from 'app/interfaces/timeframe.interface';

export class Summary {
  type: string;
  dateRange: TimeFrame;
  ratedDateRange: TimeFrame;
  miles: number;
  trips: number;
  details: string;
  alert: boolean;
  connected: boolean;
  firstException: boolean;
  lastException: boolean;
  showMsg: boolean;
  style: string;
  contentType: string;
  date: Date;
  outsideTerm: boolean;
  beforeInterimEnrollment: boolean;
  outsidePolicyTerm: boolean;
  fullDay: string;
  partial: boolean;
  alertType: string;
  isFutureDay: boolean;

  constructor() {
    this.alertType = 'none';
    this.beforeInterimEnrollment = false;
    this.outsideTerm = false;
  }
}
