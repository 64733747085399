import { Vehicle } from './vehicle';

export class Device {
  status: string;
  lastUpdated: Date;
  vehicle: Vehicle;

  constructor() { }

  buildDevice(
    status?: string, lastUpdated?: Date): void {
    this.status = status;
    this.lastUpdated = lastUpdated;
  }
}
