import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { TimeFrame } from 'app/interfaces/timeframe.interface';

@Injectable()
export class TimeframeService {
    timeframeChangeSource: Subject<TimeFrame>;
    timeframeChange$: Observable<TimeFrame>;
    setSharedTrendingColor: string;
    sharedTrendingColor: string;
    setHighestMiles: number;
    highestMiles: number;

    constructor() {
        this.timeframeChangeSource = new BehaviorSubject(TimeFrame.defaultInstance());
        this.timeframeChange$ = this.timeframeChangeSource.asObservable();
    }

    updateTimeframeRules(tf: TimeFrame): void {
        this.timeframeChangeSource.next(tf);
    }

    setHighestMonthlyMiles(value: number): void {
        this.setHighestMiles = value;
    }

    getHighestMonthlyMiles(): number {
        this.highestMiles = this.setHighestMiles;
        return this.highestMiles;
    }
}
