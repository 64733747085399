import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { UserService } from './user.service';
import { Observable } from 'rxjs';
import { APICommonService } from '@nationwide/api-common-service';

@Injectable({
  providedIn: 'root'
})
export class EcifService {
  constructor(
    private http: HttpClient,
    public user: UserService,
    private apiCommon: APICommonService
  ) { }

  getEcifData(): Observable<any> {
    const httpOptions = this.getHttpOptions();
    const ecn = this.user.getEcn();
    return this.http.get(`${environment.ecifService.url}${ecn}?apikey=${environment.authorize.client_id}`,
      { ...httpOptions }
    );
  }

  getHttpOptions(): { headers: HttpHeaders} {
    return {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'X-NW-Transaction-Id': this.apiCommon.generateTransactionId(),
          // eslint-disable-next-line camelcase
          client_id: environment.authorize.client_id,
          Accept: 'application/json, text/plain, */*',
          'Accept-Language': 'en-US,en;q=0.5'
        })
    };
  }
}
