import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SreDetailsContent } from './sre-details-content';
import { EbiEventService } from 'app/service/ebi-event.service';
import { FactorId } from 'app/service/sre-summary.service';
import * as moment from 'moment';
import { SmilesSREConstants } from 'app/model/smilessreconstants'; // TODO Why are these constants hidden so trickfully?
import { CircleConstants } from 'app/core/core.module';
import { FactorBreakdownRow } from 'app/components/factor-breakdown/factor-breakdown';
import { NavbarService } from 'app/service/nav-bar.service';
import { UserService } from 'app/service/user.service';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoggerService } from 'app/service/logger.service';

@Component({
    selector: 'app-sre-details',
    templateUrl: './sre-details.component.html',
    styleUrls: ['./sre-details.component.css']
})

export class SreDetailsComponent implements OnInit, OnDestroy {
    dataSubscription: Subscription;
    annualMilesThresholds: number[];
    projectedAnnualMiles: number;
    idleTimeRatio: number;
    content: SreDetailsContent;

    // eslint-disable-next-line max-params
    constructor(
        public route: ActivatedRoute,
        public router: Router,
        @Inject(CircleConstants) public circleConstants: SmilesSREConstants,
        public navbarService: NavbarService,
        public userService: UserService,
        public ebi: EbiEventService,
        private logger: LoggerService
    ) { }

    ngOnInit(): void {
        this.dataSubscription = this.route.data.pipe(tap((params) => {
            this.content = params['content'];
            this.setupBreakdownRowsAriaLabel();
            this.setupProjectedMilesSection();
            this.postLandingEbiEvent();
        })).subscribe(() => { }, (error) => {
            this.logger.error(error);
        });

        // XXX We should not have to configure the nav bar here:
        this.navbarService.headerDisplay(this.userService.isLoggedIn, this.router.url);
    }

    ngOnDestroy(): void {
        if (this.dataSubscription) {
            this.dataSubscription.unsubscribe();
        }
    }

    postLandingEbiEvent(): void {
        const eventType = this.route.snapshot.paramMap.get('factorId');
        const unit = this.route.snapshot.paramMap.get('periodUnit');
        switch (unit) {
            case 'total':
                switch (eventType) {
                    case 'miles':
                        this.ebi.postSrideEvent('425080013');
                        break;
                    case 'idle':
                        this.ebi.postSrideEvent('425080099');
                        break;
                    case 'night':
                        this.ebi.postSrideEvent('425080019');
                        break;
                    case 'braking':
                        this.ebi.postSrideEvent('425080015');
                        break;
                    case 'acceleration':
                        this.ebi.postSrideEvent('425080017');
                        break;
                    case 'brakingAcceleration':
                        this.ebi.postSrideEvent('425080083');
                        break;
                }
                break;
            case 'month':
                switch (eventType) {
                    case 'miles':
                        this.ebi.postSrideEvent('425080024');
                        break;
                    case 'idle':
                        this.ebi.postSrideEvent('425080087');
                        this.logger.info('user clicking on Idle Time circle', {
                            policyNumber: this.userService.getPolicyNumber(),
                            userId: this.userService.getUsername(),
                            programType: sessionStorage.getItem('selectedProgramType')
                        });
                        break;
                    case 'night':
                        this.ebi.postSrideEvent('425080030');
                        break;
                    case 'braking':
                        this.ebi.postSrideEvent('425080026');
                        break;
                    case 'acceleration':
                        this.ebi.postSrideEvent('425080028');
                        break;
                    case 'brakingAcceleration':
                        this.ebi.postSrideEvent('425080082');
                        break;
                }
                break;
            case 'week':
                switch (eventType) {
                    case 'miles':
                        this.ebi.postSrideEvent('425080054');
                        break;
                    case 'idle':
                        this.ebi.postSrideEvent('425080093');
                        break;
                    case 'night':
                        this.ebi.postSrideEvent('425080060');
                        break;
                    case 'braking':
                        this.ebi.postSrideEvent('425080056');
                        break;
                    case 'acceleration':
                        this.ebi.postSrideEvent('425080058');
                        break;
                    case 'brakingAcceleration':
                        this.ebi.postSrideEvent('425080094');
                        break;
                }
                break;
        }
    }

    sendEbiEvent(eventId: string): void {
        if (!eventId) {
            return;
        }
        this.ebi.postSrideEvent(eventId);
    }

    returnToSummary(event): void {
        event.preventDefault();
        this.sendEbiEvent(this.getEbiEventForClickSummaryLink());
        this.router.navigate([
            'sride', 'summary',
            this.route.snapshot.params.periodUnit,
            this.route.snapshot.params.periodEnd
        ]);
    }

    getEbiEventForClickSummaryLink(): string {
        switch (this.content.summary.periodUnit) {
            case 'week': switch (this.content.factor.event.factorId) {
                case FactorId.miles: return '425080004';
                case FactorId.braking: return '425080007';
                case FactorId.night: return '425080011';
                case FactorId.acceleration: return '425080009';
                // TODO EBI Summary events for brakingAcceleration, idle?
            } break;
            case 'total': switch (this.content.factor.event.factorId) {
                case FactorId.miles: return '425080029';
                case FactorId.braking: return '425080033';
                case FactorId.night: return '425080035';
                case FactorId.acceleration: return '425080031';
            } break;
            case 'month': switch (this.content.factor.event.factorId) {
                case FactorId.miles: return '425080042';
                case FactorId.braking: return '425080044';
                case FactorId.night: return '425080052';
                case FactorId.acceleration: return '425080050';
            } break;
        }
        return null;
    }

    getSummaryLinkText(): string {
        let pageTitle = '';
        if (this.content.summary.periodUnit === 'month') {
            pageTitle = 'Monthly';
        } else if (this.content.summary.periodUnit === 'week') {
            pageTitle = 'Weekly';
        } else if (this.content.summary.periodUnit === 'total') {
            pageTitle = 'Total';
        }
        const year = this.content.vehicle.year || '';
        const make = this.content.vehicle.make || '';
        const model = this.content.vehicle.model || '';
        return `< ${year} ${make} ${model} ${pageTitle} Summary`;
    }

    getPageTitle(): string {
        return this.content.factor.displayName;
    }

    getLastUpdatedText(): string {
        return moment(this.content.vehicle.device.lastUpdated).format('MM/DD/YYYY HH:mm A');
    }

    shouldShowFactorCircleLegend(): boolean {
        if (this.content.summary.periodUnit === 'total') {
            return false;
        }
        if (!this.content.factor.thresholds) {
            return false;
        }
        return true;
    }

    getPluralFactorName(): string {
        return this.content.factor.unit ? ` ${this.content.factor.unit}s` : '%';
    }

    getLegendHighLabel(): string {
        if (this.content.factor.thresholds && this.content.factor.thresholds.length > 0) {
            return `${this.content.factor.thresholds[1].toFixed(0)}${this.getPluralFactorName()} or more`;
        }
        return '';
    }

    getLegendMiddleLabel(): string {
        if (this.content.factor.thresholds && this.content.factor.thresholds.length > 0) {
            return `${this.content.factor.thresholds[0].toFixed(0)} to ` +
                `${(this.content.factor.thresholds[1] - 1).toFixed(0)}${this.getPluralFactorName()}`;
        }
        return '';
    }

    getLegendLowLabel(): string {
        if (this.content.factor.thresholds && this.content.factor.thresholds.length > 0) {
            return `${(this.content.factor.thresholds[0] - 1).toFixed(0)}${this.getPluralFactorName()} or less`;
        }
        return '';
    }

    shouldShowTip(): boolean {
        if (!this.content.factor.event) {
            return false;
        }
        if (!this.content.factor.event.tip) {
            return false;
        }
        return true;
    }

    getCannedFactorDescription(): string {
        switch (this.content.factor.event.factorId) {
            case FactorId.night: return this.circleConstants.nighttimeDrivingInfo;
            case FactorId.idle: return this.circleConstants.idleInfo;
            case FactorId.miles: return this.circleConstants.milesDrivenInfo;
            case FactorId.braking: return this.circleConstants.brakingInfo;
            case FactorId.acceleration: return this.circleConstants.accelerationInfo;
            case FactorId.brakingAcceleration: return this.circleConstants.brakingAccelerationInfo;
        }
        return '';
    }

    selectBreakdownRow(row: FactorBreakdownRow): void {
        this.logEventSelection();
        const desiredPeriodUnit: string = this.nextFinerPeriodUnit();
        this.router.navigate([
            'sride', 'details',
            this.route.snapshot.params.factorId,
            desiredPeriodUnit, row.endDate
        ]);
    }

    logEventSelection(): void {
        const eventType = this.route.snapshot.paramMap.get('factorId');
        const unit = this.route.snapshot.paramMap.get('periodUnit');
        switch (unit) {
            // When clicking 'events by Month' from total
            case 'total':
                switch (eventType) {
                    case 'miles':
                        this.ebi.postSrideEvent('425080021');
                        break;
                    case 'idle':
                        this.ebi.postSrideEvent('425080101');
                        break;
                    case 'night':
                        this.ebi.postSrideEvent('425080027');
                        break;
                    case 'braking':
                        this.ebi.postSrideEvent('425080023');
                        break;
                    case 'acceleration':
                        this.ebi.postSrideEvent('425080025');
                        break;
                    case 'brakingAcceleration':
                        this.ebi.postSrideEvent('425080102');
                        break;
                }
                break;

            // When clicking 'events by Week' from month
            case 'month':
                switch (eventType) {
                    case 'miles':
                        this.ebi.postSrideEvent('425080032');
                        break;
                    case 'idle':
                        this.ebi.postSrideEvent('425080089');
                        break;
                    case 'night':
                        this.ebi.postSrideEvent('425080040');
                        break;
                    case 'braking':
                        this.ebi.postSrideEvent('425080034');
                        break;
                    case 'acceleration':
                        this.ebi.postSrideEvent('425080036');
                        break;
                    case 'brakingAcceleration':
                        this.ebi.postSrideEvent('425080090');
                        break;
                }
                break;

            // When clicking 'events by Day' from week
            case 'week':
                switch (eventType) {
                    case 'miles':
                        this.ebi.postSrideEvent('425080062');
                        break;
                    case 'idle':
                        this.ebi.postSrideEvent('425080095');
                        break;
                    case 'night':
                        this.ebi.postSrideEvent('425080068');
                        break;
                    case 'braking':
                        this.ebi.postSrideEvent('425080064');
                        break;
                    case 'acceleration':
                        this.ebi.postSrideEvent('425080066');
                        break;
                    case 'brakingAcceleration':
                        this.ebi.postSrideEvent('425080096');
                        break;
                }
                break;
        }
    }

    nextFinerPeriodUnit(): string {
        switch (this.route.snapshot.params.periodUnit) {
            case 'total': return 'month';
            case 'month': return 'week';
            case 'week': return 'day';
        }
        return this.route.snapshot.params.periodUnit;
    }

    setupProjectedMilesSection(): void {
        if (this.content.vehicle.scoringModel === 'MO') {
            const MOMin = 4500;
            const MOMax = 12000;
            this.annualMilesThresholds = [MOMin, MOMax];
        } else if (this.content.vehicle.scoringModel === 'ND1' || this.content.vehicle.scoringModel === 'TW2') {
            const ND1Min = 6206;
            const ND1Max = 15695;
            this.annualMilesThresholds = [ND1Min, ND1Max];
        } else {
            const SM1Min = 4700;
            const SM1Max = 14999;
            this.annualMilesThresholds = [SM1Min, SM1Max];
        }
        this.projectedAnnualMiles = this.content.projectedAnnualMileage;
    }

    shouldDisplayProjectedMiles(): boolean {
        if (this.route.snapshot.params.periodUnit !== 'total') {
            return false;
        }
        if (this.route.snapshot.params.factorId !== 'miles') {
            return false;
        }
        return true;
    }

    getCssClassNameForFactorIcon(): string {
        return `factor-${FactorId[this.content.factor.event.factorId]}`;
    }

    createAnnualMileageAriaLabel(): string {
        return `Red level mileage is ${this.annualMilesThresholds[1] + 1
            } miles or more, Yellow level mileage is between ${this.annualMilesThresholds[0]} ` +
            `and ${this.annualMilesThresholds[1]
            } miles, Green level mileage is ${this.annualMilesThresholds[0] - 1} miles or less`;
    }

    createFactorLegendAriaLabel(): string {
        return `For this period Green Level is ${this.getLegendHighLabel()
            } Yellow level is ${this.getLegendMiddleLabel()
            } and Red Level is ${this.getLegendLowLabel()}`;
    }

    setupBreakdownRowsAriaLabel(): void {
        for (const row of this.content.breakdown.rows) {
            let amountString = '';
            if (row.amount !== 0 && !row.amount) {
                amountString = 'No data';
            } else {
                amountString = row.amount.toFixed(0);
                amountString = amountString + this.getPluralFactorName();
            }
            if (row.partial) {
                amountString = `${amountString} Partial Week`;
            }
            switch (this.content.breakdown.rowPeriodUnit) {
                case 'month':
                    row.ariaLabel =
                        `${moment(row.endDate).format('MMMM')} ${amountString}`;
                    break;
                case 'week':
                    row.ariaLabel =
                        `${moment(row.startDate).format('MMMM Do')} to ${
                        moment(row.endDate).format('MMMM Do')} ${amountString}`;
                    break;
                case 'day':
                    row.ariaLabel =
                        `${moment(row.startDate).format('dddd MMMM Do')} ${amountString}`;
                    break;
            }
        }
    }
}
