import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable()
export class OauthRevocationService {
  constructor(
    private _httpClient: HttpClient
  ) {
  }

  revokeOauthToken(token): Observable<any> {
    const url = environment.oauthRevocationService.url;
    const options = <any>{
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      responseType: 'text'
    };
    const body = this.encodeFormData({
      // eslint-disable-next-line camelcase
      client_id: environment.oauthRevocationService.apiKey,
      token
    });
    return this._httpClient.post(url, body, options);
  }

  encodeFormData(input): string {
    let output = '';
    let separator = '';
    for (const key of Object.keys(input)) {
      output += separator;
      output += encodeURIComponent(key);
      output += '=';
      output += encodeURIComponent(input[key]);
      separator = '&';
    }
    return output;
  }
}
