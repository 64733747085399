import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import * as moment from 'moment';
import { Moment } from 'moment';
import { TimeFrameUnit } from 'app/enum/timeframe';
import { TimeframeService } from 'app/service/timeframe.service';
import { Subscription } from 'rxjs';
import { TimeFrame } from 'app/interfaces/timeframe.interface';
import { Vehicle } from 'app/model/vehicle';
import { EbiEventService } from 'app/service/ebi-event.service';
import { NavbarService } from 'app/service/nav-bar.service';
import { UserService } from 'app/service/user.service';
import { filter } from 'rxjs/operators';
import { LoggerService } from 'app/service/logger.service';
import { TelematicsTransactionsService } from 'app/service/telematics-transactions.service';
import { environment } from 'environments/environment';

@Component({
    selector: 'app-vehicle-summary',
    templateUrl: './vehicle-summary.component.html',
    styleUrls: ['./vehicle-summary.component.css']
})
export class VehicleSummaryComponent implements OnInit, OnDestroy, AfterViewInit {
    MONTH = TimeFrameUnit.MONTH;
    WEEK = TimeFrameUnit.WEEK;
    DAY = TimeFrameUnit.DAY;
    TERM = TimeFrameUnit.TERM;

    vehicle: Vehicle;
    deviceLastUpdated: Date;
    timeFormat = 'LLL d';
    timeFormatStartMobile = 'LLLL d';
    timeFormatEndMobile = 'LLLL d, y';
    dateFormatEndNew = 'LLL d, y';
    timeFrameStart: Moment;
    timeFrameEnd: Moment;
    timeframe = this.MONTH;
    canDecrement = false;
    canIncrement = true;
    displayTimeBar = true;
    partialMonth = false;
    private routerSubscription: Subscription;
    isSmartMiles2 = false;

    // eslint-disable-next-line max-params
    constructor(
        public router: Router,
        public user: UserService,
        public navBar: NavbarService,
        public timeframeService: TimeframeService,
        public telematicTransactions: TelematicsTransactionsService,
        public route: ActivatedRoute,
        public ebiEvents: EbiEventService,
        public changeDetector: ChangeDetectorRef,
        private logger: LoggerService
    ) { }

    ngOnInit(): void {
        this.vehicle = this.route.snapshot.data.vehicle;
        this.navBar.headerDisplay(this.user.loggedIn, this.router.url);
        this.displayTimeBar = !this.router.url.includes('deviceAlert');
        this.setupSubscriptions();
        if (this.vehicle.scoringModel === 'SM2') {
            this.isSmartMiles2 = true;
        }
    }

    ngAfterViewInit(): void {
        this.changeDetector.detectChanges();

        this.routerSubscription = this.router.events.pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(this.setupSubscriptions);
    }

    setupSubscriptions = (): void => {
        const startDateString = this.route.snapshot.paramMap.get('amount');
        if (!moment(startDateString).isValid()) {
            this.logger.error(`Invalid start date "${startDateString}"`);
            this.router.navigateByUrl(`/404Error`, { replaceUrl: true });
            return;
        }
        const timeUnit = this.route.snapshot.paramMap.get('unit');
        if (this.shouldRedirectToCurrentDay(timeUnit, startDateString)) {
            this.redirectToCurrentDay(timeUnit);
        }
        this.timeFrameStart = moment(startDateString);
        if (timeUnit === TimeFrameUnit.TERM) {
            this.timeFrameStart = moment(this.user.getTermStart());
            this.timeFrameEnd = moment(this.user.getTermEnd());
            this.timeframe = TimeFrameUnit.TERM;
            this.setTimeFormatMonth();
            this.setTimeNavigationRules();
            return;
        } else if (timeUnit === TimeFrameUnit.WEEK) {
            this.timeFrameStart = moment(startDateString).startOf('week');
            if (this.timeFrameStart.isBefore(this.vehicle.smEnrollDate, 'day')) {
                this.timeFrameStart = moment(this.vehicle.smEnrollDate).startOf('week');
            }
            this.timeFrameEnd = this.timeFrameStart.clone().add(1, 'week').subtract(1, 'day');
            this.timeFormat = TimeFrameUnit.WEEK;
            this.timeframe = TimeFrameUnit.WEEK;
        } else if (timeUnit === TimeFrameUnit.DAY) {
            this.timeFrameStart = moment(startDateString).startOf('day');
            if (this.timeFrameStart.isBefore(this.vehicle.smEnrollDate, 'minutes')) {
                this.timeFrameStart = moment(this.vehicle.smEnrollDate).startOf('day');
            }
            this.timeFrameEnd = this.timeFrameStart.clone().endOf('day');
            this.timeFormat = 'EEEE, MMM d';
            this.timeframe = TimeFrameUnit.DAY;
        } else {
            this.user.setTermStart(new Date(this.vehicle.periodStart.valueOf()));
            this.user.setTermEnd(new Date(this.vehicle.periodEnd.valueOf()));
            const newTf = this.vehicle.getTermLimitsContainingMoment(moment(startDateString));
            this.timeFrameStart = moment.max(newTf.start.clone(), moment(this.vehicle.smEnrollDate));
            this.timeframe = TimeFrameUnit.MONTH;
            this.timeFrameEnd = newTf.end.clone();
        }
        this.setTimeFormatMonth();
        this.setTimeNavigationRules();
    };

    ngOnDestroy(): void {
        if (this.routerSubscription) {
            this.routerSubscription.unsubscribe();
        }
    }

    // TODO this currently does not do anything with the provided timeframe -RB 08/04/20
    setTimeFrame(timeframe: string): void {
        this.setupSubscriptions();
    }

    setMonthTimeFrame(): void {
        this.timeframe = TimeFrameUnit.MONTH;
        this.setTimeFormatMonth();
        this.ebiEvents.postEvent('Monthly summary');
        const tf = <TimeFrame>{
            start: this.vehicle.termBreakpoints[this.vehicle.termBreakpoints.length - 2],
            end: this.vehicle.termBreakpoints[this.vehicle.termBreakpoints.length - 1],
            unit: TimeFrameUnit.MONTH
        };

        if (moment(this.vehicle.smEnrollDate).isBetween(tf.start, tf.end, 'day')) {
            tf.start = moment(this.vehicle.smEnrollDate);
        }

        this.timeframeService.updateTimeframeRules(tf);
        this.setTimeNavigationRules();
    }

    decrementTimeSlice(enterKey: boolean): void {
        if (this.canDecrement) {
            switch (this.route.snapshot.paramMap.get('unit')) {
                case TimeFrameUnit.DAY:
                    this.router.navigate([this.composeUrlForPreviousDay()]);
                    this.timeframeService.updateTimeframeRules(<TimeFrame>{
                        unit: this.timeframe,
                        start: this.timeFrameStart.clone().subtract(1, 'day').startOf('day'),
                        end: this.timeFrameStart.clone().subtract(1, 'day').endOf('day')
                    });
                    break;

                case TimeFrameUnit.WEEK:
                    this.router.navigate([this.composeUrlForPreviousWeek()]);
                    this.timeframeService.updateTimeframeRules(<TimeFrame>{
                        unit: this.timeframe,
                        start: this.timeFrameStart.clone().subtract(1, 'week').startOf('week'),
                        end: this.timeFrameStart.clone().subtract(1, 'day')
                    });
                    break;

                case TimeFrameUnit.MONTH: {
                    this.timeFrameStart.subtract(1, 'month');
                    if (this.timeFrameStart.isBefore(this.vehicle.periodStart)) {
                        this.timeFrameStart = moment(this.vehicle.periodStart);
                    }
                    this.timeFrameEnd = this.timeFrameStart.clone().add(1, 'month').subtract(1, 'day').endOf('day');
                    const routeUrl = this.router.url;
                    this.router.navigate([this.composeUrlForPreviousMonth(routeUrl)]);
                    this.timeframeService.updateTimeframeRules(<TimeFrame>{
                        unit: this.timeframe,
                        start: this.timeFrameStart,
                        end: this.timeFrameEnd
                    });
                } break;
                case TimeFrameUnit.TERM: {
                    const sixMonths = 6;
                    const timeStart = new Date(this.vehicle.periodStart.valueOf());
                    timeStart.setMonth(timeStart.getMonth() - sixMonths);
                    this.timeFrameStart = moment(timeStart);
                    this.user.setTermStart(new Date(timeStart.valueOf()));
                    this.timeFrameEnd = this.timeFrameStart.clone().add(sixMonths, 'month').endOf('day');
                    const timeEnd = this.timeFrameEnd.toDate();
                    this.user.setTermEnd(new Date(timeEnd.valueOf()));
                    const routeUrl = this.router.url;
                    this.router.navigate([this.composeUrlForPreviousTerm(routeUrl)]);
                    this.timeframeService.updateTimeframeRules(<TimeFrame>{
                        unit: this.timeframe,
                        start: this.timeFrameStart,
                        end: this.timeFrameEnd
                    });
                } break;
            }
            if (enterKey) {
                const shortTimeout = 250;
                window.setTimeout(() => {
                    document.getElementById('display-date').focus();
                }, shortTimeout);
            }
            this.setTimeFormatMonth();
        }
    }

    incrementTimeSlice(enterKey: boolean): void {
        if (this.canIncrement) {
            switch (this.route.snapshot.paramMap.get('unit')) {
                case TimeFrameUnit.DAY:
                    this.router.navigate([this.composeUrlForNextDay()]);
                    this.timeframeService.updateTimeframeRules(<TimeFrame>{
                        unit: this.timeframe,
                        start: this.timeFrameStart.clone().add(1, 'day').startOf('day'),
                        end: this.timeFrameStart.clone().add(1, 'day').endOf('day')
                    });
                    break;

                case TimeFrameUnit.WEEK:
                    this.router.navigate([this.composeUrlForNextWeek()]);
                    this.timeframeService.updateTimeframeRules(<TimeFrame>{
                        unit: this.timeframe,
                        start: this.timeFrameEnd.clone().add(1, 'day').startOf('week'),
                        end: this.timeFrameEnd.clone().add(1, 'week')
                    });
                    break;

                case TimeFrameUnit.MONTH: {
                    const newTf = this.vehicle.getTermLimitsContainingMoment(this.timeFrameEnd.clone().add(1, 'day'));
                    const routeUrl = this.router.url;
                    this.router.navigate([this.composeUrlForNextMonth(newTf, routeUrl)]);
                    this.timeframeService.updateTimeframeRules(newTf);
                } break;
                case TimeFrameUnit.TERM: {
                    const newTf = this.vehicle.getTermLimitsContainingMoment(this.timeFrameEnd.clone().add(1, 'day'));
                    const routeUrl = this.router.url;
                    const d = new Date(this.user.getTermStart().valueOf());
                    const sixMonths = 6;
                    d.setMonth(d.getMonth() + sixMonths);
                    const e = new Date(this.user.getTermEnd().valueOf());
                    e.setMonth(e.getMonth() + sixMonths);
                    this.user.setTermStart(new Date(d.valueOf()));
                    this.user.setTermEnd(new Date(e.valueOf()));
                    this.timeFrameStart = moment(this.user.getTermStart());
                    this.timeFrameEnd = moment(this.user.getTermEnd());
                    this.router.navigate([this.composeUrlForNextTerm(newTf, routeUrl)]);
                    this.timeframeService.updateTimeframeRules(<TimeFrame>{
                        unit: this.timeframe,
                        start: this.timeFrameStart,
                        end: this.timeFrameEnd
                    });
                } break;
            }
            if (enterKey) {
                const shortTimeout = 250;
                window.setTimeout(() => {
                    document.getElementById('display-date').focus();
                }, shortTimeout);
            }
            this.setTimeFormatMonth();
        }
    }

    composeUrlForNextDay(): string {
        const date = moment.min(
            this.timeFrameStart.clone().add(1, 'day'),
            moment(this.vehicle.periodEnd)
        ).format('YYYYMMDD');
        return `/smiles/${date}/day/dailySummary`;
    }

    composeUrlForNextWeek(): string {
        const date = moment.min(
            this.timeFrameEnd.clone().add(1, 'day'),
            moment(this.vehicle.periodEnd)
        ).format('YYYYMMDD');
        return `/smiles/${date}/week/weeklySummary`;
    }

    composeUrlForNextMonth(newTf, routeUrl): string {
        const date = moment.min(
            newTf.start,
            moment(this.vehicle.periodEnd)
        ).format('YYYYMMDD');
        return `/smiles/${date}/month/${routeUrl.substring(routeUrl.lastIndexOf('/') + 1)}`;
    }
    composeUrlForNextTerm(newTf, routeUrl): string {
        const date = moment.min(
            newTf.start,
            moment(this.vehicle.periodEnd)
        ).format('YYYYMMDD');
        return `/smiles/${date}/term/${routeUrl.substring(routeUrl.lastIndexOf('/') + 1)}`;
    }

    composeUrlForPreviousDay(): string {
        const date = moment.max(
            this.timeFrameStart.clone().subtract(1, 'day'),
            moment(this.vehicle.smEnrollDate)
        ).format('YYYYMMDD');
        return `/smiles/${date}/day/dailySummary`;
    }

    composeUrlForPreviousWeek(): string {
        const date = moment.max(
            this.timeFrameStart.clone().subtract(1, 'week').startOf('week'),
            moment(this.vehicle.smEnrollDate)
        ).format('YYYYMMDD');
        return `/smiles/${date}/week/weeklySummary`;
    }

    composeUrlForPreviousMonth(routeUrl): string {
        const date = moment.max(
            this.timeFrameStart,
            moment(this.vehicle.smEnrollDate)
        ).format('YYYYMMDD');
        return `/smiles/${date}/month/${routeUrl.substring(routeUrl.lastIndexOf('/') + 1)}`;
    }
    composeUrlForPreviousTerm(routeUrl): string {
        const date = moment.max(
            this.timeFrameStart,
            moment(this.vehicle.smEnrollDate)
        ).format('YYYYMMDD');
        return `/smiles/${date}/term/${routeUrl.substring(routeUrl.lastIndexOf('/') + 1)}`;
    }

    composeUrlForTodayGivenUnit(timeUnit: string): string {
        const date = moment(environment.futureDate).format('YYYYMMDD');
        const routeUrl = this.router.url;
        const newUrl = `/smiles/${date}/${timeUnit}/${routeUrl.substring(routeUrl.lastIndexOf('/') + 1)}`;
        return newUrl;
    }

    setTimeFormatMonth(): void {
        const december = 11;
        if (this.timeframe === TimeFrameUnit.MONTH && this.timeFrameStart.month() === december && this.timeFrameEnd.month() === 0) {
            this.timeFormat = 'LLLL d, yyyy';
            this.timeFormatStartMobile = 'LLLL d, y';
        } else if (this.timeframe !== TimeFrameUnit.DAY) {
            this.timeFormat = 'LLL d';
            this.timeFormatStartMobile = 'LLLL d';
        }
    }

    setTimeNavigationRules(): void {
        const initialDate = moment.max(moment(this.vehicle.smEnrollDate), moment(this.vehicle.periodStart));
        const finalDate = moment(environment.futureDate);

        if (this.vehicle && this.timeFrameStart && this.timeFrameEnd) {
            if (this.timeframe === TimeFrameUnit.WEEK) {
                this.canIncrement = this.timeFrameEnd.clone().isBefore(finalDate, 'day'); // Allow navigation to partial weeks
                this.canDecrement = this.timeFrameStart.clone().isAfter(initialDate, 'day');
            } else if (this.timeframe === TimeFrameUnit.TERM) {
                if (this.user.getTermEnd().getTime() >= this.vehicle.periodEnd.getTime()) {
                    this.canIncrement = false;
                } else {
                    this.canIncrement = true;
                }

                if (this.user.getTermStart().getTime() < this.vehicle.periodStart.getTime()) {
                    this.canDecrement = false;
                } else {
                    this.canDecrement = true;
                }
            } else if (this.timeframe === TimeFrameUnit.DAY) {
                this.canIncrement = this.timeFrameStart.clone().add(1, <any> this.timeframe.valueOf()).isSameOrBefore(finalDate, 'day');
                this.canDecrement = this.timeFrameStart.clone().subtract(1, <any> this.timeframe.valueOf()).isSameOrAfter(initialDate, 'day');
            } else {
                this.canIncrement = this.timeFrameStart.clone().add(1, <any> this.timeframe.valueOf()).isSameOrBefore(finalDate, 'day');
                this.canDecrement = this.timeFrameEnd.clone().subtract(1, <any> this.timeframe.valueOf()).isSameOrAfter(initialDate, 'day');
            }
        }
    }

    get viewingPartialMonth(): boolean {
        return this.vehicle.enrolledMidTerm &&
            (this.vehicle.smEnrollDate >= this.timeFrameStart.toDate() && this.vehicle.smEnrollDate < this.timeFrameEnd.toDate());
    }

    setLabel(timeStartMoment: Moment, timeEndMoment: Moment): string {
        return `${timeStartMoment.format('MMMM Do')}-${timeEndMoment.format('MMMM Do')}`;
    }

    redirectToCurrentDay(timeUnit: string): void {
        if (moment(environment.futureDate).isBefore(moment(this.vehicle.periodEnd))) {
            const newRoute = this.composeUrlForTodayGivenUnit(timeUnit);
            this.router.navigateByUrl(newRoute, { replaceUrl: true });
        }
    }

    private shouldRedirectToCurrentDay(timeUnit: string, dateToCheck: string): boolean {
        const enrollDate = moment(this.vehicle.smEnrollDate);
        const today = moment(environment.futureDate);
        return timeUnit !== TimeFrameUnit.TERM &&
            (moment(dateToCheck).isAfter(today, 'day') || moment(dateToCheck).isBefore(enrollDate, 'day'));
    }
}
