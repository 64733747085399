export class ContactPreferences {
  sendWeeklyEmail = false;
  sendDailyNightDrivingEmail = false;
  sendDailyNightDrivingText = false;
  sendDailyIdleTimeEmail = false;
  sendDailyIdleTimeText = false;
  sendDailyBrakingAccelerationEmail = false;
  sendDailyBrakingAccelerationText = false;
  sendDailyBrakingEmail = false;
  sendDailyBrakingText = false;
  sendDailyAccelerationEmail = false;
  sendDailyAccelerationText = false;
  nickname = '';
  email = '';

  constructor() { }

  // TODO: Simplify this, don't require so many inputs.
  // See VehicleService.mapVehicleResponse().
  // eslint-disable-next-line max-params
  build = (email, sendDailyNightDrivingEmail, sendDailyNightDrivingText, sendDailyIdleTimeEmail,
    sendDailyIdleTimeText, sendDailyBrakingAccelerationEmail, sendDailyBrakingAccelerationText,
    sendDailyBrakingEmail, sendDailyBrakingText, sendDailyAccelerationEmail, sendDailyAccelerationText, nickname,
    sendWeeklyEmail): void => {
    this.sendWeeklyEmail = sendWeeklyEmail;
    this.sendDailyNightDrivingEmail = sendDailyNightDrivingEmail;
    this.sendDailyNightDrivingText = sendDailyNightDrivingText;
    this.sendDailyIdleTimeEmail = sendDailyIdleTimeEmail;
    this.sendDailyIdleTimeText = sendDailyIdleTimeText;
    this.sendDailyBrakingAccelerationEmail = sendDailyBrakingAccelerationEmail;
    this.sendDailyBrakingAccelerationText = sendDailyBrakingAccelerationText;
    this.sendDailyBrakingEmail = sendDailyBrakingEmail;
    this.sendDailyBrakingText = sendDailyBrakingText;
    this.sendDailyAccelerationEmail = sendDailyAccelerationEmail;
    this.sendDailyAccelerationText = sendDailyAccelerationText;
    this.nickname = nickname;
    this.email = email;
  };
}
