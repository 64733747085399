import { Component, OnInit, Input } from '@angular/core';
import { MobileProgram } from 'app/model/mobileprogram';
import { MobilePolicyInfoService } from 'app/service/mobile-policy-info.service';

@Component({
    selector: 'app-mobile-search-result',
    templateUrl: './mobile-search-result.component.html',
    styleUrls: ['./mobile-search-result.component.css']
})
export class MobileSearchResultComponent implements OnInit {
    @Input() mobilePolicyNumber: string;
    @Input() upperNeighborCount: number;
    mobileProgam: MobileProgram;

    constructor(private _mobilePolicyService: MobilePolicyInfoService) { }

    ngOnInit(): void {
        this._mobilePolicyService.getMobileProgramData(this.mobilePolicyNumber).subscribe((data) => {
            if (data) {
                this.mobileProgam = data;
            }
        });
    }
}
