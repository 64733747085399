import { timeout } from 'app/session-time-out/app-service-config';

/* eslint-disable camelcase */

export const environmentCommon = {
  envName: 'test',
  production: false,
  defaultRouteIsAdmin: false,
  enableAdminRoutes: false,
  apiKey: '8LzzxwScgpyhkZlNsso3iij5ApCe1vH5',
  newRelicScriptURL: '/assets/scripts/new-relic-SPA.js',
  cobrowseScriptURL: '/assets/scripts/genesys.cobrowse.test.js',
  tagMastersScriptURL: '//tags.nationwide.com/test/Bootstrap.js',
  sreApiURL: 'https://api-test.nwie.net/policymanagement/personallines/experience/v2/',
  apigeeExtHostnameURL: 'https://api-test.nwie.net/',
  targetLaunchDate: '20171001',
  myAccountUrl: 'https://st-myaccount.nationwide.nwie.net/myaccount/portfolio/RetrievePortfolio.action',
  billingDetailsUrl: 'https://servicingst.nwie.net/policyservicing/billingDetails.action',
  // eslint-disable-next-line no-magic-numbers
  futureDate: new Date(2023, 12, 1, 13, 13, 13, 13),

  // TODO: Point this to a non-prod url
  // eslint-disable-next-line sre/no-hardcoded-nw-url
  termsAndConditionsSmartRideURL: 'https://www.nationwide.com/smartridedevicetermsandconditions',
  // eslint-disable-next-line sre/no-hardcoded-nw-url
  termsAndConditionsSmartMilesURL: 'https://www.nationwide.com/smartmilestermsandconditions',
  // eslint-disable-next-line sre/no-hardcoded-nw-url
  termsAndConditionsConnectedCarURL: 'https://www.nationwide.com/smartridecctermsandconditions',
  // eslint-disable-next-line sre/no-hardcoded-nw-url
  termsAndConditionsSMConnectedCarURL: 'https://www.nationwide.com/personal/insurance/auto/discounts/smartmiles/sm-connected-terms.html',
  // eslint-disable-next-line sre/no-hardcoded-nw-url
  selfEnrollLearnMoreUrl: 'https://www.nationwide.com/personal/insurance/auto/discounts/smartride/',

  contentful: {
    space: '5tfs3g6zn870',
    environment: 'master',
    accessToken: 'yYKEGfmIHurSTAdUGQiMCq6EU0mJNQHeeGe5oweLt0k',
    contentfulDeliveryAccessToken: 'yYKEGfmIHurSTAdUGQiMCq6EU0mJNQHeeGe5oweLt0k',
    contentfulPreviewAccessToken: '3IgbbYMcdqJ9QpueQJaDpXBNwfJvgeGYmWEFOeOv8oE',
    localFileLocation: '/contentful/',
    localImageLocation: '/contentful/images/'
  },

  policyCenterShortName: 'pls04',
  eligibleEarlyRewardsDiscounts: ['MobileAppEarlyDiscount'],

  splunkProps: {
    token: '200CF725-8C9E-45F4-86AF-2CDA80998C6D',
    appName: 'DGS-ISU-SMILES'
  },

  CLIENT_LOGGING_API: {
    ENDPOINT: 'https://api-test.nwie.net/it-management/client-logging/v1/client-logs',
    PUSH_LENGTH: 1
  },

  LOGGING: {
    level: 'debug', /* debug, info, warn, err */
    logJSON: 'OBJECTS', /* can be true, false, 'OBJECTS', or 'SHORT' */
    logToConsole: true
  },

  login: {
    url: 'https://iam-st-cnp.apps.nwie.net/access/web/login-control.x?pageType=standard-login',
    continueUrl: 'https://iam-st-cnp.apps.nwie.net/access/web/login-continue.x',
    iamHostName: 'https://iam-st-cnp.apps.nwie.net',
    destination: 'st'
  },

  clientId: '8LzzxwScgpyhkZlNsso3iij5ApCe1vH5',

  authorize: {
    oauthEndpoint: 'https://api-test.nwie.net/security-processing/enterprise-user-auth/v2/authorize',
    client_id: '8LzzxwScgpyhkZlNsso3iij5ApCe1vH5',
    nonce: 'myNonce',
    redirectUri: 'https://www.sre.t.awspubliccloud.nationwide.com/',
    responseType: 'id_token token',
    scope: 'test',
    realm: 'member',
    loginTokenParam: '',
    tokenStorage: 'tokenDetails',
    idTokenStorage: 'id_token',
    authTimeout: 60,
    debug: true,
    excludedRoutes: ['/excluded', '/smartmiles/login', '/smartride/login', '/termsandconditions/form', '/smartride/login?message=expired&error=',
      '/smartmiles/login?message=expired&error=', '/smartmiles/login?message=loggedout&error=',
      '/smartride/login?message=loggedout&error=', '/not-found', '//index.html', '/index.html',
      '/smartmiles', '/smartride', '/smartmiles/login?message=&error=error', '/smartride/login?message=&error=error',
      '/smartride/login?message=&error=', '/smartmiles/login?message=&error=', '/smartrideAgent'],
    refreshThresholdSeconds: timeout.secondsToTimeoutWarning,
    loginTokenSessionStorageLocation: '',
    auth_method: 'ping',
    nwie_session_key: 'NWIESESSION'
  },

  ratedMileageService: {
    url: 'https://api-test.nwie.net/policy-management/telematics-program-enrollments/v1/policies/POLICY/vehicles/VIN/rated-mileages',
    apiKey: '8LzzxwScgpyhkZlNsso3iij5ApCe1vH5'
  },

    srmUserDetailsApiUrl: {
        url: 'https://api-test.nwie.net/policymanagement/personallines/experience/v2/mobile',
        apiKey: '8LzzxwScgpyhkZlNsso3iij5ApCe1vH5'
    },

  termsAndConditionAcceptance: {
    url: 'https://api-test.nwie.net/policymanagement/personallines/experience/v2/policies/POLICY/vehicles/VIN/acceptances'
  },

  srpProgramDetails: {
    url: 'https://api-test.nwie.net/policymanagement/personallines/experience/v2/policies/POLICY/program-details'
  },

  ebiEventService: {
    url: 'https://api-test.nwie.net/businessintelligence/enterprisebusinessintelligence/v1/log',
    apiKey: '8LzzxwScgpyhkZlNsso3iij5ApCe1vH5',
    eventId: '425081001'
  },

  personalLinesPolicyService: {
    url: 'https://api-test.nwie.net/policy-management/auto-policies/v1',
    xNwTargetEnv: 'pls04'
  },

  telematicsTransactionsService: {
    url: 'https://api-int-test.nwie.net/policy-management/telematics/v1/policies/POLICY/vehicles/VIN/estimated-premium' +
      '?estimatedPremiumStartDate=STARTDATE&estimatedPremiumEndDate=ENDDATE',
    apiKey: 'cnuBxAPjeO5tOJBWXkKAqVEm3SUadAGP'
  },

  telematicsService: {
    url: 'https://api-test.nwie.net/policy-management/telematics/v1',
    apiKey: '8LzzxwScgpyhkZlNsso3iij5ApCe1vH5'
  },
  apigeeEUAPingService: {
    url: 'https://api-test.nwie.net/security-processing/enterprise-user-auth/v2/authorize',
    apiKey: 'edArQOvJTbGjUQwoKmUsfsB7f7oAMvkN',
    redirect_uri: 'https://www.sre.t.awspubliccloud.nationwide.com/smartrideAdmin'
  },
  chatbot: {
    directlineBaseUrl: 'https://directline.botframework.com/v3/directline',
    secret: 'zyYyfoJSmQE.cwA.4ZI.fyOdcVpoaBqiyJjTtsrwIyPQydV1Fhnaw3AMZI6r41Y'
  },
  oauthAgentService: {
    agentResponseType: 'token',
    agentScope: 'openid',
    agentRealm: 'distribution-partner',
    agentAuthMethod: 'ping-racf-ia',
    agentIdentityMethod: 'ac',
    agentOauthEndPoint: 'https://api-test.nwie.net/security-processing/enterprise-user-auth/v2/authorize',
    agentRedirectUri: 'https://www.sre.t.awspubliccloud.nationwide.com?userRole=Agent',
    agentClientId: '8LzzxwScgpyhkZlNsso3iij5ApCe1vH5'
  },
  oauthRevocationService: {
    url: 'https://api-test.nwie.net/security-processing/enterprise-user-auth/v2/revoke',
    apiKey: '8LzzxwScgpyhkZlNsso3iij5ApCe1vH5'
  },
  useValidatorService: true,
  validatorUrl: 'https://validator-test.apps.nwie.net/validator/cached-responses/',
  applicationName: 'DGS-ISU-SMILES',
  // eslint-disable-next-line sre/no-hardcoded-nw-url
  pingLogOutUrl: 'https://identity-pt.nationwide.com/idp/startSLO.ping',

  policyPreferencesService: {
    url: 'https://api-test.nwie.net/customerrelationshipmanagement/preferencesmanagement/v2/customers',
    apiKey: '8LzzxwScgpyhkZlNsso3iij5ApCe1vH5'
  },

  ecifService: {
    url: 'https://api-test.nwie.net/customer-information-management/enterprise-customer/v1/customers/'
  },

  selfEnrollmentService: {
    experienceAutoServicing: 'policy-management/auto-policy-change/v1/policy-changes/',
    experienceAuto: 'policy-management/auto-policies/v1/policies/'
  },

  telematicsProgramEnrollmentsService: {
    url: 'https://api-test.nwie.net/policy-management/telematics-program-enrollments/v1/policies/POLICYNUMBER/final-discounts',
    apiKey: '8LzzxwScgpyhkZlNsso3iij5ApCe1vH5'
  }
};
