// eslint-disable-next-line @typescript-eslint/naming-convention
export const MaritalStatus = {
    type: 'edge.metadata.typeinfo.typelistinfo.dto.TypelistMetadataDTO',
    value: {
        codes: [
            {
                code: 'D',
                priority: 10,
                displayKey: 'typekey.MaritalStatus.D'
            },
            {
                code: 'M',
                priority: 30,
                displayKey: 'typekey.MaritalStatus.M'
            },
            {
                code: 'S',
                priority: 40,
                displayKey: 'typekey.MaritalStatus.S'
            },
            {
                code: 'P',
                priority: 50,
                displayKey: 'typekey.MaritalStatus.P'
            },
            {
                code: 'W',
                priority: 60,
                displayKey: 'typekey.MaritalStatus.W'
            }
        ],
        filters: [
            {
                name: 'INMaritalStatus_Ext',
                includedCodes: [
                    'D',
                    'M',
                    'S',
                    'P',
                    'W'
                ]
            },
            {
                name: 'PAQuickQuoteDriverDetailsMaritalStatus_Ext',
                includedCodes: [
                    'D',
                    'M',
                    'S',
                    'P',
                    'W'
                ]
            },
            {
                name: 'ActMartialStatus_Ext',
                includedCodes: [
                    'D',
                    'S',
                    'W'
                ]
            },
            {
                name: 'DriverAvgAccuracy_Ext',
                includedCodes: [
                    'D',
                    'S',
                    'P',
                    'W'
                ]
            },
            {
                name: 'ProdModelMartialStatusCheck_Ext',
                includedCodes: [
                    'D',
                    'S',
                    'P'
                ]
            },
            {
                name: 'ValidMaritalStatus_Ext',
                includedCodes: [
                    'D',
                    'M',
                    'S',
                    'P',
                    'W',
                    'C'
                ]
            }
        ],
        namedMetadata: []
    }
};
