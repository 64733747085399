import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { UserService } from 'app/service/user.service';
import { v4 as uuid } from 'uuid';
import { WindowRef } from 'app/service/window-ref';

@Injectable()
export class EbiEventService {
  private _window: any;
  private programType: string;

  constructor(
    public _http: HttpClient,
    public _user: UserService,
    private _windowRef: WindowRef
  ) {
    this._window = this._windowRef.nativeWindow;
  }

  assignProgramType(programType: string): void {
    this.programType = programType;
  }

  postEvent(eventName: string): void {
    const guid = uuid();
    const httpHeaders = new HttpHeaders()
      .set('Nw-App-Id', 'Smiles POC App')
      .set('X-Nw-Transaction-Id', guid)
      .set('Content-Type', 'application/json');
    const body = {
      eventId: environment.ebiEventService.eventId,
      sessionId: '9B611DF51EB31761F38228AED666A840',
      url: 'smart-miles',
      appCookie: 'sre',
      data: {
        ECN: this._user.getEcn(),
        FULLPOLICYNB: this._user.getPolicyNumber(),
        GUID: guid,
        IP_ADDRESS: '10.22.33.0/24',
        PREVIOUS_URL: this._window.location.href,
        TRIGGER_CODE: eventName,
        USER_ID: this._user.getUsername()
      }
    };
    this._http.post(
      `${environment.ebiEventService.url}?client_id=${environment.ebiEventService.apiKey}`,
      body, { headers: httpHeaders }
    ).subscribe();
  }

  postSrideEvent(eventId: string): void {
    if (eventId == null || eventId === 'undefined') {
      eventId = environment.ebiEventService.eventId;
    }
    const guid = uuid();
    const httpHeaders = new HttpHeaders()
      .set('Nw-App-Id', 'Sride POC App')
      .set('X-Nw-Transaction-Id', guid)
      .set('Content-Type', 'application/json');
    const body = {
      eventId,
      sessionId: '9B611DF51EB31761F38228AED666A840',
      url: 'smart-ride',
      appCookie: 'sre',
      data: {
        ECN: this._user.getEcn(),
        FULLPOLICYNB: this._user.getPolicyNumber(),
        GUID: guid,
        IP_ADDRESS: '10.22.33.0/24',
        JSESSION_ID: '123456',
        PREVIOUS_URL: this._window.location.href,
        PROGRAM_TYPE: this.programType,
        USER_ID: this._user.getUsername()
      }
    };
    this._http.post(
      `${environment.ebiEventService.url}?client_id=${environment.ebiEventService.apiKey}`,
      body, { headers: httpHeaders }).subscribe();
  }

  postTextingPrefEvent(eventName: string): void {
    const eventId = '425081002';
    const guid = uuid();
    const httpHeaders = new HttpHeaders()
      .set('Nw-App-Id', 'Sride POC App')
      .set('X-Nw-Transaction-Id', guid)
      .set('Content-Type', 'application/json');
    const body = {
      eventId,
      sessionId: '9B611DF51EB31761F38228AED666A840',
      url: 'smart-ride',
      appCookie: 'sre',
      data: {
        ECN: this._user.getEcn(),
        FULLPOLICYNB: this._user.getPolicyNumber(),
        GUID: guid,
        IP_ADDRESS: '10.22.33.0/24',
        TRIGGER_CODE: eventName
      }
    };
    this._http.post(
      `${environment.ebiEventService.url}?client_id=${environment.ebiEventService.apiKey}`,
      body, { headers: httpHeaders }).subscribe();
  }

  postSelfEnrollEvent(eventId: string): void {
    const guid = uuid();
    const httpHeaders = new HttpHeaders()
    .set('Nw-App-Id', 'Sride POC App')
    .set('X-Nw-Transaction-Id', guid)
    .set('Content-Type', 'application/json');
    const body = {
      eventId,
      sessionId: '9B611DF51EB31761F38228AED666A840',
      url: 'smart-ride',
      appCookie: 'sre',
      data: {
        ECN: this._user.getEcn(),
        EMAIL: this._user.getEmail(),
        FIRST_NAME: this._user.getFirstName(),
        FULLPOLICYNB: this._user.getPolicyNumber(),
        GUID: guid,
        IP_ADDRESS: '10.22.33.0/24',
        JSESSION_ID: '123456',
        LAST_NAME: this._user.getLastName(),
        PREVIOUS_URL: this._window.location.href,
        PROGRAM_TYPE: 'sride',
        STATE: this._user.getState(),
        USER_ID: this._user.getUsername()
      }
    };
    this._http.post(
      `${environment.ebiEventService.url}?client_id=${environment.ebiEventService.apiKey}`,
      body, { headers: httpHeaders }).subscribe();
  }
}
