import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Pipe({
  name: 'vehicleNicknameForDisplay'
})

@Injectable()
export class VehicleNicknameForDisplayPipe implements PipeTransform {
  private DEFAULT = 'Vehicle nickname';

  transform(value: any): any {
    if (value?.contactPreferences?.nickname) {
      let nickname = value.contactPreferences.nickname;
      nickname = nickname.trim();
      if (nickname) {
        return nickname;
      }
    }
    return this.DEFAULT;
  }
}
