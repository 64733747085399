/* eslint-disable no-console */

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from 'app/app.module';
import { environment } from 'environments/environment';
import Bolt from '@nationwide-bolt/bundle-core';

const cobrowseScript = document.createElement('script');
cobrowseScript.type = 'text/javascript';
cobrowseScript.src = environment.cobrowseScriptURL;
document.head.appendChild(cobrowseScript);

const tagMastersScript = document.createElement('script');
tagMastersScript.type = 'text/javascript';
tagMastersScript.src = environment.tagMastersScriptURL;
document.head.appendChild(tagMastersScript);

if (environment.production) {
    const newRelicScript = document.createElement('script');
    newRelicScript.type = 'text/javascript';
    newRelicScript.src = environment.newRelicScriptURL;
    document.head.appendChild(newRelicScript);

    enableProdMode();
}

Bolt.initialize().then(() => {
    platformBrowserDynamic()
      .bootstrapModule(AppModule)
      .catch( (err) => console.log(err));
  });

