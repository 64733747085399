import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'app/service/user.service';
import { Title } from '@angular/platform-browser';

@Injectable()
export class NavbarService {
    smartride = 'sride';
    deviceAlert = 'deviceAlert';
    smartmiles = 'smiles';
    nationwide = 'nationwide';
    links: boolean;
    logoType: string;
    logoutDisplay = true;

    constructor(
        public router: Router,
        public user: UserService,
        public titleService: Title
    ) {
        this.links = false;
        this.logoType = this.nationwide;
    }

    headerDisplay(isLoggedIn: boolean, navUrl: string): void {
        navUrl = this.stripQueryFromUrl(navUrl);
        if (isLoggedIn) {
            if (sessionStorage.getItem('isAdmin') === 'true') {
                this.logoutDisplay = false;
            }
            this.links = false;
            this.logoType = this.nationwide;
            if (navUrl.indexOf(this.smartmiles) !== -1 || navUrl.indexOf(this.deviceAlert) !== -1) {
                this.setTitle('SmartMiles');
                this.links = true;
                this.logoType = this.smartmiles;
            } else if (navUrl.match(/(.*)sre|smartride|sride(.*)/)) {
                this.setTitle('SmartRide');
                this.links = true;
                this.logoType = this.smartride;
            }
        } else {
            this.links = false;
            this.logoType = this.nationwide;
        }
    }

    stripQueryFromUrl(input: string): string {
        const separatorPosition = input.indexOf('?');
        if (separatorPosition < 0) {
 return input;
}
        return input.substr(0, separatorPosition);
    }

    redirect(location: string): void {
        if (location === this.nationwide) {
            this.manualRedirect('https://nationwide.com');
        } else {
            this.router.navigateByUrl(`/programSelect`);
        }
    }

    manualRedirect(href: string): void {
        document.location.href = href;
    }

    setTitle(newTitle: string): void {
        this.titleService.setTitle(newTitle);
    }
}
