import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PrintLabelService } from 'app/service/print-label.service';
import { EbiEventService } from 'app/service/ebi-event.service';
import { WindowRef } from 'app/service/window-ref';
import { NavbarService } from 'app/service/nav-bar.service';
import { UserService } from 'app/service/user.service';
import { LoggerService } from 'app/service/logger.service';

@Component({
  selector: 'app-print-label',
  templateUrl: './print-label.component.html',
  styleUrls: ['./print-label.component.css']
})

export class PrintLabelComponent implements OnInit {
  vendorIdCode: string;
  vin: string;
  private _window: Window;

  // eslint-disable-next-line max-params
  constructor(
    private route: ActivatedRoute,
    public router: Router,
    public _printLabel: PrintLabelService,
    public _ebiService: EbiEventService,
    private _windowRef: WindowRef,
    public _navBar: NavbarService,
    public _user: UserService,
    private logger: LoggerService
  ) {
    this._window = this._windowRef.nativeWindow;
  }

  ngOnInit(): void {
    this._user.selectedVin = this.route.snapshot.data.vehicle.vin;
    this._navBar.headerDisplay(this._user.isLoggedIn, this.router.url);
  }

  printLabel(): void {
    this.vendorIdCode = sessionStorage.getItem('vidcode');
    if (!this.vendorIdCode) {
      return;
    }
    this._ebiService.postSrideEvent('425080108');
    this._printLabel.printLabel(this.vendorIdCode).subscribe((respJson) => {
      if (respJson.error) {
        this.logger.error(respJson.error);
        this.router.navigateByUrl('/sride/systemError');
      } else {
        const file = new Blob([respJson], { type: 'application/pdf' });
        // eslint-disable-next-line no-extra-parens
        if ((<any> this._window.navigator)?.msSaveOrOpenBlob) {
          // eslint-disable-next-line no-extra-parens
          (<any> this._window.navigator).msSaveOrOpenBlob(file, 'SmartRide-Device-Return-Label.pdf');
        } else {
          const fileURL = URL.createObjectURL(file);
          this._window.open(fileURL, '_blank', '');
        }
      }
    });
  }
}
