import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APICommonService } from '@nationwide/api-common-service';
import { ServiceErrorHandler } from 'app/utility/service-error-handler';
import { UserService } from './user.service';
import { environment } from 'environments/environment';
import { Observable, catchError, of } from 'rxjs';
import { PolicyNumberFormatService } from './policy-number-format.service';
import { SrpProgramDetails } from 'app/model/telematics/srpProgramDetails';

@Injectable({
  providedIn: 'root'
})
export class TelematicsExperienceProgramDataService {
  policyNumber;

  // eslint-disable-next-line max-params
  constructor(
    public _http: HttpClient,
    public _errorHandler: ServiceErrorHandler,
    private apiCommonService: APICommonService,
    public user: UserService
  ) { }

  getSrpMobileProgramDetails(policyNumber: string): Observable<SrpProgramDetails> {
    const putHeader = false;
    const headers = this.getHeaders(putHeader);
    const formattedPolicyNumber = this.getFormattedPolicyNumber(policyNumber);
    const url = environment.srpProgramDetails.url
        .replace(/POLICY/, formattedPolicyNumber);
    return this._http.get(url, { headers })
        .pipe(
            catchError((error) => {
                this._errorHandler.handleError('acceptanceStatus');
                return of(error);
            })
        );
  }

  getHeaders(putHeader: boolean): HttpHeaders {
    let headers = new HttpHeaders();
    headers = headers.append('client_id', environment.apiKey);
    headers = headers.append('X-NW-Message-ID', this.apiCommonService.generateTransactionId());
    if (putHeader) {
      headers = headers.append('Content-Type', 'application/json');
    }
    return headers;
  }

  getFormattedPolicyNumber(policy: string): string {
    return PolicyNumberFormatService.safeUriEncodePolicyNumber(policy);
  }
}
