import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserService } from 'app/service/user.service';

@Injectable()
export class AlertGuard implements CanActivate {
  constructor(private _user: UserService, private router: Router) { }

  canActivate(): boolean {
    if (!this._user.alertType) {
      return true;
    }
    this.router.navigateByUrl(`smiles/deviceAlert/${this._user.alertType}`);
    return false;
  }
}
