import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cents'
})
export class CentsPipe implements PipeTransform {
  transform(value: number, args?: any): string {
    let output: string;
    const roundedByThousandth = 1000;
    const roundedByHundredth = 100;
    const decimalPlacesForThousandth = 3;
    if (value < 0) {
      value = -value;
    }
    if (args === decimalPlacesForThousandth) {
      value = Math.floor(value * roundedByThousandth);
      output = (value % roundedByThousandth).toString();
    } else {
      value = Math.floor(value * roundedByHundredth);
      output = (value % roundedByHundredth).toString();
    }
    if (output.length < 2) {
      output = `0${output}`;
    }
    return output;
  }
}
