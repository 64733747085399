import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ServiceErrorHandler } from '../utility/service-error-handler';
import { APICommonService } from '@nationwide/api-common-service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { PolicyNumberFormatService } from './policy-number-format.service';
import { TermAndConditionStatus } from 'app/model/term-and-condition-status';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})
export class TermAndConditionStatusService {
    policyNumber;
    connectedcarProgramType;

    // eslint-disable-next-line max-params
    constructor(
        public _http: HttpClient,
        public _errorHandler: ServiceErrorHandler,
        private apiCommonService: APICommonService,
        public user: UserService) {
        this.policyNumber = decodeURIComponent(sessionStorage.getItem('selectedPolicy'));
    }

    getConnectedCarConsentStatus(vin: string, type: string): Observable<TermAndConditionStatus> {
        if (!this.policyNumber) {
            this.policyNumber = this.user.getPolicyNumber();
        }
        const putHeader = false;
        const headers = this.getHeaders(putHeader);
        const params = this.getParams(type);
        const formattedPolicyNumber = this.getFormattedPolicyNumber(this.policyNumber);
        const url = environment.termsAndConditionAcceptance.url
            .replace(/POLICY/, formattedPolicyNumber)
            .replace(/VIN/, vin);
        return this._http.get(url, { params, headers})
            .pipe(
                catchError((error) => {
                    this._errorHandler.handleError('acceptanceStatus');
                    return of(error);
                })
            );
      }

      getConnectedCarConsentStatusWithPolicy(vin: string, type: string, policy: string): Observable<TermAndConditionStatus> {
        const putHeader = false;
        const headers = this.getHeaders(putHeader);
        const params = this.getParams(type);
        const formattedPolicyNumber = this.getFormattedPolicyNumber(policy);
        const url = environment.termsAndConditionAcceptance.url
            .replace(/POLICY/, formattedPolicyNumber)
            .replace(/VIN/, vin);
        return this._http.get(url, { params, headers})
            .pipe(
                catchError((error) => {
                    this._errorHandler.handleError('acceptanceStatus');
                    return of(error);
                })
            );
      }

    updateConnectedCarAcceptanceStatus(vin: string, type: string): Observable<TermAndConditionStatus> {
        if (!this.policyNumber) {
            this.policyNumber = this.user.getPolicyNumber();
        }
        const putHeader = true;
        const params = this.getParams(type);
        const headers = this.getHeaders(putHeader);
        const formattedPolicyNumber = this.getFormattedPolicyNumber(this.policyNumber);
        const url = environment.termsAndConditionAcceptance.url
            .replace(/POLICY/, formattedPolicyNumber)
            .replace(/VIN/, vin);
        return this._http.put(url, {
            statusCode: 'Y',
            statusType: 'TermsAndConditions'
        }, { params, headers })
            .pipe(
                catchError((error) => {
                    this._errorHandler.handleError('acceptanceStatus');
                    return of(error);
                })
            );
    }

    getHeaders(putHeader: boolean): HttpHeaders {
      let headers = new HttpHeaders();
      headers = headers.append('client_id', environment.apiKey);
      headers = headers.append('X-NW-Message-ID', this.apiCommonService.generateTransactionId());
      if (putHeader) {
        headers = headers.append('Content-Type', 'application/json');
      }
      return headers;
    }

    getParams(type): HttpParams {
      const programType = this.getProgramType(type);
      return new HttpParams()
        .set('program_type', programType);
    }

    getProgramType(type): string {
        return type === 'smiles' ? 'SmartMiles' : 'SmartRide';
    }

    getFormattedPolicyNumber(policy: string): string {
      return PolicyNumberFormatService.safeUriEncodePolicyNumber(policy);
    }
}
