import { Component, Input, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-wrapper',
  templateUrl: './modal-wrapper.component.html'
})
export class ModalWrapperComponent {
  @Input() id;
  @Input() class; // wait-overlay
  @Input() modalTitle;
  @Input() hideClose = true;
  @Input() primaryLabel;
  @Input() secondaryLabel;
  @Input() image; // URL
  @Output() popupAccept = new EventEmitter();

  css;
  primaryFocus = false;
  secondaryFocus = false;
  modalWrapperComponent;

  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
  ) {
    this.modalWrapperComponent = this;
  }

  popupTermsAndConditions(): void {
    if (this.primaryLabel === 'Accept') {
      this.popupAccept.emit('Terms and conditions accepted');
      sessionStorage.removeItem('selectedPolicy');
        if (sessionStorage.getItem('login') === 'true') {
            sessionStorage.setItem('login', 'false');
            sessionStorage.clear();
            this.router.navigateByUrl('/smartride/login');
        }
    }
  }

  trapFocus(event, button): void {
    const tabKeyCode = 9;
    if (button === 'primary') {
      this.primaryFocus = false;
      if (event.keyCode === tabKeyCode) {
        event.preventDefault();
        this.secondaryFocus = true;
      }
    } else if (button === 'secondary') {
      this.secondaryFocus = false;
      if (event.shiftKey && event.keyCode === tabKeyCode) {
        event.preventDefault();
        this.primaryFocus = true;
      }
    } else if (button === 'closed') {
      this.secondaryFocus = false;
      if (event.shiftKey && event.keyCode === tabKeyCode) {
        event.preventDefault();
        this.primaryFocus = true;
      }
    }
  }
}
