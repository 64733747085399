import { Component, Input, Injectable } from '@angular/core';
import { EbiEventService } from 'app/service/ebi-event.service';
import { UserService } from 'app/service/user.service';

@Component({
  selector: 'app-print-label-alert',
  templateUrl: './print-label-alert.component.html',
  styleUrls: ['./print-label-alert.component.css']
})

@Injectable()
export class PrintLabelAlertComponent {
  @Input() vin: string;

  constructor(
    private _ebiEventService: EbiEventService,
    private _user: UserService
  ) { }

  hidePrintLabel(): void {
    this._user.setShowPrintLabel(this.vin, false);
  }

  logEbi(): void {
    this._ebiEventService.postSrideEvent('425080107');
  }
}
