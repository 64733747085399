import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { UserService } from 'app/service/user.service';
import { Vehicle } from 'app/model/vehicle';
import { Observable, throwError } from 'rxjs';
import { BrandingService } from 'app/service/branding.service';
import { ProgramSelectService } from 'app/service/program-select.service';

@Injectable()
export class SmartMilesVehicleResolver implements Resolve<Vehicle> {
  constructor(
    private _user: UserService,
    private router: Router,
    private programSelectService: ProgramSelectService
  ) { }

  resolve(route: ActivatedRouteSnapshot): Observable<Vehicle> {
    const branding = BrandingService.getBranding() === 'smartmiles' ? 'smiles' : 'sride';
    if (route.parent.url[0] && branding !== route.parent.url[0].path) {
      this.router.navigateByUrl(`/not-found`);
      return throwError('app branding mismatch');
    }
    const vin = route.params['vin'] || this._user.selectedVin;
    const programType = this._user.programType;
    this._user.selectedVin = vin;
    if (!vin) {
      this.router.navigateByUrl(`/programSelect`);
      return throwError('vin not provided');
    }

    return this.programSelectService.fetchProgramByVin(vin, programType);
  }
}
