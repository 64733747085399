import { Injectable } from '@angular/core';
import { ActivatedRoute, CanActivate, Router } from '@angular/router';
import { WindowRef } from 'app/service/window-ref';
import { environment } from 'environments/environment';
import { PolicyNumberFormatService } from 'app/service/policy-number-format.service';

@Injectable()
export class AgentGuard implements CanActivate {
  userRole: string;
  agentSelectedPolicy: string;
  agentAccessToken: any;
  private _window: any;

  constructor(
    private _windowRef: WindowRef,
    public router: Router,
    public route: ActivatedRoute
  ) {
    if (sessionStorage.getItem('agentAccessToken')) {
      this.agentAccessToken = sessionStorage.getItem('agentAccessToken');
    }
    if (window.location.href.includes('policy')) {
      this.agentSelectedPolicy = window.location.href.split('policy=')[1];
      this.agentSelectedPolicy = PolicyNumberFormatService.formatPolicyNumber(this.agentSelectedPolicy);
      sessionStorage.setItem('agentSelectedPolicy', this.agentSelectedPolicy);
    }

    if (window.location.href.includes('access_token')) {
      this.agentAccessToken = window.location.href.split('access_token=')[1];
      this.agentAccessToken = this.agentAccessToken.split('&')[0];
      sessionStorage.setItem('agentAccessToken', this.agentAccessToken);
      sessionStorage.setItem('tokenDetails', this.agentAccessToken);
    }
  }

  canActivate(): boolean {
    this._window = this._windowRef.nativeWindow;
    if (this.agentAccessToken) {
      return true;
    } else if (sessionStorage.getItem('isAgent') && !this.agentAccessToken) {
      this.redirectToError();
    } else if (!this.agentAccessToken) {
        sessionStorage.setItem('isAgent', 'true');
        const eauUrl = this.buildEauUrl();
        this._window.location.href = eauUrl;
    }
  }

  redirectToError(): void {
    this.router.navigate(['/nw/systemError']);
  }

  buildEauUrl(): any {
    return `${environment.oauthAgentService.agentOauthEndPoint}?response_type=${environment.oauthAgentService.agentResponseType}&state=no&client_id=${environment.oauthAgentService.agentClientId}&nonce=${environment.authorize.nonce}&scope=${environment.oauthAgentService.agentScope}&realm=${environment.oauthAgentService.agentRealm}&auth_method=${environment.oauthAgentService.agentAuthMethod}&identity_method=${environment.oauthAgentService.agentIdentityMethod}&redirect_uri=${environment.oauthAgentService.agentRedirectUri}`;
  }
}
