import * as moment from 'moment';

export class Timeline {
  enrollDate: Date;
  installDate: Date;
  completionDate: Date;
  finalDiscountDate: Date;
  shipDate: Date;
  private _deviceStatus: string;

  // eslint-disable-next-line max-params
  constructor(enrollDate: string, installDate: string, completionDate: string, finalDiscountDate: string, shipDate: string) {
    this.enrollDate = this.receiveDate(enrollDate);
    this.installDate = this.receiveDate(installDate);
    this.completionDate = this.receiveDate(completionDate);
    this.finalDiscountDate = this.receiveDate(finalDiscountDate);
    this.shipDate = this.receiveDate(shipDate);
  }

  receiveDate(date: string): Date {
    if (!date) {
      return null;
    }
    return moment(date, 'YYYY-MM-DD').toDate();
  }

  get deviceStatus(): string {
    const today = new Date();
    if (this.installDate === undefined || this.installDate === null) {
      this._deviceStatus = 'enroll';
    } else if (today >= this.finalDiscountDate) {
      this._deviceStatus = 'final';
    } else if (this.completionDate <= today && today < this.finalDiscountDate) {
      this._deviceStatus = 'complete';
    } else if (this.installDate <= today && today < this.completionDate) {
      this._deviceStatus = 'install';
    }
    return this._deviceStatus;
  }
}
