import { NgModule } from '@angular/core';
import { NavigationEnd, Router, RouterModule, Route, Routes } from '@angular/router';
import { VehicleSummaryComponent } from 'app/components/vehicle-summary/vehicle-summary.component';
import { DailySummaryComponent } from 'app/components/vehicle-summary/daily/daily-summary.component';
import { WeeklySummaryComponent } from 'app/components/vehicle-summary/weekly/weekly-summary.component';
import { MonthlySummaryComponent } from 'app/components/vehicle-summary/monthly/monthly-summary.component';
import { TotalSummaryComponent } from 'app/components/vehicle-summary/total/total-summary.component';
import { LoginComponent } from 'app/components/login/login.component';
import { ProgramSelectComponent } from 'app/components/program-select/program-select.component';
import { PreferencesComponent } from 'app/components/preferences/preferences.component';
import { HowItWorksComponent } from 'app/components/how-it-works/how-it-works.component';
import { HelpComponent } from 'app/components/help/help.component';
import { NavigationService } from './service/navigation.service';
import { MonthlyDetailsComponent } from 'app/components/vehicle-summary/monthly-details/monthly-details.component';
import { DeviceAlertComponent } from 'app/components/device-alert/device-alert.component';
import { AlertGuard } from 'app/router-guards/alert-guard';
import { AdminComponent } from 'app/components/admin/admin.component';
import { AdminGuard } from 'app/router-guards/admin-guard.service';
import { AgentGuard } from './router-guards/agent-guard.service';
import { AdminSrpComponent } from 'app/components/admin/admin-srp/admin-srp.component';
import { MobileDeviceComponent } from 'app/components/mobile-device/mobile-device.component';
import { TokenGuard } from 'app/router-guards/token-guard';
import { SingleVehicleResolver } from 'app/router-guards/single-vehicle-resolver';
import { PrintLabelComponent } from './components/print-label/print-label.component';
import { SystemErrorComponent } from './components/system-error/system-error.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { TokenCaptureComponent } from 'app/components/token-capture/token-capture.component';
import { SreAdminReloadComponent } from './components/admin/sre-admin-reload/sre-admin-reload.component';
import { WelcomePageComponent } from 'app/components/welcome-page/welcome-page.component';
import { SmartMilesVehicleResolver } from './router-guards/smiles-vehicle-resolver';
import { SreSummaryComponent } from 'app/components/sre-summary/sre-summary.component';
import { SreDetailsComponent } from 'app/components/sre-details/sre-details.component';
import { SreSummaryContentResolver } from 'app/router-guards/sre-summary-content-resolver';
import { SreDetailsContentResolver } from 'app/router-guards/sre-details-content-resolver';
import { DailyContentResolver } from 'app/router-guards/daily-content-resolver';
import { SreDailySummaryComponent } from 'app/components/sre-daily-summary/sre-daily-summary.component';
import { environment } from 'environments/environment';
import { CanDeactivateGuardService } from './service/can-deactivate-guard.service';
import { SelfEnrollmentComponent } from 'app/components/self-enrollment/self-enrollment.component';
import { SelfEnrollmentReviewComponent } from './components/self-enrollment/review/self-enrollment-review.component';
import { SelfEnrollmentConfirmationComponent } from './components/self-enrollment/confirmation/self-enrollment-confirmation.component';
import { DeviceTimelineDetailsComponent } from 'app/components/miles-bar/device-timeline-details/device-timeline-details.component';
import { SmartRideDiscountTrendComponent } from 'app/components/vehicle-summary/smart-ride-discount-trend/smart-ride-discount-trend.component';
import { SmartRideDiscountTrendContentResolver } from 'app/components/vehicle-summary/smart-ride-discount-trend/smart-ride-discount-trend-content-resolver';
import { SelfEnrollmentGuardService } from './service/self-enrollment-guard.service';
import { QuickIdZipComponent } from './light-auth/quick-id-zip/quick-id-zip.component';
import { QuickIDMainComponent } from './light-auth/quick-id-main/quick-id-main.component';
import { MobileNumberUpdateCardComponent } from 'app/components/mobile-number-update-card/mobile-number-update-card.component';

const dummyRoute: Route = {
  path: '~',
  redirectTo: '',
  pathMatch: 'full'
};

const routes: Routes = [
  {
    path: '',
    redirectTo: environment.defaultRouteIsAdmin ? 'smartrideAdmin' : 'smartride/login',
    pathMatch: 'full'
  },
  {
    path: 'selfEnrollment/review',
    component: SelfEnrollmentReviewComponent,
    canActivate: [TokenGuard, SelfEnrollmentGuardService],
    data: {
      branding: 'smartride'
    }
  },
  {
    path: 'selfEnrollment/confirmation',
    component: SelfEnrollmentConfirmationComponent,
    canActivate: [TokenGuard, SelfEnrollmentGuardService],
    data: {
      branding: 'smartride'
    }
  },
  {
    path: 'selfEnrollment',
    component: SelfEnrollmentComponent,
    canActivate: [TokenGuard],
    data: {
      branding: 'smartride'
    }
  },
  environment.enableAdminRoutes ? {
    path: 'smartrideAgent',
    canActivate: [AgentGuard],
    component: AdminComponent
  } : dummyRoute,
  environment.enableAdminRoutes ? {
    path: 'smartrideAdmin',
    canActivate: [AdminGuard],
    component: AdminComponent
  } : dummyRoute,
  environment.enableAdminRoutes ? {
    path: 'smartrideAdmin/srp',
    canActivate: [AdminGuard],
    component: AdminSrpComponent
  } : dummyRoute,
  environment.enableAdminRoutes ? {
    path: 'smartrideAdminReload',
    component: SreAdminReloadComponent
  } : dummyRoute,

  {
    path: 'nw/systemError',
    component: SystemErrorComponent
  },
  {
    path: 'smartmiles/login',
    component: LoginComponent,
    data: {
      branding: 'smartmiles'
    }
  },
  {
    path: 'smartmiles/token',
    component: TokenCaptureComponent,
    data: {
      branding: 'smartmiles'
    }
  },
  {
    path: 'smartmiles/programSelect/token',
    component: TokenCaptureComponent,
    data: {
      branding: 'smartmiles'
    }
  },
  {
    path: 'smartride/login',
    component: LoginComponent,
    data: {
      branding: 'smartride'
    }
  },
  {
    path: 'not-found',
    component: NotFoundComponent
  },
  {
    path: 'sride/welcomePage',
    component: WelcomePageComponent,
    resolve: {
      vehicle: SingleVehicleResolver
    }
  },
  {
    path: 'programSelect',
    component: ProgramSelectComponent,
    canActivate: [TokenGuard]
  },
  {
    path: 'mobileNumberUpdate',
    component: MobileNumberUpdateCardComponent,
    canActivate: [TokenGuard]
  },
  {
    path: 'sridecc/termsandconditionsacceptance',
    component: QuickIdZipComponent
  },
  {
    path: 'smilescc/termsandconditionsacceptance',
    component: QuickIdZipComponent
  },
  {
    path: 'smartride/token',
    component: TokenCaptureComponent,
    data: {
      branding: 'smartride'
    }
  },
  {
    path: 'programSelect/token',
    component: TokenCaptureComponent,
    data: {
      branding: 'smartmiles'
    }
  },
  {
    // path: 'mobileUsers/:policyNumber',
    path: 'mobileUsers/:policyNumber',
    component: MobileDeviceComponent,
    canActivate: [TokenGuard]
  },
  {
    path: 'preferences/smiles',
    component: PreferencesComponent,
    canActivate: [TokenGuard],
    canDeactivate: [CanDeactivateGuardService],
    resolve: {
      vehicle: SmartMilesVehicleResolver
    }
  },
  {
    path: 'preferences/sride',
    component: PreferencesComponent,
    canActivate: [TokenGuard],
    canDeactivate: [CanDeactivateGuardService],
    resolve: {
      vehicle: SingleVehicleResolver
    }
  },
  {
    path: 'sride/systemError',
    component: SystemErrorComponent
  },
  {
    path: 'howItWorks/sride',
    component: HowItWorksComponent,
    resolve: {
      vehicle: SingleVehicleResolver
    }
  },
  {
    path: 'howItWorks/smiles',
    component: HowItWorksComponent,
    canActivate: [TokenGuard],
    resolve: {
      vehicle: SmartMilesVehicleResolver
    }
  },
  {
    path: 'help/sride',
    component: HelpComponent,
    canActivate: [TokenGuard],
    resolve: {
      vehicle: SingleVehicleResolver
    }
  },
  {
    path: 'help/smiles',
    component: HelpComponent,
    canActivate: [TokenGuard],
    resolve: {
      vehicle: SmartMilesVehicleResolver
    }
  },
  {
    path: 'sride/printLabel',
    component: PrintLabelComponent,
    canActivate: [TokenGuard]
  },
  {
    path: 'smiles/deviceAlert/:alertType',
    component: DeviceAlertComponent,
    canActivate: [TokenGuard],
    resolve: {
      vehicle: SmartMilesVehicleResolver
    }
  },

  {
    path: 'sride/day/:date',
    component: SreDailySummaryComponent,
    canActivate: [TokenGuard],
    resolve: {
      content: DailyContentResolver
    }
  },
  {
    path: 'sride/summary/day/:date',
    redirectTo: 'sride/day/:date'
  },
  {
    path: 'sride/details/:factorId/day/:date',
    redirectTo: 'sride/day/:date'
  },
  {
    path: 'sride/discountTrend/day/:date',
    redirectTo: 'sride/day/:date'
  },
  {
    path: 'sride/discountTrend/:periodUnit/:date',
    component: SmartRideDiscountTrendComponent,
    canActivate: [TokenGuard],
    resolve: {
      content: SmartRideDiscountTrendContentResolver
    }
  },
  {
    path: 'sride/summary/:periodUnit/:periodEnd', // TODO Use "smartride", not "sride"
    component: SreSummaryComponent,
    canActivate: [TokenGuard],
    resolve: {
      content: SreSummaryContentResolver
    }
  },
  {
    path: 'sride/deviceStatusTimeline/:returnPeriod/:returnDate',
    component: DeviceTimelineDetailsComponent,
    resolve: {
      vehicle: SingleVehicleResolver
    }
  },
  {
    path: 'sride/details/:factorId/:periodUnit/:periodEnd',
    component: SreDetailsComponent,
    canActivate: [TokenGuard],
    resolve: {
      content: SreDetailsContentResolver
    }
  },
  {
    path: 'sride/:periodUnit/:periodEnd',
    redirectTo: 'sride/summary/:periodUnit/:periodEnd'
  },
  {
    path: 'smiles/:amount/:unit',
    component: VehicleSummaryComponent,
    canActivate: [TokenGuard],
    canActivateChild: [TokenGuard],
    resolve: {
      vehicle: SmartMilesVehicleResolver
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'month'
      },
      {
        path: 'dailySummary',
        component: DailySummaryComponent,
        resolve: {
          vehicle: SmartMilesVehicleResolver
        }
      },
      {
        path: 'weeklySummary',
        component: WeeklySummaryComponent,
        resolve: {
          vehicle: SmartMilesVehicleResolver
        }
      },
      {
        path: 'monthlySummary',
        component: MonthlySummaryComponent,
        canActivate: [AlertGuard],
        resolve: {
          vehicle: SmartMilesVehicleResolver
        }
      },
      {
        path: 'monthlySummaryDetails',
        component: MonthlyDetailsComponent,
        resolve: {
          vehicle: SmartMilesVehicleResolver
        }
      },
      {
        path: 'totalSummary',
        component: TotalSummaryComponent,
        resolve: {
          vehicle: SmartMilesVehicleResolver
        }
      }
    ]
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    useHash: false,
    scrollPositionRestoration: 'enabled',
    paramsInheritanceStrategy: 'always',
    relativeLinkResolution: 'legacy'
})
  ],
  exports: [
    RouterModule
  ],
  providers: [
    CanDeactivateGuardService
  ]
})
export class AppRoutingModule {
  constructor(
    public router: Router,
    public navigation: NavigationService
  ) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        navigation.navigated(event.url);
      }
    });
  }
}
