import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Vehicle } from '../model/vehicle';
import { environment } from 'environments/environment';
import { ServiceErrorHandler } from '../utility/service-error-handler';
import { Observable, of } from 'rxjs';
import { APICommonService } from '@nationwide/api-common-service';

@Injectable()
export class SaveVehiclePrefService {
  constructor(
    private _http: HttpClient,
    private _error: ServiceErrorHandler,
    private apiCommonService: APICommonService
  ) { }

  // Caller must subscribe, even if you don't care about the result.
  setVehiclePref(vehicle: Vehicle, firstLogin?): Observable<any> {
    const vehiclesURL = `${environment.apigeeExtHostnameURL
      }policymanagement/personallines/experience/v2/preferences/${vehicle.vid
      }?policy=${sessionStorage.getItem('selectedPolicy')}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // eslint-disable-next-line camelcase
        client_id: environment.apiKey,
        'X-NW-Message-ID': this.apiCommonService.generateTransactionId()
      })
    };

    const requestBody = {
      email: vehicle.contactPreferences.email,
      nickname: vehicle.name,
      weeklyEmail: vehicle.contactPreferences.sendWeeklyEmail,
      daily: {
        brakingAcceleration: {
          email: vehicle.contactPreferences.sendDailyBrakingAccelerationEmail,
          text: vehicle.contactPreferences.sendDailyBrakingAccelerationText
        },
        acceleration: {
          email: vehicle.contactPreferences.sendDailyAccelerationEmail,
          text: vehicle.contactPreferences.sendDailyAccelerationText
        },
        braking: {
          email: vehicle.contactPreferences.sendDailyBrakingEmail,
          text: vehicle.contactPreferences.sendDailyBrakingText
        },
        idle: {
          email: vehicle.contactPreferences.sendDailyIdleTimeEmail,
          text: vehicle.contactPreferences.sendDailyIdleTimeText
        },
        night: {
          email: vehicle.contactPreferences.sendDailyNightDrivingEmail,
          text: vehicle.contactPreferences.sendDailyNightDrivingText
        }
      },
      firstLogin
    };

    return this._http.put(vehiclesURL, requestBody, httpOptions)
      .pipe(
        map((res) => res),
        catchError((error) => {
          this._error.handleError('setVehiclePref');
          return of(error);
        })
      );
  }
}
