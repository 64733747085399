/* eslint-disable @typescript-eslint/naming-convention */
import { AddressDTO } from './addressDTO';
import { AdditionalInterestDTO } from './additionalInterestDTO';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const VehicleDTO = {
    AdditionalInterests: {
        name: 'AdditionalInterests',
        class: 'com.nationwide.edge.capabilities.policychange.lob.personalauto.draft.dto.NWAdditionalInterestDTO',
        required: false,
        ...AdditionalInterestDTO
    },
    PrimaryUse: {
        name: 'PrimaryUse',
        class: 'typekey.VehiclePrimaryUse_Ext',
        required: true,
        visibilityCondition: '\'auto\' === form.value[\'VehicleType\']',
        filterCategories: [
            'form.value[\'VehicleType\']'
        ]
    },
    IsLivingOnAFarm: {
        name: 'IsLivingOnAFarm',
        type: 'Boolean',
        required: true,
        visibilityCondition: '\'Farm\' === form.value[\'PrimaryUse\']'
    },
    CommutingMiles: {
        name: 'CommutingMiles',
        type: 'Integer',
        required: true,
        visibilityCondition: '(\'Work\' === form.value[\'PrimaryUse\'] && (policyNumber true === form.value[\'CommutingMiles\']))'
    },
    IsPurchasedNew: {
        name: 'IsPurchasedNew',
        type: 'Boolean',
        required: true,
        visibilityCondition: '(\'auto\' === form.value[\'VehicleType\'] && (policyEffectiveDate  > form.value[\'Year\']) && (policyEffectiveDate  < form.value[\'Year\']))'
    },
    CalculatedMilesOneWay: {
        name: 'CalculatedMilesOneWay',
        type: 'Integer',
        required: false
    },
    Model: {
        name: 'Model',
        type: 'String',
        required: true,
        maxLength: 40,
        minLength: 1,
        visibilityCondition: '\'auto\' === form.value[\'VehicleType\']'
    },
    VinOverrideReason: {
        name: 'VinOverrideReason',
        class: 'typekey.VINOverrideReason_Ext',
        required: true,
        visibilityCondition: '\'true\' === form.value[\'IsVinOverride\']',
        minAmount: 'VINNotFound',
        maxAmount: 'VINNotFound'
    },
    PurchasedNewDate: {
        name: 'PurchasedNewDate',
        type: 'Date',
        required: true,
        visibilityCondition: '\'true\' === form.value[\'IsPurchasedNew\']'
    },
    NWVehicleNumber: {
        name: 'NWVehicleNumber',
        type: 'Integer',
        required: true,
        visibilityCondition: '\'\' === form.value[\'TempID\']'
    },
    Make: {
        name: 'Make',
        type: 'String',
        required: true,
        maxLength: 4,
        minLength: 3
    },
    TempID: {
        name: 'TempID',
        type: 'Integer',
        required: true,
        visibilityCondition: '\'\' === form.value[\'PublicID\']'
    },
    FixedId: {
        name: 'FixedId',
        type: 'Long',
        required: true,
        visibilityCondition: '\'\' === form.value[\'TempID\']'
    },
    BodyStyle: {
        name: 'BodyStyle',
        type: 'String',
        required: true,
        visibilityCondition: '(\'auto\' === form.value[\'VehicleType\'] && \'\' !== form.value[\'TempID\'] && \'true\' === form.value[\'IsVinOverride\'])'
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    IsVehRegInPolState_Ext: {
        name: 'IsVehRegInPolState_Ext',
        type: 'Boolean',
        required: false
    },
    AntiTheftDevice: {
        name: 'AntiTheftDevice',
        class: 'typekey.AntiTheftType',
        required: false
    },
    IsAnyRegOwnerAPolicyDriver: {
        name: 'IsAnyRegOwnerAPolicyDriver',
        type: 'Boolean',
        required: true,
        visibilityCondition: '(policyNumber true === form.value[\'IsAnyRegOwnerAPolicyDriver\'])'
    },
    AddlCustomizationAmnt: {
        name: 'AddlCustomizationAmnt',
        type: 'Integer',
        required: false,
        minAmount: 0,
        maxAmount: 99999
    },
    Updated: {
        name: 'Updated',
        type: 'Boolean',
        required: true,
        minAmount: true,
        maxAmount: true
    },
    Modifiers: {
        name: 'Modifiers',
        class: 'com.nationwide.edge.capabilities.policychange.lob.personalauto.draft.dto.NWModifierDTO',
        AntiTheftDevices: {
            name: 'AntiTheftDevices',
            class: 'com.nationwide.edge.capabilities.policychange.lob.personalauto.draft.dto.NWAntiTheftDeviceDTO',
            DeviceType: {
                name: 'DeviceType',
                class: 'typekey.AntiTheftType',
                required: true
            },
            Mandatory: {
                name: 'Mandatory',
                type: 'Boolean',
                required: false
            },
            Selected: {
                name: 'Selected',
                type: 'Boolean',
                required: true
            },
            DisplayName: {
                name: 'DisplayName',
                type: 'String',
                required: false
            },
            required: true,
            visibilityCondition: '\'PAAntiTheftMod_Ext\' === form.value[\'Code\']'
        },
        RefCode: {
            name: 'RefCode',
            type: 'String',
            required: false
        },
        SmartRide: {
            name: 'SmartRide',
            class: 'com.nationwide.edge.capabilities.policychange.lob.personalauto.draft.dto.NWSmartRideDTO',
            HasCustomerAgreedToInstall: {
                name: 'HasCustomerAgreedToInstall',
                type: 'Boolean',
                required: false
            },
            EnrollDate: {
                name: 'EnrollDate',
                type: 'Date',
                required: false
            },
            IsDeviceCompatible: {
                name: 'IsDeviceCompatible',
                type: 'Boolean',
                required: false
            },
            DeviceStatus: {
                name: 'DeviceStatus',
                class: 'typekey.SmartRideDeviceStatus_Ext',
                required: false
            },
            EnrollStatus: {
                name: 'EnrollStatus',
                class: 'typekey.SmartRideEnrollStatus_Ext',
                required: false
            },
            InstallDate: {
                name: 'InstallDate',
                type: 'Date',
                required: false
            },
            AnnualMileage: {
                name: 'AnnualMileage',
                type: 'Integer',
                required: false
            },
            RatedScoreModel: {
                name: 'RatedScoreModel',
                type: 'String',
                required: false
            },
            PendingScore: {
                name: 'PendingScore',
                type: 'Integer',
                required: false
            },
            RatedScore: {
                name: 'RatedScore',
                type: 'Integer',
                required: false
            },
            IssueANewDevice: {
                name: 'IssueANewDevice',
                type: 'Boolean',
                required: false
            },
            required: false
        },
        AdvancedTrainingCourseCompletionDate: {
            name: 'AdvancedTrainingCourseCompletionDate',
            type: 'Date',
            required: true,
            visibilityCondition: '(\'PAAdvancedTrainedDriverDiscount_Ext\' === form.value[\'Code\'] && \'true\' === form.value[\'BooleanModifier\'])'
        },
        PublicID: {
            name: 'PublicID',
            type: 'String',
            required: false
        },
        Priority: {
            name: 'Priority',
            type: 'Integer',
            required: false
        },
        DateModifier: {
            name: 'DateModifier',
            type: 'Date',
            required: false
        },
        Name: {
            name: 'Name',
            type: 'String',
            required: false
        },
        Code: {
            name: 'Code',
            type: 'String',
            required: true
        },
        DocdelPreference: {
            name: 'DocdelPreference',
            class: 'typekey.DocumentDeliveryType_Ext',
            required: true,
            visibilityCondition: '(\'PAPaperlessPolicyDiscount_Ext\' === form.value[\'Code\'] && \'true\' === form.value[\'BooleanModifier\'])'
        },
        TypeKeys: {
            name: 'TypeKeys',
            class: 'com.nationwide.edge.capabilities.policychange.dto.NWTypeKeyDTO',
            Description: {
                name: 'Description',
                type: 'String',
                required: false
            },
            Code: {
                name: 'Code',
                type: 'String',
                required: true
            },
            required: false
        },
        FixedId: {
            name: 'FixedId',
            type: 'Long',
            required: false
        },
        State: {
            name: 'State',
            class: 'typekey.Jurisdiction',
            required: false
        },
        SmartMiles: {
            name: 'SmartMiles',
            class: 'com.nationwide.edge.capabilities.policychange.lob.personalauto.draft.dto.NWSmartRideDTO',
            HasCustomerAgreedToInstall: {
                name: 'HasCustomerAgreedToInstall',
                type: 'Boolean',
                required: false
            },
            EnrollDate: {
                name: 'EnrollDate',
                type: 'Date',
                required: false
            },
            IsDeviceCompatible: {
                name: 'IsDeviceCompatible',
                type: 'Boolean',
                required: false
            },
            DeviceStatus: {
                name: 'DeviceStatus',
                class: 'typekey.SmartRideDeviceStatus_Ext',
                required: false
            },
            EnrollStatus: {
                name: 'EnrollStatus',
                class: 'typekey.SmartRideEnrollStatus_Ext',
                required: false
            },
            InstallDate: {
                name: 'InstallDate',
                type: 'Date',
                required: false
            },
            AnnualMileage: {
                name: 'AnnualMileage',
                type: 'Integer',
                required: false
            },
            RatedScoreModel: {
                name: 'RatedScoreModel',
                type: 'String',
                required: false
            },
            PendingScore: {
                name: 'PendingScore',
                type: 'Integer',
                required: false
            },
            RatedScore: {
                name: 'RatedScore',
                type: 'Integer',
                required: false
            },
            IssueANewDevice: {
                name: 'IssueANewDevice',
                type: 'Boolean',
                required: false
            },
            required: false
        },
        RateModifier: {
            name: 'RateModifier',
            type: 'BigDecimal',
            required: false
        },
        EmailAddress: {
            name: 'EmailAddress',
            type: 'String',
            required: false
        },
        TypeList: {
            name: 'TypeList',
            type: 'String',
            required: false
        },
        ModifierDataType: {
            name: 'ModifierDataType',
            class: 'typekey.ModifierDataType',
            required: false
        },
        Description: {
            name: 'Description',
            type: 'String',
            required: false
        },
        TypekeyModifier: {
            name: 'TypekeyModifier',
            type: 'String',
            required: false,
            hashMap: {
                10: 'Firefighter',
                11: 'LawEnforcementOfficer',
                12: 'LicensedPharmacist',
                13: 'NationwideEmployeeAgent',
                14: 'Paramedic',
                15: 'PhysicianRegisteredNurse',
                16: 'Pilot',
                17: 'ScientistOrEngineer',
                18: 'Veterinarian',
                19: 'Accounting',
                20: 'AirlinePilot',
                21: 'Alumni',
                22: 'Amway',
                23: 'CarClub',
                24: 'CivilServant',
                25: 'CommunityCivic',
                26: 'CountryClub',
                27: 'CreditUnionMember',
                28: 'Dental',
                29: 'Education',
                30: 'Engineering',
                31: 'Farming',
                32: 'Insurance',
                33: 'Medical',
                34: 'Motorcycle',
                35: 'NationalCosmetology',
                36: 'NWMutualInsuranceEmployee',
                37: 'PetIndustryJointAdversaryCouncil',
                38: 'PharmacistPharmaceuticalResearcher',
                39: 'RecVehicle',
                40: 'SeniorCitizenRetirement',
                41: 'StateBarOfMichigan',
                42: 'WholesaleMember',
                43: 'YachtClubBoatowner',
                '01': 'AirBag_Ext',
                '02': 'DriverSideAirBags_Ext',
                '03': 'PassiveSeatBelt_Ext',
                '04': 'AllOther',
                '05': 'AccreditedCollegeAlumni',
                '06': 'Accountant',
                '07': 'BusinessOwner',
                '08': 'Dentist',
                '09': 'Educator'
            }
        },
        TrainingCourseCompletionDate: {
            name: 'TrainingCourseCompletionDate',
            type: 'Date',
            required: true,
            visibilityCondition: '(\'PATrainedDriverDiscount_Ext\' === form.value[\'Code\'] && \'true\' === form.value[\'BooleanModifier\'])'
        },
        BooleanModifier: {
            name: 'BooleanModifier',
            type: 'Boolean',
            required: false
        },
        required: false
    },
    GarageLocation: {
        name: 'GarageLocation',
        class: 'com.nationwide.edge.capabilities.address.dto.NWAddressDTO',
        required: true,
        ...AddressDTO,
        AddressLine1: {
            name: 'AddressLine1',
            type: 'String',
            required: true,
            maxLength: 60,
            minLength: 1,
            regEx: '^((?!^[PpBbGgHhRr].*).*$)'
        }
    },
    BodyType: {
        name: 'BodyType',
        class: 'typekey.BodyType',
        required: false
    },
    Year: {
        name: 'Year',
        type: 'Integer',
        required: true,
        minAmount: 1981,
        maxAmount: 2021
    },
    IsVinOverride: {
        name: 'IsVinOverride',
        type: 'Boolean',
        required: false
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    BodyType_Ext: {
        name: 'BodyType_Ext',
        type: 'String',
        required: true,
        visibilityCondition: '(\'auto\' === form.value[\'VehicleType\'] && \'\' !== form.value[\'TempID\'] && \'true\' === form.value[\'IsVinOverride\'])',
        hashMap: {
            0: 'VAN',
            1: '2 DOOR',
            2: '4 DOOR',
            5: 'CONVERTIBLE',
            6: 'WAGON',
            12: '4 DOOR 100 IN',
            16: 'WAGON 100 IN',
            20: '20',
            21: 'VAN 100 IN',
            23: 'SMALL P/U',
            24: 'LARGE P/U',
            '-124': 'Large P/U',
            '-123': 'Small P/U',
            '-121': 'VAN 100IN',
            '-312': 'Four Door 100in',
            '-116': 'WAGON 100IN',
            '-112': '4 DOOR 100IN',
            '-100': 'Unavailable',
            '-224': 'Large pickup',
            '-223': 'small Pickup',
            '-221': 'Van 100 in',
            '-216': 'Wagon 100in',
            '-212': '4 Door 100in',
            '-16': 'Wagon',
            '-15': 'Convertible',
            '-12': '4 Door',
            '-11': '2 Door',
            '-10': 'Van'
        }
    },
    RegisteredOwners: {
        name: 'RegisteredOwners',
        class: 'com.nationwide.edge.capabilities.policychange.lob.personalauto.draft.dto.NWVehicleOwnersDTO',
        DriverTempID: {
            name: 'DriverTempID',
            type: 'Integer',
            required: true,
            visibilityCondition: '\'\' === form.value[\'DriverId\']'
        },
        DriverId: {
            name: 'DriverId',
            type: 'Long',
            required: false
        },
        FixedId: {
            name: 'FixedId',
            type: 'Long',
            required: false
        },
        required: true,
        visibilityCondition: '\'true\' === form.value[\'IsAnyRegOwnerAPolicyDriver\']'
    },
    EstimatedAnnualMiles: {
        name: 'EstimatedAnnualMiles',
        type: 'Integer',
        required: false
    },
    DamageLocation: {
        name: 'DamageLocation',
        type: 'String',
        required: true,
        visibilityCondition: '\'true\' === form.value[\'HasPriorDamage\']'
    },
    PublicID: {
        name: 'PublicID',
        type: 'String',
        required: true,
        visibilityCondition: '\'\' === form.value[\'TempID\']'
    },
    VehicleType: {
        name: 'VehicleType',
        class: 'typekey.VehicleType',
        required: true,
        minAmount: 'NamedNonOwner_Ext'
    },
    MilesOneWay: {
        name: 'MilesOneWay',
        type: 'Integer',
        required: true,
        visibilityCondition: '\'Work\' === form.value[\'PrimaryUse\']'
    },
    AppliedSurcharges: {
        name: 'AppliedSurcharges',
        class: 'com.nationwide.edge.capabilities.policychange.lob.personalauto.draft.dto.NWAppliedModifierDTO',
        Description: {
            name: 'Description',
            type: 'String',
            required: false
        },
        required: false
    },
    CommutingDays: {
        name: 'CommutingDays',
        type: 'Integer',
        required: true,
        visibilityCondition: '(\'Work\' === form.value[\'PrimaryUse\'] && (policyNumber true === form.value[\'CommutingDays\']))'
    },
    VehicleSubType: {
        name: 'VehicleSubType',
        class: 'typekey.VehicleSubType_Ext',
        required: false
    },
    ExcludedDriver: {
        name: 'ExcludedDriver',
        class: 'com.nationwide.edge.capabilities.policychange.lob.personalauto.draft.dto.NWVehicleDriverDTO',
        VehicleFixedID: {
            name: 'VehicleFixedID',
            type: 'Long',
            required: true
        },
        DriverId: {
            name: 'DriverId',
            type: 'Long',
            required: true
        },
        PrimaryDriver: {
            name: 'PrimaryDriver',
            type: 'Boolean',
            required: false
        },
        FixedId: {
            name: 'FixedId',
            type: 'Long',
            required: false
        },
        required: false
    },
    CostNew: {
        name: 'CostNew',
        class: 'edge.capabilities.currency.dto.AmountDTO',
        Currency: {
            name: 'Currency',
            class: 'typekey.Currency',
            required: false
        },
        Amount: {
            name: 'Amount',
            type: 'BigDecimal',
            required: false
        },
        required: true,
        visibilityCondition: '\'Trailer_Ext\' === form.value[\'VehicleType\']'
    },
    AppliedDiscounts: {
        name: 'AppliedDiscounts',
        class: 'com.nationwide.edge.capabilities.policychange.lob.personalauto.draft.dto.NWAppliedModifierDTO',
        Description: {
            name: 'Description',
            type: 'String',
            required: false
        },
        required: false
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    HasAnyDriversExcludedPD_Ext: {
        name: 'HasAnyDriversExcludedPD_Ext',
        type: 'Boolean',
        required: false
    },
    AddlCustomizationDesc: {
        name: 'AddlCustomizationDesc',
        type: 'String',
        required: true,
        visibilityCondition: '\'\' !== form.value[\'AddlCustomizationAmnt\']',
        maxLength: 250
    },
    DaysPerWeek: {
        name: 'DaysPerWeek',
        type: 'Integer',
        required: true,
        visibilityCondition: '\'Work\' === form.value[\'PrimaryUse\']'
    },
    Vin: {
        name: 'Vin',
        type: 'String',
        required: true,
        visibilityCondition: '\'auto\' === form.value[\'VehicleType\']',
        minLength: 17,
        maxLength: 17
    },
    IsCurrentPlateTransferred: {
        name: 'IsCurrentPlateTransferred',
        type: 'Boolean',
        required: true,
        visibilityCondition: '(baseState true === form.value[\'IsCurrentPlateTransferred\'])'
    },
    AnnualMiles: {
        name: 'AnnualMiles',
        type: 'Integer',
        required: true,
        visibilityCondition: ' != null'
    },
    HasPriorDamage: {
        name: 'HasPriorDamage',
        type: 'Boolean',
        required: false
    }
};
