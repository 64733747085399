import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'nearestMin' })
export class NearestMinPipe implements PipeTransform {
  transform(date: Date | number): Date {
    const oneMin = 60000;
    let result: Date;
    if (typeof date === 'number') {
      result = new Date(date);
    } else {
      result = date;
    }
    return new Date(Math.round(result.getTime() / oneMin) * oneMin);
  }
}
