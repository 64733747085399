import { TimeFrameUnit } from 'app/enum/timeframe';
import { Moment } from 'moment';
import * as moment from 'moment';

export class TimeFrame {
  unit: TimeFrameUnit;
  start: Moment;
  end: Moment;

  constructor() {
    this.unit = TimeFrameUnit.CUSTOM;
    this.start = null;
    this.end = null;
  }

  static buildTimeframe(start: Moment, end: Moment, unit: TimeFrameUnit): TimeFrame {
    const tf = new TimeFrame();
    tf.unit = unit;
    tf.start = start;
    tf.end = end;
    return tf;
  }

  static defaultInstance(): TimeFrame {
    const tf = new TimeFrame();
    tf.unit = TimeFrameUnit.CUSTOM;
    tf.start = null;
    tf.end = null;
    return tf;
  }

  contains?(time: Date | Moment): boolean {
    if (time instanceof Date) {
      time = moment(time);
    }
    return time.isBetween(this.start, this.end);
  }
}
