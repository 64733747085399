import { Component, OnInit, Input, Injectable } from '@angular/core';
import { Discount } from 'app/model/discount';

@Component({
  selector: 'app-smart-ride-discount-summary',
  templateUrl: './smart-ride-discount-summary.component.html',
  styleUrls: ['./smart-ride-discount-summary.component.css']
})

@Injectable()
export class SmartRideDiscountSummaryComponent implements OnInit {
  @Input() discount: Discount;
  @Input() periodUnit: string;

  constructor() { }

  ngOnInit(): void { }

  shouldDisplayTrend(): boolean {
    return !!this.getTrendPreviousDiscount();
  }

  discountTypeSuffix(): string {
    if (this.discount.discountType === 'Final Discount') {
      return '*';
    }
    return '';
  }

  getTrendIconUrl(): string {
    let suffix = 'equal-grey.svg';
    const trend = this.getTrendAsNumber();
    if (trend < 0) {
      suffix = 'down-red.svg';
    } else if (trend > 0) {
      suffix = 'up-green.svg';
    }
    return `/assets/image/svg/trend-arrows-${suffix}`;
  }

  getTrendText(): string {
    const trend = this.getTrendAsNumber();
    let comparison: string;
    if (trend < 0) {
      comparison = 'less than';
    } else if (trend > 0) {
      comparison = 'more than';
    } else {
      comparison = 'same as';
    }
    return `${comparison} last ${this.periodUnit}`;
  }

  getTrendAsNumber(): number {
    const previous: Discount = this.getTrendPreviousDiscount();
    if (!previous) {
      return 0;
    }
    return this.discount.amount - previous.amount;
  }

  getTrendPreviousDiscount(): Discount {
    if (this.periodUnit === 'week') {
      return this.discount.discountLastWeek;
    }
    if (this.periodUnit === 'month') {
      return this.discount.discountLastMonth;
    }
    return null;
  }

  createAriaLabel(): string {
    let label = '';
    label = `${label + this.discount.amount} Percent ${this.discount.discountType}`;
    if (this.periodUnit !== 'total') {
      label = `${label} ${this.getTrendText()}`;
    }
    return label;
  }
}
