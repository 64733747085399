import { Component, OnInit, Injectable } from '@angular/core';
import { AdminSearchFormService } from 'app/components/admin/admin-search-form.service';
import { UntypedFormGroup } from '@angular/forms';
import { VehicleService } from '../../../service/vehicle.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-srp',
  templateUrl: './admin-srp.component.html',
  styleUrls: ['./admin-srp.component.css']
})

@Injectable()
export class AdminSrpComponent implements OnInit {
  vin: string;
  policyNumber: string;
  searchForm: UntypedFormGroup;
  showError: boolean;
  result: string;
  errorMsg: string;

  constructor(
    private _adminSearchForm: AdminSearchFormService,
    private _vehicleService: VehicleService,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.searchForm = this._adminSearchForm.buildForm();
    this.result = 'No Results';
  }

  checkForm(): void {
    if (this.searchForm.valid) {
        this.vin = this.searchForm.get('vin').value;
        this.policyNumber = this.searchForm.get('policyNumber').value;
        this.showError = false;
    } else {
        this.showError = true;
        this.showError = this.searchForm.get('policyNumber').status === 'INVALID' || this.searchForm.get('vin').status === 'INVALID';
    }
  }

  back(): void {
    this.router.navigateByUrl('/smartrideAdmin');
  }

  submitSearch(): void {
    if (!this.vin && !this.policyNumber) {
      this.showError = true;
      this.errorMsg = 'Please enter a VIN or Policy Number above.';
    } else {
      this.result = 'Loading...';
      this._vehicleService.getSRPVehiclesData(this.policyNumber, this.vin).subscribe((respJson) => {
        if (respJson) {
          this.result = respJson;
          this.showError = false;
          return;
        }
        this.setErrorMsgText();
        this.result = '';
        return;
      }, () => {
        this.result = '';
        this.showError = true;
        this.setErrorMsgText();
      });
    }
  }

  setErrorMsgText(): boolean {
    this.showError = true;
    if (this.policyNumber) {
      this.errorMsg = `No results were found for Policy Number ${this.policyNumber}`;
    } else if (this.vin) {
      this.errorMsg = `No results were found for VIN ${this.vin}`;
    } else {
      this.errorMsg = 'Please enter a VIN or Policy Number above.';
    }
    return false;
  }
}
