// eslint-disable-next-line @typescript-eslint/naming-convention
export const RelationToPNI = {
    type: 'edge.metadata.typeinfo.typelistinfo.dto.TypelistMetadataDTO',
    value: {
        codes: [
            {
                code: 'PrimaryNamedInsured',
                priority: 5,
                categories: [
                    {
                        typelist: 'DescOfInterestType_Ext',
                        typecode: 'Trust'
                    },
                    {
                        typelist: 'PolicyLine',
                        typecode: 'PUPLine_PUE'
                    },
                    {
                        typelist: 'PolicyLine',
                        typecode: 'PersonalAutoLine'
                    },
                    {
                        typelist: 'PolicyLine',
                        typecode: 'HomeownersLine_HOE'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'AL'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'AK'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'AZ'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'AR'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'CA'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'CO'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'CT'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'DE'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'DC'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'FL'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'GA'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'HI'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'ID'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'IL'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'IN'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'IA'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'KS'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'KY'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'LA'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'ME'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MD'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MA'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MI'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MN'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MS'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MO'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MT'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NE'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NV'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NH'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NJ'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NM'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NY'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NC'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'ND'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'OH'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'OK'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'OR'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'PA'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'RI'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'SC'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'SD'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'TN'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'TX'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'UT'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'VT'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'VA'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'WA'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'WV'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'WI'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'WY'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'AA_Ext'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'AE_Ext'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'AB'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'AP_Ext'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'BC'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'FM'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'GU'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MB'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MH'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NB'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NL'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MP'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NT'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NS'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NU'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'ON'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'PE'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'PR'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'QC'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'SK'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'VI'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'YT'
                    }
                ],
                displayKey: 'typekey.RelationToPNI_Ext.PrimaryNamedInsured'
            },
            {
                code: 'Spouse',
                priority: 10,
                categories: [
                    {
                        typelist: 'DescOfInterestType_Ext',
                        typecode: 'SecondaryNamedInsured'
                    },
                    {
                        typelist: 'PolicyLine',
                        typecode: 'PUPLine_PUE'
                    },
                    {
                        typelist: 'PolicyLine',
                        typecode: 'PersonalAutoLine'
                    },
                    {
                        typelist: 'PolicyLine',
                        typecode: 'HomeownersLine_HOE'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'AL'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'AK'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'AZ'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'AR'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'CA'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'CO'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'CT'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'DE'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'DC'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'FL'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'GA'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'HI'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'ID'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'IL'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'IN'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'IA'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'KS'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'KY'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'LA'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'ME'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MD'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MA'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MI'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MN'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MS'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MO'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MT'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NE'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NV'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NH'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NJ'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NM'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NY'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NC'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'ND'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'OH'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'OK'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'OR'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'PA'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'RI'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'SC'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'SD'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'TN'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'TX'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'UT'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'VT'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'VA'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'WA'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'WV'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'WI'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'WY'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'AA_Ext'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'AE_Ext'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'AB'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'AP_Ext'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'BC'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'FM'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'GU'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MB'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MH'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NB'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NL'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MP'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NT'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NS'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NU'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'ON'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'PE'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'PR'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'QC'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'SK'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'VI'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'YT'
                    }
                ],
                displayKey: 'typekey.RelationToPNI_Ext.Spouse'
            },
            {
                code: 'Child',
                priority: 30,
                categories: [
                    {
                        typelist: 'PolicyLine',
                        typecode: 'PersonalAutoLine'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'AL'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'AK'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'AZ'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'AR'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'CA'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'CO'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'CT'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'DE'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'DC'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'FL'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'GA'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'HI'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'ID'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'IL'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'IN'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'IA'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'KS'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'KY'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'LA'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'ME'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MD'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MA'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MI'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MN'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MS'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MO'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MT'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NE'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NV'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NH'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NJ'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NM'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NY'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NC'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'ND'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'OH'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'OK'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'OR'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'PA'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'RI'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'SC'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'SD'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'TN'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'TX'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'UT'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'VT'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'VA'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'WA'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'WV'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'WI'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'WY'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'AA_Ext'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'AE_Ext'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'AB'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'AP_Ext'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'BC'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'FM'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'GU'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MB'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MH'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NB'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NL'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MP'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NT'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NS'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NU'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'ON'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'PE'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'PR'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'QC'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'SK'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'VI'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'YT'
                    }
                ],
                displayKey: 'typekey.RelationToPNI_Ext.Child'
            },
            {
                code: 'CivilUnion',
                priority: 50,
                categories: [
                    {
                        typelist: 'PolicyLine',
                        typecode: 'PersonalAutoLine'
                    }
                ],
                displayKey: 'typekey.RelationToPNI_Ext.CivilUnion'
            },
            {
                code: 'CoOwner',
                priority: 60,
                categories: [
                    {
                        typelist: 'PolicyLine',
                        typecode: 'PersonalAutoLine'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'AL'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'AK'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'AZ'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'AR'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'CA'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'CO'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'CT'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'DE'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'DC'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'FL'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'GA'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'HI'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'ID'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'IL'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'IN'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'IA'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'KS'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'KY'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'LA'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'ME'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MD'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MA'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MI'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MN'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MS'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MO'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MT'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NE'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NV'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NH'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NJ'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NM'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NY'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NC'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'ND'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'OH'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'OK'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'OR'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'PA'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'RI'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'SC'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'SD'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'TN'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'TX'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'UT'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'VT'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'VA'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'WA'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'WV'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'WI'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'WY'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'AA_Ext'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'AE_Ext'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'AB'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'AP_Ext'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'BC'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'FM'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'GU'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MB'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MH'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NB'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NL'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MP'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NT'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NS'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NU'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'ON'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'PE'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'PR'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'QC'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'SK'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'VI'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'YT'
                    }
                ],
                displayKey: 'typekey.RelationToPNI_Ext.CoOwner'
            },
            {
                code: 'Employee',
                priority: 70,
                categories: [
                    {
                        typelist: 'PolicyLine',
                        typecode: 'PersonalAutoLine'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'AL'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'AK'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'AZ'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'AR'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'CA'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'CO'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'CT'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'DE'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'DC'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'FL'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'GA'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'HI'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'ID'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'IL'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'IN'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'IA'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'KS'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'KY'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'LA'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'ME'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MD'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MA'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MI'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MN'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MS'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MO'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MT'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NE'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NV'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NH'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NJ'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NM'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NY'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NC'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'ND'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'OH'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'OK'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'OR'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'PA'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'RI'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'SC'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'SD'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'TN'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'TX'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'UT'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'VT'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'VA'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'WA'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'WV'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'WI'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'WY'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'AA_Ext'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'AE_Ext'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'AB'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'AP_Ext'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'BC'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'FM'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'GU'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MB'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MH'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NB'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NL'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MP'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NT'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NS'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NU'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'ON'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'PE'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'PR'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'QC'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'SK'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'VI'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'YT'
                    }
                ],
                displayKey: 'typekey.RelationToPNI_Ext.Employee'
            },
            {
                code: 'Other',
                priority: 80,
                categories: [
                    {
                        typelist: 'PolicyLine',
                        typecode: 'PersonalAutoLine'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'AL'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'AK'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'AZ'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'AR'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'CA'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'CO'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'CT'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'DE'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'DC'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'FL'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'GA'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'HI'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'ID'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'IL'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'IN'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'IA'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'KS'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'KY'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'LA'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'ME'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MD'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MA'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MI'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MN'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MS'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MO'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MT'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NE'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NV'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NH'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NJ'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NM'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NY'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NC'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'ND'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'OH'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'OK'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'OR'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'PA'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'RI'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'SC'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'SD'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'TN'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'TX'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'UT'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'VT'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'VA'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'WA'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'WV'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'WI'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'WY'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'AA_Ext'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'AE_Ext'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'AB'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'AP_Ext'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'BC'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'FM'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'GU'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MB'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MH'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NB'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NL'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MP'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NT'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NS'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'NU'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'ON'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'PE'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'PR'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'QC'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'SK'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'VI'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'YT'
                    }
                ],
                displayKey: 'typekey.RelationToPNI_Ext.Other'
            },
            {
                code: 'OtherRelative',
                priority: 80,
                categories: [
                    {
                        typelist: 'PolicyLine',
                        typecode: 'PersonalAutoLine'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MI'
                    }
                ],
                displayKey: 'typekey.RelationToPNI_Ext.OtherRelative'
            },
            {
                code: 'Parent',
                priority: 80,
                categories: [],
                displayKey: 'typekey.RelationToPNI_Ext.Parent'
            },
            {
                code: 'HouseholdMember',
                priority: 90,
                categories: [],
                displayKey: 'typekey.RelationToPNI_Ext.HouseholdMember'
            },
            {
                code: 'OtherNonRelative',
                priority: 90,
                categories: [
                    {
                        typelist: 'PolicyLine',
                        typecode: 'PersonalAutoLine'
                    },
                    {
                        typelist: 'Jurisdiction',
                        typecode: 'MI'
                    }
                ],
                displayKey: 'typekey.RelationToPNI_Ext.OtherNonRelative'
            }
        ],
        filters: [
            {
                name: 'HOPolicyInfo',
                includedCodes: [
                    'PrimaryNamedInsured',
                    'Spouse',
                    'Child',
                    'Other',
                    'Parent'
                ]
            },
            {
                name: 'CommonRelationToPNI',
                includedCodes: [
                    'PrimaryNamedInsured',
                    'Spouse',
                    'Child',
                    'Other',
                    'Parent',
                    'HouseholdMember'
                ]
            },
            {
                name: 'AutoMIRelationToPNI',
                includedCodes: [
                    'PrimaryNamedInsured',
                    'Spouse',
                    'Child',
                    'CoOwner',
                    'Employee',
                    'OtherRelative',
                    'OtherNonRelative'
                ]
            },
            {
                name: 'RelationToPNIForHC',
                includedCodes: [
                    'Spouse',
                    'Child',
                    'OtherRelative'
                ]
            }
        ],
        namedMetadata: []
    }
};
