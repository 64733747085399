import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Vehicle } from 'app/model/vehicle';
import { Timeline } from 'app/model/timeline';
import { WindowRef } from 'app/service/window-ref';
import * as moment from 'moment';
import { VehicleResponse } from 'app/service/vehicle.service';

@Component({
  selector: 'app-device-timeline-bar',
  templateUrl: './device-timeline-bar.component.html',
  styleUrls: ['./device-timeline-bar.component.css']
})
export class DeviceTimelineBarComponent implements OnInit {
  @Input() fullView: boolean;
  @Input() vehicle: Vehicle | VehicleResponse;
  @Input() returnPeriod: string;
  @Input() returnDate: string;
  timelineHeader: string;
  deviceStatus: string;
  hoverStyle: string;
  innerWidth: number;
  private programStartDate: Date;
  private programEndDate: Date;
  private MOBILE_DISPLAY_SIZE: number;

  constructor(
    public router: Router,
    public window: WindowRef
  ) {
    this.MOBILE_DISPLAY_SIZE = 534;
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.innerWidth = window.innerWidth;
  }

  ngOnInit(): void {
    if (this.vehicle !== undefined) {
      this.timelineHeader = this.getHeader(this.vehicle.deviceStatusTimeline);
      this.getTimelineInfo(this.vehicle.deviceStatusTimeline);
    }
    this.innerWidth = this.window.nativeWindow.innerWidth;
    this.setHover();
  }

  getTimelineInfo(timeline: Timeline): void {
    const today = new Date();
    if (timeline.installDate === undefined || timeline.installDate === null) {
      this.programStartDate = timeline.enrollDate;
    } else {
      this.programStartDate = timeline.installDate;
    }
    if (timeline.completionDate <= today || this.fullView === true) {
      this.programEndDate = timeline.finalDiscountDate;
    } else if (today < timeline.completionDate) {
      this.programEndDate = timeline.completionDate;
    }
    if (!this.programEndDate) {
      this.programEndDate = timeline.completionDate || timeline.finalDiscountDate || new Date();
    }
  }
  getHeader(timeline: Timeline): any {
    let header;
    const millisecondsInOneDay = 86400000;
    const endDate = timeline.completionDate || timeline.finalDiscountDate;
    let remaining = Math.ceil((endDate.getTime() - new Date().getTime()) / millisecondsInOneDay);
    remaining = remaining < 0 ? 0 : remaining;
    if (timeline.installDate === undefined || timeline.installDate === null && remaining > 0) {
      header = `${remaining} days left`;
    } else if (remaining <= 0) {
      header = 'Data Collection Complete';
    } else if (remaining === 1) {
      header = `Data Collection : ${remaining} day left`;
    } else {
      header = `Data Collection : ${remaining} days left`;
    }
    return header;
  }

  getPercent(): Object {
    const fractionToPercent = 100;
    const oneHundredPercent = 100;
    const elapsed = new Date().getTime() - this.programStartDate.getTime();
    const total = this.programEndDate.getTime() - this.programStartDate.getTime();
    let percent = elapsed / total * fractionToPercent;
    if (percent > oneHundredPercent) {
      percent = oneHundredPercent;
    }
    return {
      width: `${percent}%`,
      height: `${percent}%`
    };
  }

  getRatio(timeline: Timeline): Object {
    const fractionToPercent = 100;
    const oneHundredPercent = 100;
    const endDate = timeline.completionDate || timeline.finalDiscountDate;
    const portion = endDate.getTime() - this.programStartDate.getTime();
    const total = this.programEndDate.getTime() - this.programStartDate.getTime();
    let percent = portion / total * fractionToPercent;
    if (percent > oneHundredPercent) {
      percent = oneHundredPercent;
    }
    return innerWidth > this.MOBILE_DISPLAY_SIZE ? {
      left: `${percent}%`
    } : {
        top: `${percent}%`
      };
  }

  setHover(): void {
    if (this.router.url.includes('deviceStatusTimeline')) {
      this.hoverStyle = 'container--hover';
    } else {
      this.hoverStyle = 'container--nohover';
    }
  }

  redirect(): void {
    this.router.navigate([
      'sride',
      'deviceStatusTimeline',
      this.returnPeriod,
      this.returnDate
    ]);
  }

  createAriaLabel(): string {
    let label = '';
    label = `${label
       }Program starts on ${
       moment(this.programStartDate).format('MMMM Do YYYY')
       } and ends on ${
       moment(this.programEndDate).format('MMMM Do YYYY')} ${
       this.timelineHeader}`;
    return label;
  }
}
