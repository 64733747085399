import { Pipe, PipeTransform } from '@angular/core';
import { Trip } from 'app/model/trip';
import { TimeFrame } from 'app/interfaces/timeframe.interface';
import * as moment from 'moment';
import { RatedMileage } from '../model/rated-mileage';

@Pipe({ name: 'inTimeFrame' })
export class TimeframePipe implements PipeTransform {
  transform(data: Array<RatedMileage | Trip>, timeframe: TimeFrame, granularity?: string): Array<RatedMileage | Trip> {
    let g = granularity;
    if (granularity === undefined || ['month', 'week', 'day', 'minute', 'second'].indexOf(granularity.toLowerCase()) === -1) {
      g = 'day';
    }
    if (data && data[0] instanceof RatedMileage) {
      const dataAsMileage = <RatedMileage[]>data;
      return dataAsMileage.filter((datum) =>

          moment(datum.ratedMileageDate).isSameOrAfter(timeframe.start, <any>g) &&
          moment(datum.ratedMileageDate).isSameOrBefore(timeframe.end, <any>g)
        );
    } else if (data && data[0] instanceof Trip) {
      const dataAsTrips = <Trip[]>data;
      return dataAsTrips.filter((datum) =>

          moment(datum.startTS).isSameOrAfter(timeframe.start, <any>g) &&
          moment(datum.endTS).isSameOrBefore(timeframe.end, <any>g)
        );
    } else {
      return [];
    }
  }
}
