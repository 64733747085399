/* eslint-disable @typescript-eslint/naming-convention */
import { AdditionalInterestDTO } from './metadata-content/additionalInterestDTO';
import { AddressDTO } from './metadata-content/addressDTO';
import { DriverDTO } from './metadata-content/driverDTO';
import { EmploymentStatusType } from './metadata-content/employmentStatusType';
import { HealthCarePlan } from './metadata-content/healthCarePlan';
import { MaritalStatus } from './metadata-content/maritalStatus';
import { NameSuffix } from './metadata-content/nameSuffix';
import { RelationToPNI } from './metadata-content/relationToPNI';
import { State } from './metadata-content/state';
import { Translations } from './metadata-content/translations';
import { VehicleDTO } from './metadata-content/vehicleDTO';
import { VehiclePrimaryUse } from './metadata-content/vehiclePrimaryUse';

export const METADATA = {
    AddressDTO,
    NWAdditionalInterestDTO: AdditionalInterestDTO,
    NWVehicleDTO: VehicleDTO,
    NWDriverDTO: DriverDTO,
    Translations,
    'typekey.EmploymentStatusType': EmploymentStatusType,
    'typekey.HealthCarePlan_Ext': HealthCarePlan,
    'typekey.MaritalStatus': MaritalStatus,
    'typekey.NameSuffix': NameSuffix,
    'typekey.RelationToPNI_Ext': RelationToPNI,
    'typekey.State': State,
    'typekey.VehiclePrimaryUse_Ext': VehiclePrimaryUse,
    'VehicleDTO.GarageLocation': VehicleDTO.GarageLocation
};
