import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { SelfEnrollmentService } from './self-enrollment.service';
import { WindowRef } from './window-ref';

@Injectable({
  providedIn: 'root'
})
export class SelfEnrollmentGuardService implements CanActivate {
  _window: any;

  constructor(
    private selfEnrollmentService: SelfEnrollmentService,
    private _windowRef: WindowRef
    ) {
      this._window = this._windowRef.nativeWindow;
    }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.selfEnrollmentService.isSelfEnrollFlowInPlay || this._window.location.replace(environment.myAccountUrl);
  }
}
