import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Questions } from '@nationwide/dgs-internet-servicing-dynamic-forms';
import { Subscription } from 'rxjs/internal/Subscription';

@Injectable()
export class QuickIdDataService {
    form: FormGroup;
    formTypeMap: any;
    isSaveComplete: boolean;
    page: string;
    pageLevelError: string;
    policyChangeData: any;
    policyData: any;
    questions: Questions;
    showPageLevelError: boolean;
    subscriptions: Subscription[] = [];

    clear(): void {
        this.form = undefined;
        this.formTypeMap = undefined;
        this.isSaveComplete = true;
        this.page = undefined;
        this.pageLevelError = '';
        this.policyChangeData = undefined;
        this.policyData = undefined;
        this.questions = undefined;
        this.showPageLevelError = false;
        this.subscriptions = [];
    }
}
