import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'factorTitle' })
export class FactorTitlePipe implements PipeTransform {
  titles = {
    miles: 'Miles Driven',
    braking: 'Hard Braking',
    acceleration: 'Fast Acceleration',
    night: 'Nighttime Driving',
    idle: 'Idle Time',
    brakingAcceleration: 'Hard Braking / Acceleration',
    discount: 'Discount'
  };

  transform(factor: string): any {
    return this.titles[factor] || factor;
  }
}
