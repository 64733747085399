import { Component, HostListener, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { TermAndConditionStatusService } from 'app/service/term-and-condition-status.service';
import { VehicleService } from 'app/service/vehicle.service';
import { ProgramSelectService } from 'app/service/program-select.service';

@Component({
    selector: 'app-terms-and-conditions-popup',
    templateUrl: './terms-and-conditions-popup.component.html',
    styleUrls: ['./terms-and-conditions-popup.component.scss']
})
export class TermsAndConditionsPopupComponent implements OnInit {
    termsAndConditionsPopup;
    termsAndConditionsText: string;
    url: string;
    smartMilesTermsAndConditions: string;
    smartRideTermsAndConditions: string;
    innerWidth: number;
    mobileView: boolean;
    linkText: string;
    connectedCarData: any = [];

    @HostListener('window:resize', ['$event'])
    onResize(): void {
        this.checkInnerWidth(window.innerWidth);
    }

    // eslint-disable-next-line max-params
    constructor(public activeModal: NgbActiveModal,
        public router: Router,
        public vehicleService: VehicleService,
        private programSelectService: ProgramSelectService,
        private termAndConditionStatusService: TermAndConditionStatusService) {
        this.termsAndConditionsPopup = this;
        if (sessionStorage.getItem('login') === 'true') {
            this.connectedCarData = this.vehicleService.getConnectedCarDataForLogin();
        } else {
            this.connectedCarData = this.programSelectService.getConnectedCarDataForLogin();
        }
    }

    ngOnInit(): void {
        this.frameModalText();
    }

    frameModalText(): void {
        this.linkText = 'Terms and Conditions';
        if (this.mobileView) {
            this.linkText = 'Terms and <br/> Conditions';
        }
        this.termsAndConditionsText = `<img src='assets/image/Info.png' class='infoIcon' id='sre-info-icon' alt='Information icon'/> <h3 class= 'headerTextStyle' >Please agree to the Terms and Conditions to continue in the program:</h3></br></br>`;
        this.smartMilesTermsAndConditions = `<p class ="termsAndConditionsText">SmartMiles <a tabindex="0" rel="noopener" class="urlStyle" target="_blank" href="${environment.termsAndConditionsSMConnectedCarURL}"> ${this.linkText}</a></p>`;
        this.smartRideTermsAndConditions = `<p class ="termsAndConditionsText">SmartRide <a tabindex="0" rel="noopener" class="urlStyle" target="_blank" href="${environment.termsAndConditionsConnectedCarURL}">${this.linkText}</a></p>`;
        const smartRide = sessionStorage.getItem('SmartRide ConnectedCar');
        const smartMiles = sessionStorage.getItem('SmartMiles ConnectedCar');
        if (smartRide && smartMiles) {
            this.termsAndConditionsText +=
                `${this.smartMilesTermsAndConditions}<br/>${this.smartRideTermsAndConditions}`;
        } else if (smartRide) {
            this.termsAndConditionsText += this.smartRideTermsAndConditions;
        } else if (smartMiles) {
            this.termsAndConditionsText += this.smartMilesTermsAndConditions;
        }
    }

    checkInnerWidth(screenWidth: number): void {
        this.mobileView = false;
        const maxMobileWidth = 768;
        const minWidth = 320;
        const maxWidth = 540;
        const nonMobileWidth = [minWidth, maxWidth];
        if (screenWidth < maxMobileWidth && !nonMobileWidth.includes(screenWidth)) {
            this.mobileView = true;
        }
        this.frameModalText();
    }

    submitAcceptance(event): void {
        if (this.connectedCarData) {
            for (const vehicle of this.connectedCarData) {
                sessionStorage.removeItem('SmartMiles ConnectedCar');
                this.termAndConditionStatusService
                    .updateConnectedCarAcceptanceStatus(
                        vehicle.vin,
                        vehicle.type
                    )
                    .subscribe((response) => {
                        this.activeModal.close();
                    });
            }
        }
    }
}
