import { Moment } from 'moment';

export class Discount {
  date: Moment;
  beginningOfWeek: Moment;
  endOfWeek: Moment;
  afterPolicyEnd: boolean;
  amount: number;
  discountType: string;

  // Populated by mapper to calculate trend, could be null:
  discountLastWeek: Discount;
  discountLastMonth: Discount;

  constructor(date: Moment, amount: number, discountType: string) {
    this.date = date;
    this.amount = amount;
    this.discountType = discountType;
  }

  clone(): Discount {
    const output = new Discount(this.date.clone(), this.amount, this.discountType);
    output.beginningOfWeek = this.beginningOfWeek ? this.beginningOfWeek.clone() : null;
    output.endOfWeek = this.endOfWeek ? this.endOfWeek.clone() : null;
    output.afterPolicyEnd = this.afterPolicyEnd;
    output.discountLastWeek = null;
    output.discountLastMonth = null;
    return output;
  }
}
