import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { RatedMileage } from '../model/rated-mileage';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { ServiceErrorHandler } from '../utility/service-error-handler';
import { UserService } from 'app/service/user.service';
import { TimeFrame } from 'app/interfaces/timeframe.interface';
import * as moment from 'moment';
import { PolicyNumberFormatService } from 'app/service/policy-number-format.service';
import { APICommonService } from '@nationwide/api-common-service';
import { Vehicle } from 'app/model/vehicle';

@Injectable()
export class RatedMileageService {
    // key for this is the policy number and the vin concatenated together (in that order)
    // this protects the use case of a user that is enrolled, then sells the car to another person who enrolls with a new policy
    mileageResponses = new Map<string, RatedMileage[]>();
    vehicle: Vehicle;

    // eslint-disable-next-line max-params
    constructor(
        private http: HttpClient,
        public errorHandler: ServiceErrorHandler,
        public user: UserService,
        private apiCommonService: APICommonService
    ) {
        user.logOutEventEmitter.subscribe(() => {
            this.clearCache();
        });
    }

    // eslint-disable-next-line max-params
    getRatedMileage(vin: string, policyNumber = null, startDate: Date, endDate: Date): Observable<RatedMileage[]> {
        if (!policyNumber) {
            policyNumber = this.user.getPolicyNumber();
        }
        if (this.mileageResponses.has(policyNumber + vin)) {
            return of(this.mileageResponses.get(policyNumber + vin));
        }
        const formattedPolicyNumber = PolicyNumberFormatService.safeUriEncodePolicyNumber(policyNumber);

        const url = environment.ratedMileageService.url
            .replace(/POLICY/, formattedPolicyNumber)
            .replace(/VIN/, vin)
            .replace(/STARTDATE/, this.formatDate(startDate))
            .replace(/ENDDATE/, this.formatDate(endDate));

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'X-NW-MessageID': this.apiCommonService.generateTransactionId(),
                // eslint-disable-next-line camelcase
                client_id: environment.ratedMileageService.apiKey
            })
        };
        if (environment.useValidatorService && sessionStorage.getItem('useValidator') === 'true') {
            const validatorURL = `${environment.validatorUrl}9112J05030/retrieve`;
            const validatorParams = {
                serviceName: 'SmartRideExperience_2',
                applicationName: 'DGS-ISU-SMILES',
                endpoint: 'https://api-test.nwie.net/policymanagement/policy-management/telematics/v1/policies'
            };
            return this.http.post(validatorURL, validatorParams, httpOptions).pipe(
                map((response) =>
                    this.mapRatedMileage(JSON.parse(response['cachedResponses'][0]['payload']), vin, policyNumber)),
                tap((response) => {
                    this.mileageResponses.set(policyNumber + vin, response);
                }),
                catchError(this.errorHandler.handleError('getRatedMileage')));
        } else {
            return this.http.get(url, httpOptions)
                .pipe(
                    map((response) => this.mapRatedMileage(response, vin, policyNumber)),
                    tap((response) => {
                        this.mileageResponses.set(policyNumber + vin, response);
                    }),
                    catchError((error) => {
                        this.errorHandler.handleError('getRatedMileage');
                        return of(error);
                    })
                );
        }
    }

    mapRatedMileage = (input: any, vin?: string, policyNumber?: string): RatedMileage[] => {
        const currentMoment = moment(environment.futureDate);

        const response = [];
        const allMileage = input['ratedMileage'];
        for (const day of allMileage) {
            const reportMoment = moment(day['ratedMileageDate']);
            if (reportMoment.isAfter(currentMoment, 'day')) {
                continue;
            }

            const mileage = new RatedMileage();
            mileage.ratedMileage = day['ratedMileage'];
            mileage.ratedMileageDate = reportMoment.toDate();
            mileage.heartbeatIndicator = day['heartbeatIndicator'];
            mileage.overriddenMileage = day['overriddenMileage'];
            mileage.reconnects = day['reconnects'];
            mileage.ruleApplied = day['ruleApplied'];
            mileage.actualMileage = day['actualMileage'];
            mileage.policyNumber = policyNumber;
            mileage.vin = vin;
            response.push(mileage);
        }
        return response;
    };

    determineIfGracePeriod(mileages: RatedMileage[]): boolean {
        if (mileages.length > 0) {
            let mostRecentRecord = mileages[0];
            for (const i of mileages) {
                if (moment(i.ratedMileageDate).isAfter(moment(mostRecentRecord.ratedMileageDate), 'day')) {
                    mostRecentRecord = i;
                }
            }
            if (mostRecentRecord && mostRecentRecord.ruleApplied.toString() === 'GRACE') {
                return true;
            }
        }
        // return true; Return true everytime, for analysis SMCC
        return false;
    }

    getTotalMileageInTimeframe(vin: string, tf: TimeFrame): number {
        const mileageKey = this.user.getPolicyNumber() + vin;
        if (!this.mileageResponses.has(mileageKey)) {
            return 0;
        }
        return this.mileageResponses.get(mileageKey).filter((mileage) =>
            moment(mileage.ratedMileageDate).isBetween(tf.start.clone().subtract(1, 'day'), tf.end)).map((mileage) => mileage.ratedMileage || 0)
            .reduce((first, next) => first + next, 0);
    }

    formatDate(d: Date): string {
        return `${d.getFullYear()}-${this.padNumber(d.getMonth() + 1)}-${this.padNumber(d.getDate())}`;
    }

    padNumber(num: number): string {
        return num.toString().length === 1 ? `0${num}` : num.toString();
    }

    clearCache(): void {
        this.mileageResponses = new Map<string, RatedMileage[]>();
    }
}
