import { MonthlyWeeklyTotalSummary, EventInformation } from 'app/service/sre-summary.service';
import { Discount } from 'app/model/discount';
import { VehicleResponse } from 'app/service/vehicle.service';

export class SreSummaryContent {
    vehicle: VehicleResponse;
    discount: Discount;
    factors: RatingFactorForDisplay[];
    summary: MonthlyWeeklyTotalSummary;
    firstSummaryDate: string;
    lastSummaryDate: string;
    projectedAnnualMileage: number;
    dateAsRequested: string;

    constructor(
    ) {
        this.vehicle = new VehicleResponse();
        this.discount = new Discount(null, 0, '');
        this.factors = [];
        this.summary = <MonthlyWeeklyTotalSummary>{};
        this.firstSummaryDate = ''; // Recipient defaults to allowing all backward navigation if this is falsey.
        this.lastSummaryDate = '';
        this.projectedAnnualMileage = 0;
        this.dateAsRequested = '';
    }
}

export class RatingFactorForDisplay {
    event: EventInformation;
    displayName: string;
    unit: string;
    thresholds: number[];
    title: string;
    percent: string;
}
