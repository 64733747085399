import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { LoggerService } from 'app/service/logger.service';
import { OAuthEmitterService } from '@nationwide/angular-oauth-module';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DataService } from 'app/service/data.service';
import { PolicyNumberFormatService } from 'app/service/policy-number-format.service';

@Injectable()
export class AdminGuard implements CanActivate {
  // eslint-disable-next-line max-params
  constructor(
    public router: Router,
    private logger: LoggerService,
    private oauthEmitterService: OAuthEmitterService,
    private dataService: DataService
  ) { }

  canActivate(): boolean {
    if (window.location.href.includes('policy')) {
      let selectedPolicy = window.location.href.split('policy=')[1];
      selectedPolicy = PolicyNumberFormatService.formatPolicyNumber(selectedPolicy);
      sessionStorage.setItem('agentSelectedPolicy', selectedPolicy);
    }
    if (this.isAlreadyLoggedIn()) {
      return true;
    } else {
      const notifier = new Subject();
      this.oauthEmitterService.oauthEmitter.pipe(takeUntil(notifier)).subscribe((event) => {
        if (event.type === 'OAuthSuccess' || event.type === 'OAuthNotNecessary') {
          sessionStorage.setItem('isAdmin', 'true');
          this.dataService.setUser('Admin');
          notifier.next(event);
          notifier.complete();
          this.router.navigateByUrl('/smartrideAdmin');
        } else if (event.type === 'OAuthFailure') {
          this.logger.error('failure oauth check');
          notifier.next(event);
          notifier.complete();
          this.redirectToError();
        }
      });
    }
    return false;
  }

  isAlreadyLoggedIn(): boolean {
    const accessToken = sessionStorage.getItem('tokenDetails');
    if (accessToken) {
      return true;
    }
    return false;
  }

  redirectToError(): void {
    this.router.navigate(['/nw/systemError']);
  }
}
