import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-skip-link',
  templateUrl: './skip-link.component.html',
  styleUrls: ['./skip-link.component.css']
})
export class SkipLinkComponent implements OnInit {
  @Output() skip = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }
}
