import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { WindowRef } from 'app/service/window-ref';
import { NavbarService } from 'app/service/nav-bar.service';
import { UserService } from 'app/service/user.service';
import { EbiEventService } from 'app/service/ebi-event.service';
import { EcifService } from 'app/service/ecif.service';
import { LoggerService } from 'app/service/logger.service';
import { SelfEnrollmentService } from 'app/service/self-enrollment.service';

@Component({
  selector: 'app-self-enrollment',
  templateUrl: './self-enrollment.component.html',
  styleUrls: ['./self-enrollment.component.css']
})
export class SelfEnrollmentComponent implements OnInit {
  _window: any;
  mobileAppEarlyDiscount: boolean;

  // eslint-disable-next-line max-params
  constructor(
    public router: Router,
    public _user: UserService,
    public _navBar: NavbarService,
    public _windowRef: WindowRef,
    public _ebiEvents: EbiEventService,
    private ecif: EcifService,
    private _logger: LoggerService,
    private _selfEnrollmentService: SelfEnrollmentService
  ) {
    this._window = this._windowRef.nativeWindow;
  }

  ngOnInit(): void {
    this._navBar.headerDisplay(this._user.loggedIn, this.router.url);
    this._selfEnrollmentService.isSelfEnrollFlowInPlay = true;
    this.getEcifData();
    this.displayVideoByDiscountType();
    sessionStorage.setItem('selectedPolicy', this._user.getSelfEnrollPolicy());
  }

  continue(): void {
    this._ebiEvents.postSelfEnrollEvent('425080113');
    this.router.navigateByUrl('/selfEnrollment/review');
  }

  isEligibleForEarlyRewards(): boolean {
    const eligibleDiscountId = sessionStorage.getItem('eligibleDiscountId');
    return environment.eligibleEarlyRewardsDiscounts.indexOf(eligibleDiscountId) >= 0;
  }

  getEcifData(): void {
    this.ecif.getEcifData().subscribe((res: any) => {
      for (const contact of res.contactPoints) {
        if (contact.contactPointType === 'Postal Address') {
          this._user.setState(contact.postalAddress.state);
        }
        if (contact.contactPointType === 'Telephone Number') {
          this._user.setPhoneNumber(contact.phone.referenceNumber);
        }
      }
    }, (error) => {
      this._logger.error(error);
      this.router.navigate(['nw/systemError']);
    });
  }

  goToLearnMore(): void {
    window.open(environment.selfEnrollLearnMoreUrl, '_blank');
  }

  cancel(): void {
    this._window.location.replace(environment.myAccountUrl);
  }

  displayVideoByDiscountType(): void {
    if (sessionStorage.getItem('eligibleDiscountId') === 'MobileAppEarlyDiscount') {
      this.mobileAppEarlyDiscount = true;
    } else {
      this.mobileAppEarlyDiscount = false;
    }
  }
}
