import { Injectable } from '@angular/core';
import { AbstractLoggerService, LoggingLevel, LoggingJSON, SplunkService } from '@nationwide/client-logging';
import { environment } from 'environments/environment';

@Injectable()
export class LoggerService extends AbstractLoggerService {
  retryLogCurrentContentsTime: number;
  // Required settings for AbstractLoggerService
  clientLoggingEnabled = true;
  maxLogLength: number;
  maxRetryAttempts: number;
  retryDelay = 0;
  splunkToken = environment.splunkProps.token;
  appName = environment.splunkProps.appName;
  pushLength = environment.CLIENT_LOGGING_API.PUSH_LENGTH;
  clientId = environment.authorize.client_id;
  endpoint = environment.CLIENT_LOGGING_API.ENDPOINT;
  loggingJson = <LoggingJSON>environment.LOGGING.logJSON;
  loggingLevel = <LoggingLevel>environment.LOGGING.level;
  envName = environment.envName;
  logToConsole = environment.LOGGING.logToConsole === undefined ? true : environment.LOGGING.logToConsole;

  // forLocal
  userName: string;

  constructor(
    splunkService: SplunkService
  ) {
    super(splunkService);
    this.maxLogLength = 190000;
    this.maxRetryAttempts = 5;
  }

  getAuthToken(): string {
    const oauthToken = sessionStorage.getItem('tokenDetails');
    return oauthToken?.split(',')[0] || '';
  }

  info(statement: string, json?: object): void {
    super.info(statement, json);
  }

  error(statement: string, json: object = {}): void {
    super.error(statement, json);
  }

  setUser(userName): void {
    this.userName = userName;
  }

  getUser(): string {
    return this.userName;
  }

  getSessionId(): string {
    const oauthToken = sessionStorage.getItem('tokenDetails');
    return oauthToken?.split(',')[1] || '';
  }
}
