import { Component, OnInit, OnDestroy, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Device } from 'app/model/device';
import { Vehicle } from 'app/model/vehicle';
import { UserService } from 'app/service/user.service';
import { NavbarService } from 'app/service/nav-bar.service';
import { Subscription } from 'rxjs';
import { TimeframeService } from 'app/service/timeframe.service';
import { TimeFrameUnit } from 'app/enum/timeframe';
import * as moment from 'moment';

import { TelematicsTransactionsService } from 'app/service/telematics-transactions.service';
import { EstimatedPaymentDetails } from 'app/model/estimatedPaymentDetails';
import { EbiEventService } from 'app/service/ebi-event.service';
import { PersonalLinesPolicyService } from 'app/service/personal-lines-policy.service';
import { RatedMileageService } from 'app/service/rated-mileage.service';
import { TimeFrame } from 'app/interfaces/timeframe.interface';
import { environment } from 'environments/environment';
import { TelematicsPaymentDetails } from 'app/model/telematicsPaymentDetails';
import { LoggerService } from 'app/service/logger.service';
import { TermAndConditionStatusService } from 'app/service/term-and-condition-status.service';
import { BrandingService } from 'app/service/branding.service';
import { DataService } from 'app/service/data.service';

@Component({
    selector: 'app-monthly-summary',
    templateUrl: './monthly-summary.component.html',
    styleUrls: ['./monthly-summary.component.css']
})
export class MonthlySummaryComponent implements OnInit, OnDestroy, AfterViewInit {
    dateStart;
    dateEnd;
    deviceLastUpdated: Date;
    device: Device;
    vehicle: Vehicle;
    milesInTimeframe: number = null;
    date1Format: string;
    date2Format: string;
    timeFrameSubscription: Subscription;
    paymentDetails: TelematicsPaymentDetails;
    estimatedPremiumEnd: string;
    estimatedPremiumStart: string;
    gracePeriod = false;
    paymentDataLoaded = false;
    partialMonth = false;
    parent: any;
    noData = false;
    shouldDisplayCustomAlert = false;
    currentMonth: boolean;
    termAndConditionConsent: string;
    dataStatus: boolean;
    showVehicleNickName = false;
    mileageData: TelematicsPaymentDetails;
    userRole: string;

    // sm2 variables
    smProgramType: string;
    nonWeightedCostPerMile;
    nonWeightedCostPerMileFormat: string;
    nonWeightedCostPerMileAsString: string;
    ratedTermMileage: any;
    sm2MilesDriven: number;
    previousMonthMiles: any;
    getEstimatedPaymentDetailsDataPrevoiusMonthAssignment: boolean;
    previousMonthMilesDisplay;
    previousMonthNonWeightedCostPerMile;
    previousMonthNonWeightedCostPerMileFormat: string;
    previousMonthNonWeightedCostPerMileAsString: string;
    monthUnit: string;
    previousMonthMileageData: any;
    mileageDifference: any;
    estimatedMiles: any;
    reverifiedMiles: any;
    currentTermMileage: any;
    agentView: any;

    // eslint-disable-next-line max-params
    constructor(
        private user: UserService,
        private route: ActivatedRoute,
        private navBar: NavbarService,
        public timeframeService: TimeframeService,
        public router: Router,
        public telematicTransactions: TelematicsTransactionsService,
        public ebiEvents: EbiEventService,
        public policy: PersonalLinesPolicyService,
        public mileage: RatedMileageService,
        public changeDetector: ChangeDetectorRef,
        private logger: LoggerService,
        public dataService: DataService,
        public termAndConditionStatusService: TermAndConditionStatusService) { }

    ngOnInit(): void {
        if (sessionStorage.getItem('isAgent')) {
            this.agentView = true;
        }
        this.parent = this.route.parent.snapshot;
        this.vehicle = this.route.snapshot.data.vehicle;
        sessionStorage.setItem('VehicleType', this.vehicle.make);
        this.smProgramType = this.vehicle.scoringModel;
        if (this.dateStart !== undefined && this.dateEnd !== undefined) {
            const tf = TimeFrame.buildTimeframe(moment(this.dateStart), moment(this.dateEnd), TimeFrameUnit.MONTH);
            this.milesInTimeframe = this.vehicle.calcMilesDrivenInTimeframe(tf);
        } else {
            this.buildMonthTimeFrame();
        }
        this.overwriteDeviceStatus();
        this.overwriteDeviceLastUpdated();
        this.dataStatus = this.isDataSharingForConnectedcar(this.vehicle.device.status);
        this.termAndConditionConsent = this.getTermsAndConditionConsentStatus(this.vehicle.vin, 'smiles');
        this.getSmilesVersionType();

        // User Role Splunk Logging
        // Reading roles directly from session storage due to current issues with role data service (data.service.ts)
        const userPolicyNumber = this.user.getPolicies()[0] ? this.user.getPolicies()[0].toString() : '';
        const statePrefix = 2;
        const stateSuffix = 3;

        let currentSessionStorageUserRole;
        if (sessionStorage.getItem('isAgent') === 'true') {
          currentSessionStorageUserRole = 'Agent';
        } else if (sessionStorage.getItem('isAdmin') === 'true') {
          currentSessionStorageUserRole = 'Admin';
        } else {
          currentSessionStorageUserRole = 'Member';
        }

        this.logger.info('Landed on SmartMiles Monthly Summary Page', {
          policyNumber: userPolicyNumber,
          policyState: userPolicyNumber[statePrefix] + userPolicyNumber[stateSuffix],
          brand: 'SmartMiles',
          deviceTypeIdentifier: this.vehicle.vendorIdCode,
          role: currentSessionStorageUserRole
        });
    }

    ngAfterViewInit(): void {
        this.setupSubscriptions();
        this.changeDetector.detectChanges();
    }

    buildMonthTimeFrame(): void {
        const startDateString = this.route.snapshot.paramMap.get('amount');
        const newTf = this.vehicle.getTermLimitsContainingMoment(moment(startDateString));
        this.dateStart = moment.max(newTf.start.clone(), moment(this.vehicle.smEnrollDate));
        this.dateEnd = newTf.end.clone();
        this.timeframeService.updateTimeframeRules(newTf);
    }

    setupSubscriptions = (): void => {
        this.timeFrameSubscription = this.timeframeService.timeframeChange$.subscribe((tf) => {
            if (tf.unit !== TimeFrameUnit.MONTH) {
                return;
            }
            this.dateStart = moment.max(tf.start, moment(this.vehicle.smEnrollDate));
            this.dateEnd = tf.end.toDate();
            this.date1Format = tf.start.year() === tf.end.year() ? 'LLLL d' : 'LLLL d, y';

            this.partialMonth = this.dateStart.isSame(moment(this.vehicle.smEnrollDate), 'day') &&
                moment(this.vehicle.smEnrollDate).isAfter(moment(this.vehicle.periodStart), 'day') &&
                this.dateStart.isAfter(this.dateStart.clone().startOf('month'), 'day');
            if (this.vehicle !== undefined) {
                if (!this.vehicle.ratedMileage || this.vehicle.ratedMileage.length === 0) {
                    this.mileage.getRatedMileage(
                        this.vehicle.vin, null, this.vehicle.smEnrollDate, this.vehicle.periodEnd).subscribe(() => {
                            this.milesInTimeframe = this.mileage.getTotalMileageInTimeframe(this.vehicle.vin, tf);
                        });
                } else {
                    this.milesInTimeframe = this.mileage.getTotalMileageInTimeframe(this.vehicle.vin, tf);
                }
                this.gracePeriod = this.mileage.determineIfGracePeriod(this.vehicle.ratedMileage);
                this.vehiclePaymentDetails(tf);
            }
        });

        if (this.parent) {
            this.navBar.headerDisplay(this.user.isLoggedIn, this.parent.url.toString());
        }
    };

    vehiclePaymentDetails = (tf): void => {
        this.currentMonth = false;
        if (tf.unit !== TimeFrameUnit.MONTH) {
            return;
        }
        this.paymentDataLoaded = false;
        this.estimatedPremiumStart = tf.start.format('YYYY-MM-DD');
        this.estimatedPremiumEnd = tf.end.format('YYYY-MM-DD');
        if (moment(this.estimatedPremiumEnd).isSameOrAfter(moment(environment.futureDate).format('YYYY-MM-DD'))) {
            this.currentMonth = true;
            this.monthUnit = 'miles and counting';
        } else {
            this.monthUnit = 'miles';
        }
        if (this.vehicle.paymentDetails && this.vehicle.paymentDetails.length > 0) {
            for (const paymentDetail of this.vehicle.paymentDetails) {
                if (moment(paymentDetail.startDate).isSame(tf.start, 'day')) {
                    this.paymentDetails = paymentDetail;
                    this.paymentDataLoaded = true;
                    this.noData = false;
                    return;
                }
            }
        } else {
            this.vehicle.paymentDetails = [];
        }

        this.getEstimatedPaymentDetailsData(tf);
    };

    getEstimatedPaymentDetailsData(tf): void {
        this.reverifiedMiles = 0;
        this.mileageData = this.telematicTransactions.getTelematicsPaymentDetailsData(
            this.vehicle?.vehicleId, this.estimatedPremiumStart, this.vehicle.vehicleEnrollments
        );
        if (
            this.vehicle.paymentDetails &&
            this.vehicle.paymentDetails.length > 0
        ) {
            // sm2 factor-circle box/main monthly data refresh after period transition
              this.nonWeightedCostPerMile = this.paymentDetails.nonWeightedCostPerMile;
              this.setNonWeightedCostPerMileFormat(this.nonWeightedCostPerMile);
              this.nonWeightedCostPerMileAsString = this.costPerMileStringConversion(this.nonWeightedCostPerMile);
              this.ratedTermMileage = this.paymentDetails.ratedTermMileage;
              this.sm2MilesDriven = this.paymentDetails.actualMiles;

              this.previousMonthMilesDisplay = this.paymentDetails.previousMonthMiles;
              this.previousMonthNonWeightedCostPerMile = this.paymentDetails.previousMonthNonWeightedCostPerMile;
              this.setPreviousMonthNonWeightedCostPerMileFormat(this.previousMonthNonWeightedCostPerMile);
              this.previousMonthNonWeightedCostPerMileAsString = this.costPerMileStringConversion(this.previousMonthNonWeightedCostPerMile);

            for (const paymentDetail of this.vehicle.paymentDetails) {
                if (moment(paymentDetail.startDate).isSame(tf.start, 'day')) {
                    this.paymentDetails = paymentDetail;
                    this.paymentDataLoaded = true;
                    this.noData = false;
                    return;
                }
            }
        } else if (this.mileageData) {
            // initial sm2-factor-circle/main monthly data cards population
            // this.vehicle.paymentDetails.push(this.mileageData);
            this.paymentDetails = this.mileageData;

            this.nonWeightedCostPerMile = this.paymentDetails.nonWeightedCostPerMile;
            this.setNonWeightedCostPerMileFormat(this.nonWeightedCostPerMile);
            this.costPerMileStringConversion(this.nonWeightedCostPerMile);
            this.nonWeightedCostPerMileAsString = this.costPerMileStringConversion(this.nonWeightedCostPerMile);
            this.ratedTermMileage = this.paymentDetails.ratedTermMileage;
            this.sm2MilesDriven = this.paymentDetails.actualMiles;
            this.currentTermMileage = this.paymentDetails.currentTermMileage;

            this.previousMonthMilesDisplay = this.paymentDetails.previousMonthMiles;
            this.previousMonthNonWeightedCostPerMile = this.paymentDetails.previousMonthNonWeightedCostPerMile;
            this.setPreviousMonthNonWeightedCostPerMileFormat(this.previousMonthNonWeightedCostPerMile);
            this.previousMonthNonWeightedCostPerMileAsString = this.costPerMileStringConversion(this.previousMonthNonWeightedCostPerMile);
            this.mileageDifference = Math.abs(this.paymentDetails.mileageDifference);
            this.estimatedMiles = this.paymentDetails.estimatedMiles;
            if (this.paymentDetails.reverifiedMiles && this.paymentDetails.reverifiedMiles !== 0) {
                this.reverifiedMiles = this.paymentDetails.reverifiedMiles;
            }

            this.paymentDataLoaded = true;
            this.noData = false;
        } else {
            this.noData = true;
            this.paymentDataLoaded = true;
        }
    }

    routeToTotal(): void {
        this.ebiEvents.postEvent('Monthly timeline');
        const termTf = <TimeFrame>{
            start: this.vehicle.periodStart ? moment(this.vehicle.periodStart) : moment(this.vehicle.deviceStatusTimeline.installDate),
            end: this.vehicle.periodEnd ? moment(this.vehicle.periodEnd) : moment(this.vehicle.deviceStatusTimeline.completionDate),
            unit: TimeFrameUnit.TERM
        };
        const date = moment(environment.futureDate).isBefore(moment(this.vehicle.periodEnd)) ?
            moment(environment.futureDate).format('YYYYMMDD') :
            moment(this.vehicle.periodEnd).format('YYYYMMDD');
        this.timeframeService.updateTimeframeRules(termTf);
        this.router.navigateByUrl(`smiles/${date}/term/totalSummary`);
    }

    ngOnDestroy(): void {
        if (this.timeFrameSubscription) {
            this.timeFrameSubscription.unsubscribe();
            this.timeFrameSubscription = null;
        }
    }

    shouldProvideEditLinkForNickname(): boolean {
        return !(this.vehicle?.contactPreferences && this.nicknameIsValid(this.vehicle.contactPreferences.nickname));
    }

    getLinkForEditNickname(): string {
        return '/preferences/smiles/';
    }

    overwriteDeviceStatus(): void {
        const previousDayIndex = 1;
        if (this.vehicle?.device && this.vehicle.ratedMileage.length > 1) {
            if (!this.vehicle.ratedMileageDataUnavailable && (this.vehicle.ratedMileage[previousDayIndex].heartbeatIndicator || this.vehicle.ratedMileage[previousDayIndex + 1].heartbeatIndicator)) {
                if (this.vehicle.device.status.toLowerCase().indexOf('installed') >= 0) {
                    this.vehicle.device.status = 'Device: Active';
                }
            }
        }
    }

    private nicknameIsValid(nickname: string): boolean {
        if (!nickname) {
            return false;
        }
        return !!nickname.trim();
    }

    /*
      Fix for Defect QC 476011 for SMiles, overwrite device last updated as the last ratedMileage recieved from device
      Iterates through the entire array of rated mileage and selects the most recent one
    */
    private overwriteDeviceLastUpdated(): void {
        if (this.vehicle?.ratedMileage && this.vehicle.ratedMileage.length > 0) {
            let lastUpdated = this.vehicle.deviceStatusTimeline.enrollDate;
            this.vehicle.ratedMileage.forEach((mileage) => {
                if (moment(mileage.ratedMileageDate).isSameOrAfter(moment(lastUpdated))) {
                    lastUpdated = mileage.ratedMileageDate;
                }
            });
            this.deviceLastUpdated = lastUpdated;
        }
    }

    getTermsAndConditionConsentStatus(vin, type): string {
        let status: string;
        if ((this.vehicle.scoringModel === 'SM1' || this.vehicle.scoringModel === 'SM2') && this.vehicle.vendorIdCode === 'FORD') {
            this.termAndConditionStatusService.getConnectedCarConsentStatus(vin, type).subscribe((response) => {
                status = response?.[0]?.statusCode;
            });
        }
        return status;
    }

    showDataNotSharingNotification(): boolean {
        if (this.isSmilesConnectedCar() && !this.dataStatus) {
            return true;
        }
        return false;
    }

    isDataSharingForConnectedcar(deviceStatus): boolean {
        if (deviceStatus === 'Device: Active' || deviceStatus === 'Data status: Sharing' || deviceStatus.includes('Active')) {
            return true;
        }
        // else {
        //     return false;
        // }
    }

    isSmilesConnectedCar(): boolean {
        return (this.vehicle.scoringModel === 'SM1' || this.vehicle.scoringModel === 'SM2') && this.vehicle.vendorIdCode === 'FORD';
    }

    getMyAccountUrl(): string {
        return environment.myAccountUrl;
    }

    accountBillingClicked(ebiEventToPost?: string): void {
        if (ebiEventToPost === 'My Accounts') {
            this.logger.info('User clicked on MyAccounts/Billing', {
                policyNumber: this.user.getPolicyNumber(),
                userId: this.user.getUsername(),
                programType: sessionStorage.getItem('selectedProgramType')
            });
        }
    }

    getSmilesVersionType(): void {
        const scoringModel = this.vehicle.scoringModel;
        if (scoringModel === 'SM2') {
            BrandingService.setSmartMilesVersion('2.0');
        } else {
            BrandingService.setSmartMilesVersion('1.0');
        }
    }

    setNonWeightedCostPerMileFormat(cpm): void {
      if (cpm >= 1.00) {
        this.nonWeightedCostPerMileFormat = 'dollars';
      } else {
        this.nonWeightedCostPerMileFormat = 'cents';
      }
    }

    setPreviousMonthNonWeightedCostPerMileFormat(cpm): void {
      if (cpm >= 1.00) {
        this.previousMonthNonWeightedCostPerMileFormat = 'dollars';
      } else {
        this.previousMonthNonWeightedCostPerMileFormat = 'cents';
      }
    }

    costPerMileStringConversion(cpm: number): string {
      let cpmString = '';
      try {
        // eslint-disable-next-line no-magic-numbers
        cpmString = cpm.toFixed(3);
      } catch {
        cpmString = '0';
      }
      return cpmString;
    }
  }
