import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { environment } from 'environments/environment';
import { PolicyNumberFormatService } from 'app/service/policy-number-format.service';
import { ServiceErrorHandler } from 'app/utility/service-error-handler';

@Injectable()
export class TelematicsService {
    private apiKey = environment.telematicsService.apiKey;
    private telematicsURL = environment.telematicsService.url;
    private deviceProgramURL = '/device-program-enrollments';
    private mobileProgramURL = '/mobile-program-enrollments';

    constructor(
        private http: HttpClient,
        private _serviceErrorHandler: ServiceErrorHandler
    ) { }

    getDeviceProgramEnrollments(vin: string, policyNumber: string): Observable<any> {
        const headers = new HttpHeaders()
            .set('X-NW-MessageID', uuid())
            .set('client_id', this.apiKey);

        const params = new HttpParams()
            .set('policyNumber', PolicyNumberFormatService.safeUriEncodePolicyNumber(policyNumber))
            .set('vin', vin);

        return this.http.get(this.telematicsURL + this.deviceProgramURL, { params, headers })
            .pipe(
                map((response: Observable<any>) =>
                    response),
                catchError((error) => {
                    this._serviceErrorHandler.handleError('getDeviceProgramEnrollments');
                    return of(error);
                })
            );
    }

    getMobileProgramEnrollments(policyNumber: string): Observable<any> {
        const headers = new HttpHeaders()
            .set('X-NW-MessageID', uuid())
            .set('client_id', this.apiKey);

        const params = new HttpParams()
            .set('policyNumber', PolicyNumberFormatService.safeUriEncodePolicyNumber(policyNumber));

        return this.http.get(this.telematicsURL + this.mobileProgramURL, { params, headers })
            .pipe(
                map((response: Observable<any>) =>
                    response),
                catchError((error) => {
                    this._serviceErrorHandler.handleError('getDeviceProgramEnrollments');
                    return of(error);
                })
            );
    }
}
