import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { PbsService } from '@nationwide/dgs-internet-servicing-pbs';
import { APICommonService } from '@nationwide/api-common-service';
import { QuickFlowDataService } from '../quick-flow/quick-flow-data.service';
import { MessageFilterService } from '@nationwide/internet-servicing-angular-pipes';
import { SchemaFormService } from '../../service/schema-form.service';
import { QuickAuthenticateService } from '../quick-flow/quick-authenticate.service';
import { DatePipe } from '@angular/common';
import { QuickIDSearchFormService } from './quick-id-search-form.service';
import { BuildDynamicQuestionService, FormsHelperService } from '@nationwide/dgs-internet-servicing-dynamic-forms';
import { QuickIdDataService } from './quick-id-data.service';
import { ContentfulService } from '@nationwide/internet-servicing-contentful';
import { LoggerService } from 'app/service/logger.service';
import { ActiveApiCall, WaitMessageService } from '@nationwide/internet-servicing-angular-components';
import { timeout } from 'rxjs';

@Component({
    selector: 'app-quick-id-main',
    templateUrl: './quick-id-main.component.html',
    styleUrls: ['../quick-id.scss', './quick-id-main.component.scss'],
    providers: [QuickAuthenticateService, PbsService]
})
export class QuickIDMainComponent implements OnInit {
    birthDate = '';
    cookieData = {};
    dateOfBirthError: string;
    euaUrl = '';
    flowHeader = 'Enter details to continue';
    globalErrorMessage: string;
    ineligiblePolicyErrorMessage: string;
    isDateOfBirthError = false;
    isFutureDatedPolicy = false;
    isLoadComplete = false;
    isMasked = false;
    isPageLevelError = false;
    isPhoneNumberError = false;
    isPhoneNumberFieldVisible = true;
    isPolicyCancelled = false;
    isPolicyNumberError = false;
    isZipError = false;
    listener: () => void;
    loadIframe = false;
    messageData: ActiveApiCall;
    monthSlashDayLength: number;
    noControlsTouched = false;
    pageLevelErrorMessage: string;
    pbsCallTimeOut: number;
    phoneNumber: any;
    phoneNumberError: string;
    policyNumber: string;
    policyNumberError: string;
    response: any;
    searchFormGroup: FormGroup;
    searchGroup: any = {};
    serverSideErrorMessage: string;
    unMaskedDateOfBirth = '';
    zipCode = '';
    zipError: string;
    private _isPhoneNumberRadioButtonSelected = true;

    // eslint-disable-next-line max-params
    constructor(
        private router: Router,
        private apiCommonService: APICommonService,
        private contentfulService: ContentfulService,
        private pbsService: PbsService,
        private quickFlowDataService: QuickFlowDataService,
        private quickAuthenticationService: QuickAuthenticateService,
        private messageFilter: MessageFilterService,
        private renderer: Renderer2,
        private datePipe: DatePipe,
        private LOGGER: LoggerService,
        private quickIDSearchFormService: QuickIDSearchFormService,
        private schemaFormService: SchemaFormService,
        private dynamicService: BuildDynamicQuestionService,
        private formsHelperService: FormsHelperService,
        private waitMessageService: WaitMessageService,
        public quickIdDataService: QuickIdDataService
    ) {
        this.monthSlashDayLength = 5;
        this.isPhoneNumberFieldVisible = this.checkPhoneNumberFieldVisible();
        this.isPhoneNumberRadioButtonSelected = this.isPhoneNumberFieldVisible;
    }

    authenticate(): void {
        this.formatInputs();
        this.euaUrl = this.quickAuthenticationService.nwExpressAuth(this.searchGroup);
        this.loadIframe = true;
        this.isLoadComplete = false;
        this.LOGGER.info('Quick-ID Landing Page Continue Click');
        this.messageData = this.contentfulService.getContentfulItemById('quickWaitMessage');
        this.waitMessageService.waitMessageUpdate(this.messageData, true);
        this.listener = this.renderer.listen('window', 'message', (event): void => this.receiveMessage(event, this.listener));
    }

    checkSessionStorageError(errorKey: string): void {
        if (sessionStorage.getItem(errorKey)) {
            this[errorKey] = JSON.parse(sessionStorage.getItem(errorKey));
            sessionStorage.removeItem(errorKey);
        }
    }

    clearInputErrors(): void {
        this.isDateOfBirthError = false;
        this.isZipError = false;
        if (this.isPhoneNumberRadioButtonSelected) {
            this.isPhoneNumberError = false;
        } else {
            this.isPolicyNumberError = false;
        }
    }

    continue(): void {
        const phoneNumber = this.getControlValueValid('quickIDSearch-PhoneNumber');
        const policyNumber = this.getControlValueValid('quickIDSearch-PolicyNumber');
        const zipCode = this.getControlValueValid('quickIDSearch-ZipInput');
        const dob = this.getControlValueValid('quickIDSearch-DobInput');

        const isPageValid = (phoneNumber.valid || policyNumber.valid) && zipCode.valid && dob.valid;
        if (isPageValid) {
            this.phoneNumber = this.trimPhoneNumber(phoneNumber.value);
            this.policyNumber = policyNumber.value;
            this.zipCode = zipCode.value;
            this.birthDate = dob.value;
            this.isPageLevelError = false;
        } else {
            this.displayPageLevelError('Please check the information below and try again.');
        }

        const trimmedFormValues = this.formsHelperService.getFormKeyValueObject(this.quickIdDataService.questions, this.quickIdDataService.form);
        const shownSections = Object.keys(trimmedFormValues).filter((section) => Object.keys(trimmedFormValues[section]).length > 0);
        const trimmedQuestions = {};
        shownSections.forEach((section) => {
            trimmedQuestions[section] = this.quickIdDataService.questions[section];
        });

        if (this.formsHelperService.checkValid(trimmedQuestions, this.quickIdDataService.form)) {
                this.authenticate();
        } else {
            this.displayPageLevelError('Please check the information below and try again.');
        }
    }

    displayPageLevelError(errorMessage: string): void {
        this.isPageLevelError = true;
        this.isLoadComplete = true;
        if (errorMessage === 'serverSideError') {
            this.pageLevelErrorMessage = this.serverSideErrorMessage;
            this.unMaskedDateOfBirth = '';
        } else {
            this.pageLevelErrorMessage = errorMessage;
        }
    }

    formatDOB($event): void {
        if ($event.inputType !== 'deleteContentForward' && $event.inputType !== 'deleteContentBackward') {
            const dob = $event.target.value;
            const dayMonthAndSlashEntered = 6;
            if (dob.length < dayMonthAndSlashEntered) {
                this.insertSlashes(dob, $event);
            }
        }
    }

    formatInputs(): void {
        this.searchGroup = {
            zipCode: this.zipCode,
            birthDate: this.datePipe.transform(new Date(this.birthDate), 'yyyy-MM-dd')
        };
        if (this.policyNumber) {
            this.policyNumber = this.policyNumber.toUpperCase();
            this.searchGroup.agreementNumber = this.policyNumber;
        } else {
            this.searchGroup.phoneNumber = this.phoneNumber;
        }
    }

    goToLoginPage(): void {
        this.router.navigateByUrl('smartride/login');
    }

    ngOnInit(): void {
        this.generateDynamicForm();
        this.isPageLevelError = false;
        this.setErrorMessagesFromContentful();
        this.isLoadComplete = true;
        this.quickFlowDataService.fromQuickIdPolicySelectionPage = false;
    }

    receiveMessage(event: any, listener: () => void): void {
        if (!this.messageFilter.checkEvent(event)) {
            return;
        }
        this.loadIframe = false;

        if (event.data.includes('_success')) {
            if (this.getControlValueValid('quickIDSearch-PhoneNumber').value) {
                this.searchByPhoneNumber();
            } else {
                this.setSearchInputValuesToFlow(this.birthDate, this.zipCode, 'Agreement');
                sessionStorage.setItem('selectedPolicy', this.policyNumber);
                this.goToLoginPage();
            }
        } else {
            this.displayPageLevelError('serverSideError');
            this.quickIdDataService.form.reset();
        }
        listener();
    }

    receiveServiceCall(event): void {
        if (event.includes('policyNumber')) {
            this.policyNumber = this.getControlValueValid('quickIDSearch-PolicyNumber').value;
            this.setControlValue(event, this.policyNumber?.toUpperCase());
        }
    }

    searchByPhoneNumber(): void {
        this.isLoadComplete = false;
        let quickIDParams: any = {};
        const dateOfBirth = this.birthDate.replace(/\//g, '');
        this.quickFlowDataService.dateOfBirth = dateOfBirth;
        const token = sessionStorage.getItem('access_token');
        this.pbsCallTimeOut = 10000;
        quickIDParams = {
            phoneNumber: this.phoneNumber,
            dob: dateOfBirth,
            zipCode: this.zipCode,
            accessToken: token,
            transactionId: this.apiCommonService.generateTransactionId(),
            appCookie: 'testuser',
            sessionId: '',
            currentPageUrl: this.router.url
        };
        this.quickFlowDataService.phoneNumber = quickIDParams.phoneNumber;
        this.setSearchInputValuesToFlow(this.birthDate, quickIDParams.zipCode, 'Phone');
        this.messageData = this.contentfulService.getContentfulItemById('ecifWaitMessage');
        this.waitMessageService.waitMessageUpdate(this.messageData, true);
        this.pbsService.searchByPhoneNumber(quickIDParams).pipe(timeout(this.pbsCallTimeOut)).subscribe((res) => {
            const unsuccessfulResponse = res.error;
            this.isLoadComplete = true;
            if (res && !unsuccessfulResponse) {
                this.quickFlowDataService.pbsResponse = res;
                if (res.quickIdAccounts?.length >= 1) {
                    sessionStorage.setItem('policyNumber', res.quickIdAccounts[0].agreementNumber);
                    this.goToLoginPage();
                } else {
                    this.displayPageLevelError(this.ineligiblePolicyErrorMessage);
                    this.quickIdDataService.form.reset();
                }
            } else {
                this.displayPageLevelError('serverSideError');
                this.quickIdDataService.form.reset();
            }
        });
    }

    setErrorMessagesFromContentful(): void {
      this.serverSideErrorMessage = this.contentfulService.getContentfulContentById('serverSideError');
      this.ineligiblePolicyErrorMessage = this.contentfulService.getContentfulContentById('ineligiblePolicyError');
    }

    setGlobalValidationErrorMessage(): void {
        this.displayPageLevelError('Please check the information below and try again.');
    }

    setSearchInputValuesToFlow(dateOfBirth: string, zipCode: string, searchType: string): void {
        this.quickFlowDataService.dateOfBirth = dateOfBirth;
        this.quickFlowDataService.zipCode = zipCode;
        this.quickFlowDataService.searchType = searchType;
        this.quickFlowDataService.previousUrl = window.document.location.href;
    }

    private checkPhoneNumberFieldVisible(): boolean {
        const listOfCookieValues = document.cookie.split(';');
        listOfCookieValues.forEach((cookie) => {
            cookie = cookie.trim();
            const values = cookie.split('=').map((value: string) => value.trim());
            this.cookieData[decodeURIComponent(values[0])] = decodeURIComponent(values.splice(1).join('='));
        });
        const cookieValue = this.cookieData['nwisc'];
        const sessionValue = window.sessionStorage.getItem('phoneTab');
        return !(cookieValue !== undefined && cookieValue === 'phnbl' || sessionValue !== undefined && sessionValue === 'Blocked');
    }

    private generateDynamicForm(): void {
        const formTypeMap = {
            quickIDSearch: {
                SelectNumber: 'true',
                PhoneNumber: '',
                PolicyNumber: '',
                ZipCode: '',
                DateOfBirth: ''
            }
        };
        this.quickIdDataService.formTypeMap = formTypeMap;
        const sreForm = this.quickIDSearchFormService.returnJSON(this.isPhoneNumberFieldVisible);
        const formattedQuestions = this.schemaFormService.buildForm(sreForm, this.quickIdDataService.formTypeMap);
        this.quickIdDataService.questions = this.dynamicService.getQuestions(formattedQuestions);
        this.quickIdDataService.form = this.formsHelperService.createForm(this.quickIdDataService.questions, formattedQuestions);
    }

    private getControlValueValid(controlName): any {
        try {
            const section = this.getSectionFromControlName(controlName);
            // eslint-disable-next-line keyword-spacing, no-extra-parens
            const control = (<FormGroup>this.quickIdDataService.form.controls[section]).controls[controlName];
            return { valid: control.valid, value: control.value };
        } catch (e) {
            return { valid: false, value: '' };
        }
    }

    private trimPhoneNumber(phoneNumber: string): string {
      return phoneNumber ? phoneNumber.replace(/[()-\s]/g, '') : '';
    }

    private getSectionFromControlName(controlName: string): string {
        return controlName.slice(0, controlName.indexOf('-'));
    }

    private insertSlashes(dob, $event): void {
        const SIX = 6;
        const dayAndMonthEntered = 5;
        const expectedFirstSlash = 3;
        if (dob.length < SIX) {
            if (dob.length === 2) {
                if (dob.indexOf('/') <= -1) {
                    $event.target.value = `${dob}/`;
                }
            } else if (dob.length === dayAndMonthEntered) {
                if (dob.indexOf('/', expectedFirstSlash) <= -1) {
                    $event.target.value = `${dob}/`;
                }
            }
        }
    }

    private setControlValue(event, value): void {
        const eventAndFieldName = event.split(':');
        const field = eventAndFieldName[1];
        const section = field.split('-')[0];
        const idSearchForm = this.quickIdDataService.form.controls[section]['controls'];
        idSearchForm[field].setValue(value);
    }

    get isPhoneNumberRadioButtonSelected(): boolean {
        return this._isPhoneNumberRadioButtonSelected;
    }

    set isPhoneNumberRadioButtonSelected(isPhoneNumberRadioButtonSelected: boolean) {
        this._isPhoneNumberRadioButtonSelected = isPhoneNumberRadioButtonSelected;
        this.clearInputErrors();
        this.isPageLevelError = false;
    }
}
