import { Component, OnDestroy, OnInit, HostListener } from '@angular/core';
import { TripDataService } from 'app/service/tripSpeed-data-service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Vehicle } from 'app/model/vehicle';
import { UserService } from 'app/service/user.service';
import { NgbPanelChangeEvent, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { TimeFrame } from 'app/interfaces/timeframe.interface';
import { Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { Trip } from 'app/model/trip';
import * as moment from 'moment';
import { TimeFrameUnit } from 'app/enum/timeframe';
import { TimeframePipe } from 'app/pipes/timeframe.pipe';
import { EbiEventService } from 'app/service/ebi-event.service';
import { RatedMileageService } from 'app/service/rated-mileage.service';
import { RatedMileage } from 'app/model/rated-mileage';
import { LoggerService } from 'app/service/logger.service';
import { DataService } from 'app/service/data.service';

@Component({
    selector: 'app-daily-summary',
    templateUrl: './daily-summary.component.html',
    styleUrls: ['./daily-summary.component.css'],
    providers: [NgbTooltipConfig]
})
export class DailySummaryComponent implements OnInit, OnDestroy {
    routerSubscription: Subscription;
    tripsInTimeframe: Trip[];
    totalTripCount: number;
    vehicle: Vehicle;
    dateStart;
    dateEnd;
    timeframe: TimeFrame;
    ratedMileage: RatedMileage[];
    totalMilesDriven = 0;
    accordionRows: boolean[] = [];
    noTrips: boolean;
    roadTripDetected: boolean;
    maxMiles: number; // TODO: Should be provided from somewhere else eventually
    defaultMiles: number; // TODO: Should be provided from somewhere else eventually
    vehicleStats: VehicleStat[];
    mapLegend: string[][];
    mapTwNdLegend: string[][];
    mapTwTw1Legend: string[][];
    displaySpeedGraph = false;
    graphKeys: string[];
    tripsLength: number;
    serviceDown: boolean;
    collapse: boolean;
    shouldDisplayCustomAlert = false;
    isGraceRuleApplied: boolean;
    isOverriddenRuleApplied: boolean;
    isSmartMiles2: boolean;
    noDataRuleApplied: boolean;
    innerWidth: number;
    mobileView: boolean;
    isBanner = false;
    isAgent = false;

    @HostListener('window:resize', ['$event'])
    onResize(): void {
        this.checkInnerWidth(window.innerWidth);
    }
    // eslint-disable-next-line max-params
    constructor(
        public user: UserService,
        private route: ActivatedRoute,
        public router: Router,
        public mileage: RatedMileageService,
        public tfp: TimeframePipe,
        public config: NgbTooltipConfig,
        public tripService: TripDataService,
        public ebiEvents: EbiEventService,
        private logger: LoggerService,
        private dataService: DataService
    ) {
        config.placement = 'right';
    }

    ngOnInit(): void {
        this.vehicleStats = [];
        this.tripsInTimeframe = [];
        this.totalTripCount = 0;
        this.vehicle = this.route.snapshot.data.vehicle;
        this.isSmartMiles2 = false;
        if (this.vehicle.scoringModel === 'SM2') {
            this.isSmartMiles2 = true;
        }
        this.routerSubscription = this.router.events.pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(this.loadTripData);
        this.loadTripData(null);
        this.isAgent = false;
        if (sessionStorage.getItem('isAgent') === 'true') {
            this.isAgent = true;
        }
    }

    ngOnDestroy(): void {
        if (this.routerSubscription) {
            this.routerSubscription.unsubscribe();
        }
    }

    loadTripData = (event): void => {
        if (event && event.url.indexOf('dailySummary') === -1) {
            return;
        }
        this.dateStart = moment.max(moment(this.vehicle.smEnrollDate), moment(this.route.snapshot.paramMap.get('amount'))).endOf('day');
        this.vehicle.trips = [];
        const piidValue = this.vehicle.vin;
        this.timeframe = <TimeFrame>{
            start: this.dateStart.clone().startOf('day'),
            end: this.dateStart.clone().endOf('day'),
            unit: TimeFrameUnit.DAY
        };
        this.tripsInTimeframe = [];
        this.tripService.getTripData(
            piidValue, moment(this.vehicle.smEnrollDate).format('YYYYMMDD').toString(),
            moment(this.vehicle.periodEnd).format('YYYYMMDD').toString()
        ).subscribe((trips) => {
            if (trips === undefined) {
                this.serviceDown = true;
            } else {
                trips = <Trip[]> this.tfp.transform(trips, this.timeframe, 'minute');
                if (trips && trips.length > 0) {
                    this.totalTripCount = trips.length;
                    for (const trip of trips) {
                        this.tripService.getTripSpeedData(trip.tripNb.toString(), piidValue).pipe(tap((thisTrip) => {
                            const tmpTrip = new Trip();
                            tmpTrip.miles = trip['miles'];
                            tmpTrip.startTS = moment.unix(thisTrip['start']).toDate();
                            tmpTrip.endTS = moment.unix(thisTrip['end']).toDate();
                            tmpTrip.acceleration = thisTrip['acceleration'];
                            tmpTrip.speeds = thisTrip['mph'];
                            tmpTrip.coordinates = thisTrip['latlong'];
                            tmpTrip.fastAccelerationCount = thisTrip['acceleration'].length;
                            if (!this.isSmartMiles2) {
                                tmpTrip.braking = thisTrip['braking'];
                                tmpTrip.hardBrakeCount = thisTrip['braking'].length;
                            }
                            tmpTrip.stop = thisTrip['stop'];
                            tmpTrip.stopsCount = tmpTrip.stop ? tmpTrip.stop.length : 0;
                            tmpTrip.idle = trip['idle'];
                            tmpTrip.idleTimeRatio = trip['idleTimeRatio'];
                            tmpTrip.night = trip['night'];
                            tmpTrip.brakingAcceleration = trip['brakingAcceleration'];
                            tmpTrip.setIncidents();
                            if (moment(tmpTrip.startTS).isSameOrAfter(this.timeframe.start, 'day') &&
                                moment(tmpTrip.endTS).isSameOrBefore(this.timeframe.end, 'day')) {
                                this.tripsInTimeframe.push(tmpTrip);
                            }
                            this.tripsLength = this.tripsInTimeframe.length;
                            this.tripsInTimeframe.sort((a: any, b: any) => {
                                if (a.startTS && b.startTS) {
                                    if (a.startTS > b.startTS) {
                                        return -1;
                                    } else if (a.startTS < b.startTS) {
                                        return 1;
                                    }
                                }
                                return 0;
                            });
                            this.setupAccordion();
                        })).subscribe(() => { }, (error) => {
                            this.logger.error('Error retrieving trip speed');
                            this.logger.error(error);
                        });
                    }
                    this.setMapLegend();
                } else {
                    this.tripsInTimeframe = [];
                    this.totalTripCount = 0;
                }
                this.setTotalMilesDriven();
            }
        }, (error) => {
            this.logger.error('Error retrieving trips');
            this.logger.error(error);
        });
    };

    setTotalMilesDriven(): void {
        this.roadTripDetected = false;
        this.isGraceRuleApplied = false;
        this.isOverriddenRuleApplied = false;
        this.totalMilesDriven = 0;
        this.mileage.getRatedMileage(this.vehicle.vin, null, this.vehicle.smEnrollDate, this.vehicle.periodEnd).subscribe((mileage) => {
            this.ratedMileage = <RatedMileage[]> this.tfp.transform(mileage, this.timeframe);
            if (this.ratedMileage && this.ratedMileage.length > 0) {
                this.totalMilesDriven = this.ratedMileage[0].ratedMileage;
                this.noTrips = this.ratedMileage[0].ruleApplied.toUpperCase() === 'UNVERIFIED';
                this.roadTripDetected = this.ratedMileage[0].ruleApplied.toUpperCase() === 'CAPPED';
                this.isGraceRuleApplied = this.ratedMileage[0].ruleApplied.toUpperCase() === 'GRACE';
                this.isOverriddenRuleApplied = this.ratedMileage[0].ruleApplied.toUpperCase() === 'OVERRIDDEN';
                this.noDataRuleApplied = this.ratedMileage[0].ruleApplied.toUpperCase() === 'NO DATA';
                this.isBanner = true;
            }
            this.totalMilesDriven = Math.round(this.totalMilesDriven);
        });
    }

    setupAccordion = (): void => {
        this.accordionRows = [];
        let i = 0;
        while (this.tripsInTimeframe !== undefined && i < this.tripsInTimeframe.length) {
            /* eslint no-unused-expressions: [2, { allowTernary: true }]*/
            i === 0 ? this.accordionRows.push(true) : this.accordionRows.push(false);
            i = i + 1;
        }
    };

    toggle(event: NgbPanelChangeEvent): void {
        for (const i in this.accordionRows) {
            if (`toggle-${i}` === event.panelId || `card-toggle-${i}` === event.panelId) {
                this.accordionRows[i] = !this.accordionRows[i];
            } else {
                this.accordionRows[i] = false;
            }
        }
        this.displaySpeedGraph = false;
    }

    setMapLegend(): void {
        if (this.isSmartMiles2) {
            this.mapLegend = [
                ['night', 'Nighttime Driving', 'minutes', 'night'],
                ['stop', 'Number of stops', 'events', 'stopsCount'],
                ['acceleration', 'Fast Acceleration', 'events', 'fastAccelerationCount']
            ];
            this.graphKeys = ['night', 'stop', 'acceleration'];
        } else {
            this.mapLegend = [
                ['night', 'Nighttime Driving', 'minutes', 'night'],
                ['stop', 'Number of stops', 'events', 'stopsCount'],
                ['braking', 'Hard Braking', 'events', 'hardBrakeCount'],
                ['acceleration', 'Fast Acceleration', 'events', 'fastAccelerationCount']
            ];
            this.graphKeys = ['night', 'stop',
            'braking',
            'acceleration'];
        }
    }

    mapErrorHandler(): void { }

    checkInnerWidth(screenWidth: number): void {
        this.mobileView = false;
        const maxMobileWidth = 768;
        if (screenWidth < maxMobileWidth) {
            this.mobileView = true;
        }
    }
    getBackgroundColor(): object {
        if (this.isSmartMiles2) {
            return {'background-color': 'rgba(0,0,0,0)'};
        } else {
            return {'background-color': '#e7e7e7'};
        }
    }
    getTripDetailsStyle(): object {
        if (this.isSmartMiles2 && this.isBanner) {
            return {'padding-top': '10px'};
        } else {
            return {'padding-top': '0px'};
        }
    }

    getIdDailyAccordion(): string {
        if (this.isSmartMiles2) {
            return 'sm2_daily_accordion';
        }
        return 'daily_accordion';
    }
}

interface VehicleStat {
    value: number;
    label: string;
}

