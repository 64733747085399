import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Trip } from '../model/trip';
import { environment } from 'environments/environment';
import { ServiceErrorHandler } from '../utility/service-error-handler';
import { BrandingService } from './branding.service';
import { APICommonService } from '@nationwide/api-common-service';
import { LoggerService } from './logger.service';

// TODO Why is this file called 'tripSpeed-data-service.ts'? Is it a trap?
@Injectable()
export class TripDataService {
    // Map piid to trips for entire term
    trips = new Map<string, Trip[]>();

    // eslint-disable-next-line max-params
    constructor(
        private _http: HttpClient,
        public _errorHandler: ServiceErrorHandler,
        private apiCommonService: APICommonService,
        private logger: LoggerService
    ) { }

    // For SRE, supply vehicle.vid for piid value. For Smiles use vehicle.vin for piid.
    getTripData(piid: string, startDt: string, endDt: string): Observable<Trip[]> {
        if (BrandingService.getBranding() === 'smartmiles' && this.trips.has(piid + startDt + endDt)) {
            return of(this.trips.get(piid + startDt + endDt));
        }

        const urlPrefix = environment.sreApiURL;
        const tripUrl = `${urlPrefix}trips/${piid}`;
        const reqHeader = new HttpHeaders()
            .set('X-NW-Message-ID', this.apiCommonService.generateTransactionId())
            .set('client_id', environment.apiKey);

        let params = new HttpParams();
        params = params.append('policy', sessionStorage.getItem('selectedPolicy'));
        params = params.append('date', startDt);
        params = params.append('enddate', endDt);
        params = params.append('search', 'speeds');
        params = BrandingService.getBranding() === 'smartmiles' ? params.append('apptype', 'smiles') : params;
        if (environment.useValidatorService && sessionStorage.getItem('useValidator') === 'true') {
            const url = `${environment.validatorUrl}${piid}/retrieve`;
            const validatorParams = {
                serviceName: 'SmartRideExperience_2',
                applicationName: 'DGS-ISU-SMILES',
                endpoint: 'https://api-test.nwie.net/policymanagement/personallines/experience/v2/trips'
            };
            return this._http.post(url, validatorParams, { headers: reqHeader })
                .pipe(
                    map((response) => {
                        this.logger.debug(`myLog: tripSpeed-data-service.ts: ${response}`);
                        const cleanResponse = JSON.parse(response['cachedResponses'][0]['payload']);
                        return this.mapTrips(cleanResponse);
                    }),
                    tap((trips) => {
                        if (BrandingService.getBranding() === 'smartmiles') {
                            this.trips.set(piid + startDt + endDt, trips);
                        }
                    }),
                    catchError((error) => {
                        this._errorHandler.handleError('getTripData');
                        return of(error);
                    })
                );
        } else {
            return this._http.get(tripUrl, {
                headers: reqHeader,
                params
            })
                .pipe(
                    map(this.mapTrips),
                    tap((trips) => {
                        if (BrandingService.getBranding() === 'smartmiles') {
                            this.trips.set(piid + startDt + endDt, trips);
                        }
                    }),
                    catchError((error) => {
                        this._errorHandler.handleError('getTripData');
                        return of(error);
                    })
                );
        }
    }

    // For SRE, supply vehicle.vid for piid value. For Smiles use vehicle.vin for piid.
    getTripSpeedData(tripNb: string, piid: string): Observable<Trip> {
        const urlPrefix = environment.sreApiURL;
        const tripSpeedUrl = `${urlPrefix}tripspeed/${piid}`;

        const reqHeader = new HttpHeaders()
            .set('X-NW-Message-ID', this.apiCommonService.generateTransactionId())
            .set('client_id', environment.apiKey);

        let params = new HttpParams();
        params = params.append('policy', sessionStorage.getItem('selectedPolicy'));
        params = params.append('tripid', tripNb.substring(0, 10));
        params = BrandingService.getBranding() === 'smartmiles' ? params.append('apptype', 'smiles') : params;

        return this._http.get(tripSpeedUrl, {
            headers: reqHeader,
            params,
            responseType: 'text'
        })
            .pipe(
                map((res) => {
                    let respJson: Trip;
                    try {
                        respJson = JSON.parse(res.replace(/;/g, ','));
                    } catch (err) {
                        const errorTrip = new Trip();
                        errorTrip.err = true;
                        return errorTrip;
                    }
                    return respJson;
                }),
                catchError((error) => {
                    this._errorHandler.handleError('getTripSpeedData');
                    return of(error);
                })
            );
    }

    mapTrips = (input: any): Trip[] => {
        const allTrips = input['data'];
        if (allTrips === undefined) {
            return allTrips;
        }
        const result = [];
        for (const trip of allTrips) {
            const t = new Trip();
            t.startTS = new Date(trip['start']);
            t.endTS = new Date(trip['end']);
            t.acceleration = trip['acceleration'];
            t.brakingAcceleration = trip['brakingAcceleration'];
            t.braking = trip['braking'];
            t.tripNb = trip['tripNb'];
            t.miles = trip['miles'];
            t.idle = trip['idle'];
            t.night = trip['night'];
            t.tripsCount = trip['tripsCount'];
            t.idleTimeRatio = trip['idleTimeRatio'];
            result.push(t);
        }
        return result;
    };

    clearCache(): void {
        this.trips = new Map<string, Trip[]>();
    }
}
