import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { SreDetailsContent } from 'app/components/sre-details/sre-details-content';
import { RatingFactorForDisplay } from 'app/components/sre-summary/sre-summary-content';
import { VehicleService } from 'app/service/vehicle.service';
import { SreSummaryService, MonthlyWeeklyTotalSummary, EventInformation } from 'app/service/sre-summary.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { UserService } from 'app/service/user.service';
import { map, mergeMap } from 'rxjs/operators';

@Injectable()
export class SreDetailsContentResolver {
    // eslint-disable-next-line max-params
    constructor(
        private vehicleService: VehicleService,
        private sreSummaryService: SreSummaryService,
        private user: UserService,
        private router: Router
    ) { }

    resolve(route: ActivatedRouteSnapshot): Observable<SreDetailsContent> {
        const factorKey: string = route.params.factorId;
        const vin: string = this.user.selectedVIN;
        const programType: string = this.user.selectedVehicleProgramType;
        const periodUnit: string = route.params.periodUnit;
        const periodEnd: string = route.params.periodEnd;
        const result: SreDetailsContent = new SreDetailsContent();
        if (!vin) {
            this.router.navigateByUrl('programSelect');
        } else if (!factorKey) {
            this.router.navigateByUrl('not-found');
        }
        if (periodUnit !== 'month' && periodUnit !== 'day' && periodUnit !== 'week' && periodUnit !== 'total' || !periodEnd) {
            this.router.navigateByUrl('not-found');
        }
        return this.vehicleService.getVehicleByVin(vin, programType).pipe(mergeMap((vehicleResponse) => {
            result.vehicle = vehicleResponse;

            return this.sreSummaryService.fetchOneSummaryReport(
                vehicleResponse.vid, periodEnd, periodUnit,
                vehicleResponse.scoringModel, vehicleResponse.state, vehicleResponse.deviceStatusTimeline
            ).pipe(mergeMap((summaryResponse) => {
                result.summary = summaryResponse;
                result.projectedAnnualMileage = this.sreSummaryService.getProjectedAnnualMileage(
                    vehicleResponse.vid, periodEnd, periodUnit, vehicleResponse.deviceStatusTimeline);
                result.firstSummaryDate = this.sreSummaryService.getFirstCachedDateForPeriodUnit(periodUnit, vehicleResponse.vid);
                result.lastSummaryDate = this.sreSummaryService.getLastCachedDateForPeriodUnit(periodUnit, vehicleResponse.vid);
                result.factor = this.findFactorInSummary(summaryResponse, factorKey);

                return this.sreSummaryService.fetchSummaryBreakdown(
                    vehicleResponse.vid, summaryResponse, factorKey,
                    vehicleResponse.scoringModel, vehicleResponse.state, vehicleResponse.deviceStatusTimeline
                ).pipe(map((breakdownResponse) => {
                    result.breakdown = breakdownResponse;

                    return result;
                }));
            }));
        }));
    }

    findFactorInSummary(summary: MonthlyWeeklyTotalSummary, factorKey: string): RatingFactorForDisplay {
        const event: EventInformation = summary[factorKey];
        const number100 = 100;
        if (factorKey === 'idle') {
            event.amount = summary['idleTimeRatio']?.amount * number100;
        }
        if (!event) {
            this.router.navigateByUrl('nw/systemError');
            throw new Error(`Factor "${factorKey}" not found in summary data.`);
        }
        const factor: RatingFactorForDisplay = new RatingFactorForDisplay();
        factor.event = event;
        if (summary.thresholds) {
            factor.thresholds = summary.thresholds[factorKey];
        }

        // TODO Validate these strings. Better yet, move them to one canonical place:
        switch (factorKey) {
            case 'idle':
                factor.displayName = 'Idle Time';
                factor.unit = '';
               break;
            case 'night':
                factor.displayName = 'Nighttime Driving';
                factor.unit = 'minute';
               break;
            case 'miles':
                factor.displayName = 'Miles Driven';
                factor.unit = 'mile';
               break;
            case 'braking':
                factor.displayName = 'Hard Braking';
                factor.unit = 'event';
               break;
            case 'acceleration':
                factor.displayName = 'Fast Acceleration';
                factor.unit = 'event';
               break;
            case 'brakingAcceleration':
                factor.displayName = 'Hard Braking / Acceleration';
                factor.unit = 'event';
               break;
            case 'tripsCount':
                factor.displayName = 'Trip Count';
                factor.unit = 'trip';
                break;
            case 'idleTimeRatio':
                factor.displayName = 'Idle Time Ratio';
                factor.unit = '';
                break;

            default: throw new Error(`Invalid factor key "${factorKey}"`);
        }
        return factor;
    }
}
