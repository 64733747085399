/* eslint-disable max-params*/
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { TimeFrame } from '../interfaces/timeframe.interface';
import * as moment from 'moment';
import { unitOfTime } from 'moment';
import { catchError, map, tap, mergeMap } from 'rxjs/operators';
import { ServiceErrorHandler } from '../utility/service-error-handler';
import { Observable, of } from 'rxjs';
import Moment = moment.Moment;
import { FactorBreakdown, FactorBreakdownRow } from 'app/components/factor-breakdown/factor-breakdown';
import { Timeline } from 'app/model/timeline';
import DurationConstructor = unitOfTime.DurationConstructor;
import { APICommonService } from '@nationwide/api-common-service';
import { LoggerService } from './logger.service';

export class Threshold {
    idle: number[];
    night: number[];
    brakingAcceleration: number[];
    miles: number[];
    braking: number[];
    acceleration: number[];

    constructor() { }
}

export class Thresholds {
    week: Threshold;
    month: Threshold;

    constructor(week: Threshold, month: Threshold) {
        this.week = week;
        this.month = month;
    }
}

export enum FactorId {
    acceleration, idle, tripsCount, night, braking, brakingAcceleration, miles, idleTimeRatio
}

export class EventInformation {
    amount: number;
    status: number;
    tip: string;
    trend: number;
    factorId: FactorId;
    percent: number;
    percentTrend: number;

    constructor(amount: number, status: number, tip: string, trend: number) {
        this.amount = amount;
        this.status = status;
        this.tip = tip;
        this.trend = trend;
    }
}

export class MonthlyWeeklyTotalSummary {
    date: string;
    startDate: string; // Used for Partial Weeks on Details Page
    endDate: string;
    periodUnit: string;
    duration: number;
    acceleration: EventInformation;
    idle: EventInformation;
    idleTimeRatio: EventInformation;
    tripsCount: EventInformation;
    night: EventInformation;
    braking: EventInformation;
    brakingAcceleration: EventInformation;
    miles: EventInformation;
    thresholds: Threshold;

    constructor() { }
}

export class SreSummary {
    vin: string;
    piid: string | number;
    thresholds: Thresholds;
    totalSummary: MonthlyWeeklyTotalSummary[];
    weeklySummary: MonthlyWeeklyTotalSummary[];
    monthlySummary: MonthlyWeeklyTotalSummary[];
    dailySummary: MonthlyWeeklyTotalSummary[];

    constructor() {
        this.thresholds = new Thresholds(new Threshold(), new Threshold());
        this.totalSummary = [new MonthlyWeeklyTotalSummary()];
        this.weeklySummary = [new MonthlyWeeklyTotalSummary()];
        this.monthlySummary = [new MonthlyWeeklyTotalSummary()];
        this.dailySummary = [new MonthlyWeeklyTotalSummary()];
    }
}

export class TotalSummary {
    acceleration: EventInformation;
    idle: EventInformation;
    tripsCount: EventInformation;
    night: EventInformation;
    braking: EventInformation;
    brakingAcceleration: EventInformation;
    miles: EventInformation;
    idleTimeRatio: EventInformation;

    constructor() { }
}

@Injectable()
export class SreSummaryService {
    // TODO Are all these instance members really necessary?
    // Hint: no.
    sreVehicleSummary: SreSummary;
    totalTmp: MonthlyWeeklyTotalSummary;
    thresholdsTmp: Thresholds;
    totalSummaryTmp: MonthlyWeeklyTotalSummary[];
    vin: string;
    piid: string | number;
    summaryCache = new Map<string, SreSummary>();

    constructor(
        public _http: HttpClient,
        private _error: ServiceErrorHandler,
        private apiCommonService: APICommonService,
        private logger: LoggerService
    ) { }

    fetchOneSummaryReport(
        piid: string, date: string, periodUnit: string, scoringModel: string, state: string, vehicleTimeline: Timeline
    ): Observable<MonthlyWeeklyTotalSummary> {
        const summariesForProgram: SreSummary = this.getAllSummariesForProgramFromCache(piid);
        let summary: MonthlyWeeklyTotalSummary = this.findSummaryInGroup(summariesForProgram, date, periodUnit);
        if (summary) {
            return of(summary);
        }

        return this.retrieveAllSummariesForProgramFromRemote(piid, scoringModel, state, vehicleTimeline).pipe(mergeMap((response) => {
            this.addSummariesToCache(piid, response);
            summary = this.findSummaryInGroup(response, date, periodUnit);
            if (summary) {
                return of(summary);
            }
            throw new Error(`Summaries for ${piid}, ${date}, ${periodUnit} not found.`);
        }));
    }

    fetchSummaryReportsForPeriodUnit(
        piid: string, periodUnit: string, scoringModel: string, state: string, vehicleTimeline: Timeline
    ): Observable<MonthlyWeeklyTotalSummary[]> {
        const summariesForProgram: SreSummary = this.getAllSummariesForProgramFromCache(piid);
        let summary: MonthlyWeeklyTotalSummary[] = this.getAllSummariesForPeriodUnit(summariesForProgram, periodUnit);
        if (summary) {
            return of(summary);
        }

        return this.retrieveAllSummariesForProgramFromRemote(piid, scoringModel, state, vehicleTimeline).pipe(mergeMap((response) => {
            this.addSummariesToCache(piid, response);
            summary = this.getAllSummariesForPeriodUnit(response, periodUnit);
            if (summary) {
                return of(summary);
            }
            throw new Error(`Summaries for ${piid}, ${periodUnit} not found.`);
        }));
    }

    calculateProjectedAnnualMileage(summaries: MonthlyWeeklyTotalSummary[], date: string, vehicleTimeline: Timeline): number {
        const daysInAYear = 365;
        let endDate;
        let totalMiles;
        let daysElapsed;
        const startDate = vehicleTimeline.installDate ? vehicleTimeline.installDate : vehicleTimeline.enrollDate;
        if (summaries[0].periodUnit === 'total') {
            daysElapsed = moment(vehicleTimeline.completionDate).diff(moment(startDate), 'days');
            endDate = moment(vehicleTimeline.completionDate);
            totalMiles = summaries[0].miles.amount || 0;
        } else {
            const todayDate = moment(environment.futureDate).format('YYYYMMDD');
            const endPeriodDate = moment(todayDate).endOf(<any>summaries[0].periodUnit);
            date = date === 'latest' ? moment(vehicleTimeline.completionDate).format('YYYYMMDD') : date;
            endDate = moment(date).endOf(<any>summaries[0].periodUnit);
            endDate = this.getPeriodEndDate(endDate, endPeriodDate, moment(vehicleTimeline.completionDate));
            endDate = endDate.isAfter(moment(vehicleTimeline.completionDate)) ? moment(vehicleTimeline.completionDate) : endDate;
            daysElapsed = endDate.diff(moment(startDate), 'days');
            totalMiles = summaries.filter((summary) => {
                if (!moment(summary.date).isSameOrBefore(endDate, <any>summaries[0].periodUnit)) {
                    return false;
                }
                if (!summary.miles.amount) {
                    return false;
                }
                if (summary.miles.amount <= 0) {
                    return false;
                }
                return true;
            }).reduce((a, b) => a + b.miles.amount, 0);
        }
        // Projected annual mileage is the miles driven up to last day of period being viewed, divided by the days since the start, times 365
        return Math.round(totalMiles / daysElapsed * daysInAYear);
    }

    getPeriodEndDate(endDate, endPeriodDate, completionDate): any {
        if (endDate.format('YYYYMMDD') === endPeriodDate.format('YYYYMMDD')) {
            endDate = completionDate;
        }
        return endDate;
    }

    getProjectedAnnualMileage(piid: string, date: string, periodUnit: string, vehicleTimeline: Timeline): number {
        const summariesUpToDate: MonthlyWeeklyTotalSummary[] = this.getAllSummariesForPeriodUnit(this.getAllSummariesForProgramFromCache(piid), periodUnit);
        const projectedMileage: number = this.calculateProjectedAnnualMileage(summariesUpToDate, date, vehicleTimeline);
        return projectedMileage;
    }

    getAllSummariesForProgramFromCache(piid: string): SreSummary {
        return this.summaryCache.get(piid);
    }

    addSummariesToCache(piid: string, summaries: SreSummary): void {
        this.summaryCache.set(piid, summaries);
    }

    getAllSummariesForPeriodUnit(group: SreSummary, periodUnit: string): MonthlyWeeklyTotalSummary[] {
        if (!group) {
            return null;
        }
        switch (periodUnit) {
            case 'total': {
                return group.totalSummary;
            }
            case 'month': {
                return group.monthlySummary;
            }
            case 'week': {
                return group.weeklySummary;
            }
            case 'day': {
                return group.dailySummary;
            }
        }
        return null;
    }

    findSummaryInGroup(group: SreSummary, date: string, periodUnit: string): MonthlyWeeklyTotalSummary {
        if (!group) {
            return null;
        }
        switch (periodUnit) {
            case 'total': {
                return group.totalSummary[0];
            }
            case 'month': {
                if (date === 'latest') {
                    return this.latestSummaryFromList(group.monthlySummary);
                } else {
                    const summary = this.summaryMatchingMonth(group.monthlySummary, date);
                    if (summary) {
                        return summary;
                    }
                    return this.nearestMatchForSummary(group.monthlySummary, date);
                }
            }
            case 'week': {
                if (date === 'latest') {
                    return this.latestSummaryFromList(group.weeklySummary);
                } else {
                    const summary = this.summaryMatchingWeek(group.weeklySummary, date);
                    if (summary) {
                        return summary;
                    }
                    return this.nearestMatchForSummary(group.weeklySummary, date);
                }
            }
            case 'day': {
                if (date === 'latest') {
                    return this.latestSummaryFromList(group.dailySummary);
                } else {
                    const summary = this.summaryMatchingDay(group.dailySummary, date);
                    if (summary) {
                        return summary;
                    }
                    return this.nearestMatchForSummary(group.dailySummary, date);
                }
            }
        }
        return null;
    }

    getFirstCachedDateForPeriodUnit(unit: string, piid: string): string {
        const summariesForProgram: SreSummary = this.getAllSummariesForProgramFromCache(piid);
        if (!summariesForProgram) {
            return '';
        }
        let summary: MonthlyWeeklyTotalSummary = null;
        switch (unit) {
            case 'day': summary = this.earliestSummaryFromList(summariesForProgram.dailySummary); break;
            case 'week': summary = this.earliestSummaryFromList(summariesForProgram.weeklySummary); break;
            case 'month': summary = this.earliestSummaryFromList(summariesForProgram.monthlySummary); break;
        }
        if (summary) {
            return summary.date;
        }
        return '';
    }

    getLastCachedDateForPeriodUnit(unit: string, piid: string): string {
        const summariesForProgram: SreSummary = this.getAllSummariesForProgramFromCache(piid);
        if (!summariesForProgram) {
            return '';
        }
        let summary: MonthlyWeeklyTotalSummary = null;
        switch (unit) {
            case 'day': summary = this.latestSummaryFromList(summariesForProgram.dailySummary); break;
            case 'week': summary = this.latestSummaryFromList(summariesForProgram.weeklySummary); break;
            case 'month': summary = this.latestSummaryFromList(summariesForProgram.monthlySummary); break;
        }
        if (summary) {
            return summary.date;
        }
        return '';
    }

    earliestSummaryFromList(summaries: MonthlyWeeklyTotalSummary[]): MonthlyWeeklyTotalSummary {
        if (!summaries) {
            return null;
        }
        let earliest: MonthlyWeeklyTotalSummary = null;
        for (const summary of summaries) {
            if (!earliest || summary.date < earliest.date) {
                earliest = summary;
            }
        }
        return earliest;
    }

    latestSummaryFromList(summaries: MonthlyWeeklyTotalSummary[]): MonthlyWeeklyTotalSummary {
        if (!summaries) {
            return null;
        }
        let latest: MonthlyWeeklyTotalSummary = null;
        for (const summary of summaries) {
            if (!latest || summary.date > latest.date) {
                latest = summary;
            }
        }
        return latest;
    }

    nearestMatchForSummary(summaries: MonthlyWeeklyTotalSummary[], date: string): MonthlyWeeklyTotalSummary {
        if (!summaries) {
            return null;
        }
        let nearest: MonthlyWeeklyTotalSummary = null;
        let nearestDistance: number;
        for (const summary of summaries) {
            if (nearest) {
                // Distance between dates in 'YYYYMMDD' is the difference as integers.
                // This creates an implicit penalty for crossing to the next month. (because months have fewer than 100 days)
                const distance = Math.abs(Number(summary.date) - Number(date));
                if (distance < nearestDistance) {
                    nearest = summary;
                    nearestDistance = distance;
                }
            } else {
                nearest = summary;
                nearestDistance = Math.abs(Number(summary.date) - Number(date));
            }
        }
        return nearest;
    }

    generateEmptySummary(date: string, periodUnit: string, thresholds: Thresholds, scoringModel: string): MonthlyWeeklyTotalSummary {
        const summary: MonthlyWeeklyTotalSummary = new MonthlyWeeklyTotalSummary();
        summary.date = date;
        summary.periodUnit = periodUnit;

        summary.miles = new EventInformation(NaN, 0, '', null);
        summary.miles.factorId = FactorId.miles;
        summary.tripsCount = new EventInformation(NaN, 0, '', null);
        summary.tripsCount.factorId = FactorId.tripsCount;
        summary.idleTimeRatio = new EventInformation(NaN, 0, '', null);
        summary.idleTimeRatio.factorId = FactorId.idleTimeRatio;
        if (['ND1', 'TW2'].indexOf(scoringModel) !== -1) {
            summary.idle = new EventInformation(NaN, 0, '', null);
            summary.idle.factorId = FactorId.idle;
            summary.brakingAcceleration = new EventInformation(-1, 0, '', null);
            summary.brakingAcceleration.factorId = FactorId.brakingAcceleration;
        }
        if (['TW', 'TW1'].indexOf(scoringModel) !== -1) {
            summary.braking = new EventInformation(NaN, 0, '', null);
            summary.braking.factorId = FactorId.braking;
            summary.acceleration = new EventInformation(NaN, 0, '', null);
            summary.acceleration.factorId = FactorId.acceleration;
        }
        if (['ND1', 'TW2', 'TW1', 'TW'].indexOf(scoringModel) !== -1) {
            summary.night = new EventInformation(NaN, 0, '', null);
            summary.night.factorId = FactorId.night;
        }
        switch (periodUnit) {
            case 'month':
                summary.thresholds = thresholds.month;
                break;
            case 'week':
                summary.thresholds = thresholds.week;
                break;
            default:
                summary.thresholds = new Threshold();
                if (scoringModel === 'ND1') {
                    const ND1Min = 6206;
                    const ND1Max = 15696;
                    summary.thresholds.miles = [ND1Min, ND1Max];
                } else {
                    const SM1Min = 4500;
                    const SM1Max = 12000;
                    summary.thresholds.miles = [SM1Min, SM1Max];
                }
                break;
        }
        return summary;
    }

    summaryMatchingMonth(summaries: MonthlyWeeklyTotalSummary[], date: string): MonthlyWeeklyTotalSummary {
        if (!summaries) {
            return null;
        }
        const datePrefixLength = 6;
        const datePrefix = date.substr(0, datePrefixLength);
        for (const summary of summaries) {
            if (summary.date.substr(0, datePrefixLength) === datePrefix) {
                return summary;
            }
        }
        return null;
    }

    summaryMatchingWeek(summaries: MonthlyWeeklyTotalSummary[], date: string): MonthlyWeeklyTotalSummary {
        if (!summaries) {
            return null;
        }
        const firstDateOfWeek = moment(date).day(0).format('YYYYMMDD');
        const sunday = 6;
        const lastDateOfWeek = moment(date).day(sunday).format('YYYYMMDD');
        for (const summary of summaries) {
            if (summary.date < firstDateOfWeek) {
                continue;
            }
            if (summary.date > lastDateOfWeek) {
                continue;
            }
            return summary;
        }
        return null;
    }

    summaryMatchingDay(summaries: MonthlyWeeklyTotalSummary[], date: string): MonthlyWeeklyTotalSummary {
        if (!summaries) {
            return null;
        }
        for (const summary of summaries) {
            if (summary.date === date) {
                return summary;
            }
        }
        return null;
    }

    getAllSummariesForVehicle(piid: string, scoringModel: string, state: string, vehicleTimeline: Timeline): Observable<SreSummary> {
        if (this.summaryCache.has(piid)) {
            return of(this.summaryCache.get(piid));
        } else {
            return this.retrieveAllSummariesForProgramFromRemote(piid, scoringModel, state, vehicleTimeline).pipe(map((summary) => {
                summary.monthlySummary = summary.monthlySummary.filter((month) => {
                    const startDate = moment(vehicleTimeline.installDate || vehicleTimeline.enrollDate);
                    const endDate = moment(vehicleTimeline.completionDate || vehicleTimeline.finalDiscountDate);
                    return moment(month.date).isSameOrAfter(startDate, 'month') && moment(month.date).isSameOrBefore(endDate, 'month');
                });
                return summary;
            })).pipe(tap((summary) => {
                this.addSummariesToCache(piid, summary);
            }));
        }
    }

    retrieveAllSummariesForProgramFromRemote(
        piid: string, scoringModel: string, state: string, vehicleTimeline: Timeline
    ): Observable<SreSummary> {
        const startDate = moment(vehicleTimeline.installDate || vehicleTimeline.enrollDate).format('YYYYMMDD');
        const endDate = moment(vehicleTimeline.completionDate || vehicleTimeline.finalDiscountDate).format('YYYYMMDD');

        const url = `${environment.sreApiURL}summary/${piid}`;
        const validatorUrl = `${environment.validatorUrl}${piid}/retrieve`;
        const headers = new HttpHeaders({
            'X-NW-Message-ID': this.apiCommonService.generateTransactionId(),
            // eslint-disable-next-line camelcase
            client_id: environment.apiKey
        });
        const params = {
            policy: sessionStorage.getItem('selectedPolicy'),
            startdate: startDate,
            enddate: endDate,
            scoringmodel: scoringModel,
            search: 'thresholds',
            state
        };
        if (environment.useValidatorService && sessionStorage.getItem('useValidator') === 'true') {
            this.logger.debug('myLog: sre-summary.service.ts: USE VALIDATOR');
            const validatorParams = {
                serviceName: 'SmartRideExperience_2',
                applicationName: 'DGS-ISU-SMILES',
                endpoint: 'https://api-test.nwie.net/policymanagement/personallines/experience/v2/summary'
            };
            const myHeaders = headers;
            return this._http.post(validatorUrl, validatorParams, { headers: myHeaders })
                .pipe(
                    map((response) => {
                        this.logger.debug(`myLog: sre-summary.service.ts: ${response}`);
                        const cleanResponse = JSON.parse(response['cachedResponses'][0]['payload']);
                        return this.mapSummaryData(cleanResponse, vehicleTimeline, scoringModel);
                    }),
                    catchError((error) => {
                        this._error.handleError('SreSummary');
                        return of(error);
                    })
                );
        }

        return this._http.get(url, {
            headers,
            params
        })
            .pipe(
                map((response) =>
                    this.mapSummaryData(response, vehicleTimeline, scoringModel)),
                catchError((error) => {
                    this._error.handleError('SreSummary');
                    return of(error);
                })
            );
    }

    mapSummaryData(res: any, vehicleTimeline: Timeline, scoringModel: string): SreSummary {
        const summary = res['data'];
        const summaryTmp = new SreSummary();
        if (summary !== undefined) {
            summaryTmp.piid = this.piid;

            // Map Thresholds

            this.thresholdsTmp = summary['thresholds'] || {};
            summaryTmp.thresholds = this.thresholdsTmp;

            // Map Total

            this.totalTmp = summary['total']; // TODO Why is there 'this'?
            this.totalTmp.periodUnit = 'total';
            const endDateBound = vehicleTimeline ? vehicleTimeline.completionDate || vehicleTimeline.finalDiscountDate : new Date();
            this.totalTmp.date = moment.min(moment(environment.futureDate), moment(endDateBound)).format('YYYYMMDD');
            this.setFactorKeysInMonthlyWeeklyTotalSummary(this.totalTmp);
            this.totalSummaryTmp = [];
            this.totalSummaryTmp.push(this.totalTmp);
            summaryTmp.totalSummary = this.totalSummaryTmp;

            // Map Week Data

            const weekData = summary['week'];
            let dateArray;
            let weekArray = [];
            if (weekData !== undefined) {
                dateArray = Object.keys(weekData);
                for (const date of dateArray) {
                    let weekDataTmp = new MonthlyWeeklyTotalSummary();
                    weekDataTmp = weekData[date];
                    const weekTmp = new MonthlyWeeklyTotalSummary();
                    weekTmp.date = date;
                    weekTmp.periodUnit = 'week';
                    weekTmp.acceleration = weekDataTmp.acceleration;
                    weekTmp.braking = weekDataTmp.braking;
                    weekTmp.brakingAcceleration = weekDataTmp.brakingAcceleration;
                    weekTmp.idle = weekDataTmp.idle;
                    weekTmp.miles = weekDataTmp.miles;
                    weekTmp.night = weekDataTmp.night;
                    weekTmp.tripsCount = weekDataTmp.tripsCount;
                    weekTmp.idleTimeRatio = weekDataTmp.idleTimeRatio;
                    weekTmp.duration = weekDataTmp.duration;
                    weekTmp.thresholds = summaryTmp.thresholds.week;
                    this.setFactorKeysInMonthlyWeeklyTotalSummary(weekTmp);
                    weekArray.push(weekTmp);
                }
                weekArray = this.fillMissingSummaries(weekArray, vehicleTimeline, 'week', scoringModel);
            }
            summaryTmp.weeklySummary = weekArray;

            // Map Month Data

            const monthData = summary['month'];
            let monthArray = [];
            dateArray = [];
            if (monthData !== undefined) {
                dateArray = Object.keys(monthData);
                for (const date of dateArray) {
                    // let monthDataTmp = new MonthlyWeeklyTotalSummary();
                    const monthDataTmp = monthData[date];
                    const monthTmp = new MonthlyWeeklyTotalSummary();
                    monthTmp.date = date;
                    monthTmp.periodUnit = 'month';
                    monthTmp.acceleration = monthDataTmp.acceleration;
                    monthTmp.braking = monthDataTmp.braking;
                    monthTmp.brakingAcceleration = monthDataTmp.brakingAcceleration;
                    monthTmp.idle = monthDataTmp.idle;
                    monthTmp.miles = monthDataTmp.miles;
                    monthTmp.night = monthDataTmp.night;
                    monthTmp.tripsCount = monthDataTmp.tripsCount;
                    monthTmp.idleTimeRatio = monthDataTmp.idleTimeRatio;
                    monthTmp.duration = monthDataTmp.duration;
                    monthTmp.thresholds = summaryTmp.thresholds.month;
                    this.setFactorKeysInMonthlyWeeklyTotalSummary(monthTmp);
                    monthArray.push(monthTmp);
                }
                monthArray = this.fillMissingSummaries(monthArray, vehicleTimeline, 'month', scoringModel);
            }
            summaryTmp.monthlySummary = monthArray;

            // Map Days Data

            const dayData = summary['day'];
            let dayArray = [];
            dateArray = [];
            if (dayData !== undefined) {
                dateArray = Object.keys(dayData);
                for (const date of dateArray) {
                    const dayDataTmp = dayData[date];
                    const dayTmp = new MonthlyWeeklyTotalSummary();
                    dayTmp.date = date;
                    dayTmp.periodUnit = 'day';
                    dayTmp.acceleration = dayDataTmp.acceleration;
                    dayTmp.braking = dayDataTmp.braking;
                    dayTmp.brakingAcceleration = dayDataTmp.brakingAcceleration;
                    dayTmp.idle = dayDataTmp.idle;
                    dayTmp.miles = dayDataTmp.miles;
                    dayTmp.night = dayDataTmp.night;
                    dayTmp.tripsCount = dayDataTmp.tripsCount;
                    dayTmp.idleTimeRatio = dayDataTmp.idleTimeRatio;
                    dayTmp.duration = dayDataTmp.duration;
                    this.setFactorKeysInMonthlyWeeklyTotalSummary(dayTmp);
                    dayArray.push(dayTmp);
                }
                dayArray = this.fillMissingSummaries(dayArray, vehicleTimeline, 'day', scoringModel);
            }
            summaryTmp.dailySummary = dayArray;
        }

        return this.trimExtraSummaries(summaryTmp, vehicleTimeline);
    }

    // Backfill missing summary days/weeks/months in the summary response
    fillMissingSummaries(
        summary: MonthlyWeeklyTotalSummary[], vehicleTimeline: Timeline, periodUnit: string, scoringModel: string
    ): MonthlyWeeklyTotalSummary[] {
        const firstDate = moment(vehicleTimeline.installDate || vehicleTimeline.enrollDate).endOf(<DurationConstructor>periodUnit);
        const lastDate = moment.min(moment(environment.futureDate), moment(vehicleTimeline.completionDate) || moment('99991231'));

        // If everything is future dated, just show the single default summary that the API returns with no navigation enabled
        if (firstDate.isSameOrAfter(moment(environment.futureDate), 'day')) {
            return summary;
        }

        while (firstDate.isSameOrBefore(lastDate, <DurationConstructor>periodUnit)) {
            const foundSummary = summary.find((sum) => sum.date === firstDate.format('YYYYMMDD'));
            if (!foundSummary) {
                summary.push(this.generateEmptySummary(
                    firstDate.format('YYYYMMDD'), periodUnit, this.thresholdsTmp, scoringModel
                ));
            }
            firstDate.add(1, <DurationConstructor>periodUnit).endOf(<DurationConstructor>periodUnit);
        }

        // eslint-disable-next-line no-extra-parens
        return summary.sort((a, b) => (a.date > b.date ? 1 : (a.date < b.date ? -1 : 0)));
    }

    trimExtraSummaries(summary: SreSummary, vehicleTimeline: Timeline): SreSummary {
        const firstDate = vehicleTimeline.installDate || vehicleTimeline.enrollDate;

        // If everything is future dated, prevent removing the default
        if (moment(firstDate).isSameOrAfter(moment(environment.futureDate), 'day')) {
            return summary;
        }

        const firstMoment = moment(firstDate);
        const lastDate = moment.min(moment(environment.futureDate), moment(vehicleTimeline.completionDate) || moment('99991231'));
        summary.monthlySummary = summary.monthlySummary.filter((sum) =>
            moment(sum.date).isBetween(firstMoment, lastDate, 'month', '[]'));
        summary.weeklySummary = summary.weeklySummary.filter((sum) =>
            moment(sum.date).isBetween(firstMoment, lastDate, 'week', '[]'));
        summary.dailySummary = summary.dailySummary.filter((sum) =>
            moment(sum.date).isBetween(firstMoment, lastDate, 'day', '[]'));
        return summary;
    }

    setFactorKeysInMonthlyWeeklyTotalSummary(summary: MonthlyWeeklyTotalSummary): void {
        if (summary.acceleration) {
            summary.acceleration.factorId = FactorId.acceleration;
        }
        if (summary.idle) {
            summary.idle.factorId = FactorId.idle;
        }
        if (summary.tripsCount) {
            summary.tripsCount.factorId = FactorId.tripsCount;
        }
        if (summary.idleTimeRatio) {
            summary.idleTimeRatio.factorId = FactorId.idleTimeRatio;
        }
        if (summary.night) {
            summary.night.factorId = FactorId.night;
        }
        if (summary.braking) {
            summary.braking.factorId = FactorId.braking;
        }
        if (summary.brakingAcceleration) {
            summary.brakingAcceleration.factorId = FactorId.brakingAcceleration;
        }
        if (summary.miles) {
            summary.miles.factorId = FactorId.miles;
        }
    }

    calcEventsInTerm = (tf: TimeFrame): Observable<MonthlyWeeklyTotalSummary[]> => {
        if (this.sreVehicleSummary !== undefined) {
            return of(this.sreVehicleSummary.monthlySummary.filter((monthlySummary) =>
                moment(monthlySummary.date).isBetween(
                    tf.start.clone().subtract(1, 'month'), tf.end.clone().add(1, 'month'), 'month'
                )));
        }
    };

    fetchSummaryBreakdown(
        piid: string, outer: MonthlyWeeklyTotalSummary, factor: string, scoringModel: string, state: string, vehicleTimeline: Timeline
    ): Observable<FactorBreakdown> {
        const summariesForProgram: SreSummary = this.getAllSummariesForProgramFromCache(piid);
        let breakdown: FactorBreakdown = this.generateFactorBreakdown(summariesForProgram, outer, factor);
        if (breakdown) {
            return of(breakdown);
        }

        return this.getAllSummariesForVehicle(piid, scoringModel, state, vehicleTimeline).pipe(map(() => {
            breakdown = this.generateFactorBreakdown(summariesForProgram, outer, factor);
            if (breakdown) {
                return breakdown;
            }
            throw new Error(`Summaries for ${piid} not found.`);
        }));
    }

    generateFactorBreakdown(summaries: SreSummary, outer: MonthlyWeeklyTotalSummary, factor: string): FactorBreakdown {
        switch (outer.periodUnit) {
            case 'total': return this.generateFactorBreakdownOfMonths(summaries, factor);
            case 'month': return this.generateFactorBreakdownOfWeeks(summaries, outer.date, factor);
            case 'week': return this.generateFactorBreakdownOfDays(summaries, outer.date, factor);
            default: return null;
        }
    }

    generateFactorBreakdownOfMonths(summaries: SreSummary, factor: string): FactorBreakdown {
        const breakdown: FactorBreakdown = new FactorBreakdown();
        breakdown.headerDescription = `${this.titleCaseUnitForFactor(factor)} by Month`;
        breakdown.rowPeriodUnit = 'month';
        for (const summary of summaries.monthlySummary) {
            const row: FactorBreakdownRow = this.generateFactorBreakdownRow(summary, factor);

            if (row.endDate > summary.date) {
                row.partial = true;
            }
            // else if (row.startDate < ?) row.partial = true; // TODO Do we have a reliable start of program date?

            if (row.amount > breakdown.maximumAmount) {
                breakdown.maximumAmount = row.amount;
            }

            breakdown.rows.push(row);
        }
        this.addMissingMonthsToBreakdown(breakdown);
        return breakdown;
    }

    generateFactorBreakdownOfWeeks(summaries: SreSummary, date: string, factor: string): FactorBreakdown {
        const breakdown: FactorBreakdown = new FactorBreakdown();
        breakdown.headerDescription = `${this.titleCaseUnitForFactor(factor)} by Week`;
        breakdown.rowPeriodUnit = 'week';
        for (const summary of summaries.weeklySummary) {
            if (this.weeklySummaryDateMatchesMonth(summary.date, date)) {
                let row = new FactorBreakdownRow();
                row.startDate = moment(summary.date).startOf('week').format('YYYYMMDD');
                if (moment(row.startDate).isBefore(moment(date), 'month')) {
                    row.partial = true;
                    row.startDate = moment(date).clone().startOf('month').format('YYYYMMDD');
                    row.endDate = moment(row.startDate).clone().endOf('week').format('YYYYMMDD');
                    row.amount = this.calcEventsInPartialWeek(summaries.dailySummary, row.startDate, row.endDate, factor);
                } else if (moment(summary.date).isAfter(moment(date), 'month')) {
                    row.partial = true;
                    row.endDate = moment(row.startDate).clone().endOf('month').format('YYYYMMDD');
                    row.amount = this.calcEventsInPartialWeek(summaries.dailySummary, row.startDate, row.endDate, factor);
                } else {
                    row = this.generateFactorBreakdownRow(summary, factor);
                }

                if (row.amount > breakdown.maximumAmount) {
                    breakdown.maximumAmount = row.amount;
                }

                breakdown.rows.push(row);
            }
        }
        this.addMissingWeeksToBreakdown(breakdown, date);
        return breakdown;
    }

    calcEventsInPartialWeek(dailySummary: MonthlyWeeklyTotalSummary[], startDate, endDate, factor): number {
        let amount = 0;
        for (const summary of dailySummary) {
            if (moment(summary.date).isBetween(moment(startDate).clone().subtract(1, 'day'), moment(endDate).clone().add(1, 'day'))) {
                amount = amount + (summary[factor].amount || 0);
            }
        }
        return amount;
    }

    generateFactorBreakdownOfDays(summaries: SreSummary, date: string, factor: string): FactorBreakdown {
        const breakdown: FactorBreakdown = new FactorBreakdown();
        breakdown.headerDescription = `${this.titleCaseUnitForFactor(factor)} by Day`;
        breakdown.rowPeriodUnit = 'day';
        for (const summary of summaries.dailySummary) {
            if (this.dailySummaryDateMatchesWeek(summary.date, date)) {
                const row: FactorBreakdownRow = this.generateFactorBreakdownRow(summary, factor);
                if (row.amount > breakdown.maximumAmount) {
                    breakdown.maximumAmount = row.amount;
                }
                breakdown.rows.push(row);
            }
        }
        this.addMissingDaysToBreakdown(breakdown, date);
        return breakdown;
    }

    titleCaseUnitForFactor(factor: string): string {
        switch (factor) {
            case 'miles': return 'Miles';
            case 'idle': return 'Percentage';
            case 'night': return 'Minutes';
            default: return 'Events';
        }
    }

    // We don't judge the first or last month; only fill in gaps.
    addMissingMonthsToBreakdown(breakdown: FactorBreakdown): void {
        if (breakdown.rows.length < 2) {
            return;
        }
        let expectedDate = breakdown.rows[0].startDate;
        for (let i = 0; i < breakdown.rows.length; i++) {
            const row = breakdown.rows[i];
            if (row.startDate > expectedDate) {
                const newRow = new FactorBreakdownRow();
                newRow.startDate = expectedDate;
                newRow.endDate = moment(expectedDate).endOf('month').format('YYYYMMDD');
                newRow.dataMissing = true;
                newRow.partial = false;
                breakdown.rows.splice(i, 0, newRow);
            }

            expectedDate = moment(expectedDate).add(1, 'month').format('YYYYMMDD');
        }
    }

    addMissingWeeksToBreakdown(breakdown: FactorBreakdown, date: string): void {
        const daysInWeekStartingAtZero = 6;
        const firstDayOfMonth: Moment = moment(date).startOf('month');
        let expectedMoment: Moment = moment(firstDayOfMonth).day(daysInWeekStartingAtZero);
        let rowIndex = 0;
        do {
            const formattedWeekEndDate: string = expectedMoment.format('YYYYMMDD');
            if (rowIndex < breakdown.rows.length && breakdown.rows[rowIndex].endDate === formattedWeekEndDate) {
                // got it
            } else {
                const newRow = new FactorBreakdownRow();
                newRow.startDate = moment(formattedWeekEndDate).clone().day(0).format('YYYYMMDD');
                newRow.endDate = formattedWeekEndDate;
                newRow.dataMissing = true;
                newRow.partial = moment(newRow.endDate).month() !== moment(newRow.startDate).month();
                breakdown.rows.splice(rowIndex, 0, newRow);
            }
            rowIndex++;
            expectedMoment = expectedMoment.add(1, 'week');
        } while (expectedMoment.month() === firstDayOfMonth.month());
        const nextDate = moment(breakdown.rows[breakdown.rows.length - 1].endDate).clone().add(1, 'day');
        if (nextDate.month() === moment(date).endOf('month').month()) {
            const newRow = new FactorBreakdownRow();
            newRow.startDate = nextDate.format('YYYYMMDD');
            newRow.endDate = moment(date).endOf('month').format('YYYYMMDD');
            newRow.dataMissing = true;
            newRow.partial = true;
            breakdown.rows.push(newRow);
        }
    }

    addMissingDaysToBreakdown(breakdown: FactorBreakdown, date: string): void {
        const daysInWeek = 7;
        let expectedMoment: Moment = moment(date).day(0);
        let rowIndex = 0;
        for (; rowIndex < daysInWeek; rowIndex++) {
            const formattedDate: string = expectedMoment.format('YYYYMMDD');
            if (rowIndex < breakdown.rows.length && breakdown.rows[rowIndex].startDate === formattedDate) {
                // got it
            } else {
                const newRow = new FactorBreakdownRow();
                newRow.startDate = formattedDate;
                newRow.endDate = formattedDate;
                newRow.dataMissing = true;
                newRow.partial = true;
                breakdown.rows.splice(rowIndex, 0, newRow);
            }
            expectedMoment = expectedMoment.add(1, 'day');
        }
    }

    weeklySummaryDateMatchesMonth(weekDate: string, monthDate: string): boolean {
        // Week is allowed to extend beyond the month, on either side.
        const daysInWeekStartingAtZero = 6;
        const weekStart = moment(weekDate).day(0);
        const weekEnd = moment(weekDate).day(daysInWeekStartingAtZero);
        const reference = moment(monthDate);
        if (weekStart.month() === reference.month() && weekStart.year() === reference.year()) {
            return true;
        }
        if (weekEnd.month() === reference.month() && weekEnd.year() === reference.year()) {
            return true;
        }
        return false;
    }

    dailySummaryDateMatchesWeek(dayDate: string, weekDate: string): boolean {
        // moment.js defines week as Sun-Sat in US locale, same as our summaries.
        // If this code runs in another locale, it might behave wrongly.
        const a = moment(dayDate);
        const b = moment(weekDate);
        if (a.week() !== b.week()) {
            return false;
        }
        if (a.year() !== b.year()) {
            return false;
        }
        return true;
    }

    generateFactorBreakdownRow(summary: MonthlyWeeklyTotalSummary, factor: string): FactorBreakdownRow {
        const row: FactorBreakdownRow = new FactorBreakdownRow();
        row.endDate = summary.date;
        switch (summary.periodUnit) {
            case 'month': row.startDate = moment(row.endDate).startOf('month').format('YYYYMMDD'); break;
            case 'week':
                row.startDate = moment(row.endDate).startOf('week').format('YYYYMMDD');
                break;
            case 'day': row.startDate = row.endDate; break;
        }
        const event: EventInformation = summary[factor];
        if (event) {
            row.amount = event.amount;
        }
        row.partial = false; // Our caller should set this, if partial is possible.
        return row;
    }

    clearCache(): void {
        this.sreVehicleSummary = null;
        this.totalTmp = null;
        this.thresholdsTmp = null;
        this.totalSummaryTmp = null;
        this.vin = null;
        this.piid = null;
        this.summaryCache = new Map<string, SreSummary>();
    }
}
