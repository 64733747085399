// eslint-disable-next-line @typescript-eslint/naming-convention
export const EmploymentStatusType = {
    type: 'edge.metadata.typeinfo.typelistinfo.dto.TypelistMetadataDTO',
    value: {
        codes: [
            {
                code: 'GE12MoNotInclStudent_Ext',
                priority: 10,

                displayKey: 'typekey.EmploymentStatusType.GE12MoNotInclStudent_Ext'
            },
            {
                code: '6To11MoNotInclStudent_Ext',
                priority: 20,

                displayKey: 'typekey.EmploymentStatusType.6To11MoNotInclStudent_Ext'
            },
            {
                code: '0To5MoNotInclStudent_Ext',
                priority: 30,

                displayKey: 'typekey.EmploymentStatusType.0To5MoNotInclStudent_Ext'
            },
            {
                code: 'GE12MoStudent_Ext',
                priority: 40,

                displayKey: 'typekey.EmploymentStatusType.GE12MoStudent_Ext'
            },
            {
                code: '6To11MoStudent_Ext',
                priority: 50,

                displayKey: 'typekey.EmploymentStatusType.6To11MoStudent_Ext'
            },
            {
                code: '0To5MoStudent_Ext',
                priority: 60,

                displayKey: 'typekey.EmploymentStatusType.0To5MoStudent_Ext'
            },
            {
                code: 'Homemaker_Ext',
                priority: 70,

                displayKey: 'typekey.EmploymentStatusType.Homemaker_Ext'
            },
            {
                code: 'LaidOff_Ext',
                priority: 80,

                displayKey: 'typekey.EmploymentStatusType.LaidOff_Ext'
            },
            {
                code: 'Unemployed_Ext',
                priority: 90,

                displayKey: 'typekey.EmploymentStatusType.Unemployed_Ext'
            },
            {
                code: 'Retired_Ext',
                priority: 100,

                displayKey: 'typekey.EmploymentStatusType.Retired_Ext'
            },
            {
                code: 'AFULL',
                priority: -1,

                displayKey: 'typekey.EmploymentStatusType.AFULL'
            },
            {
                code: 'APART',
                priority: -1,

                displayKey: 'typekey.EmploymentStatusType.APART'
            },
            {
                code: 'OFF',
                priority: -1,

                displayKey: 'typekey.EmploymentStatusType.OFF'
            },
            {
                code: 'STRI',
                priority: -1,

                displayKey: 'typekey.EmploymentStatusType.STRI'
            },
            {
                code: 'Other',
                priority: -1,

                displayKey: 'typekey.EmploymentStatusType.Other'
            },
            {
                code: 'PART',
                priority: -1,

                displayKey: 'typekey.EmploymentStatusType.PART'
            },
            {
                code: 'TIME',
                priority: -1,

                displayKey: 'typekey.EmploymentStatusType.TIME'
            },
            {
                code: 'PI',
                priority: -1,

                displayKey: 'typekey.EmploymentStatusType.PI'
            },
            {
                code: 'REG',
                priority: -1,

                displayKey: 'typekey.EmploymentStatusType.REG'
            },
            {
                code: 'RET',
                priority: -1,

                displayKey: 'typekey.EmploymentStatusType.RET'
            },
            {
                code: 'SEASN',
                priority: -1,

                displayKey: 'typekey.EmploymentStatusType.SEASN'
            },
            {
                code: 'UNEM',
                priority: -1,

                displayKey: 'typekey.EmploymentStatusType.UNEM'
            },
            {
                code: 'UNEMPSCR',
                priority: -1,

                displayKey: 'typekey.EmploymentStatusType.UNEMPSCR'
            },
            {
                code: 'VOLUN',
                priority: -1,

                displayKey: 'typekey.EmploymentStatusType.VOLUN'
            }
        ],
        filters: [
            {
                name: 'CurrentEmploymen_Ext',
                includedCodes: [
                    'GE12MoNotInclStudent_Ext',
                    '6To11MoNotInclStudent_Ext',
                    '0To5MoNotInclStudent_Ext',
                    'GE12MoStudent_Ext',
                    '6To11MoStudent_Ext',
                    '0To5MoStudent_Ext',
                    'Homemaker_Ext',
                    'LaidOff_Ext',
                    'Unemployed_Ext',
                    'Retired_Ext'
                ]
            },
            {
                name: 'TOClearAddress_Ext',
                includedCodes: [
                    'Homemaker_Ext',
                    'LaidOff_Ext',
                    'Unemployed_Ext',
                    'Retired_Ext'
                ]
            },
            {
                name: 'TOCALLQPCSERIVCE',
                includedCodes: [
                    'GE12MoNotInclStudent_Ext',
                    '6To11MoNotInclStudent_Ext',
                    '0To5MoNotInclStudent_Ext',
                    'GE12MoStudent_Ext',
                    '6To11MoStudent_Ext',
                    '0To5MoStudent_Ext'
                ]
            }
        ],
        namedMetadata: []
    }
};
