import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'app/service/user.service';
import { NavbarService } from 'app/service/nav-bar.service';
import { EbiEventService } from 'app/service/ebi-event.service';
import { LoggerService } from 'app/service/logger.service';
import { SelfEnrollmentService } from '../../../service/self-enrollment.service';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-self-enrollment-review',
  templateUrl: './self-enrollment-review.component.html',
  styleUrls: ['./self-enrollment-review.component.css']
})

export class SelfEnrollmentReviewComponent implements OnInit {
  phoneNumber = '';
  startSpinner = false;
  waitMessage;
  reviewForm: UntypedFormGroup;
  submitted = false;
  userPolicyNumber = '';

  // eslint-disable-next-line max-params
  constructor(
    public router: Router,
    public _user: UserService,
    public _navBar: NavbarService,
    public _ebiEvent: EbiEventService,
    public _loggerService: LoggerService,
    private _selfEnrollmentService: SelfEnrollmentService
  ) { }

  ngOnInit(): void {
    this._navBar.headerDisplay(this._user.loggedIn, this.router.url);
    this._selfEnrollmentService.isSelfEnrollFlowInPlay = true;
    this.userPolicyNumber = this._user.getPolicyNumber();
    this.reviewForm = new UntypedFormGroup({
      email: new UntypedFormControl(this._user.getEmail()),
      formPhoneNumber: new UntypedFormControl(this._user.getPhoneNumber().replace(/(\d{3})-?(\d{3})-?(\d{4})/, '($1) $2-$3'), { validators: Validators.compose([Validators.required, this.validatePhone]), updateOn: 'blur' }),
      agreementCheckBox: new UntypedFormControl(null, Validators.requiredTrue)
    });
  }

  submit(): void {
    this.submitted = true;
    if (this.reviewForm.valid) {
      this.startSpinner = true;
      const phoneNumber = this.reviewForm.get('formPhoneNumber').value;
      this._selfEnrollmentService.startMobileEnrollment(this.userPolicyNumber, phoneNumber).subscribe((data: any) => {
        if (data.error) {
          this.startSpinner = false;
          this._loggerService.error('Failed Mobile Enrollment', {
            policyNumber: this._user.getPolicyNumber(),
            userId: this._user.getUsername(),
            info: data.error
          });
          this.router.navigate(['nw/systemError']);
        } else if (data?.status === 'Bound') {
          this.startSpinner = false;
          this._ebiEvent.postSrideEvent('425080115');
          this.router.navigate(['selfEnrollment/confirmation']);
        } else {
          this.startSpinner = false;
          this._loggerService.error('Member is not eligible for mobile enrollment', {
            policyNumber: this._user.getPolicyNumber(),
            userId: this._user.getUsername()
          });
          this.router.navigate(['nw/systemError'], { state: { ineligibleToEnroll: true } });
        }
      });
    } else {
      return;
    }
  }

  pressBack(): void {
    this.router.navigate(['/selfEnrollment']);
  }

  updatePhone(): void {
    const formattedPhone = this.reviewForm.controls['formPhoneNumber'].value.replace(/(\d{3})-?(\d{3})-?(\d{4})/, '($1) $2-$3');
    this.reviewForm.controls['formPhoneNumber'].setValue(formattedPhone);
  }

  validatePhone(control: AbstractControl): { [key: string]: boolean } {
    const phoneRegEx = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/g;
    if (control.value && !phoneRegEx.test(control.value)) {
      return { invalidPhoneNumber: true };
    }
    return null;
  }
}
