import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { UserService } from 'app/service/user.service';
import { Observable } from 'rxjs';
import { BrandingService } from 'app/service/branding.service';

@Injectable()
export class TokenGuard implements CanActivate, CanActivateChild {
    isAuthorized: boolean;

    constructor(
        private _user: UserService,
        public _branding: BrandingService
    ) {
    }

    canActivate(previousRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
        const oauthToken = sessionStorage.getItem('tokenDetails');
        if (oauthToken) {
            this._user.loggedIn = true;
            this._user.tokenDetails = sessionStorage.getItem('tokenDetails');
            return true;
        }

        const errorMessage = this.extractParameterFromUrl(state.url, 'error');
        const errorIndicator = errorMessage ? 'error' : '';
        let branding = BrandingService.getBranding();
        sessionStorage.clear();
        if (!branding && previousRoute && previousRoute.data) {
            branding = previousRoute.data.branding;
        }
        BrandingService.setBranding(branding);
        this._branding.logoutWithBranding('', errorIndicator, true);
        return false;
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
        return this.canActivate(route, state);
    }

    private extractParameterFromUrl(url: string, key: string): string {
        if (!url) {
 return null;
}
        const queryPosition = url.indexOf('?');
        if (queryPosition < 0) {
 return null;
}
        const queryFields = url.substr(queryPosition + 1).split('&');
        const keyWithSeparator = `${key}=`;
        for (const field of queryFields) {
            if (!field.startsWith(keyWithSeparator)) {
 continue;
}
            return this.decodeURIComponentAsManyTimesAsItTakes(field.substr(keyWithSeparator.length));
        }
        return null;
    }

    /* This will fail if there's ever a real percent sign in the parameter's value.
     */
    private decodeURIComponentAsManyTimesAsItTakes(input: string): string {
        let output = input;
        while (output.indexOf('%') >= 0) {
            output = decodeURIComponent(output);
        }
        return output;
    }
}
