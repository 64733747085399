/* eslint-disable max-params*/
import { Injectable } from '@angular/core';
import { Trip } from 'app/model/trip';
import * as d3 from 'd3';

@Injectable()
export class SpeedGraphService {
  constructor() { }

  drawArrows = (svg: any, width: number, start: number, end: number): void => {
    if (start < 0) {
      const offsetLeft = 14;
      this.drawArrow(svg, 'arrow-left', offsetLeft, '12,0 0,20 12,40');
    } else {
      this.drawArrow(svg, 'arrow-left', 0, '');
    }
    if (end > width) {
      const offsetRight = 60;
      this.drawArrow(svg, 'arrow-right', width - offsetRight, '0,0 12,20 0,40');
    } else {
      this.drawArrow(svg, 'arrow-right', 0, '');
    }
  };

  drawArrow = (svg, name, xOffset, points): void => {
    svg.select(`.${name}`)
      .attr('fill', 'transparent')
      .attr('stroke', '#333')
      .attr('opacity', '.2')
      .attr('stroke-width', '5')
      .attr('transform', `translate(${xOffset}, 52)`)
      .attr('points', points);
  };

  mapNighttime = (svg, data, height, scale, x): void => {
    const oneHourInMilliseconds = 3600000;
    const oneHourInSeconds = 3600;
    const millisecondsInSecond = 1000;
    const start = new Date(data.start);
    const fiveAMInHours = 5;
    const fiveAM = new Date(
      start.getFullYear(),
      start.getMonth(),
      start.getDate(),
      fiveAMInHours
    ).getTime();

    svg.selectAll('g.nighttime rect').remove();
    for (let i = data.startTS.getTime(); i <= data.endTS.getTime(); i = i + millisecondsInSecond) {
      const date = new Date(i);
      const fourAMInHours = 4;
      if (date.getHours() <= fourAMInHours) {
        let firstHour;
        let duration;
        if (start.getHours() === fourAMInHours) {
          firstHour = start.getTime();
          duration = (fiveAM - start.getTime()) / millisecondsInSecond * scale;
        } else {
          firstHour = new Date(
            Math.floor(date.getTime() / oneHourInMilliseconds) * oneHourInMilliseconds
          );
          duration = (fiveAMInHours - firstHour.getHours()) * oneHourInSeconds * scale;
        }
        svg.select('g.nighttime').append('rect')
          .attr('width', duration)
          .attr('x', x(firstHour))
          .attr('height', height);
        i = i + duration * millisecondsInSecond;
      }
    }
  };

  mapIdleTime = (svg: any, mph: number[], scale: number, height: number): void => {
    let startTime;
    let beyondFirstZero = false;
    svg.selectAll('g.idle line').remove();
    for (let second = 0; second < mph.length; second++) {
      if (mph[second] > 0 || beyondFirstZero) {
        beyondFirstZero = true;
      }
      if (mph[second] === 0 && !startTime && beyondFirstZero) {
        startTime = second;
      }
      if (startTime && mph[second] !== 0) {
        // eslint-disable-next-line no-magic-numbers
        const heightScale = 20 / 140;
        svg.select('g.idle').append('line')
          .attr('x1', (startTime + second) * scale / 2)
          .attr('x2', (startTime + second) * scale / 2)
          .attr('y1', height - height * heightScale)
          .attr('y2', height)
          .style('fill', 'none')
          .style('stroke', '#B83731')
          .style('stroke-width', (second - startTime) * scale)
          .style('stroke-dasharray', '4 3');
        startTime = null;
      }
    }
  };

  drawXAxis = (svg: any, xAxis: Function, margin: any): void => {
    svg.select('g.x.axis').call(xAxis);
    svg.append('rect')
      .attr('class', 'xbg')
      .attr('width', '100%')
      .attr('height', margin.top - 1)
      .attr('transform', `translate(${-margin.left}, ${-margin.top})`);
    svg.append('g')
      .attr('class', 'x axis')
      .attr('transform', 'translate(0, 0)');
  };

  drawYAxis = (svg: any, yAxis: Function, margin: any, height: number): void => {
    svg.append('rect')
      .attr('class', 'ybg')
      .attr('width', margin.left)
      .attr('height', height)
      .attr('transform', `translate(${-margin.left}, 0)`);
    svg.append('g')
      .attr('class', 'y axis')
      .attr('transform', 'translate(0,0)');
    svg.select('g.y.axis').call(yAxis);
    svg.selectAll('g.y.axis g.tick text')
      .attr('transform', 'translate(0,-7)');
  };

  drawInset = (svg: any, width: number, height: number, margin: any, insetShadowSize: number): void => {
    this.createGraydient(svg, 'gradientRight', 'x1');
    svg.append('rect')
      .attr('class', 'paneRight')
      .attr('x', width - insetShadowSize)
      .attr('width', insetShadowSize)
      .attr('height', height)
      .style('fill', 'url(#gradientRight)');

    this.createGraydient(svg, 'gradientLeft', 'x2');
    svg.append('rect')
      .attr('x', '0')
      .attr('width', insetShadowSize)
      .attr('height', height)
      .style('fill', 'url(#gradientLeft)');

    this.createGraydient(svg, 'gradientBottom', 'y1');
    svg.append('rect')
      .attr('y', height - insetShadowSize)
      .attr('width', '100%')
      .attr('height', insetShadowSize)
      .style('fill', 'url(#gradientBottom)');

    this.createGraydient(svg, 'gradientTop', 'y2');
    svg.append('rect')
      .attr('x', -margin.left)
      .attr('y', '-1')
      .attr('width', '100%')
      .attr('height', insetShadowSize)
      .style('fill', 'url(#gradientTop)');
  };

  createGraydient = (svg: any, id: string, direction: string): void => {
    const defs = svg.select('defs');
    const mediumOpacity = 0.4;
    const minorOpacity = 0.2;
    const gradientFilter = defs.append('svg:linearGradient')
      .attr('id', id)
      .attr('x1', '0%').attr('x2', '0%')
      .attr('y1', '0%').attr('y2', '0%')
      .attr(direction, '100%')
      .attr('spreadMethod', 'pad');
    gradientFilter.append('svg:stop')
      .attr('offset', '0%')
      .attr('stop-color', '#000')
      .attr('stop-opacity', mediumOpacity);
    gradientFilter.append('svg:stop')
      .attr('offset', '20%')
      .attr('stop-color', '#000')
      .attr('stop-opacity', minorOpacity);
    gradientFilter.append('svg:stop')
      .attr('offset', '100%')
      .attr('stop-color', '#000')
      .attr('stop-opacity', 0);
  };

  drawPane = (svg: any, mph: number[], height: number, line: string): void => {
    svg.select('defs').append('clipPath')
      .attr('id', 'clip-graph')
      .append('rect')
      .attr('width', '100%')
      .attr('height', height)
      .attr('x', '0')
      .attr('y', '0');
    svg.append('polyline').attr('class', 'arrow-left');
    svg.append('polyline').attr('class', 'arrow-right');
    svg.append('g')
      .attr('class', 'pane')
      .attr('clip-path', 'url(#clip-graph)');
    svg.select('.pane').append('path')
      .attr('class', 'line')
      .data([mph])
      .attr('d', line);
  };

  mapEvents = (svg: any, data: Trip, event: string): void => {
    const edgeLength = 12;
    const iconSize = edgeLength * edgeLength;
    const symbols = {
      acceleration: 'triangle-up',
      braking: 'circle'
    };
    const shape = d3.symbol()
      .type(symbols[event])
      .size(iconSize);
    const uniqueId = `dropShadow-${event}`;
    const offset = 3;
    const mediumOpacity = 0.4;
    this.createShadow(svg, uniqueId, offset, offset, 2, mediumOpacity);
    svg.selectAll(`g.event.${event}`).remove();
    svg.select('.pane')
      .selectAll(`.${event}`)
      .data(data[event]).enter() // Removed '.seconds'
      .append('g')
      .attr('class', `event ${event}`)
      .append('path')
      .attr('d', shape)
      .attr('filter', `url(#${uniqueId})`);
  };

  createShadow = (svg: any, id: string, offsetX: number, offsetY: number, blur: number, alpha: string | number): void => {
    const defs = svg.select('defs');
    const shadowFilter = defs.append('svg:filter')
      .attr('id', id)
      .attr('filterUnits', 'userSpaceOnUse')
      .attr('width', '110%')
      .attr('height', '110%');
    shadowFilter.append('svg:feGaussianBlur')
      .attr('in', 'SourceGraphic')
      .attr('stdDeviation', blur)
      .attr('result', 'blur');
    shadowFilter.append('svg:feColorMatrix')
      .attr('in', 'blur')
      .attr('type', 'matrix')
      .attr('values',
        `0.0 0.0 0.0 0 0 0.0 0.0 0.0 0 0 0.0 0.0 0.0 0 0 0 0 0 ${alpha} 0`)
      .attr('result', 'gray');
    shadowFilter.append('svg:feOffset')
      .attr('in', 'gray')
      .attr('dx', offsetX)
      .attr('dy', offsetY)
      .attr('result', 'offset');
    shadowFilter.append('svg:feBlend')
      .attr('in', 'SourceGraphic')
      .attr('in2', 'offset')
      .attr('mode', 'normal');
  };
}
