import { Component, OnDestroy, OnInit } from '@angular/core';
import { TimeFrameUnit } from 'app/enum/timeframe';
import * as moment from 'moment';
import { TimeframeService } from 'app/service/timeframe.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Vehicle } from 'app/model/vehicle';
import { TimeframePipe } from 'app/pipes/timeframe.pipe';
import { TimeFrame } from 'app/interfaces/timeframe.interface';
import { Summary } from 'app/model/summary';
import { TripDataService } from 'app/service/tripSpeed-data-service';
import { EbiEventService } from 'app/service/ebi-event.service';
import { RatedMileageService } from 'app/service/rated-mileage.service';
import { Trip } from 'app/model/trip';
import { Subscription, of } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { UserService } from 'app/service/user.service';
import { RatedMileage } from 'app/model/rated-mileage';
import { TelematicsPaymentDetails } from 'app/model/telematicsPaymentDetails';
import { TelematicsTransactionsService } from 'app/service/telematics-transactions.service';
import { environment } from 'environments/environment';

@Component({
    selector: 'app-weekly-summary',
    templateUrl: './weekly-summary.component.html',
    providers: [TimeframePipe],
    styleUrls: ['./weekly-summary.component.css']
})
export class WeeklySummaryComponent implements OnInit, OnDestroy {
    routerSubscription: Subscription;
    timeframe: TimeFrame;
    vehicle: Vehicle;
    milesInTimeframe = 0;
    monthlyMiles: number;
    mileageDays: Summary[];
    trips: Trip[];
    mileage: RatedMileage[];
    gracePeriod = false;
    vid: string;
    weekCrossesTerm: boolean;
    date1Format: string;
    date2Format: string;
    shouldDisplayCustomAlert = false;
    showVehicleNickName = false;
    isSmartMiles2 = false;
    currentMonth: boolean;
    sm2MilesDriven: number;
    mileageData: TelematicsPaymentDetails;
    estimatedPremiumStart: string;
    estimatedPremiumEnd: string;
    estimatedMiles: any;
    mileageDifference: any;

    // eslint-disable-next-line max-params
    constructor(
        public route: ActivatedRoute,
        public router: Router,
        public timeframeService: TimeframeService,
        public tfp: TimeframePipe,
        public ebiEvents: EbiEventService,
        public tripService: TripDataService,
        public ratedMileage: RatedMileageService,
        public telematicTransactions: TelematicsTransactionsService,
        public user: UserService
    ) { }

    ngOnInit(): void {
        this.vehicle = this.route.snapshot.data.vehicle;
        this.mileage = this.vehicle.ratedMileage;
        this.gracePeriod = this.ratedMileage.determineIfGracePeriod(this.mileage);
        if ( this.vehicle.scoringModel === 'SM2') {
            this.isSmartMiles2 = true;
        }
        this.routerSubscription = this.router.events.pipe(filter((event) => event instanceof NavigationEnd)
        ).subscribe(this.loadWeeklyData);
        this.loadWeeklyData(null);
    }

    ngOnDestroy(): void {
        if (this.routerSubscription) {
            this.routerSubscription.unsubscribe();
            this.routerSubscription = null;
        }
        this.trips = null;
        this.mileage = null;
        this.mileageDays = null;
    }

    loadWeeklyData = (event): void => {
        this.trips = null;
        this.mileageDays = null;

        if (event && event.url.indexOf('weeklySummary') === -1) {
            return;
        }

        const tf = new TimeFrame();
        tf.start = moment.max(moment(this.vehicle.smEnrollDate), moment(this.route.snapshot.paramMap.get('amount'))).startOf('week');
        tf.end = tf.start.clone().add(1, 'week').subtract(1, 'day');
        tf.unit = TimeFrameUnit.WEEK;
        this.timeframe = TimeFrame.buildTimeframe(moment(tf.start), moment(tf.end), TimeFrameUnit.WEEK);
        this.handleTimeframeChange(this.timeframe);
    };

    handleTimeframeChange(tf: TimeFrame): void {
        this.timeframe = tf;
        this.setDateFormats();
        if (this.vehicle !== undefined) {
            this.buildMileageTable();
        }
    }

    setDateFormats(): void {
        this.date1Format = 'LLLL d';
        this.date2Format = 'd, y';

        if (this.timeframe.start.year() !== this.timeframe.end.year()) {
            this.date1Format = 'LLLL d, y';
            this.date2Format = 'LLLL d, y';
        } else if (this.timeframe.start.month() !== this.timeframe.end.month()) {
            this.date1Format = 'LLLL d';
            this.date2Format = 'LLLL d, y';
        }
    }

    setFoundVehicle(vehicle: Vehicle): void {
        if (vehicle !== undefined && this.timeframe && this.timeframe.start && this.timeframe.end) {
            let tf = TimeFrame.buildTimeframe(this.timeframe.start, this.timeframe.end, TimeFrameUnit.MONTH);
            this.milesInTimeframe = this.vehicle.calcMilesDrivenInTimeframe(tf);
            tf = TimeFrame.buildTimeframe(this.timeframe.end.clone().subtract(1, 'month'), this.timeframe.end, TimeFrameUnit.MONTH);
            // getting miles value from rated mileage api
            this.monthlyMiles = this.vehicle.calcMilesDrivenInTimeframe(tf);
        }
    }

    calculateMonthlyMiles(): void {
        const timeFrame = TimeFrame.buildTimeframe(this.timeframe.start, this.timeframe.end, TimeFrameUnit.MONTH);
        const newtimeFrame = this.vehicle.getTermLimitsContainingMoment(moment(this.timeframe.start));
        timeFrame.start = moment.max(newtimeFrame.start.clone(), moment(this.vehicle.smEnrollDate));
        timeFrame.unit = TimeFrameUnit.MONTH;
        timeFrame.end = newtimeFrame.end.clone();
        // getting miles value from rated mileage api
        this.monthlyMiles = this.vehicle.calcMilesDrivenInTimeframe(timeFrame);
        this.calculateMonthlyMiles_sm2(timeFrame);
    }

    calculateMonthlyMiles_sm2(tf): void {
        this.estimatedPremiumStart = tf.start.format('YYYY-MM-DD');
        this.estimatedPremiumEnd = tf.end.format('YYYY-MM-DD');
        this.mileageData = this.telematicTransactions.getTelematicsPaymentDetailsData(
            this.vehicle?.vehicleId, this.estimatedPremiumStart, this.vehicle.vehicleEnrollments
        );
        this.currentMonth = false;
        if (moment(this.estimatedPremiumEnd).isSameOrAfter(moment(environment.futureDate).format('YYYY-MM-DD'))) {
            this.currentMonth = true;
        }

        if (this.vehicle.paymentDetails?.length > 0) {
            this.sm2MilesDriven = this.vehicle.paymentDetails[0].actualMiles;
            this.estimatedMiles = this.vehicle.paymentDetails[0].estimatedMiles;
            this.mileageDifference = Math.abs(this.vehicle.paymentDetails[0].mileageDifference);
          } else if (this.mileageData) {
            this.sm2MilesDriven = this.mileageData.actualMiles;
            this.estimatedMiles = this.mileageData.estimatedMiles;
            this.mileageDifference = Math.abs(this.mileageData.mileageDifference);
          }
    }

    buildMileageTable(): void {
        this.mileageDays = [];
        this.milesInTimeframe = 0;
        const termLimit = this.vehicle.getTermLimitInTimeframe(this.timeframe);
        const policyStartDate = moment(this.vehicle.periodStart);
        const policyEndDate = moment(this.vehicle.periodEnd);

        const tripsObs = this.trips && this.trips.length > 0 ?
            of(this.trips) :
            this.tripService.getTripData(
                this.vehicle.vin, moment(this.vehicle.smEnrollDate).format('YYYYMMDD'),
                moment(this.vehicle.periodEnd).format('YYYYMMDD')
            );

        tripsObs.pipe(tap((response) => {
            this.trips = <Trip[]>response;

            if (!this.trips) {
                this.trips = [];
            }
            if (!this.mileage) {
                this.mileage = [];
            }

            const tripsInTimeframe: Trip[] = <Trip[]> this.tfp.transform(this.trips, this.timeframe, 'day');
            const mileageInTimeframe: RatedMileage[] = <RatedMileage[]> this.tfp.transform(this.mileage, this.timeframe);

            let currentDay = this.timeframe.start.clone();
            const todaysDate = moment().startOf('day');
            this.weekCrossesTerm = false;
            if (termLimit !== null) {
                if (this.timeframe.end.isAfter(termLimit)) {
                    this.weekCrossesTerm = true;
                }
            }
            while (
                currentDay.toDate().getFullYear() < this.timeframe.end.toDate().getFullYear() ||
                currentDay.dayOfYear() <= this.timeframe.end.dayOfYear()
            ) {
                let mileageDay: Summary = new Summary();
                const todayTimeframe = <TimeFrame>{
                    start: currentDay.clone().startOf('day'),
                    end: currentDay.clone().endOf('day'),
                    unit: TimeFrameUnit.DAY
                };
                const tripsToday = <Trip[]> this.tfp.transform(tripsInTimeframe, todayTimeframe, 'minute');
                const mileageToday = <RatedMileage[]> this.tfp.transform(mileageInTimeframe, todayTimeframe, 'second');
                mileageDay.ratedDateRange = <TimeFrame>{
                    start: currentDay.clone().startOf('day'),
                    end: currentDay.clone().startOf('day').endOf('day'),
                    unit: TimeFrameUnit.DAY
                };
                mileageDay.dateRange = mileageDay.ratedDateRange;
                mileageDay.trips = 0;
                if (tripsToday.length > 0) {
                    mileageDay.trips = tripsToday.length;
                }
                mileageDay.fullDay = currentDay.format('dddd');
                mileageDay.showMsg = false;
                mileageDay.date = currentDay.toDate();
                mileageDay.beforeInterimEnrollment = currentDay.isBefore(this.vehicle.smEnrollDate, 'day');
                if (this.weekCrossesTerm) {
                    mileageDay.outsideTerm = currentDay.isAfter(termLimit, 'day');
                }

                if (currentDay.diff(todaysDate, 'days') >= 1) {
                  mileageDay.isFutureDay = true;
                } else {
                  mileageDay.isFutureDay = false;
                }

                mileageDay.outsidePolicyTerm = currentDay.isBefore(policyStartDate, 'day') || currentDay.isAfter(policyEndDate, 'day');
                mileageDay.miles = mileageToday.map((mileage) => mileage.ratedMileage || 0).reduce((acc, next) => acc + next, 0);
                this.milesInTimeframe += mileageDay.miles;
                mileageDay.alert = false;
                mileageDay = this.setupAlerts(mileageDay);
                this.mileageDays.push(mileageDay);
                currentDay = currentDay.add(1, 'days');
            }
            this.combineAlerts(this.mileageDays);
        })).subscribe();
        this.calculateMonthlyMiles();
    }

    toggleAlert(id: number): void {
        this.mileageDays[id].showMsg = !this.mileageDays[id].showMsg;
    }

    viewDailySummary(day: Date): void {
        const timeFrame = <TimeFrame>{
            start: moment(day).startOf('day'),
            end: moment(day).endOf('day'),
            unit: TimeFrameUnit.DAY
        };
        this.ebiEvents.postEvent('Weekly summary-view');
        this.timeframeService.updateTimeframeRules(timeFrame);
        this.router.navigateByUrl(`smiles/${moment(timeFrame.start).format('YYYYMMDD')}/day/dailySummary`);
    }

    logEbiEvent(): void {
        this.ebiEvents.postEvent('Weekly miles circle');
    }

    combineAlerts(mileageDays: Summary[]): void {
        for (let i = 1; i < mileageDays.length; i++) {
            const mileageDay = mileageDays[i];
            const mileageDayNext = mileageDays[i + 1];
            if (mileageDayNext !== undefined && (mileageDay.alert && mileageDayNext.alert)) {
                if (mileageDayNext !== undefined && mileageDayNext.alertType !== mileageDay.alertType) {
                    mileageDay.alertType = 'both';
                    mileageDayNext.alertType = 'both';
                }
            }
        }
    }

    setupAlerts(day: Summary): Summary {
        const allTrips = <RatedMileage[]> this.tfp.transform(this.mileage, day.ratedDateRange);
        let appliedRuleFlags = [];
        if (allTrips) {
            for (const trip of allTrips) {
                appliedRuleFlags = appliedRuleFlags.concat(trip.ruleApplied);
            }
        }
        if (day !== undefined) {
            if (appliedRuleFlags.length > 0) {
                this.checkAppliedRuleFlag(appliedRuleFlags, day);
            }
        }
        return day;
    }
    checkAppliedRuleFlag(appliedRuleFlags: string | any[], day: Summary): any {
        if (appliedRuleFlags.indexOf('CAPPED') !== -1) {
            day.alert = true;
            if (day.alertType === 'none') {
                day.alertType = 'rte';
            } else {
                day.alertType = 'both';
            }
            day.contentType = 'head';
        } else if (appliedRuleFlags.indexOf('UNVERIFIED') !== -1 || appliedRuleFlags.indexOf('NO DATA') !== -1) {
            day.alert = true;
            if (day.alertType === 'none') {
                day.alertType = 'noData';
            } else {
                day.alertType = 'both';
            }
            day.contentType = 'head';
        } else if (appliedRuleFlags.indexOf('GRACE') !== -1 && day.trips > 0 && day.miles === 0) {
            day.alert = true;
            day.alertType = 'grace';
        } else if (appliedRuleFlags.indexOf('OVERRIDDEN') !== -1) {
            day.alert = true;
            day.alertType = 'overridden';
        } else if (appliedRuleFlags.indexOf('NODATA') !== -1) {
            day.alert = true;
            day.alertType = 'null';
        }
    }

    setDayLabel(dayDate: Date, alert: string): string {
        let alertString = '';
        switch (alert) {
            case 'rte': alertString = 'Road Trip Exception. '; break;
            case 'noData': alertString = 'No Data. '; break;
            case 'both': alertString = 'Road Trip Exception and No Data. '; break;
        }
        return alertString + moment(dayDate).format('MMMM Do');
    }

    setButtonLabel(dayDate: Date): string {
        return `View Daily Summary for ${moment(dayDate).format('dddd MMMM Do')}`;
    }

    formatDate(d: Date): string {
        return `${d.getFullYear()}${this.padNumber(d.getMonth() + 1)}${this.padNumber(d.getDate())}`;
    }

    padNumber(num: number): string {
        return num.toString().length === 1 ? `0${num}` : num.toString();
    }

    shouldEnableDayDetails(day): boolean {
        if (day.beforeInterimEnrollment) {
            return false;
        }
        if (day.outsidePolicyTerm) {
            return false;
        }
        if (day.alertType === 'null') {
            return false;
        }
        if (day.isFutureDay) {
          return false;
        }
        return true;
    }
}
