import { VehicleResponse } from 'app/service/vehicle.service';
import { RatingFactorForDisplay } from 'app/components/sre-summary/sre-summary-content';
import { MonthlyWeeklyTotalSummary } from 'app/service/sre-summary.service';
import { FactorBreakdown } from 'app/components/factor-breakdown/factor-breakdown';

export class SreDetailsContent {
  vehicle: VehicleResponse;
  factor: RatingFactorForDisplay;
  summary: MonthlyWeeklyTotalSummary;
  projectedAnnualMileage: number;
  firstSummaryDate: string;
  lastSummaryDate: string;
  breakdown: FactorBreakdown;

  constructor(
  ) {
  }
}
