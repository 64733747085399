import { IncidentType } from 'app/enum/incidentType';

export class Incident {
  eventType: IncidentType;
  timestamp: Date; // Number of seconds since the trip began (when the incident occurred)

  constructor(eventType: string, timestamp: Date) {
    this.timestamp = timestamp;
    switch (eventType) {
      case 'braking':
        this.eventType = IncidentType.HARD_BRAKING;
        break;
      case 'acceleration':
        this.eventType = IncidentType.FAST_ACCELERATION;
        break;
      case 'nighttimeStart':
        this.eventType = IncidentType.NIGHT_DRIVING_START;
        break;
      case 'nighttimeEnd':
        this.eventType = IncidentType.NIGHT_DRIVING_END;
        break;
    }
  }

  describeIdleTime(seconds: number): string {
    const secondsInMinute = 60;
    const secondsInHour = 3600;
    const minutesInHour = 60;

    if (seconds <= 0) {
      return '(0 seconds)';
    }
    if (seconds < secondsInMinute) {
      return `(${seconds} seconds)`;
    }
    if (seconds < secondsInHour) {
      return `(${(seconds / secondsInMinute).toPrecision(2)} minutes)`;
    }
    const minutes = Math.floor(seconds / secondsInMinute);
    let hours = minutes / minutesInHour;
    if (hours < 10) {
      return `(${hours.toPrecision(2)} hours)`;
    }
    hours = Math.floor(hours);
    return `(${hours} hours)`;
  }
}
