/* eslint-disable max-params */
import { Component, OnDestroy, OnInit, Injectable } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Vehicle } from 'app/model/vehicle';
import { Summary } from 'app/model/summary';
import { Subscription, of } from 'rxjs';
import { TimeframeService } from 'app/service/timeframe.service';
import * as moment from 'moment';
import { TimeFrameUnit } from 'app/enum/timeframe';
import { TimeFrame } from 'app/interfaces/timeframe.interface';
import { EbiEventService } from 'app/service/ebi-event.service';
import { TripDataService } from 'app/service/tripSpeed-data-service';
import { Trip } from 'app/model/trip';
import { filter } from 'rxjs/operators';
import { TimeframePipe } from 'app/pipes/timeframe.pipe';
import { RatedMileageService } from 'app/service/rated-mileage.service';
import { Moment } from 'moment';
import { LoggerService } from 'app/service/logger.service';
import { TelematicsPaymentDetails } from 'app/model/telematicsPaymentDetails';
import { TelematicsTransactionsService } from 'app/service/telematics-transactions.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-monthly-details',
  templateUrl: './monthly-details.component.html',
  styleUrls: ['./monthly-details.component.css']
})

@Injectable()
export class MonthlyDetailsComponent implements OnInit, OnDestroy {
  vehicle: Vehicle;
  monthStartDate: Date;
  dateStart: Date;
  dateEnd: Date;
  totalMilesDriven = 0;
  weeks: Summary[];
  rows = [];
  weekRangeLabel: string;
  gracePeriod = false;
  private routerSubscription: Subscription;
  showVehicleNickName = false;
  currentMonth: boolean;
  sm2MilesDriven: number;
  mileageData: TelematicsPaymentDetails;
  estimatedPremiumStart: string;
  estimatedPremiumEnd: string;
  estimatedMiles: any;
  mileageDifference: any;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    public timeframeService: TimeframeService,
    public ebiEvents: EbiEventService,
    public mileage: RatedMileageService,
    public tripService: TripDataService,
    public tfp: TimeframePipe,
    public telematicTransactions: TelematicsTransactionsService,
    private logger: LoggerService
  ) { }

  setupWeeks = (): void => {
    const weeks: Summary[] = [];
    const reportStartDay = this.monthStartDate;
    let milesDrivenThisTimeframe = 0;

    const tripsObs = this.vehicle.trips && this.vehicle.trips.length > 0 ?
      of(this.vehicle.trips) :
      this.tripService.getTripData(
        this.vehicle.vin, moment(this.vehicle.smEnrollDate).format('YYYYMMDD'), moment(this.vehicle.periodEnd).format('YYYYMMDD')
      );
    tripsObs.subscribe((response) => {
      const daysInWeekMinusOne = 6;
      this.vehicle.trips = <Trip[]>response;
      let beginDate = moment(reportStartDay).startOf('week');
      let endDate = beginDate.clone().add(daysInWeekMinusOne, 'days').endOf('day');
      let weekCount = 1;
      let lastWeek = false;

      while (beginDate <= moment(this.dateEnd)) {
        let week: Summary = new Summary();
        const tf = TimeFrame.buildTimeframe(beginDate, endDate, TimeFrameUnit.WEEK);
        week.dateRange = tf;
        week.alert = false;
        week.alertType = 'none';
        week.contentType = 'head';
        week.partial = this.weekIsPartial(week, this.dateStart, this.dateEnd);
        week.beforeInterimEnrollment =
          week.dateRange.start.isBefore(this.vehicle.smEnrollDate) &&
          week.dateRange.end.isSameOrBefore(this.vehicle.smEnrollDate);

        if (weekCount === 1) {
          week.ratedDateRange = <TimeFrame>{
            start: moment(this.dateStart), end: endDate, unit: TimeFrameUnit.WEEK
          };
        } else if (lastWeek) {
          week.ratedDateRange = <TimeFrame>{
            start: beginDate, end: moment(this.dateEnd), unit: TimeFrameUnit.WEEK
          };
        } else {
          week.ratedDateRange = week.dateRange;
        }
        const tripsInTimeframe: Trip[] = <Trip[]> this.tfp.transform(this.vehicle.trips, week.ratedDateRange, 'minute');
        week.trips = tripsInTimeframe.length;
        week.miles = this.vehicle.calcMilesDrivenInTimeframe(week.ratedDateRange);
        if (week.miles > 0) {
          milesDrivenThisTimeframe += week.miles;
        }
        week.showMsg = false;
        week = this.setupAlerts(week);
        weeks.push(week);
        beginDate = endDate.clone().add(1, 'day').startOf('day');
        endDate = beginDate.clone().add(daysInWeekMinusOne, 'day');
        if (endDate > moment(this.dateEnd)) {
          lastWeek = true;
        }
        weekCount = weekCount + 1;
      }
      this.totalMilesDriven = milesDrivenThisTimeframe;
      this.weeks = this.combineAlerts(weeks);
    }, (err) => {
      this.logger.error(err);
    }, () => {
      this.identifyExceptionRowSequences();
    });
  };

  weekIsPartial = (week: Summary, startDt: Date, endDt: Date): boolean => {
    const startDateOutside = moment(week.dateRange.start).isBefore(startDt, 'day') && moment(week.dateRange.end).isBetween(startDt, endDt);
    const endDateOutside = moment(endDt).isBefore(week.dateRange.end, 'day') && moment(week.dateRange.start).isBetween(startDt, endDt);
    return startDateOutside || endDateOutside;
  };

  ngOnInit(): void {
    this.buildTimeFrame();
    this.routerSubscription = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd)
      ).subscribe(() => {
        this.buildTimeFrame();
      });
  }

  buildTimeFrame(): void {
    if (this.vehicle === undefined) {
      this.vehicle = this.route.snapshot.data.vehicle;
    }
    this.gracePeriod = this.mileage.determineIfGracePeriod(this.vehicle.ratedMileage);
    const newTimeframe = this.vehicle.getTermLimitsContainingMoment(moment(this.route.snapshot.paramMap.get('amount')));
    this.monthStartDate = newTimeframe.start.toDate();
    this.dateStart = moment.max(moment(this.monthStartDate), moment(this.vehicle.smEnrollDate)).toDate();
    this.dateEnd = newTimeframe.end.toDate();
    this.setupWeeks();
    this.calculateMonthlyMilesSM2(newTimeframe);
  }

  displayAlert(id: number): boolean {
    this.weeks[id].showMsg = !this.weeks[id].showMsg;
    return this.weeks[id].showMsg;
  }

  viewWeeklySummary(tf: TimeFrame): void {
    this.ebiEvents.postEvent('Monthly details-view');
    tf.unit = TimeFrameUnit.WEEK;
    this.router.navigateByUrl(`smiles/${moment(tf.start).format('YYYYMMDD')}/week/weeklySummary`);
    return;
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
      this.routerSubscription = null;
    }
  }

  combineAlerts(weeks: Summary[]): Summary[] {
    for (let i = 0; i < weeks.length - 1; i++) {
      const week = weeks[i];
      const weekNext = weeks[i + 1];
      if (weekNext !== undefined && (week.alert && weekNext.alert)) {
        if (weekNext !== undefined && weekNext.alertType !== week.alertType) {
          week.alertType = 'both';
          weekNext.alertType = 'both';
        }
      }
    }
    return weeks;
  }

  identifyExceptionRowSequences(): void {
    let previousWeek = null;
    for (let i = 0; i < this.weeks.length; i++) {
      const currentWeek = this.weeks[i];
      const nextWeek = i < this.weeks.length - 1 ? this.weeks[i + 1] : null;

      if (currentWeek.alert) {
        if (!previousWeek?.alert) {
          currentWeek.firstException = true;
        }
        if (nextWeek?.alert) {
          currentWeek.connected = true;
        } else {
          currentWeek.lastException = true;
        }
      }

      previousWeek = currentWeek;
    }
  }

  setupAlerts(week: Summary): Summary {
    week.alert = false;
    const allTrips = this.vehicle.getTripsInTimeframe(week.ratedDateRange);
    let ruleAppliedFlag = [];
    if (allTrips) {
      ruleAppliedFlag = allTrips.map((ratedMiles) =>
        ratedMiles.ruleApplied);
    }
    if (ruleAppliedFlag.length > 0) {
      for (const flag of ruleAppliedFlag) {
        const ruleApplied = flag;
        if (week.alertType === 'rte' && ruleApplied === 'UNVERIFIED' || ruleApplied === 'CAPPED' && week.alertType === 'noData') {
          week.alert = true;
          week.alertType = 'both';
          week.contentType = 'head';
        } else if (ruleApplied === 'CAPPED') {
          week.alert = true;
          week.alertType = 'rte';
          week.contentType = 'head';
        } else if (ruleApplied === 'UNVERIFIED') {
          week.alert = true;
          week.alertType = 'noData';
          week.contentType = 'head';
        } else if (ruleApplied === 'GRACE') {
            week.alert = true;
            week.alertType = 'grace';
        } else if (ruleApplied === 'OVERRIDDEN') {
            week.alert = true;
            week.alertType = 'overridden';
        } else if (ruleApplied === 'NODATA') {
            week.alert = true;
            week.alertType = 'null';
        }
      }
    }
    return week;
  }

  setWeekLabel(startDate: string, endDate: string, miles: string, trips: string): string {
    const startDateMoment = moment(startDate);
    const endDateMoment = moment(endDate);
    return `${startDateMoment.format('MMMM Do')} to ${
      endDateMoment.format('MMMM Do')} ${miles} miles ${
      trips} trips Click to go to Weekly Detail`; // TODO Is this right? Missing a period?
  }

  setDateLabel(startDate: Moment, endDate: Moment, alert: string, partial: boolean): string {
    let alertString = '';
    let partialWeekString = '';
    switch (alert) {
      case 'rte': alertString = 'Road Trip Exception.'; break;
      case 'both': alertString = 'Road Trip Exception and No Data Exception.'; break;
      case 'noData': alertString = 'No Data.'; break;
    }
    if (partial) {
      partialWeekString = ' Partial Week';
    }
    return `${startDate.format('MMMM Do')} to ${endDate.format('MMMM Do')}. ${alertString}${partialWeekString}`;
  }

  setButtonLabel(startDate: Moment, endDate: Moment): string {
    return `View Daily Summary for ${startDate.format('MMMM Do')} to ${endDate.format('MMMM Do')}`;
  }

  formatDate(d: Date): string {
    return `${d.getFullYear()}${this.padNumber(d.getMonth() + 1)}${this.padNumber(d.getDate())}`;
  }

  padNumber(num: number): string {
    return num.toString().length === 1 ? `0${num}` : num.toString();
  }

  shouldProvideEditLinkForNickname(): boolean {
    return !(this.vehicle?.contactPreferences && this.nicknameIsValid(this.vehicle.contactPreferences.nickname));
  }

  getLinkForEditNickname(): string {
    return `/preferences/smiles/${this.vehicle.vin}`;
  }

  private nicknameIsValid(nickname: string): boolean {
    if (!nickname) {
      return false;
    }
    return !!nickname.trim();
  }

  calculateMonthlyMilesSM2(timeframe): void {
    this.estimatedPremiumStart = timeframe.start.format('YYYY-MM-DD');
    this.estimatedPremiumEnd = timeframe.end.format('YYYY-MM-DD');
    this.mileageData = this.telematicTransactions.getTelematicsPaymentDetailsData(
        this.vehicle?.vehicleId, this.estimatedPremiumStart, this.vehicle.vehicleEnrollments
    );
    this.currentMonth = false;
    if (moment(this.estimatedPremiumEnd).isSameOrAfter(moment(environment.futureDate).format('YYYY-MM-DD'))) {
        this.currentMonth = true;
    }

    if (this.vehicle.paymentDetails?.length > 0) {
        this.sm2MilesDriven = this.vehicle.paymentDetails[0].actualMiles;
        this.estimatedMiles = this.vehicle.paymentDetails[0].estimatedMiles;
        this.mileageDifference = Math.abs(this.vehicle.paymentDetails[0].mileageDifference);
      } else if (this.mileageData) {
        this.sm2MilesDriven = this.mileageData.actualMiles;
        this.estimatedMiles = this.mileageData.estimatedMiles;
        this.mileageDifference = Math.abs(this.mileageData.mileageDifference);
      }
  }
}
