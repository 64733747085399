export class SmilesSREConstants {
  miles;
  braking;
  night;
  idle;
  acceleration;
  brakingAcceleration;
  eventsFactor;
  minutesFactor;
  milesFactor;
  milesDrivenInfo;
  nighttimeDrivingInfo;
  brakingInfo;
  accelerationInfo;
  idleInfo;
  brakingAccelerationInfo;
  milesDrivenTitle;
  nighttimeDrivingTitle;
  idletimeTitle;
  brakingTitle;
  brakingAccelerationTitle;
  nightTimeDrivingInfoTitle;
  accelerationInfoTitle;
  brakingInfoTitle;
  accelerationTitle;
  totalView;
  week;
  term;
  month;
  day;
}
