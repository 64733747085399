import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FactorBreakdown, FactorBreakdownRow } from './factor-breakdown';
import * as moment from 'moment';

@Component({
  selector: 'app-factor-breakdown',
  templateUrl: './factor-breakdown.component.html',
  styleUrls: ['./factor-breakdown.component.css']
})
export class FactorBreakdownComponent implements OnInit {
  @Input() content: FactorBreakdown;
  @Input() unit: string;
  @Output() select: EventEmitter<FactorBreakdownRow> = new EventEmitter<FactorBreakdownRow>();

  constructor() { }

  ngOnInit(): void { }

  selectRow(row: FactorBreakdownRow): void {
    if (!row.dataMissing) {
      this.select.emit(row);
    }
  }

  formatDateForDisplay(startDate: string, endDate: string): string {
    switch (this.content.rowPeriodUnit) {
      case 'month': return moment(endDate).format('MMMM');
      case 'week':
        if (moment(startDate).isSame(moment(endDate), 'day')) {
          return moment(startDate).format('MMM D');
        }
        return `${moment(startDate).format('MMM D')} \u2013 ${moment(endDate).format('MMM D')}`;
      case 'day': return `${moment(endDate).format('dd')[0]} \u2013 ${moment(endDate).format('MMM D')}`;
    }
    return `${startDate} - ${endDate}`;
  }

  formatAmountForDisplay(row: FactorBreakdownRow): string {
    let output = '';
    if (row.dataMissing || row.amount === undefined || row.amount === null || isNaN(row.amount) || row.amount < 0) {
      output += '\u2013\u2013';
    } else {
      output += row.amount.toFixed(0);
    }
    if (row.partial) {
      if (this.content.rowPeriodUnit !== 'day') {
        output += '*';
      }
    }
    return output;
  }

  valueForProgressBar(value: number, limit: number): number {
    if (!value) {
      return 0;
    }
    if (limit < 1) {
      return 0;
    }
    const progressBarValueFactor = 100;
    return value * progressBarValueFactor / limit;
  }

  getTextForPartialDataOrEmpty(): string {
    if (this.content.rowPeriodUnit === 'day') {
      return '';
    }
    for (const row of this.content.rows) {
      if (row.partial) {
        if (this.content.rowPeriodUnit === 'week') {
          return '* Partial Week';
        } else if (this.content.rowPeriodUnit === 'total') {
          return '* Partial Month';
        }
      }
    }
    return '';
  }
}
