import { Component, Input, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import * as d3 from 'd3';
import { Trip } from 'app/model/trip';
import { SreSummary } from 'app/service/sre-summary.service';
import { SpeedGraphService } from 'app/service/speed-graph.service';
import { WindowRef } from 'app/service/window-ref';

@Component({
  selector: 'app-speed-graph',
  templateUrl: './speed-graph.component.html',
  styleUrls: ['./speed-graph.component.css']
})

export class SpeedGraphComponent implements OnInit, OnDestroy {
  @Input() tripData: Trip;
  @Input() summaryData: SreSummary;
  @Input() keys: string[][];
  @Input() tripNumber: number;

  @ViewChild('speedGraphDrawArea', { static: false }) speedGraphDrawArea: ElementRef;

  readonly INSET_SHADOW_SIZE: number;
  readonly MAX_ZOOM: number;
  readonly MARGIN: any;
  readonly HEIGHT: number;
  window: any;

  margin: object;
  defaultWidth: number;
  width: number;
  mph: number[];
  defaultScale: number;
  svg: any;

  // D3 created variables, typed to 'any' (they're probably typed to es6 Function)
  x: any;
  y: any;
  xAxis: any;
  yAxis: any;
  line: any;
  zoom: any;

  onWindowResize = ((): any => {
    this.width = this.speedGraphDrawArea.nativeElement.offsetWidth - this.MARGIN.left - this.MARGIN.right;
    this.draw();
    // eslint-disable-next-line no-extra-bind
  }).bind(this);

  constructor(
    public speedGraph: SpeedGraphService,
    private windowRef: WindowRef
  ) {
    this.window = this.windowRef.nativeWindow;
    this.defaultScale = 1;
    this.INSET_SHADOW_SIZE = 16;
    this.MAX_ZOOM = 6;
    this.MARGIN = { top: 30, right: 0, bottom: 0, left: 40 };
    const maxHeight = 200;
    this.HEIGHT = maxHeight - this.MARGIN.top - this.MARGIN.bottom;
  }

  ngOnInit(): void {
    window.setTimeout(() => {
      this.initialize();
    }, 1);
  }

  ngOnDestroy(): void {
    this.window.removeEventListener('resize', this.onWindowResize);
    if (this.svg && this.svg.length > 0) {
      for (const graph of this.svg) {
        if (graph[0]?.remove) {
          graph[0].remove();
        }
      }
    }
  }

  getWidthFromDomAndMargins(): number {
    const defaultWidth = 800;
    if (!this.speedGraphDrawArea) {
      return defaultWidth;
    }
    let parentElement = this.speedGraphDrawArea.nativeElement.parentNode;
    if (!parentElement) {
      return defaultWidth;
    }
    parentElement = parentElement.parentNode;
    if (!parentElement) {
      return defaultWidth;
    }
    return parentElement.offsetWidth - this.MARGIN.left - this.MARGIN.right;
  }

  draw(): void {
    this.x.range([0, this.width]);
    this.xAxis.scale(this.x);
    this.yAxis.tickSize(this.width * -1);
    if (this.mph.length < this.width) {
      this.defaultScale = this.mph.length / this.width;
      this.zoom.scaleBy(this.svg, 1);
    } else {
      this.defaultScale = 1;
      this.zoom.scaleBy(this.svg, this.mph.length / this.width);
    }
    this.zoom.translateBy(this.svg, 0, 0);
    this.renderGraph(0);
    this.svg.select('g.y.axis').call(this.yAxis);
    this.svg.select('.paneRight').attr('x', this.width - this.INSET_SHADOW_SIZE);
  }

  zoomGraph(): void {
    const zoomedScale = d3.event.transform.rescaleX(this.x);
    this.xAxis.scale(zoomedScale);
    const zoomTrans = [0, 0];
    this.zoom.translateBy(this.svg);
    if (zoomTrans[0] >= 0) {
      zoomTrans[0] = 0;
    } else if (zoomTrans[0] <= this.defaultWidth * (1 - this.zoom.scale())) {
      zoomTrans[0] = this.defaultWidth * (1 - this.zoom.scale());
    }
    this.zoom.translateBy(this.svg, zoomTrans[0], zoomTrans[1]);
    this.renderGraph(zoomTrans[0]);
  }

  renderGraph(zoomX: number): void {
    this.speedGraph.drawArrows(this.svg, this.width, this.x(this.tripData.startTS.getTime()), this.x(this.tripData.endTS.getTime()));
    this.speedGraph.mapNighttime(this.svg, this.tripData, this.HEIGHT,
      this.zoom.scaleBy(this.svg) * this.width / (this.mph.length - 1), this.x);
    if (this.keys.map((keyList) => keyList[0]).indexOf('idle') >= 0) {
      this.speedGraph.mapIdleTime(this.svg, this.mph, this.zoom.scaleBy(this.svg) * this.width / this.mph.length, this.HEIGHT);
    }
    this.svg.selectAll('g.event').attr('transform', (d) =>
      `translate(${d * this.zoom.scale() * this.width / this.mph.length + zoomX * this.width / this.defaultWidth},${this.y(this.mph[d])})`
    );
    this.svg.selectAll('g.idle')
      .attr('transform', `translateBy(this.svg, ${zoomX * this.width / this.defaultWidth}, 0)`);

    this.svg.select('path.line').attr('d', this.line);
    this.svg.select('g.x.axis').call(this.xAxis);
    this.svg.selectAll('g.y.axis g.tick')
      .append('text')
      .attr('x', '-30')
      .attr('y', '10')
      .text('mph');
  }

  resizeRedraw(): void {
    this.speedGraph.drawInset(this.svg, this.width, this.HEIGHT, this.MARGIN, this.INSET_SHADOW_SIZE);
    this.speedGraph.drawXAxis(this.svg, this.xAxis, this.MARGIN);
    this.speedGraph.drawYAxis(this.svg, this.yAxis, this.MARGIN, this.HEIGHT);
    this.speedGraph.drawPane(this.svg, this.mph, this.HEIGHT, this.line);
    this.speedGraph.mapEvents(this.svg, this.tripData, 'acceleration');
    this.speedGraph.mapEvents(this.svg, this.tripData, 'braking');
    this.draw();
  }

  initialize(): void {
    const elem = document.getElementById('speed-graph');
    if (
      this.tripData?.speeds && !elem
    ) {
      this.window.addEventListener('resize', this.onWindowResize);

      this.defaultWidth = this.getWidthFromDomAndMargins();
      this.width = this.defaultWidth;
      this.mph = this.tripData.speeds;

      this.x = d3.scaleTime()
        .domain([this.tripData.startTS.getTime(), this.tripData.endTS.getTime()])
        .range([0, this.width]);

      const domainLeft = -20;
      const domainRight = 120;
      this.y = d3.scaleLinear()
        .range([this.HEIGHT, 0])
        .domain([domainLeft, domainRight]);

      this.xAxis = d3.axisBottom(this.x)
        .ticks(10)
        .tickSize(this.HEIGHT * -1)
        .tickPadding(10)
        .tickFormat(d3.timeFormat('%I:%M:%S %p'));

      const tickCount = 3;
      const tickPadding = 6;
      this.yAxis = d3.axisLeft(this.y)
        .ticks(tickCount)
        .tickSize(this.width * -1)
        .tickPadding(tickPadding);

      const xFactor = 1000;
      this.line = d3.line()
        .curve(d3.curveBasis)
        .x((_d, i) =>
          this.x(this.tripData.startTS.getTime() + i * xFactor))
        .y((d) =>
          this.y(d));

      this.zoom = d3.zoom()
        .scaleExtent([1, this.MAX_ZOOM])
        .on('zoom', this.zoomGraph.bind(this));

      this.svg = d3.select('#speed-graph-draw-area').append('svg')
        .attr('id', 'speed-graph')
        .attr('width', '100%')
        .attr('height', this.HEIGHT + this.MARGIN.top + this.MARGIN.bottom)
        .append('g')
        .attr('transform', `translate(${this.MARGIN.left},${this.MARGIN.top})`)
        .call(this.zoom);

      this.svg.append('defs');

      this.svg.append('rect')
        .attr('width', '100%')
        .attr('height', this.HEIGHT)
        .style('fill', 'transparent');

      this.svg.append('g').attr('class', 'nighttime');

      // eslint-disable-next-line no-magic-numbers
      const heightFactor = 20 / 120;
      this.svg.append('rect')
        .attr('class', 'idle')
        .attr('y', this.y('0'))
        .attr('width', '100%')
        .attr('height', this.y('0') * heightFactor);
      this.svg.append('g').attr('class', 'idle');

      this.resizeRedraw();
    }
  }
}
