import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { UserService } from 'app/service/user.service';
import { TelematicsPaymentDetails } from 'app/model/telematicsPaymentDetails';

/**
 * service call to telematics-transactions to get estimated payment details
 * TODO: Rename to EstimatedPaymentService
 */
@Injectable({
  providedIn: 'root'
})
export class TelematicsTransactionsService {
  // key for this is the policy number and the vin concatenated together (in that order)
  // this protects the use case of a user that is enrolled, then sells the car to another person who enrolls with a new policy

  constructor(
    public user: UserService ) {
  }

    getTelematicsPaymentDetailsData(vehicleId: any, startDate: string, vehicleEnrollments: any): any {
        let monthCount = 0;
        const paymentDetails = new TelematicsPaymentDetails();
        for (const vehicleData of vehicleEnrollments.vehicles) {
            if (Number(vehicleData.vehicleId) === vehicleId) {
                paymentDetails.annualMileage = vehicleData.annualMileage;
                paymentDetails.version = vehicleData.version;
                if (paymentDetails.version === '2.0') {
                    sessionStorage.setItem('SmartMiles2.0', 'true');
                } else {
                    sessionStorage.removeItem('SmartMiles2.0');
                }
                paymentDetails.typicalAnnualMileage =
                    vehicleData.typicalAnnualMileage;
                paymentDetails.currentTermMileage =
                    vehicleData.smartMilesMileageInfo.currentTermMileage;
                paymentDetails.estimatedTermMileage =
                    vehicleData.smartMilesMileageInfo.estimatedTermMileage;
                paymentDetails.ratedTermMileage =
                    vehicleData.smartMilesMileageInfo.ratedTermMileage;
                const monthLength =
                    vehicleData.smartMilesMileageInfo.monthlySummaries.length;

                // previous month mileage summaries
                const previousMonthSummaryStartDateTarget = moment(startDate).subtract(1, 'months').format('YYYY-MM-DD');
                let previousMonthActualDate;
                let isPreviousMonthActualDateAssigned = false;

                for (const mileageData of vehicleData.smartMilesMileageInfo.monthlySummaries) {
                    const currentIndexMonthSummaryStartDate = mileageData.startDate;
                    if (moment(currentIndexMonthSummaryStartDate).isSame(previousMonthSummaryStartDateTarget, 'month')) {
                        previousMonthActualDate = currentIndexMonthSummaryStartDate;
                        isPreviousMonthActualDateAssigned = true;
                    }
                    if (isPreviousMonthActualDateAssigned === true) {
                        paymentDetails.previousMonthMiles = mileageData.actualMiles;
                        paymentDetails.previousMonthNonWeightedCostPerMile = mileageData.unweightedCostPerMile; // data coming in as "unweightedCostPerMile". variable name subject to change
                        break;
                    }
                }

                if (isPreviousMonthActualDateAssigned === false) {
                    paymentDetails.previousMonthMiles = 0;
                    paymentDetails.previousMonthNonWeightedCostPerMile = 0;
                }
                // current month mileage summaries
                for (const mileageData of vehicleData.smartMilesMileageInfo
                    .monthlySummaries) {
                    // Current Term Date Progress Range
                    if (!paymentDetails.firstMonthStartDate) {
                        paymentDetails.firstMonthStartDate = mileageData.startDate;
                    }
                    monthCount++;
                    // current month mileage details
                    if (moment(mileageData.startDate).isSame(startDate, 'month')) {
                        paymentDetails.startDate = startDate;
                        paymentDetails.actualMiles = mileageData.actualMiles;
                        paymentDetails.estimatedMileagePremium =
                            mileageData.estimatedMileagePremium;
                        paymentDetails.actualTotalPremium =
                            mileageData.actualTotalPremium;
                        paymentDetails.estimatedMiles = mileageData.estimatedMiles;
                        paymentDetails.costPerMile = mileageData.costPerMile;
                        paymentDetails.nonWeightedCostPerMile = mileageData.unweightedCostPerMile; // data coming in as "unweightedCostPerMile". variable name subject to change
                        paymentDetails.mileagePremiumDifference =
                            mileageData.mileagePremiumDifference;
                        paymentDetails.mileageDifference =
                            mileageData.mileageDifference;
                        paymentDetails.reverifiedMiles =
                            mileageData.reverifiedMiles;
                        paymentDetails.monthlyBasePremium =
                            mileageData.monthlyBasePremium;
                        paymentDetails.actualMileagePremium =
                            mileageData.actualMileagePremium;
                        return paymentDetails;
                    } else if (monthCount === monthLength) {
                            return paymentDetails;
                    }
                }
                return paymentDetails;
            }
        }
    }
}
