import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sre-admin-reload',
  templateUrl: './sre-admin-reload.component.html',
  styleUrls: ['./sre-admin-reload.component.css']
})

export class SreAdminReloadComponent implements OnInit {
  constructor(public router: Router) { }

  ngOnInit(): void {
    this.router.navigateByUrl('/smartrideAdmin');
    return;
  }
}
