import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class WaitSpinnerService {
    waitMessageChanges$: Observable<string>;
    private _waitMessageChanges = new BehaviorSubject<string>('');

    constructor() {
        this.waitMessageChanges$ = this._waitMessageChanges.asObservable();
    }

    waitMessageUpdate(message: string): void {
        this._waitMessageChanges.next(message);
    }
}
