import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { DailyContent } from 'app/components/vehicle-summary/daily/daily-content';
import { Observable, of, forkJoin } from 'rxjs';
import { VehicleService } from 'app/service/vehicle.service';
import { MonthlyWeeklyTotalSummary, SreSummaryService } from 'app/service/sre-summary.service';
import { TripDataService } from 'app/service/tripSpeed-data-service';
import { UserService } from 'app/service/user.service';
import * as moment from 'moment';
import { map, mergeMap } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DailyContentResolver implements Resolve<DailyContent> {
  // eslint-disable-next-line max-params
  constructor(
    private vehicleService: VehicleService,
    private sreSummaryService: SreSummaryService,
    private tripDataService: TripDataService,
    private user: UserService,
    private router: Router
  ) { }

  resolve(route: ActivatedRouteSnapshot): Observable<DailyContent> {
    const vin: string = this.user.selectedVin;
    const programType: string = this.user.selectedVehicleProgramType;
    const date: string = route.params.date;
    const result: DailyContent = new DailyContent();
    if (!date) {
      this.router.navigateByUrl('not-found');
    }
    if (!vin) {
      this.router.navigateByUrl('programSelect');
    }
    if (vin === '' || date === '') {
      this.router.navigateByUrl('not-found');
    }
    if (date !== 'latest' && !moment(date).isValid()) {
      this.router.navigateByUrl('not-found');
    }
    result.date = date;
    result.firstDate = date;
    result.lastDate = date;
    const dateForTripsService = this.todayIfLatest(date);

    return this.vehicleService.getVehicleByVin(vin, programType).pipe(mergeMap((vehicleResponse) => {
      result.vehicle = vehicleResponse;
      const piidValue = result.vehicle.scoringModel === 'SM1' || result.vehicle.scoringModel === 'SM2' ? result.vehicle.vin : result.vehicle.vid;

      const tripsObservable = this.tripDataService.getTripData(piidValue, dateForTripsService, dateForTripsService);

      let summaryObservable;
      if (result.vehicle.scoringModel === 'SM1' || result.vehicle.scoringModel === 'SM2') {
        summaryObservable = of(new MonthlyWeeklyTotalSummary());
      } else {
        summaryObservable = this.sreSummaryService.fetchOneSummaryReport(
          vehicleResponse.vid, date, 'day', vehicleResponse.scoringModel, vehicleResponse.state, vehicleResponse.deviceStatusTimeline
        );
      }

      return forkJoin([tripsObservable, summaryObservable]).pipe(map((args: any[]) => {
        const tripsResponse = args[0];
        const summaryResponse = args[1];

        result.trips = tripsResponse;

        if (result.vehicle.scoringModel !== 'SM1' && result.vehicle.scoringModel !== 'SM2') {
          result.firstDate = this.sreSummaryService.getFirstCachedDateForPeriodUnit('day', vehicleResponse.vid);
          result.lastDate = this.sreSummaryService.getLastCachedDateForPeriodUnit('day', vehicleResponse.vid);
          result.date = summaryResponse['date'];
        }

        return result;
      }));
    }));
  }

  todayIfLatest(input: string): string {
    if (input === 'latest') {
      return moment(environment.futureDate).format('YYYYMMDD');
    }
    return input;
  }
}
