import { Injectable } from '@angular/core';

@Injectable()
export class PolicyNumberFormatService {
  static formatPolicyNumber(policyNumber: string): string {
    if (!policyNumber) {
      return '';
    }
    const policyPrefixLength = 5;
    const policySuffixLength = 6;
    const policyNumberWithoutSpacesLength = 11;
    policyNumber = policyNumber.replace(/\s/g, '');
    if (!policyNumber.charAt(policyPrefixLength).match('[A-Za-z]') && policyNumber.indexOf('%20') === -1 && policyNumber.length === policyNumberWithoutSpacesLength) {
      policyNumber = `${policyNumber.substr(0, policyPrefixLength)} ${policyNumber.substr(policyPrefixLength, policySuffixLength)}`;
    } else if (policyNumber.indexOf('%20') !== -1) {
      policyNumber = policyNumber.replace('%20', ' ');
    }
    return policyNumber;
  }

  static safeUriEncodePolicyNumber(policyNumber: string): string {
    return policyNumber ? policyNumber.trim().replace(/ /g, '%20') : policyNumber;
  }
}
