import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { APICommonService } from '@nationwide/api-common-service';
import { DriverDetails } from 'app/model/driver-details';
import { Observable, catchError, map, of } from 'rxjs';
import { ServiceErrorHandler } from 'app/utility/service-error-handler';
import { LoggerService } from 'app/service/logger.service';
@Injectable({
    providedIn: 'root'
})
export class DriverDetailsService {
    // eslint-disable-next-line max-params
    constructor(public http: HttpClient,
        private apiCommonService: APICommonService,
        public _errorHandler: ServiceErrorHandler,
        private logger: LoggerService) { }

    getSrmUserDetails(accountId: string): Observable<DriverDetails> {
        const headers = this.getHeaders();
        const url = `${environment.srmUserDetailsApiUrl.url}/get_users_and_vehicles`;
        const request = this.getRequestBodyData(accountId);

        return this.http.post(url, request, { headers })
            .pipe(
                map((res) => res),
                catchError((error) => {
                    this._errorHandler.handleError('srmUserDetails');
                    return of(error);
                })
            );
    }

    getRequestBodyData(accountId): any {
        const data = {
            users: [
                {
                    account_id: accountId
                }
            ]
        };
        return data;
    }

    getHeaders(): HttpHeaders {
        let headers = new HttpHeaders();
        headers = headers.append('client_id', environment.apiKey);
        headers = headers.append('X-NW-Message-ID', this.apiCommonService.generateTransactionId());
        return headers;
    }

    updateDriverMobileNumber(updatedData: DriverDetails, upDatedMobile: string): Observable<DriverDetails> {
        const UpdateUrl = `${environment.srmUserDetailsApiUrl.url}/update_users_and_vehicles`;

        const headers = this.getHeaders();

        const requestBody = {
            users: [
                {
                    account_id: updatedData.account_id,
                    discount_applied_date: updatedData.discount_applied_date,
                    mobile: upDatedMobile, /* upDatedMobile*/
                    mobile_sms: upDatedMobile, /* upDatedMobile*/
                    freeze_score_date: updatedData.freeze_score_date,
                    first_name: updatedData.first_name,
                    last_name: updatedData.last_name,
                    program_id: updatedData.program_id,
                    group_id: updatedData.group_id
                }
            ]
        };

        return this.http.post(UpdateUrl, requestBody, { headers })
            .pipe(
                map((res) => res),
                catchError((error) => {
                    this._errorHandler.handleError('upDateDriverPhoneNumber');
                    this.logger.error('SRM Update api failed with error', {
                        error
                    });
                    return of(error);
                })
            );
    }
}
