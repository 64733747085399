import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, forkJoin, of, pipe } from 'rxjs';
import { catchError, map, tap, flatMap } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { DtoMapperService } from 'app/service/dto-mapper.service';
import { ServiceErrorHandler } from 'app/utility/service-error-handler';
import { PersonalLinesPolicy } from 'app/model/rest/personal-lines-policy';
import { APICommonService } from '@nationwide/api-common-service';

@Injectable()
export class PersonalLinesPolicyService {
    policies = new Map<string, PersonalLinesPolicy>();

    // eslint-disable-next-line max-params
    constructor(
        public http: HttpClient,
        public errorHandler: ServiceErrorHandler,
        private apiCommonService: APICommonService
    ) { }

    getPolicyData(policyNumber: string): Observable<any> {
        const programEnrollments = 3;
        if (this.policies.has(policyNumber)) {
            return of(this.policies.get(policyNumber));
        } else {
            const url = `${environment.personalLinesPolicyService.url}/policies/${policyNumber}`;
            const options = this.getOptions();
            if (environment.useValidatorService && sessionStorage.getItem('useValidator') === 'true') {
                return this.http.get(url, options)
                    .pipe(
                        flatMap((response: any) =>
                            forkJoin({
                                policyResponse: of(response),
                                policyInfo: this.retrieveInfoByPolicy(policyNumber, response)
                            })),
                        map(({ policyResponse, policyInfo }) =>
                            DtoMapperService.personalLinesPolicyFromServiceResponse(
                                policyResponse,
                                policyInfo[0],
                                policyInfo[1],
                                policyInfo[2],
                                JSON.parse(policyInfo[programEnrollments]['cachedResponses'][0]['payload'])
                            )),
                        tap((policy) => {
 this.policies.set(policyNumber, policy);
}),
                        pipe(catchError(this.errorHandler.handleError('PersonalLinesPolicyService')))
                    );
            } else {
                return this.http.get(url, options)
                    .pipe(
                        flatMap((response: any) =>
                            forkJoin({
                                policyResponse: of(response),
                                policyInfo: this.retrieveInfoByPolicy(policyNumber, response)
                            })),
                        map(({ policyResponse, policyInfo }) =>
                            DtoMapperService.personalLinesPolicyFromServiceResponse(
                                policyResponse,
                                policyInfo[0],
                                policyInfo[1],
                                policyInfo[2],
                                policyInfo[programEnrollments]
                            )),
                        tap((policy) => {
 this.policies.set(policyNumber, policy);
}),
                        pipe(catchError(this.errorHandler.handleError('PersonalLinesPolicyService')))
                    );
            }
        }
    }

    retrieveInfoByPolicy(policy, response: any): Observable<any> {
        const url: string = environment.personalLinesPolicyService.url;
        const options = this.getOptions();

        const driversURL = this.http.get(`${url}${response.drivers.href}`, options);
        const discountsURL = this.http.get(`${url}${response.discounts.href}`, options);
        const vehiclesURL = this.http.get(`${url}${response.vehicles.href}`, options);
        let programEnrollmentsURL;
        if (environment.useValidatorService && sessionStorage.getItem('useValidator') === 'true') {
            const validatorURL = `${environment.validatorUrl}${policy}/retrieve`;
            const validatorParams = {
                serviceName: 'AutoPolicies_1',
                applicationName: 'DGS-ISU-SMILES',
                endpoint: 'https://api-test.nwie.net/policy-management/auto-policies/v1/policies/'
            };
            programEnrollmentsURL = this.http.post(validatorURL, validatorParams, options);
        } else {
            programEnrollmentsURL = this.http.get(`${url}/policies/${policy}/telematics-enrollments?effectiveDate=${response.effectiveDate}&includeMileageData=true`, options);
        }
        return forkJoin([driversURL, discountsURL, vehiclesURL, programEnrollmentsURL]);
    }

    clearCache(): void {
        this.policies = new Map<string, PersonalLinesPolicy>();
    }

    getOptions(): any {
        let requestHeaders = {
            headers: new HttpHeaders({
                // eslint-disable-next-line camelcase
                client_id: environment.apiKey,
                'X-NW-Message-ID': this.apiCommonService.generateTransactionId(),
                'X-NW-Target-Env': environment.personalLinesPolicyService.xNwTargetEnv
            })
        };
        if (environment.production) {
            requestHeaders = {
                headers: new HttpHeaders({
                    // eslint-disable-next-line camelcase
                    client_id: environment.apiKey,
                    'X-NW-Message-ID': this.apiCommonService.generateTransactionId()
                })
            };
        }
        return requestHeaders;
    }
}
