/* eslint-disable @typescript-eslint/naming-convention */
import { AddressDTO } from './addressDTO';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const DriverDTO = {
    IsNewlyAddedDriver: {
        name: 'IsNewlyAddedDriver',
        type: 'Boolean',
        required: true
    },
    IsFinancialFilingReq: {
        name: 'IsFinancialFilingReq',
        type: 'Boolean',
        required: true,
        visibilityCondition: '\'Driver\' === form.value[\'DriverType\']'
    },
    EmploymentAddress: {
        name: 'EmploymentAddress',
        class: 'com.nationwide.edge.capabilities.address.dto.NWAddressDTO',
        required: false,
        ...AddressDTO
    },
    isDomesticHelp: {
        name: 'isDomesticHelp',
        type: 'Boolean',
        required: true,
        visibilityCondition: '\'Employee\' === form.value[\'RelationToPNI\']'
    },
    GroupOccupation: {
        name: 'GroupOccupation',
        class: 'typekey.OccupationGroup_Ext',
        required: true,
        visibilityCondition: '(\'PrimaryNamedInsured\' === form.value[\'RelationToPNI\'] || \'Spouse\' === form.value[\'RelationToPNI\'])'
    },
    LicenseNumber: {
        name: 'LicenseNumber',
        type: 'String',
        required: true,
        visibilityCondition: '(\'Driver\' === form.value[\'DriverType\'] && \'false\' === form.value[\'IsInternationalLicense\'] && !(\'14\' > form.value[\'Age\']))'
    },
    Infractions: {
        name: 'Infractions',
        class: 'com.nationwide.edge.capabilities.policychange.lob.personalauto.draft.dto.NWInfractionDTO',
        InfractionType: {
            name: 'InfractionType',
            class: 'typekey.InfractionType_Ext',
            required: false
        },
        InfractionSource: {
            name: 'InfractionSource',
            class: 'typekey.InfractionSource_Ext',
            required: false
        },
        PrayerForJudgement: {
            name: 'PrayerForJudgement',
            type: 'Boolean',
            required: false
        },
        PublicID: {
            name: 'PublicID',
            type: 'String',
            required: false
        },
        InfractionDesc: {
            name: 'InfractionDesc',
            class: 'typekey.InfractionSubType_Ext',
            required: false
        },
        ForgiveOrWaiveIndicator: {
            name: 'ForgiveOrWaiveIndicator',
            class: 'typekey.ForgiveWaiveIndicator_Ext',
            required: false
        },
        OccurrenceDate: {
            name: 'OccurrenceDate',
            type: 'Date',
            required: false
        },
        FixedId: {
            name: 'FixedId',
            type: 'Long',
            required: false
        },
        required: false
    },
    FinResDetails: {
        name: 'FinResDetails',
        class: 'com.nationwide.edge.capabilities.policychange.lob.personalauto.draft.dto.NWFinResDetails',
        FinResCaseNum: {
            name: 'FinResCaseNum',
            type: 'String',
            required: false,
            maxLength: 10
        },
        FinResFilingState: {
            name: 'FinResFilingState',
            class: 'typekey.Jurisdiction',
            required: true
        },
        PublicID: {
            name: 'PublicID',
            type: 'String',
            required: true,
            visibilityCondition: '\'\' === form.value[\'TempID\']'
        },
        FinResFilingType: {
            name: 'FinResFilingType',
            class: 'typekey.FinResType_Ext',
            required: true
        },
        TempID: {
            name: 'TempID',
            type: 'Integer',
            required: true,
            visibilityCondition: '\'\' === form.value[\'PublicID\']'
        },
        FixedId: {
            name: 'FixedId',
            type: 'Long',
            required: true,
            visibilityCondition: '\'\' === form.value[\'TempID\']'
        },
        required: false
    },
    TempID: {
        name: 'TempID',
        type: 'Integer',
        required: true,
        visibilityCondition: '\'\' === form.value[\'PublicID\']'
    },
    FixedId: {
        name: 'FixedId',
        type: 'Long',
        required: true,
        visibilityCondition: '\'\' === form.value[\'TempID\']'
    },
    ReasonNonDriver: {
        name: 'ReasonNonDriver',
        class: 'typekey.NonDriverReason_Ext',
        required: true,
        visibilityCondition: '(\'NonDriver\' === form.value[\'DriverType\'] || \'Excluded\' === form.value[\'DriverType\'])'
    },
    IsRatedOnOtherNWPolicy: {
        name: 'IsRatedOnOtherNWPolicy',
        type: 'Boolean',
        required: false
    },
    PrincipalDriver: {
        name: 'PrincipalDriver',
        type: 'Boolean',
        required: true,
        visibilityCondition: '\'Driver\' === form.value[\'DriverType\']'
    },
    YearsLicensed: {
        name: 'YearsLicensed',
        type: 'Integer',
        required: false
    },
    Updated: {
        name: 'Updated',
        type: 'Boolean',
        required: true,
        minAmount: true,
        maxAmount: true
    },
    Modifiers: {
        name: 'Modifiers',
        class: 'com.nationwide.edge.capabilities.policychange.lob.personalauto.draft.dto.NWModifierDTO',
        AntiTheftDevices: {
            name: 'AntiTheftDevices',
            class: 'com.nationwide.edge.capabilities.policychange.lob.personalauto.draft.dto.NWAntiTheftDeviceDTO',
            DeviceType: {
                name: 'DeviceType',
                class: 'typekey.AntiTheftType',
                required: true
            },
            Mandatory: {
                name: 'Mandatory',
                type: 'Boolean',
                required: false
            },
            Selected: {
                name: 'Selected',
                type: 'Boolean',
                required: true
            },
            DisplayName: {
                name: 'DisplayName',
                type: 'String',
                required: false
            },
            required: true,
            visibilityCondition: '\'PAAntiTheftMod_Ext\' === form.value[\'Code\']'
        },
        RefCode: {
            name: 'RefCode',
            type: 'String',
            required: false
        },
        SmartRide: {
            name: 'SmartRide',
            class: 'com.nationwide.edge.capabilities.policychange.lob.personalauto.draft.dto.NWSmartRideDTO',
            HasCustomerAgreedToInstall: {
                name: 'HasCustomerAgreedToInstall',
                type: 'Boolean',
                required: false
            },
            EnrollDate: {
                name: 'EnrollDate',
                type: 'Date',
                required: false
            },
            IsDeviceCompatible: {
                name: 'IsDeviceCompatible',
                type: 'Boolean',
                required: false
            },
            DeviceStatus: {
                name: 'DeviceStatus',
                class: 'typekey.SmartRideDeviceStatus_Ext',
                required: false
            },
            EnrollStatus: {
                name: 'EnrollStatus',
                class: 'typekey.SmartRideEnrollStatus_Ext',
                required: false
            },
            InstallDate: {
                name: 'InstallDate',
                type: 'Date',
                required: false
            },
            AnnualMileage: {
                name: 'AnnualMileage',
                type: 'Integer',
                required: false
            },
            RatedScoreModel: {
                name: 'RatedScoreModel',
                type: 'String',
                required: false
            },
            PendingScore: {
                name: 'PendingScore',
                type: 'Integer',
                required: false
            },
            RatedScore: {
                name: 'RatedScore',
                type: 'Integer',
                required: false
            },
            IssueANewDevice: {
                name: 'IssueANewDevice',
                type: 'Boolean',
                required: false
            },
            required: false
        },
        AdvancedTrainingCourseCompletionDate: {
            name: 'AdvancedTrainingCourseCompletionDate',
            type: 'Date',
            required: true,
            visibilityCondition: '(\'PAAdvancedTrainedDriverDiscount_Ext\' === form.value[\'Code\'] && \'true\' === form.value[\'BooleanModifier\'])'
        },
        PublicID: {
            name: 'PublicID',
            type: 'String',
            required: false
        },
        Priority: {
            name: 'Priority',
            type: 'Integer',
            required: false
        },
        DateModifier: {
            name: 'DateModifier',
            type: 'Date',
            required: false
        },
        Name: {
            name: 'Name',
            type: 'String',
            required: false
        },
        Code: {
            name: 'Code',
            type: 'String',
            required: true
        },
        DocdelPreference: {
            name: 'DocdelPreference',
            class: 'typekey.DocumentDeliveryType_Ext',
            required: true,
            visibilityCondition: '(\'PAPaperlessPolicyDiscount_Ext\' === form.value[\'Code\'] && \'true\' === form.value[\'BooleanModifier\'])'
        },
        TypeKeys: {
            name: 'TypeKeys',
            class: 'com.nationwide.edge.capabilities.policychange.dto.NWTypeKeyDTO',
            Description: {
                name: 'Description',
                type: 'String',
                required: false
            },
            Code: {
                name: 'Code',
                type: 'String',
                required: true
            },
            required: false
        },
        FixedId: {
            name: 'FixedId',
            type: 'Long',
            required: false
        },
        State: {
            name: 'State',
            class: 'typekey.Jurisdiction',
            required: false
        },
        SmartMiles: {
            name: 'SmartMiles',
            class: 'com.nationwide.edge.capabilities.policychange.lob.personalauto.draft.dto.NWSmartRideDTO',
            HasCustomerAgreedToInstall: {
                name: 'HasCustomerAgreedToInstall',
                type: 'Boolean',
                required: false
            },
            EnrollDate: {
                name: 'EnrollDate',
                type: 'Date',
                required: false
            },
            IsDeviceCompatible: {
                name: 'IsDeviceCompatible',
                type: 'Boolean',
                required: false
            },
            DeviceStatus: {
                name: 'DeviceStatus',
                class: 'typekey.SmartRideDeviceStatus_Ext',
                required: false
            },
            EnrollStatus: {
                name: 'EnrollStatus',
                class: 'typekey.SmartRideEnrollStatus_Ext',
                required: false
            },
            InstallDate: {
                name: 'InstallDate',
                type: 'Date',
                required: false
            },
            AnnualMileage: {
                name: 'AnnualMileage',
                type: 'Integer',
                required: false
            },
            RatedScoreModel: {
                name: 'RatedScoreModel',
                type: 'String',
                required: false
            },
            PendingScore: {
                name: 'PendingScore',
                type: 'Integer',
                required: false
            },
            RatedScore: {
                name: 'RatedScore',
                type: 'Integer',
                required: false
            },
            IssueANewDevice: {
                name: 'IssueANewDevice',
                type: 'Boolean',
                required: false
            },
            required: false
        },
        RateModifier: {
            name: 'RateModifier',
            type: 'BigDecimal',
            required: false
        },
        EmailAddress: {
            name: 'EmailAddress',
            type: 'String',
            required: false
        },
        TypeList: {
            name: 'TypeList',
            type: 'String',
            required: false
        },
        ModifierDataType: {
            name: 'ModifierDataType',
            class: 'typekey.ModifierDataType',
            required: false
        },
        Description: {
            name: 'Description',
            type: 'String',
            required: false
        },
        TypekeyModifier: {
            name: 'TypekeyModifier',
            type: 'String',
            required: false,
            hashMap: {
                10: 'Firefighter',
                11: 'LawEnforcementOfficer',
                12: 'LicensedPharmacist',
                13: 'NationwideEmployeeAgent',
                14: 'Paramedic',
                15: 'PhysicianRegisteredNurse',
                16: 'Pilot',
                17: 'ScientistOrEngineer',
                18: 'Veterinarian',
                19: 'Accounting',
                20: 'AirlinePilot',
                21: 'Alumni',
                22: 'Amway',
                23: 'CarClub',
                24: 'CivilServant',
                25: 'CommunityCivic',
                26: 'CountryClub',
                27: 'CreditUnionMember',
                28: 'Dental',
                29: 'Education',
                30: 'Engineering',
                31: 'Farming',
                32: 'Insurance',
                33: 'Medical',
                34: 'Motorcycle',
                35: 'NationalCosmetology',
                36: 'NWMutualInsuranceEmployee',
                37: 'PetIndustryJointAdversaryCouncil',
                38: 'PharmacistPharmaceuticalResearcher',
                39: 'RecVehicle',
                40: 'SeniorCitizenRetirement',
                41: 'StateBarOfMichigan',
                42: 'WholesaleMember',
                43: 'YachtClubBoatowner',
                '01': 'AirBag_Ext',
                '02': 'DriverSideAirBags_Ext',
                '03': 'PassiveSeatBelt_Ext',
                '04': 'AllOther',
                '05': 'AccreditedCollegeAlumni',
                '06': 'Accountant',
                '07': 'BusinessOwner',
                '08': 'Dentist',
                '09': 'Educator'
            }
        },
        TrainingCourseCompletionDate: {
            name: 'TrainingCourseCompletionDate',
            type: 'Date',
            required: true,
            visibilityCondition: '(\'PATrainedDriverDiscount_Ext\' === form.value[\'Code\'] && \'true\' === form.value[\'BooleanModifier\'])'
        },
        BooleanModifier: {
            name: 'BooleanModifier',
            type: 'Boolean',
            required: false
        },
        required: false
    },
    IsGraduatedFromCllg: {
        name: 'IsGraduatedFromCllg',
        type: 'Boolean',
        required: false
    },
    LicenseStatusOverride: {
        name: 'LicenseStatusOverride',
        class: 'typekey.LcnseStatusOverride_Ext',
        required: false
    },
    DriverType: {
        name: 'DriverType',
        class: 'typekey.DriverType_Ext',
        required: true
    },
    IsLicenseInSameStateForThreeYrsMandatory: {
        name: 'IsLicenseInSameStateForThreeYrsMandatory',
        type: 'Boolean',
        required: true,
        visibilityCondition: '(\'false\' === form.value[\'IsInternationalLicense\'] && \'14\' < form.value[\'Age\'] && \'true\' === form.value[\'IsNewlyAddedDriver\'])'
    },
    PublicID: {
        name: 'PublicID',
        type: 'String',
        required: true,
        visibilityCondition: '\'\' === form.value[\'TempID\']'
    },
    CurrentEmployment: {
        name: 'CurrentEmployment',
        class: 'typekey.EmploymentStatusType',
        required: true,
        visibilityCondition: '\'Driver\' === form.value[\'DriverType\']'
    },
    DateFirstLicensed: {
        name: 'DateFirstLicensed',
        type: 'Date',
        required: true,
        visibilityCondition: '(\'Driver\' === form.value[\'DriverType\'] && !(\'14\' > form.value[\'Age\']))'
    },
    IsLvngWthParentNWInsd: {
        name: 'IsLvngWthParentNWInsd',
        type: 'Boolean',
        required: false
    },
    FullTimeEmployment: {
        name: 'FullTimeEmployment',
        class: 'typekey.DurationAtWorkLocat_Ext',
        required: true,
        visibilityCondition: '\'Driver\' === form.value[\'DriverType\']'
    },
    AppliedSurcharges: {
        name: 'AppliedSurcharges',
        class: 'com.nationwide.edge.capabilities.policychange.lob.personalauto.draft.dto.NWAppliedModifierDTO',
        Description: {
            name: 'Description',
            type: 'String',
            required: false
        },
        required: false
    },
    LicenseState: {
        name: 'LicenseState',
        class: 'typekey.State',
        required: true,
        visibilityCondition: '(\'Driver\' === form.value[\'DriverType\'] && \'false\' === form.value[\'IsInternationalLicense\'] && !(\'14\' > form.value[\'Age\']))'
    },
    LicenseStatus: {
        name: 'LicenseStatus',
        class: 'typekey.LicenseStatus_Ext',
        required: false
    },
    AgeFirstLicensed: {
        name: 'AgeFirstLicensed',
        type: 'Integer',
        required: false
    },
    RelationToPNI: {
        name: 'RelationToPNI',
        class: 'typekey.RelationToPNI_Ext',
        required: true,
        filterCategories: [
            'PersonalAutoLine',
            'baseState'
        ]
    },
    Person: {
        name: 'Person',
        class: 'com.nationwide.edge.capabilities.quote.person.dto.NWPersonDTO',
        MiddleName: {
            name: 'MiddleName',
            type: 'String',
            required: false,
            maxLength: 30,
            minLength: 0
        },
        Gender: {
            name: 'Gender',
            class: 'typekey.GenderType',
            required: true,
            visibilityCondition: '\'true\' !== form.value[\'IsNonSpecifiedGender\']'
        },
        Suffix: {
            name: 'Suffix',
            class: 'typekey.NameSuffix',
            required: false
        },
        LastName: {
            name: 'LastName',
            type: 'String',
            required: true,
            maxLength: 30,
            minLength: 1
        },
        Prefix: {
            name: 'Prefix',
            class: 'typekey.NamePrefix',
            required: false
        },
        MaritalStatus: {
            name: 'MaritalStatus',
            class: 'typekey.MaritalStatus',
            required: true,
            visibilityCondition: '\'Driver\' === form.value[\'DriverType\']'
        },
        DateOfBirth: {
            name: 'DateOfBirth',
            type: 'Date',
            required: true
        },
        FirstName: {
            name: 'FirstName',
            type: 'String',
            required: true,
            maxLength: 30,
            minLength: 1
        },
        Ssn: {
            name: 'Ssn',
            type: 'String',
            required: false,
            regEx: '[0-9]{3}-[0-9]{2}-[0-9]{4}'
        },
        IsNonSpecifiedGender: {
            name: 'IsNonSpecifiedGender',
            type: 'boolean',
            required: false
        },
        Age: {
            name: 'Age',
            type: 'Integer',
            required: false
        },
        required: false
    },
    AppliedDiscounts: {
        name: 'AppliedDiscounts',
        class: 'com.nationwide.edge.capabilities.policychange.lob.personalauto.draft.dto.NWAppliedModifierDTO',
        Description: {
            name: 'Description',
            type: 'String',
            required: false
        },
        required: false
    },
    HealthCarePlan: {
        name: 'HealthCarePlan',
        class: 'typekey.HealthCarePlan_Ext',
        required: true,
        visibilityCondition: '((\'PrimaryNamedInsured\' === form.value[\'RelationToPNI\'] || \'Spouse\' === form.value[\'RelationToPNI\'] || \'Child\' === form.value[\'RelationToPNI\'] || \'OtherRelative\' === form.value[\'RelationToPNI\']))'
    },
    NWDriverNumber: {
        name: 'NWDriverNumber',
        type: 'Integer',
        required: false
    },
    IsInternationalLicense: {
        name: 'IsInternationalLicense',
        type: 'Boolean',
        required: true,
        visibilityCondition: '!(\'14\' > form.value[\'Age\'])'
    }
};
